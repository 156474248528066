// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Svelte Plugin
import Framework7Svelte from 'framework7-svelte';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';
import '../css/tailwind.less';
import '../../node_modules/tui-image-editor/dist/tui-image-editor.min.css';
import '../../node_modules/tui-color-picker/dist/tui-color-picker.min.css';

// Import App Component
import App from '../components/App.svelte';

// Init F7 Svelte Plugin
Framework7.use(Framework7Svelte);

// Mount Svelte App
const app = new App({
  target: document.getElementById('app'),
});
