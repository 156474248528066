<script>
  import { Page, Navbar, Preloader } from 'framework7-svelte';
  import { isArray, isEmpty } from 'lodash-es';
  import InfiniteLoading from '../components/InfiniteLoading.svelte';
  import ContactListContent from '../components/ContactListContent.svelte';
  import {
    closeSecondaryView,
    remoteCompositeSearch,
    selectedDesk,
    storeUpdateIfDifferent,
    selectedDeskContactFilter,
  } from '../js/store';
  import SearchBar from '../components/SearchBar.svelte';
  import { searchDebounceMs, advancedSearchFullSize } from '../js/config';
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';

  export let props = {};
  const {
    // Remote search result type.
    type,
    // Value will be bounded to searchbar.
    searchTermStore,
    // Target contactId to limit search range.
    contactId,
    // Page title
    title,
    // Show search result title.
    showTitle,
    // Show search result counter.
    showCounter,
    closeSecondaryViewOnBack,
    // Result on click handler.
    onClick,
  } = props;
  let { searchResultStore } = props;

  let searchBarHeight = 0;

  let lastSearchId = 0;
  let initialSearchComplete = false;
  let searchCount = 0;

  if (!searchResultStore) {
    searchResultStore = writable([]);
  }
  if (isEmpty($searchResultStore) || !isArray($searchResultStore)) {
    $searchResultStore = [];
  }

  function remoteSearch(term) {
    const searchId = Date.now();
    initialSearchComplete = false;

    // Clear chat list on search term changes so cursor will always start from top.
    $searchResultStore = [];
    searchCount = 0;

    if (term) {
      if (searchId > lastSearchId) {
        lastSearchId = searchId;
      }
      remoteCompositeSearch({
        deskId: $selectedDesk._id,
        field: { [type]: { term, size: advancedSearchFullSize, contactId } },
        filter: $selectedDeskContactFilter,
      }).then((result) => {
        // Check whether the searched term had changed, only show result if not changed.
        if ($searchTermStore) {
          if (lastSearchId === searchId) {
            $searchResultStore = result[type];
            searchCount = result.count[type];
            initialSearchComplete = true;
          }
        } else {
          $searchResultStore = [];
          searchCount = 0;
        }
      });
    }
  }

  onMount(() => {
    const unsubscribeFnList = [];

    // Trigger search again whenever search term or filter is modified.
    unsubscribeFnList.push(
      searchTermStore.subscribe((term) => {
        remoteSearch(term);
      })
    );
    unsubscribeFnList.push(
      selectedDeskContactFilter.subscribe(() => {
        remoteSearch($searchTermStore);
      })
    );

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });
</script>

<Page>
  <Navbar
    title="{title || ''} {showCounter && searchCount ? `(${searchCount})` : ''}"
    backLink
    on:clickBack="{() => {
      if (closeSecondaryViewOnBack) {
        closeSecondaryView();
      }
    }}"
  />

  <div class="h-full overflow-auto">
    <SearchBar
      bind:searchBarHeight
      placeholder="Search"
      debounceMs="{searchDebounceMs}"
      focusOnMount="{isEmpty($searchTermStore)}"
      initialValue="{$searchTermStore || ''}"
      on:search="{({ detail }) => {
        storeUpdateIfDifferent(searchTermStore, detail.query);
      }}"
    />

    <!-- Dynamically recalculate remaining available height -->
    <!-- Additional -1rem for internal padding of search bar, the rounded style -->
    <div
      class="list media-list no-hairlines h-full overflow-auto overscroll-y-contain"
      style="height: calc(100% - {searchBarHeight}px - 1rem);"
    >
      <ul>
        <ContactListContent
          type="{type}"
          itemList="{$searchResultStore}"
          showTitle="{showTitle}"
          on:click="{(e) => {
            if (onClick && typeof onClick === 'function') {
              onClick(e.detail);
            }
          }}"
        />

        <!-- Draw placeholder loading icon on initial search -->
        {#if $searchTermStore && !initialSearchComplete}
          <li>
            <span class="flex w-full justify-center py-3">
              <Preloader />
            </span>
          </li>
        {:else if $searchTermStore && isEmpty($searchResultStore)}
          <div class="flex w-full justify-center py-3">No Result</div>
        {:else if $searchTermStore && $searchResultStore.length}
          <InfiniteLoading
            distance="600"
            on:infinite="{(event) => {
              // Already reached end.
              if ($searchResultStore.length < advancedSearchFullSize) {
                return event.detail.complete();
              }

              const searchId = lastSearchId;
              // Load more search result.
              remoteCompositeSearch({
                deskId: $selectedDesk._id,
                field: {
                  [type]: {
                    term: $searchTermStore,
                    size: $searchResultStore.length + advancedSearchFullSize,
                    contactId,
                  },
                },
                filter: $selectedDeskContactFilter,
              }).then((result) => {
                // Check whether the searched term had changed, only show result if not changed.
                if ($searchTermStore) {
                  if (lastSearchId === searchId) {
                    $searchResultStore = result[type];
                  }
                  if (result[type].length % advancedSearchFullSize) {
                    event.detail.complete();
                    console.log('Search reached end');
                  } else {
                    console.log('More search loaded');
                    event.detail.loaded();
                  }
                }
              });
            }}"
          >
            <span slot="noResults"></span>
            <span slot="noMore"></span>
            <!-- Framework7 default platform specific infinite load spinner -->
            <span slot="spinner" class="flex w-full justify-center py-3">
              <Preloader />
            </span>
            <span slot="error" let:attemptLoad>
              <span
                on:click="{attemptLoad}"
                class="flex justify-center cursor-pointer text-blue-primary font-medium py-3 px-3"
                >Retry</span
              >
            </span>
          </InfiniteLoading>
        {/if}
      </ul>
    </div>
  </div>
</Page>
