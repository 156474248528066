import { get } from 'svelte/store';
import { mainView, remoteAutoReplyDelete, showLoadingDialog, mainViewNavigateBack } from './store';

export function deleteAutoReply(name, type, autoReplyId) {
  get(mainView)
    .app.dialog.create({
      title: '',
      text: `Delete ${type === 'bot' ? 'Bot' : 'Greetings'} - ${name}`,
      closeByBackdropClick: true,
      buttons: [
        {
          text: 'Cancel',
          keyCodes: [27],
        },
        {
          text: 'Delete',
          bold: true,
          color: 'red',
          keyCodes: [13],
          onClick: () => {
            showLoadingDialog(async () => {
              await remoteAutoReplyDelete(autoReplyId);
              mainViewNavigateBack();
            }, 'Processing');
          },
        },
      ],
    })
    .open();
}
