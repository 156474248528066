import Dexie from 'dexie';
import io from 'socket.io-client';
import ky from 'ky';
import { writable, get } from 'svelte/store';
import { tick } from 'svelte';
import objectHash from 'object-hash';
import IdleJs from 'idle-js';
import {
  isEmpty,
  remove,
  isArray,
  cloneDeep,
  compact,
  isEqual,
  omit,
  omitBy,
  isNil,
  last,
  first,
  pick,
} from 'lodash-es';
import {
  generateRandomString,
  clearCookie,
  contactPickProfilePic,
  mongoIdToTimestamp,
  serverUrl,
  pushNotificationHandlerInit,
  isEpochZero,
  isNativeApp,
} from './util';
import {
  contactLoadPerRequest,
  chatLoadPerRequest,
  contactLockExtendGraceMs,
  idleMs,
  socketReconnectDelay,
  socketReconnectDelayMax,
} from './config';

export const userInfo = writable({});
export const deskInfoList = writable([]);
export const selectedDesk = writable({});
export const selectedDeskUserInfo = writable({});
export const selectedDeskMemberList = writable([]);
export const selectedDeskChannelList = writable([]);
export const selectedDeskChannelListIncludeDeactivated = writable([]);
export const selectedContact = writable({});
export const selectedChatList = writable([]);
export const selectedContactList = writable([]);
export const selectedChannel = writable({});
export const selectedDeskAutoReplyList = writable([]);
export const selectedDeskContactFilter = writable({});
export const selectedDeskReportSetting = writable({});
export const selectedDeskContactLockList = writable([]);
export const selectedDeskMemberIdleList = writable([]);
export const selectedBroadcast = writable({});

export const contactSearchTerm = writable('');
export const contactAdvancedSearchTerm = writable('');
export const chatSearchTerm = writable('');
export const selectedChatSearch = writable({});
export const selectedChatSearchReachedBottom = writable(false);
export const selectedChatSearchExistInLocalDb = writable(false);
export const selectedChatSearchRemoteFetchedChatCache = writable([]);
export const chatSearchResult = writable([]);

export const quotedMsgId = writable('');

export const loggedIn = writable(false);
export const fullyLoaded = writable(false);
export const scriptLoaded = writable(false);
export const f7Loaded = writable(false);
export const mainView = writable({});
export const secondaryView = writable({});
export const sharedF7 = writable({});
export const showSecondaryView = writable(false);
export const fullScreen = writable(false);
export const multiView = writable(window.innerWidth > 800);
export const colorTheme = writable(window.localStorage.getItem('color_theme'));
export const darkMode = writable(false);
export const hasInternetConnection = writable(false);
export const isIdle = writable(false);
export const deviceUuid = writable('');
export const fingerPrintRequired = writable(false);
export const fingerPrintSupported = writable(false);
export const fingerPrintVerified = writable(false);
export const contactSearchType = writable(
  window.localStorage.getItem('contact_search_type')
    ? window.localStorage.getItem('contact_search_type')
    : 'basic'
);

export const contactListContainer = writable({});

const pendingEventQueue = writable('');

export const socketInfo = writable({ socketId: '', country: '', vapid: '', pushRegistered: false });

let lastVirginInfo = {};

const kyApi = ky.extend({
  timeout: 1000 * 60 * 20,
});

const db = new Dexie('chat');
db.version(1).stores({
  chat: '&_id, deskId, orderAt, lastAt, contactId, [deskId+orderAt], [deskId+lastAt]',
  user: '&_id',
  desk: '&_id, deactivatedAt',
  member: '&_id, deskId, userId, deactivatedAt, [deskId+userId]',
  contact:
    '&hid, hash, _id, deskId, orderAt, lastAt, channel, route, status, platform, *tag, [hash+orderAt], [hash+lastAt]',
  channel: '&_id, deskId, lastAt, deactivatedAt',
  autoReply: '&_id, deskId',
  preference: '&_id',
});
window.db = db;

let sharedSocket;

// Emit then wait until ack is received, then depending on return status, if error raise an exception.
function socketEmit(
  event,
  payload,
  autoRetry = true,
  autoRetryMax = 30,
  autoRetryTimeoutMs = 250,
  retriedCount = 0
) {
  return new Promise((resolve, reject) => {
    if (!sharedSocket) {
      if (autoRetry) {
        if (retriedCount < autoRetryMax) {
          console.log(
            `Socket not ready, auto retrying, remaining: ${autoRetryMax - retriedCount}`,
            event
          );
          setTimeout(() => {
            socketEmit(
              event,
              payload,
              autoRetry,
              autoRetryMax,
              autoRetryTimeoutMs,
              retriedCount + 1
            )
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(err);
              });
          }, autoRetryTimeoutMs);
        } else {
          // showDialog('Disconnected from server');
          reject(new Error('Socket not ready, retry max exceeded'));
        }
      } else {
        showDialog('Socket not ready');
        reject(new Error('Socket not ready'));
      }
    } else {
      sharedSocket.emit(event, payload, (data) => {
        if (data.success) {
          resolve(data.data);
        } else {
          reject(data.msg);
        }
      });
    }
  });
}

/**
 * Only update svelte store if new value is different from current value to avoid unnecessary expensive reactive call.
 * @param {import('svelte/store').Writable} store
 * @param {object} value
 * @param {boolean} forceRefresh Force update regardless of whether value had changed.
 */
export function storeUpdateIfDifferent(store, value, forceRefresh = false) {
  if (forceRefresh || !isEqual(get(store), value)) {
    store.set(value);
  }
}

/**
 * Convert contact filter setting into hash, same setting will have same hash, used to uniquely identify this set of contact filter.
 * @param {object} contactFilter
 */
export function generateFilterHash(contactFilter = {}) {
  const cloned = cloneDeep(contactFilter);
  if (cloned.startAt) {
    cloned.startAt = cloned.startAt.toString();
  }
  if (cloned.endAt) {
    cloned.endAt = cloned.endAt.toString();
  }
  return objectHash(
    pick(cloned, [
      'archive',
      'reverse',
      'status',
      'channel',
      'member',
      'tag',
      'startAt',
      'endAt',
      'startEndBy',
      'deskId',
    ]),
    {
      unorderedArrays: true,
      respectType: false,
      respectFunctionNames: false,
    }
  );
}

const fileUploadProgressCbList = [];
/**
 * If deskId is not specified, image will be treated as user's personal profile pic.
 * deskId will be used to track who this file belongs to in order to calculate storage usage accordingly.
 * The files that are uploaded either accidentally or maliciously and not used in any chat will also show up in storage usage.
 * Only logged in user can upload file, and can only upload file with deskId if they have access to that desk.
 * Profile pic will be auto stretched to 200px x 200px, thus make sure to supply deskId to overcome this.
 * Upload progress callback is also available to show a progress bar if needed.
 * userId is not needed, it will be deduced from cookie or sessionId (if enabled) on server side.
 * @param {object} payload
 * @param {File} payload.file
 * @param {string} payload.deskId
 * @param {boolean} payload.emitProgress
 * @param {function} payload.progressCb
 * @param {boolean} payload.showDefaultOverlayProgress
 * @param {boolean} payload.convertToProfilePic Convert to 200x200 jpg
 */
export async function fileUpload({
  file,
  deskId,
  emitProgress,
  progressCb,
  showDefaultOverlayProgress,
  convertToProfilePic = false,
  shortenUrl = false,
}) {
  // UploadId will be echoed back by server to identify upload progress
  const uploadId = generateRandomString(5);

  // multipart/form-data
  const formData = new FormData();
  // Field name need not to be 'file', backend will deduce it based on whether it is a html file object
  // and send as file accordingly.
  formData.set('file', file);

  const queryParam = { uploadId };

  if (convertToProfilePic) {
    queryParam.convertToProfilePic = true;
  }

  if (deskId) {
    queryParam.deskId = deskId;
  }

  if (shortenUrl) {
    queryParam.shortenUrl = true;
  }

  if (window.localStorage.getItem('sessionId')) {
    queryParam.sessionId = window.localStorage.getItem('sessionId');
  }

  // Progress will only be emitted to that socketId.
  // No verification of socketId will be made, non confidential information.
  if (emitProgress) {
    queryParam.socketId = get(socketInfo).socketId;
    fileUploadProgressCbList.push({
      uploadId,
      showDefaultOverlayProgress,
      cb: progressCb,
    });
  }

  return kyApi
    .post(`${serverUrl}/api/upload`, {
      body: formData,
      searchParams: queryParam,
    })
    .text();
}

export async function remoteUserDetailUpdate(payload) {
  return socketEmit('user_info_update', payload);
}
export async function remoteSetIdleStatus(deskId, idle) {
  if (deskId) {
    socketEmit('user_idle_update', { deskId, idle });
  }
}
export async function remoteDeskSortUpdate(payload) {
  return socketEmit('desk_sort_update', payload);
}
export async function remoteMemberSortUpdate(deskId, order) {
  return socketEmit('member_sort_update', { deskId, order });
}

export async function remoteMemberDetailUpdate(deskId, memberId, payload) {
  return socketEmit('update_member_info', { deskId, memberId, payload });
}

export async function remoteCreateDesk(name, timezone) {
  return socketEmit('create_desk', { name, timezone });
}
export async function remoteQuitDesk(deskId) {
  return socketEmit('quit_desk', { deskId });
}
export async function remoteDeleteDesk(deskId) {
  return socketEmit('delete_desk', { deskId });
}
export async function remoteDeskSettingUpdate(deskId, payload) {
  return socketEmit('update_desk_setting', { deskId, payload });
}
export async function remoteDeskInfoUpdate(deskId, payload) {
  return socketEmit('update_desk_info', { deskId, payload });
}
export async function remoteDeskContactFilterUpdate(payload) {
  return socketEmit('update_desk_contact_filter', payload);
}
export async function remoteDeskReportSettingUpdate(payload) {
  return socketEmit('update_desk_report_setting', payload);
}
export async function remoteDeskTagDistinct(deskId) {
  return socketEmit('fetch_desk_distinct_tag', { deskId });
}

export async function remoteAddMember(deskId, name, email, profilePic, role) {
  // Record this user initiated the event so after it returns user will be forwarded accordingly.
  storeUpdateIfDifferent(pendingEventQueue, 'add_member');
  return socketEmit('add_member', { deskId, name, email, profilePic, role });
}
export async function remoteDeleteMember(deskId, memberId) {
  return socketEmit('delete_member', { deskId, memberId });
}

export async function remoteVerifyDeskToken(token) {
  return socketEmit('verify_desk_token', { token });
}
export async function remoteAcceptDesk(invitationId) {
  return socketEmit('member_accept_invitation', { invitationId });
}
export async function remoteRejectDesk(invitationId) {
  return socketEmit('member_reject_invitation', { invitationId });
}
export async function remoteGenerateReport(payload) {
  return socketEmit('desk_generate_report', payload);
}

export async function remoteContactDetailUpdate(contactId, payload) {
  return socketEmit('contact_info_update', { contactId, payload });
}

export async function remoteAddChannel(deskId, payload) {
  // Record this user initiated the event so after it returns user will be forwarded accordingly.
  storeUpdateIfDifferent(pendingEventQueue, 'add_channel');
  return socketEmit('add_channel', { deskId, payload });
}
export async function remoteDeleteChannel(channelId) {
  return socketEmit('delete_channel', channelId);
}
export async function remoteChannelInfoUpdate(channelId, payload) {
  return socketEmit('update_channel_info', { channelId, payload });
}
export async function remoteWhatsAppPersonalFetchQr(channelId) {
  return socketEmit('wa_personal_fetch_qr', { channelId });
}
export async function remoteWhatsAppPersonalUnlink(channelId) {
  return socketEmit('wa_personal_unlink', { channelId });
}
export async function remoteWhatsAppPersonalRestart(channelId) {
  return socketEmit('wa_personal_restart', { channelId });
}

export async function remoteCreateBot(channelId, deskId, payload, subscribedChannelIdList) {
  return socketEmit('bot_create', { channelId, deskId, payload, subscribedChannelIdList });
}
export async function remoteCreateGreeting(channelId, deskId, payload, subscribedChannelIdList) {
  return socketEmit('greeting_create', { channelId, deskId, payload, subscribedChannelIdList });
}
export async function remoteAutoReplyUpdate(autoReplyId, payload, subscribedChannelIdList) {
  return socketEmit('auto_reply_update', { autoReplyId, payload, subscribedChannelIdList });
}
export async function remoteAutoReplyDelete(autoReplyId) {
  return socketEmit('auto_reply_delete', { autoReplyId });
}
export async function remoteChannelBotTestMsg(channelId, payload) {
  return socketEmit('bot_outbound_test', { channelId, payload });
}

export async function remoteFetchPromoCode(payload) {
  return socketEmit('fetch_promocode', payload);
}
export async function remoteGeneratePromoCode(payload) {
  return socketEmit('generate_promocode', payload);
}

export async function remoteSendOutboundMsg(payload) {
  return socketEmit('outbound_send', payload);
}
export async function remoteRecallOutboundMsg(payload) {
  return socketEmit('outbound_send_recall', payload);
}
/**
 * List of msg ids to cancel.
 * @param {Array<string>} msgIdList
 */
export async function remoteCancelOutboundMsg(msgIdList) {
  return socketEmit('outbound_send_cancel', { msgIdList });
}
/**
 * List of msg ids to resend.
 * @param {Array<string>} msgIdList
 */
export async function remoteResendTimeoutMsg(msgIdList) {
  return socketEmit('outbound_resend_timeout', { msgIdList });
}
export async function remoteFetchVirginContact(term, deskId) {
  return socketEmit('fetch_virgin_contact', { term, deskId });
}
export async function remoteFetchQueuedOutboundMsg(channelId, type) {
  return socketEmit('fetch_queued_outbound_send', { channelId, type });
}

export async function remoteCloseCase(payload) {
  return socketEmit('case_close', payload);
}
export async function remoteMemo(payload) {
  return socketEmit('memo', payload);
}
export async function remoteFlash(payload) {
  return socketEmit('flash', payload);
}

export async function remoteLockContact(contactId, deskId) {
  // Virgin number doesn't need to lock.
  if (contactId === 'virgin') {
    return;
  }
  return socketEmit('contact_lock', { contactId, deskId });
}
export async function remoteUnlockContact() {
  if (get(loggedIn)) {
    return socketEmit('contact_unlock');
  }
}

export async function remoteCountCaseForAllDesk() {
  return socketEmit('user_count_case_all_desk', {});
}

export async function remoteFetchBroadcast(deskId, limit) {
  return socketEmit('broadcast_fetch', { deskId, limit });
}
export async function remoteFetchBroadcastDetail(broadcastId) {
  return socketEmit('broadcast_fetch_detail', { broadcastId });
}
export async function remoteCreateBroadcast(deskId, payload) {
  return socketEmit('broadcast_create', { deskId, payload });
}
export async function remoteBroadcastUpdate(payload) {
  return socketEmit('broadcast_update', { payload });
}
export async function remoteBroadcastRecipientGenerate(filter) {
  return socketEmit('broadcast_recipient_generate_by_filter', { filter });
}

export async function remotePushNotificationRegister(payload) {
  return socketEmit('push_register', payload);
}
export async function remotePushNotificationUnregister() {
  return socketEmit('push_unregister');
}
export async function remotePushNotificationTest() {
  return socketEmit('push_test');
}

/**
 * Fetch open graph metadata via server.
 * Auto prepend https:// if not specified.
 * @param {string} url
 */
export async function remoteOpenGraphPreview(url) {
  // https://stackoverflow.com/questions/3543187/prepending-http-to-a-url-that-doesnt-already-contain-http
  if (!/^https?:\/\//i.test(url)) {
    url = `http://${url}`;
  }

  return socketEmit('ogp', { url });
}

/**
 * Composite search's individual field.
 * @typedef {object} SearchField
 * @prop {string|Array<string>} term
 * @prop {number} size
 * @prop {string} contactId
 */
/**
 * Composite search that search thru contact and chat contents.
 * @param {object} payload
 * @param {string} payload.deskId
 * @param {object} payload.field
 * @param {SearchField} payload.field.contact
 * @param {SearchField} payload.field.name
 * @param {SearchField} payload.field.remark
 * @param {SearchField} payload.field.tag
 * @param {SearchField} payload.field.member
 * @param {SearchField} payload.field.message
 * @param {object} payload.filter
 */
export async function remoteCompositeSearch(payload) {
  return socketEmit('composite_search', payload);
}

/**
 * Fetch multiple types of data in one single request depending on config.
 * @param {object} config
 * @param {string} deskId
 */
async function remoteCompositeFetch(deskId, config) {
  return socketEmit('fetch_composite', { deskId, config });
}

async function remoteCompositeFetchUserSelfInfo() {
  return socketEmit('fetch_composite_user');
}

/**
 * Switch desk then run composite fetch in one single request to avoid round trip.
 * If no deskId is specified, first accessible desk will be selected.
 * NOTE: Must switch desk to subscribe to desk scoped update.
 * @param {object} config
 * @param {string} deskId
 */
async function remoteSwitchDeskThenCompositeFetch(deskId, config) {
  return socketEmit('switch_desk', {
    deskId,
    config,
  });
}

async function remoteCompositeFetchThenSaveToLocalDb(deskId, config) {
  return mergeRemoteCompositeDataToLocalDb(await remoteCompositeFetch(deskId, config));
}

/**
 * Store remote data into local DB.
 * Data can only grow, never decrease in size unless user clear it themselves via clear cache btn OR delete cache from browser on their own.
 * Only merge them into DB if they are newer than existing or doesn't exist to prevent data corruption.
 * Remote data are guaranteed to come in predetermined format, and merge-able directly into local db.
 * @param {object} data Composite data fetched from remote.
 */
async function mergeRemoteCompositeDataToLocalDb(
  data,
  contactFilter = get(selectedDeskContactFilter)
) {
  const summary = {
    modified: false,
    fetchedCount: 0,
    modifiedCount: 0,
    user: { fetchedCount: 0, modifiedCount: 0 },
    desk: { fetchedCount: 0, modifiedCount: 0 },
    member: { fetchedCount: 0, modifiedCount: 0 },
    contact: { fetchedCount: 0, modifiedCount: 0 },
    chat: { fetchedCount: 0, modifiedCount: 0 },
    channel: { fetchedCount: 0, modifiedCount: 0 },
    autoReply: { fetchedCount: 0, modifiedCount: 0 },
    status: { lock: { fetchedCount: 0 }, idle: { fetchedCount: 0 } },
  };
  if (isEmpty(data)) {
    return summary;
  }

  const promiseList = [];
  if (!isEmpty(data.user)) {
    if (!isArray(data.user)) {
      data.user = [data.user];
    }
    summary.user.fetchedCount = data.user.length;

    compact(data.user).forEach(async (user) => {
      promiseList.push(
        db.transaction('rw', db.user, async () => {
          const origin = (await db.user.toArray())[0];
          if (!origin) {
            await db.user.add(user);
            summary.user.modifiedCount += 1;
          } else if (origin.lastAt < user.lastAt) {
            await db.user.update(user._id, user);
            summary.user.modifiedCount += 1;
          }
        })
      );
    });
  }

  if (!isEmpty(data.desk)) {
    summary.desk.fetchedCount = data.desk.length;
    compact(data.desk).forEach(async (desk) => {
      promiseList.push(
        db.transaction('rw', db.desk, async () => {
          const origin = await db.desk.get(desk._id);
          if (!origin) {
            await db.desk.add(desk);
            summary.desk.modifiedCount += 1;
          } else if (origin.lastAt < desk.lastAt) {
            await db.desk.update(desk._id, desk);
            summary.desk.modifiedCount += 1;
          }
        })
      );
    });
  }

  if (!isEmpty(data.member)) {
    summary.member.fetchedCount = data.member.length;
    compact(data.member).forEach(async (member) => {
      promiseList.push(
        db.transaction('rw', db.member, async () => {
          const origin = await db.member.get(member._id);
          if (!origin) {
            await db.member.add(member);
            summary.member.modifiedCount += 1;
          } else if (origin.lastAt < member.lastAt) {
            await db.member.update(member._id, member);
            summary.member.modifiedCount += 1;
          }
        })
      );
    });
  }

  if (!isEmpty(data.contact)) {
    summary.contact.fetchedCount = data.contact.length;
    compact(data.contact).forEach(async (contact) => {
      if (!contact.hash) {
        console.log('Contact has no hash', contact);
        showDialog('Received invalid chat data');
        throw new Error('Contact has no hash');
      }

      const hid = `${contact.hash}_${contact._id}`;
      contact.hid = hid;

      promiseList.push(
        db.transaction('rw', db.contact, async () => {
          const origin = await db.contact.get({ hid });
          if (!origin) {
            await db.contact.add(contact);
            summary.contact.modifiedCount += 1;
          } else if (origin.lastAt < contact.lastAt) {
            await db.contact.update(hid, contact);
            summary.contact.modifiedCount += 1;
          }
        })
      );
    });
  }

  if (!isEmpty(data.chat)) {
    summary.chat.fetchedCount = data.chat.length;
    compact(data.chat).forEach(async (chat) => {
      promiseList.push(
        db.transaction('rw', db.chat, async () => {
          const origin = await db.chat.get(chat._id);
          if (!origin) {
            await db.chat.add(chat);
            summary.chat.modifiedCount += 1;
          } else if (origin.lastAt < chat.lastAt) {
            await db.chat.update(chat._id, chat);
            summary.chat.modifiedCount += 1;
          }
        })
      );
    });
  }

  if (!isEmpty(data.channel)) {
    summary.channel.fetchedCount = data.channel.length;
    compact(data.channel).forEach(async (channel) => {
      promiseList.push(
        db.transaction('rw', db.channel, async () => {
          const origin = await db.channel.get(channel._id);
          if (!origin) {
            await db.channel.add(channel);
            summary.channel.modifiedCount += 1;
          } else if (origin.lastAt < channel.lastAt) {
            await db.channel.update(channel._id, channel);
            summary.channel.modifiedCount += 1;
          }
        })
      );
    });
  }

  if (!isEmpty(data.autoReply)) {
    summary.autoReply.fetchedCount = data.autoReply.length;
    compact(data.autoReply).forEach(async (autoReply) => {
      promiseList.push(
        db.transaction('rw', db.autoReply, async () => {
          const origin = await db.autoReply.get(autoReply._id);
          if (!origin) {
            await db.autoReply.add(autoReply);
            summary.autoReply.modifiedCount += 1;
          } else if (origin.lastAt < autoReply.lastAt) {
            await db.autoReply.update(autoReply._id, autoReply);
            summary.autoReply.modifiedCount += 1;
          }
        })
      );
    });
  }

  if (!isEmpty(data.status)) {
    // Apply latest lock info and member idle status, these will not be saved to DB as it is ever changing.
    if (!isEmpty(data.status.lock)) {
      summary.status.lock.fetchedCount = data.status.lock.length;
      storeUpdateIfDifferent(selectedDeskContactLockList, data.status.lock);
    }
    if (!isEmpty(data.status.idle)) {
      summary.status.idle.fetchedCount = data.status.idle.length;
      storeUpdateIfDifferent(selectedDeskMemberIdleList, data.status.idle);
    }
  }

  await Promise.all(promiseList);

  // Compute summary.
  summary.fetchedCount =
    summary.user.fetchedCount +
    summary.desk.fetchedCount +
    summary.member.fetchedCount +
    summary.contact.fetchedCount +
    summary.chat.fetchedCount +
    summary.channel.fetchedCount +
    summary.autoReply.fetchedCount +
    summary.status.lock.fetchedCount;
  summary.modifiedCount =
    summary.user.modifiedCount +
    summary.desk.modifiedCount +
    summary.member.modifiedCount +
    summary.contact.modifiedCount +
    summary.chat.modifiedCount +
    summary.channel.modifiedCount +
    summary.autoReply.modifiedCount;
  summary.modified = summary.modifiedCount > 0;
  console.log('Composite merge', summary, data);

  return summary;
}

/**
 * Last remote fetch at, used to skip fetching already fetched data if there is no changes.
 * Exclude chat and contact as they are volatile and have separate lastAt than normal metadata.
 * @param {'user'|'desk'|'deskSummary'|'member'|'channel'|'autoReply'} type
 * @param {string} deskId
 */
async function getMetadataLastAt(type, deskId) {
  function findLatestLastAt(itemList) {
    if (isEmpty(itemList)) {
      return 0;
    }
    const item = itemList.reduce((a, b) => {
      return a.lastAt > b.lastAt ? a : b;
    });
    return item && item.lastAt ? new Date(item.lastAt) : 0;
  }

  if (type === 'user') {
    const user = (await loadUserInfoFromDb(true)).data;
    return user && user.lastAt ? new Date(user.lastAt) : 0;
  }
  if (type === 'desk') {
    const desk = (await loadDeskFromDb(deskId, true)).data;
    return desk && desk.lastAt ? new Date(desk.lastAt) : 0;
  }
  if (type === 'deskSummary') {
    const deskList = (await loadDeskSummaryFromDb(true)).data;
    return findLatestLastAt(deskList);
  }
  if (type === 'member') {
    const memberList = (await loadDeskMember(deskId, true)).data;
    // If he is the only member (rarely happens), likely means he just joined the desk
    // and had never visited the actual desk yet, thus download the full member list
    // instead of using lastAt if so.
    if (memberList.length > 1) {
      return findLatestLastAt(memberList);
    }
    return 0;
  }
  if (type === 'channel') {
    const channelList = (await loadDeskChannel(deskId, true)).data;
    return findLatestLastAt(channelList);
  }
  if (type === 'autoReply') {
    const autoReplyList = (await loadDeskAutoReply(deskId, true)).data;
    return findLatestLastAt(autoReplyList);
  }

  return 0;
}

/**
 * Determine desk, filter scoped contact lastAt.
 * Use to sync any missed contact between last lastAt until now.
 * @param {string} deskId
 * @param {object} filter
 */
async function getContactLastAt(deskId, filter) {
  if (!deskId) {
    return 0;
  }

  // Hash is unique to particular desk's contact's filter setting.
  // Can use it to target particular contact inside particular filter setting sorted by lastAt (last updated time).
  const contactFilterHash = generateFilterHash(filter);

  // From all contact of this desk, get the one with latest lastAt.
  const contact = await db.contact
    // Filter by deskId and bind it to orderTs.
    .where('[hash+lastAt]')
    .between([contactFilterHash, Dexie.minKey], [contactFilterHash, Dexie.maxKey])
    .reverse()
    .limit(1)
    .sortBy('lastAt');

  if (Array.isArray(contact) && !isEmpty(contact)) {
    return new Date(contact[0].lastAt);
  } else {
    return 0;
  }
}

/**
 * Generate default composite fetch config.
 * @param {string} deskId
 * @param {Array<string>} pickList The field name you wish to include, include all if not specified.
 */
async function generateDefaultCompositeFetchConfig(
  deskId = get(selectedDesk)._id,
  pickList = [],
  includeStickyContact = false
) {
  let config = {
    user: { include: true },
    desk: { include: true, summary: { include: true } },
    member: { include: true },
    contact: { include: true, limit: contactLoadPerRequest }, // Contact list will include 1 latest msg.
    channel: { include: true },
    autoReply: { include: true },
    status: { lock: { include: true }, idle: { include: true } },
  };

  if (!isEmpty(pickList)) {
    config = pick(config, pickList);
  }

  // Include lastAt if available to skip fetching already fetched desk metadata.
  const [userLastAt, deskLastAt, deskSummaryLastAt, memberLastAt, channelLastAt, autoReplyLastAt] =
    await Promise.all([
      config.user ? getMetadataLastAt('user', deskId) : 0,
      config.desk ? getMetadataLastAt('desk', deskId) : 0,
      config.desk && config.desk.summary ? getMetadataLastAt('deskSummary', deskId) : 0,
      config.member ? getMetadataLastAt('member', deskId) : 0,
      config.channel ? getMetadataLastAt('channel', deskId) : 0,
      config.autoReply ? getMetadataLastAt('autoReply', deskId) : 0,
    ]);
  if (config.user) {
    config.user.lastAt = userLastAt;
  }
  if (config.desk) {
    config.desk.lastAt = deskLastAt;
  }
  if (config.desk && config.desk.summary) {
    config.desk.summary.lastAt = deskSummaryLastAt;
  }
  if (config.member) {
    config.member.lastAt = memberLastAt;
  }
  if (config.channel) {
    config.channel.lastAt = channelLastAt;
  }
  if (config.autoReply) {
    config.autoReply.lastAt = autoReplyLastAt;
  }

  if (config.contact) {
    // Include desk contact filter if available.
    const contactFilter = get(selectedDeskContactFilter);
    if (!isEmpty(contactFilter)) {
      config.contact.archive = contactFilter.archive;
      config.contact.reverse = contactFilter.reverse;
      config.contact.status = contactFilter.status;
      config.contact.channel = contactFilter.channel;
      config.contact.member = contactFilter.member;
      config.contact.tag = contactFilter.tag;
      config.contact.startAt = contactFilter.startAt;
      config.contact.endAt = contactFilter.endAt;
      config.contact.startEndBy = contactFilter.startEndBy;

      config.contact = omitBy(config.contact, isNil);
    }

    // Fetch contact up to lastAt if lastAt is given.
    // When fetching using lastAt lift limit as we cannot know how many do we miss and we want them all.
    const contactLastAt = await getContactLastAt(deskId, contactFilter);
    if (contactLastAt) {
      config.contact.lastAt = contactLastAt;
      config.contact.limit = 0;
    }

    // Fetch both sticky and non sticky contact.
    if (includeStickyContact) {
      config.contact.sticky = 'all';
    }
  }

  return config;
}

/**
 * Switch to specified desk OR first available desk if not specified.
 * Fetch latest data from remote, merge to local then apply the result to svelte store.
 * Can optionally load snapshot from local, remote fetch will be ran in parallel.
 * @param {object} payload
 * @param {string} [payload.deskId]
 * @param {boolean} [payload.clearHistory]
 * @param {boolean} [payload.loadSnapshot]
 * @param {boolean} [payload.clearSearchTerm]
 */
export async function switchDesk({
  deskId = '',
  clearHistory = true,
  loadSnapshot = true,
  clearSearchTerm = false,
}) {
  if (clearHistory) {
    // Clear drawn chat.
    storeUpdateIfDifferent(selectedDesk, {});
    storeUpdateIfDifferent(selectedDeskMemberList, []);
    storeUpdateIfDifferent(selectedContact, {});
    storeUpdateIfDifferent(selectedChatList, []);
    storeUpdateIfDifferent(selectedContactList, []);
    storeUpdateIfDifferent(selectedDeskChannelList, []);
    storeUpdateIfDifferent(selectedDeskChannelListIncludeDeactivated, []);
    storeUpdateIfDifferent(selectedDeskAutoReplyList, []);

    // Navigate back to main page.
    mainViewNavigateRoot();
  }

  if (clearSearchTerm) {
    storeUpdateIfDifferent(contactSearchTerm, '');
    storeUpdateIfDifferent(contactAdvancedSearchTerm, '');
    storeUpdateIfDifferent(chatSearchTerm, '');
  }

  // If user info is empty.
  // Most likely is first launch, block all operation and fetch user info from remote.
  // Cannot do anything without knowing basic user info.
  if (isEmpty(get(userInfo))) {
    await loadUserInfoFromDb();

    // Failed to get from local DB, fetch from remote instead.
    if (isEmpty(get(userInfo))) {
      await mergeRemoteCompositeDataToLocalDb(await remoteCompositeFetchUserSelfInfo());
      await loadUserInfoFromDb();
      console.log('Fetch and merged initial user info from remote');
    }
  }

  // Can only load snapshot if target deskId is specified.
  // Discard snapshot if remote fetch is faster than snapshot fetch from DB.
  let remoteFetchCompleted = false;
  if (loadSnapshot && deskId) {
    loadDeskStateFromDb(deskId, true).then(async (applyToStore) => {
      if (!remoteFetchCompleted) {
        await applyToStore();
        console.log('Loaded desk snapshot in parallel fetch switch desk');
      } else {
        console.log('Remote fetch faster than local fetch, discarded swith desk');
      }
    });
  }

  const compositeResult = await remoteSwitchDeskThenCompositeFetch(
    deskId,
    await generateDefaultCompositeFetchConfig(deskId, [], true)
  );
  remoteFetchCompleted = true;

  // No desk found
  if (isEmpty(compositeResult)) {
    // If is new user, automatically create a desk for them then navigate to that desk.
    if (Date.now() - mongoIdToTimestamp(get(userInfo)._id).valueOf() < 1000 * 60 * 60 * 24) {
      await remoteCreateDesk('My First Desk', Intl.DateTimeFormat().resolvedOptions().timeZone);
      switchDesk({ deskId });
      return;
    }
    // Old user, simply tell them no desk found, most likely they had deleted their old last desk.
    showDialog('No desk found');
    storeUpdateIfDifferent(selectedDesk, { name: 'No Desk Found' });
  }
  // Desk found and returned composite data we asked for.
  else {
    // Remember this desk, back end will tell us which desk is the recommended desk to show in case we didn't specify deskId.
    // If we did specify deskId, the deskId will be returned and set as designatedDeskId.
    // Save to localStorage instead of DB to ensure after clearing cache it will persist.
    window.localStorage.setItem('last_desk_id', compositeResult.designatedDeskId);

    // Save to local DB then reload it from local DB for guaranteed sync.
    await mergeRemoteCompositeDataToLocalDb(compositeResult);
    await loadDeskStateFromDb(compositeResult.designatedDeskId);
  }
}

/**
 * Sync remote with local DB and apply the result to svelte store.
 * Can optionally load snapshot from local, remote fetch will be ran in parallel.
 * @param {string} deskId
 * @param {object} config
 * @param {boolean} loadSnapshot
 */
async function remoteCompositeSyncDesk(
  deskId,
  config,
  clearDrawnContact = false,
  loadSnapshot = true
) {
  // Clear drawn contact on switching filter so contact infinite loader get to reset.
  // Wait until at least 1 fetch is ready before clearing to avoid showing blank screen.
  // Scroll to top only once so if snapshot is available, user won't be scrolled to top again after remote fetch is complete.
  let alreadyScrolledToTop = false;
  async function clearContactList() {
    if (clearDrawnContact) {
      if (!alreadyScrolledToTop) {
        alreadyScrolledToTop = true;
        if (!isEmpty(get(contactListContainer))) {
          get(contactListContainer).scrollTo(0, 0);
        }
        await tick();
      }
      storeUpdateIfDifferent(selectedContactList, []);
    }
  }

  // Load desk snapshot from DB and remote in parallel.
  let remoteFetchCompleted = false;
  if (deskId && loadSnapshot) {
    loadDeskStateFromDb(deskId, true).then(async (applyToStore) => {
      if (!remoteFetchCompleted) {
        await clearContactList();
        await applyToStore();
        console.log('Loaded desk snapshot in parallel fetch');
      } else {
        console.log('Remote fetch faster than local fetch, discarded');
      }
    });
  }

  const compositeResult = await remoteCompositeFetch(deskId, config);
  remoteFetchCompleted = true;
  // Desk found, save to local DB then reload it from local DB for guaranteed sync.
  if (!isEmpty(compositeResult)) {
    await mergeRemoteCompositeDataToLocalDb(compositeResult);
    const applyToStore = await loadDeskStateFromDb(deskId, true);
    await clearContactList();
    await applyToStore();
    console.log('Loaded desk post delta merge');
  }
}

async function loadDeskMember(deskId, fetchOnly = false) {
  const memberList = deskId
    ? filterDeactivatedMember(await db.member.where({ deskId }).toArray())
    : [];
  let applied = false;
  async function applyToStore() {
    if (applied) {
      return;
    }
    applied = true;
    if (!isEmpty(memberList)) {
      storeUpdateIfDifferent(
        selectedDeskMemberList,
        sortMemberListByPreference(deskId, memberList, get(userInfo).preference).map((member) => {
          // Generate additional status for component consumption.
          let status = '';
          if (!member.verified) {
            status = 'pending';
          }
          member.status = status;
          return member;
        })
      );
      storeUpdateIfDifferent(
        selectedDeskUserInfo,
        memberList.find((member) => member.userId === get(userInfo)._id)
      );
    }
  }
  if (!fetchOnly) {
    await applyToStore();
  }
  return { apply: applyToStore, data: memberList };
}

async function loadDeskChannel(deskId, fetchOnly = false) {
  const channelList = deskId
    ? filterDeactivatedChannel(await db.channel.where({ deskId }).toArray())
    : [];
  const fullChannelList = deskId ? await db.channel.where({ deskId }).toArray() : [];
  let applied = false;
  async function applyToStore() {
    if (applied) {
      return;
    }
    applied = true;
    if (!isEmpty(channelList)) {
      storeUpdateIfDifferent(selectedDeskChannelList, channelList);
    }
    if (!isEmpty(fullChannelList)) {
      storeUpdateIfDifferent(selectedDeskChannelListIncludeDeactivated, fullChannelList);
    }
  }
  if (!fetchOnly) {
    await applyToStore();
  }
  return { apply: applyToStore, data: channelList };
}

async function loadDeskAutoReply(deskId, fetchOnly = false) {
  const autoReplyList = deskId
    ? filterDeactivatedAutoReply(await db.autoReply.where({ deskId }).toArray())
    : [];
  let applied = false;
  async function applyToStore() {
    if (applied) {
      return;
    }
    applied = true;
    if (!isEmpty(autoReplyList)) {
      storeUpdateIfDifferent(selectedDeskAutoReplyList, autoReplyList);
    }
  }
  if (!fetchOnly) {
    await applyToStore();
  }
  return { apply: applyToStore, data: autoReplyList };
}

async function loadUserInfoFromDb(fetchOnly = false) {
  const userList = await db.user.toArray();
  let applied = false;
  async function applyToStore() {
    if (applied) {
      return;
    }
    if (!isEmpty(userList)) {
      storeUpdateIfDifferent(userInfo, userList[0]);
      storeUpdateIfDifferent(
        fingerPrintRequired,
        Array.isArray(userList[0].biometric) && userList[0].biometric.includes(get(deviceUuid))
      );
    }
  }
  if (!fetchOnly) {
    await applyToStore();
  }
  return { apply: applyToStore, data: userList };
}

async function loadDeskFromDb(deskId, fetchOnly = false) {
  if (!deskId) {
    return () => {};
  }
  const desk = await db.desk.get(deskId);
  let applied = false;
  async function applyToStore() {
    if (applied) {
      return;
    }
    applied = true;

    if (!isEmpty(desk)) {
      storeUpdateIfDifferent(selectedDesk, desk);
    }
  }
  if (!fetchOnly) {
    await applyToStore();
  }
  return { apply: applyToStore, data: desk };
}

async function loadDeskSummaryFromDb(fetchOnly = false) {
  const deskList = await db.desk.toArray();
  let applied = false;
  async function applyToStore() {
    if (applied) {
      return;
    }
    applied = true;
    if (!isEmpty(deskList)) {
      // Sort desk list according to preference.
      storeUpdateIfDifferent(
        deskInfoList,
        sortDeskListByPreference(
          await filterDeskByAccess(deskList, get(userInfo)._id),
          get(userInfo).preference
        )
      );
    }
  }
  if (!fetchOnly) {
    await applyToStore();
  }
  return { apply: applyToStore, data: deskList };
}

async function loadContactFromDb(
  filter,
  lastCursor,
  deskChannelList,
  fetchOnly = false,
  includeStickyContact = false
) {
  const contactList = await fetchContactFromDb(
    filter,
    deskChannelList,
    lastCursor,
    includeStickyContact
  );
  let applied = false;
  async function applyToStore() {
    if (applied) {
      return;
    }
    applied = true;

    storeUpdateIfDifferent(selectedContactList, contactList);
  }
  if (!fetchOnly) {
    await applyToStore();
  }
  return { apply: applyToStore, data: contactList };
}

/**
 * Fetch chat contact from DB, filtered by filter and support paged load more via specifying orderAt and contactId.
 * @param {string} deskId
 * @param {object} filter
 * @param {Array} deskChannelList
 * @param {object} last
 * @param {Date} last.orderAt Contact's orderAt.
 * @param {string} last.contactId Contact Id to continue from.
 * @param {boolean} last.continueFromOrderAt True to continue from last contactId, False to fetch up to this contactId.
 * @param {number} last.limit How many to fetch.
 */
async function fetchContactFromDb(
  filter,
  deskChannelList = get(selectedDeskChannelList),
  { orderAt = 0, contactId = '', continueFromOrderAt = true, limit = contactLoadPerRequest },
  includeStickyContact = false
) {
  async function fetch(sticky = false) {
    // Filter by tags, tags includes both member and normal tag. Archive is one of the normal tag as _archive.
    let combinedTags = [];
    let includeUnassigned = false;
    let includeUntagged = false;
    if (!isEmpty(filter)) {
      if (Array.isArray(filter.member)) {
        combinedTags = combinedTags.concat(
          filter.member.filter((member) => member !== '_unassigned')
        );
        includeUnassigned = filter.member.find((member) => member === '_unassigned');
      }
      if (Array.isArray(filter.tag)) {
        combinedTags = combinedTags.concat(filter.tag.filter((tag) => tag !== '_untagged'));
        includeUntagged = filter.tag.find((tag) => tag === '_untagged');
      }
      if (filter.archive === 'archived') {
        combinedTags.push('_archive');
      }
    }

    // Only fetch those that matches the hash, to achieve contact isolation between different contact filters.
    const contactFilterHash = generateFilterHash(filter);
    const hid = `${contactFilterHash}_${contactId}`;

    // Fetch contacts ordered by orderTs, filtered by desk filter.
    // https://github.com/dfahlander/Dexie.js/issues/867
    // SQL "WHERE deskId='deskId' ORDER BY orderAt"
    let contactQueryPlan = db.contact
      // Filter by hash (deskId + filter) and bind it to orderTs.
      .where('[hash+orderAt]')
      .between([contactFilterHash, Dexie.minKey], [contactFilterHash, Dexie.maxKey])
      // Further advanced filter across the whole remaining collection.
      // Return true to include the item.
      .and((item) => {
        if (item.hid === hid) {
          return false;
        }

        // Skip 'ucc' desk chat if the feature is not enabled.
        // selectedDesk might not be populated yet on boot, so let it pass if so.
        if (item.platform === 'ucc' && !isEmpty(get(selectedDesk)) && !get(selectedDesk).deskChat) {
          return false;
        }

        // Skip those that are older or younger than orderAt.
        // Only non sticky contact cares about orderAt, if is sticky simply fetch all.
        if (!sticky && orderAt) {
          // Start fetching from orderAt.
          if (continueFromOrderAt) {
            // Show from oldest chat on top, skip newest chat.
            if (filter.reverse) {
              if (new Date(orderAt) >= new Date(item.orderAt)) {
                return false;
              }
            }
            // Show newest chat on top, skip older chat.
            else {
              if (new Date(orderAt) <= new Date(item.orderAt)) {
                return false;
              }
            }
          }
          // Fetch until orderAt
          else {
            // Oldest chat on top, fetch newer chat until it reaches orderAt.
            if (filter.reverse) {
              if (new Date(item.orderAt) > new Date(orderAt)) {
                return false;
              }
            } else {
              // Newest chat on top, fetch older chat until it reaches orderAt.
              if (new Date(item.orderAt) < new Date(orderAt)) {
                return false;
              }
            }
          }
        }

        // Filter by whether contact is sticky.
        if (sticky && Array.isArray(item.tag) && !item.tag.includes('_sticky')) {
          return false;
        }
        if (!sticky && Array.isArray(item.tag) && item.tag.includes('_sticky')) {
          return false;
        }

        // Filter by case status.
        // Contact may have no status if it is ucc desk chat.
        if (item.status) {
          if (filter.status === 'O' && item.status !== 'O') {
            return false;
          }
          if (filter.status === 'C' && item.status !== 'C') {
            return false;
          }
        }

        // Filter by tags, tags includes both member and normal tag.
        if (!isEmpty(combinedTags)) {
          let tagMatched = false;
          combinedTags.forEach((tag) => {
            if (Array.isArray(item.tag) && item.tag.includes(tag)) {
              tagMatched = true;
            }
          });

          // Only need to match either tag or unassigned/untagged.
          if (!tagMatched) {
            if (includeUnassigned || includeUntagged) {
              if (includeUnassigned && !item.unassigned) {
                return false;
              }
              if (includeUntagged && !item.untagged) {
                return false;
              }
            } else {
              return false;
            }
          }
        }

        // Exclude any archived contact.
        if (filter.archive === 'unarchived') {
          if (Array.isArray(item.tag) && item.tag.includes('_archive')) {
            return false;
          }
        }

        // Filter by channel, channel is represented as channel's route instead of channel's _id.
        if (!isEmpty(filter.channel)) {
          let channelMatched = false;
          deskChannelList.forEach((channel) => {
            if (filter.channel.includes(channel.route)) {
              channelMatched = true;
            }
          });
          if (!channelMatched) {
            return false;
          }
        }

        return true;
      });

    // Only set limit if is fetching for non-sticky contact as load more contact is only for fetching more non-sticky contact only,
    // so sticky contact must all be fetched at one go.
    if (!sticky && limit) {
      contactQueryPlan.limit(limit);
    }

    // Reverse means sort by oldest message first, by default we want to search by latest first.
    if (!filter.reverse) {
      contactQueryPlan = contactQueryPlan.reverse();
    }

    const contactList = compact(await contactQueryPlan.sortBy('orderAt'));

    // Extract last chat and put it into contact.
    return compact(
      await Promise.all(
        contactList.map(async (contact) => {
          contact.lastChat = await db.chat.get({ _id: contact.lastChatId });
          contact.profilePic = contactPickProfilePic(contact);
          return contact;
        })
      )
    );
  }

  // Fetch both sticky and non-sticky contact, sticky contact will be appear before non-sticky contact in returned result.
  if (includeStickyContact) {
    const [stickyContactList, nonStickyContactList] = await Promise.all([fetch(true), fetch()]);
    return [...stickyContactList, ...nonStickyContactList];
  }
  // Fetch non-sticky contact only.
  return fetch();
}

function fetchChatFromDb(
  contactId,
  { orderAt = 0, chatId, continueFromOrderAt = true, limit = chatLoadPerRequest, reverse = false }
) {
  if (!contactId) {
    return [];
  }

  const query = db.chat.where({ contactId }).and((item) => {
    // Skip those that are older or younger than orderAt.
    if (orderAt) {
      // Start fetching from orderAt.
      if (continueFromOrderAt) {
        // Skip newer chat.
        if (reverse) {
          if (new Date(orderAt) >= new Date(item.orderAt)) {
            return false;
          }
        }
        // Skip older chat.
        else {
          if (new Date(orderAt) <= new Date(item.orderAt)) {
            return false;
          }
        }
      }
      // Fetch until orderAt
      else {
        // Newest chat on top, fetch older chat until it reaches orderAt.
        if (new Date(item.orderAt) < new Date(orderAt)) {
          return false;
        }
      }
    }

    return true;
  });
  if (limit) {
    query.limit(limit);
  }
  return query.reverse().sortBy('orderAt');
}

function sortDeskListByPreference(deskList, preference) {
  if (
    preference &&
    preference.desk &&
    Array.isArray(preference.desk.order) &&
    !isEmpty(preference.desk.order)
  ) {
    // Order by desk preference, if desk is not specified inside preference, append to the end of desk.
    let preferredOrder = [];
    deskList = cloneDeep(deskList);
    preference.desk.order.forEach((deskId) => {
      preferredOrder = preferredOrder.concat(remove(deskList, (item) => item._id === deskId));
    });
    return preferredOrder.concat(deskList);
  }
  return deskList;
}
function sortMemberListByPreference(deskId, memberList, preference) {
  if (
    preference &&
    preference.member &&
    Array.isArray(preference.member) &&
    !isEmpty(preference.member)
  ) {
    const selectedMemberPreference = preference.member.find(
      (memberPreference) => memberPreference.deskId === deskId
    );
    if (!isEmpty(selectedMemberPreference)) {
      // Order by member preference, if member is not specified inside preference, append.
      let preferredOrder = [];
      memberList = cloneDeep(memberList);
      selectedMemberPreference.order.forEach((memberId) => {
        preferredOrder = preferredOrder.concat(remove(memberList, (item) => item._id === memberId));
      });
      return preferredOrder.concat(memberList);
    }
  }
  return memberList;
}

/**
 * Load desk state from local DB and optionally apply the data to store.
 * @param {string} deskId
 * @param {boolean} fetchOnly If fetchOnly, will not apply the fetched changes to store.
 * @param {boolean} preapplyDependencies Fetch and apply dependencies required by some store function.
 * @return {Promise<Function>} Execute to apply changes to local store, running multiple times has no effect.
 */
async function loadDeskStateFromDb(deskId, fetchOnly = false, preapplyDependencies = true, filter) {
  // Load latest user info from db and set proper filter first, before attempting full load to ensure freshness.
  await loadUserInfoFromDb();
  loadDeskScopedFilter(deskId);
  loadDeskScopedReportSetting(deskId);

  if (!filter) {
    filter = get(selectedDeskContactFilter);
  }

  // Need to preload member and channel into store in case user had set channel or member filter, else it won't load the first time.
  if (preapplyDependencies) {
    await Promise.all([loadDeskChannel(deskId), loadDeskMember(deskId)]);
  }

  // Sync drawn contact with DB if there is already some drawn contact and the filter had not changed in the mean time.
  const contactCursor = {};
  const lastContact = last(get(selectedContactList)) || {};
  if (
    !isEmpty(lastContact) &&
    generateFilterHash(get(selectedDeskContactFilter)) === lastContact.hash
  ) {
    contactCursor.orderAt = lastContact.orderAt;
    contactCursor.continueFromOrderAt = false;
  }

  const [
    { apply: deskFunc },
    { apply: deskListFunc },
    { apply: memberListFunc },
    { apply: contactListFunc },
    { apply: channelListFunc },
    { apply: autoReplyListFunc },
  ] = await Promise.all([
    loadDeskFromDb(deskId, true),
    loadDeskSummaryFromDb(true),
    loadDeskMember(deskId, true),
    loadContactFromDb(filter, contactCursor, undefined, true, true),
    loadDeskChannel(deskId, true),
    loadDeskAutoReply(deskId, true),
  ]);

  let appliedToStore = false;
  async function applyToStore() {
    if (appliedToStore) {
      return;
    }
    appliedToStore = true;

    // Channel is required by desk filter.
    await channelListFunc();

    // Load desk first so loadMoreContact infinite loader will have deskId to work with after initial contact list is loaded.
    await deskFunc();

    Promise.all([deskListFunc(), memberListFunc(), contactListFunc(), autoReplyListFunc()]);
  }

  if (!fetchOnly) {
    applyToStore();
  }
  return applyToStore;
}
async function filterDeskByAccess(deskList, userId) {
  const filteredDeskList = [];
  for (let desk of deskList) {
    if (userId) {
      const memberList = filterDeactivatedMember(
        await db.member.where({ deskId: desk._id, userId }).toArray()
      );
      if (!isEmpty(memberList)) {
        filteredDeskList.push(desk);
      }
    }
  }
  return filteredDeskList;
}
function filterDeactivatedMember(memberList = []) {
  return memberList.filter((member) => !member.deactivatedAt);
}
function filterDeactivatedChannel(channelList = []) {
  return channelList.filter((channel) => !channel.deactivatedAt);
}
function filterDeactivatedAutoReply(autoReplyList = []) {
  return autoReplyList.filter((autoReply) => !autoReply.deactivatedAt);
}

/**
 * Redraw drawn contact using latest data, idempotent.
 * Call when new contact update comes in.
 * Fetch up to last drawn contact.
 * @param {boolean} forceRefresh Force refresh drawn contact, normally only redraw if different.
 */
export async function syncDeskContactFromDb(forceRefresh = false) {
  const lastContact = last(get(selectedContactList)) || {};
  const contactList = await fetchContactFromDb(
    get(selectedDeskContactFilter),
    get(selectedDeskChannelList),
    { orderAt: lastContact.orderAt, continueFromOrderAt: false, limit: 0 },
    true
  );
  storeUpdateIfDifferent(selectedContactList, contactList, forceRefresh);

  // Check if actual data for this virgin contact has arrived, if so select it.
  if (!isEmpty(lastVirginInfo)) {
    const targetContact = contactList.find((c) => c._id === lastVirginInfo._id);
    if (targetContact) {
      if (isEmpty(get(selectedContact))) {
        storeUpdateIfDifferent(selectedContact, targetContact, forceRefresh);
      }
      lastVirginInfo = {};
    }
  }
}

/**
 * Redraw drawn chat using latest data, idempotent.
 * Call when new chat update comes in.
 * Fetch up to last drawn chat.
 *
 * If in chat search mode and a chat is selected and jumped to it,
 * do not override search progress unless the chat search had reached bottom end.
 * On reaching bottom end means chat had loaded to latest and can return to normal load from DB first approach.
 * @param {boolean} forceRefresh Force update regardless of whether value had changed.
 */
export async function syncChatFromDb(forceRefresh = false) {
  if (get(selectedContact).isVirgin) {
    return;
  }

  if (isEmpty(get(selectedChatSearch)) || get(selectedChatSearchReachedBottom)) {
    const lastChat = last(get(selectedChatList)) || {};
    const chatList = await fetchChatFromDb(get(selectedContact)._id, {
      orderAt: lastChat.orderAt,
      chatId: lastChat._id,
      continueFromOrderAt: false,
      limit: 0,
    });
    storeUpdateIfDifferent(selectedChatList, chatList, forceRefresh);
  }
}

export async function syncChatAndContactFromDb(forceRefresh = false) {
  return Promise.all([syncDeskContactFromDb(forceRefresh), syncChatFromDb(forceRefresh)]);
}

/**
 * Automatically load more contact from DB then remote server if needed.
 * @return {Promise<boolean>} True if reached end and no more contact available.
 */
export async function loadMoreContact() {
  const lastContact = last(get(selectedContactList)) || {};
  const lastQuery = { orderAt: lastContact.orderAt, contactId: lastContact._id };

  const deskContactFilter = get(selectedDeskContactFilter);

  // Load from local then remote if local not available.
  // Load from local db.
  const loadedContactList = await fetchContactFromDb(
    get(selectedDeskContactFilter),
    get(selectedDeskChannelList),
    lastQuery
  );

  if (!isEmpty(loadedContactList)) {
    console.log('More contact from local DB', loadedContactList);
    storeUpdateIfDifferent(
      selectedContactList,
      compact(get(selectedContactList).concat(loadedContactList))
    );
  }
  // Load from remote.
  else {
    const summary = await remoteCompositeFetchThenSaveToLocalDb(get(selectedDesk)._id, {
      contact: {
        include: true,
        last: lastQuery,
        limit: contactLoadPerRequest,
        archive: deskContactFilter.archive,
        reverse: deskContactFilter.reverse,
        status: deskContactFilter.status,
        channel: deskContactFilter.channel,
        member: deskContactFilter.member,
        tag: deskContactFilter.tag,
        startAt: deskContactFilter.startAt,
        endAt: deskContactFilter.endAt,
        startEndBy: deskContactFilter.startEndBy,
      },
    });

    if (summary.modified) {
      console.log('More contact from remote');
      const moreContact = await fetchContactFromDb(
        get(selectedDeskContactFilter),
        get(selectedDeskChannelList),
        lastQuery
      );
      storeUpdateIfDifferent(selectedContactList, get(selectedContactList).concat(moreContact));

      // Asked for X but returned less than X, means it had reached bottom.
      if (moreContact.length < contactLoadPerRequest) {
        return true;
      }
    } else {
      // No more data.
      return true;
    }
  }

  return false;
}

export function unselectContact() {
  storeUpdateIfDifferent(selectedContact, {});
}
export function clearSelectedChat() {
  storeUpdateIfDifferent(selectedChatList, []);
}

/**
 * Load more chat from DB then remote.
 * If load more from bottom then always remote until reached bottom, which will then revert back to normal load from DB then remote.
 * @param {string} contactId
 * @param {'top'|'bottom'} direction Top = older, bottom = newer chat
 * @return {Promise<boolean>} True if no more chat to load reached end, false if more chat available.
 */
export async function loadMoreChat(contactId, direction) {
  async function mergeChatIntoStore(chatList) {
    if (direction === 'top') {
      storeUpdateIfDifferent(selectedChatList, get(selectedChatList).concat(chatList));
    } else if (direction === 'bottom') {
      storeUpdateIfDifferent(selectedChatList, chatList.concat(get(selectedChatList)));
    }
  }

  let lastChat = {};
  if (direction === 'top') {
    lastChat = last(get(selectedChatList)) || {};
  } else if (direction === 'bottom') {
    lastChat = first(get(selectedChatList)) || {};
  }
  const lastQuery = {
    orderAt: lastChat.orderAt,
    chatId: lastChat._id,
    reverse: direction === 'bottom',
  };
  const remoteFetchPayload = {
    chat: {
      include: true,
      contactId,
      last: lastQuery,
      limit: chatLoadPerRequest,
    },
  };

  // Normal load more older chat from DB then remote.
  // No chat search jump specified OR chat search had reached bottom OR chat search jump target exist in local DB, thus can fetch from local.
  if (
    isEmpty(get(selectedChatSearch)) ||
    get(selectedChatSearchReachedBottom) ||
    get(selectedChatSearchExistInLocalDb)
  ) {
    // Load from local db.
    const loadedChatList = await fetchChatFromDb(contactId, lastQuery);

    if (!isEmpty(loadedChatList)) {
      console.log('More chat from local DB', loadedChatList);
      mergeChatIntoStore(loadedChatList);
    }
    // Load from remote.
    else {
      const summary = await remoteCompositeFetchThenSaveToLocalDb(
        get(selectedDesk)._id,
        remoteFetchPayload
      );

      if (summary.modified) {
        console.log('More chat from remote');
        const moreChat = await fetchChatFromDb(contactId, lastQuery);
        mergeChatIntoStore(moreChat);

        // Asked for X but returned less than X, means it had reached end.
        if (moreChat.length < chatLoadPerRequest) {
          return true;
        }
      } else {
        // No more data.
        return true;
      }
    }
    return false;
  }

  async function saveRemoteCachedChatToLocalDb() {
    get(selectedChatSearchRemoteFetchedChatCache).forEach(async (cache) => {
      await mergeRemoteCompositeDataToLocalDb(cache);
    });
    storeUpdateIfDifferent(selectedChatSearchRemoteFetchedChatCache, []);
  }

  // Chat search jump, always load from remote unless user had scrolled to bottom end.
  const remoteResult = await remoteCompositeFetch(get(selectedDesk)._id, remoteFetchPayload);

  // Cache remote result, so if afterward user scroll and reached any chat that matches any in DB,
  // means remote chat history had matched with local DB history, by then save the cached data into DB and revert to load from local first from there on.
  storeUpdateIfDifferent(selectedChatSearchRemoteFetchedChatCache, [
    ...get(selectedChatSearchRemoteFetchedChatCache),
    remoteResult,
  ]);

  // Merge and draw newly loaded chat.
  mergeChatIntoStore(remoteResult.chat);

  // Check if loaded remote chat matches any local DB chat.
  const chatIdList = remoteResult.chat.map((c) => c._id);
  const remoteResultExistInLocalDb = !isEmpty(
    (await db.chat.bulkGet(chatIdList)).find((c) => !isEmpty(c))
  );
  if (remoteResultExistInLocalDb) {
    storeUpdateIfDifferent(selectedChatSearchExistInLocalDb, true);
    await saveRemoteCachedChatToLocalDb();
  }

  // Chat reached top / bottom.
  if (chatIdList.length < chatLoadPerRequest) {
    if (direction === 'bottom') {
      storeUpdateIfDifferent(selectedChatSearchReachedBottom, true);
      await saveRemoteCachedChatToLocalDb();
    }
    return true;
  }
  return false;
}

export async function clearLocalDb() {
  await db.delete();
}

export function mainViewNavigate(url, props = {}, reload = false, additionalOptions = {}) {
  if (get(fullyLoaded)) {
    get(mainView).router.navigate(url, {
      props: { props },
      reloadCurrent: reload,
      ...additionalOptions,
    });
  }
}

export function mainViewNavigateBack() {
  if (get(fullyLoaded)) {
    get(mainView).router.back();
  }
}

export function mainViewNavigateRoot(reloadPage) {
  if (get(fullyLoaded)) {
    // Main view may not be ready when user calls redirect before f7 init is completed.
    const view = get(mainView);
    if (isEmpty(view)) {
      console.log('Main view not ready, auto retrying');
      return setTimeout(() => {
        mainViewNavigateRoot(reloadPage);
      }, 100);
    }
    view.router.clearHistory();
    if (reloadPage) {
      window.location.replace('/');
    } else {
      mainViewNavigate('/', {}, true);
      get(mainView).router.updateCurrentUrl('');
    }
  }
}

export function openSecondaryView(url, props = {}, reload = false, additionalOptions = {}) {
  if (get(fullyLoaded)) {
    if (get(multiView) && !get(fullScreen)) {
      get(secondaryView).router.navigate(url, {
        props: { props },
        reloadCurrent: reload,
        ...additionalOptions,
      });
      storeUpdateIfDifferent(showSecondaryView, true);
    } else {
      // Mobile should not use reload current else navigation history will be lost.
      get(mainView).router.navigate(url, { props: { props }, reloadCurrent: false });
    }
  }
}
export function closeSecondaryView() {
  if (get(multiView) && !get(fullScreen)) {
    storeUpdateIfDifferent(showSecondaryView, false);
  } else {
    mainViewNavigateBack();
  }
}

export function generateDefaultDeskContactFilter(deskId) {
  if (!deskId) {
    return {};
  }

  return {
    archive: 'unarchived',
    reverse: false,
    status: 'A',
    channel: [],
    member: [],
    tag: [],
    startAt: '',
    endAt: '',
    startEndBy: 'history', // Can only either be 'creation' | 'history'
    deskId,
    lastAt: new Date(),
  };
}

export function isEqualContactFilter(f1, f2) {
  return generateFilterHash(f1) === generateFilterHash(f2);
}

function loadDeskScopedFilter(deskId) {
  if (!deskId) {
    storeUpdateIfDifferent(selectedDeskContactFilter, {});
    return;
  }

  const filters = get(userInfo).filter || {};
  const finalDeskFilter = cloneDeep(filters[deskId]) || generateDefaultDeskContactFilter(deskId);

  // Desk filter doesn't exist, create and save it to local and remote.
  if (!filters[deskId]) {
    filters[deskId] = finalDeskFilter;
    userInfo.update((u) => {
      u.filter = filters;
      return u;
    });
    db.user.update(get(userInfo)._id, get(userInfo));
    remoteDeskContactFilterUpdate(filters[deskId]);
  }

  // Reset epoch 0 to empty string.
  if (isEpochZero(finalDeskFilter.startAt)) {
    finalDeskFilter.startAt = '';
  }
  if (isEpochZero(finalDeskFilter.endAt)) {
    finalDeskFilter.endAt = '';
  }

  if (!isEqualContactFilter(get(selectedDeskContactFilter), finalDeskFilter)) {
    storeUpdateIfDifferent(selectedDeskContactFilter, finalDeskFilter);
  }
}

function loadDeskScopedReportSetting(deskId) {
  const reportSettings = get(userInfo).report || {};
  const finalReportSetting = cloneDeep(reportSettings[deskId]) || {
    type: 'contact',
    format: 'csv',
    fromCase: 0,
    toCase: 0,
    filter: generateDefaultDeskContactFilter(deskId),
  };

  // Desk report setting doesn't exist, create and save it to local DB.
  // No need to send to remote DB since remote if is new desk will auto assume default setting.
  if (!reportSettings[deskId]) {
    reportSettings[deskId] = finalReportSetting;
    userInfo.update((u) => {
      u.report = reportSettings;
      return u;
    });
    db.user.update(get(userInfo)._id, get(userInfo));
  }

  // Reset epoch 0 to empty string.
  if (isEpochZero(finalReportSetting.filter.startAt)) {
    finalReportSetting.filter.startAt = '';
  }
  if (isEpochZero(finalReportSetting.filter.endAt)) {
    finalReportSetting.filter.endAt = '';
  }

  storeUpdateIfDifferent(selectedDeskReportSetting, finalReportSetting);
}

export function contactOnSelectSetupChatList(contact, forceUseLastChat = false) {
  if (contact._id) {
    // Clear chat first so infinite loader will reset.
    clearSelectedChat();
    storeUpdateIfDifferent(selectedChatSearch, {});

    // Virgin number, no chat available, simply show empty chat screen.
    if (contact._id === 'virgin') {
      return;
    }

    // Virgin snapshot received from server bypassing filter,
    // it comes with its own snapshot of chat list, so no need to apply any chat update here.
    if (contact.isVirgin) {
      return;
    }

    // Search result matched part of contact, tell user which part it matched.
    if (contact.collection === 'contact' && !isEmpty(contact.highlight)) {
      if (contact.highlight.name || contact.highlight.pfName) {
        showDialog('Matched Name');
      }
      if (contact.highlight.remark) {
        showDialog('Matched Notes');
      }
      if (contact.highlight.tag) {
        showDialog('Matched Tag');
      }
      if (contact.highlight.channel || contact.highlight.route) {
        showDialog('Matched Channel');
      }
      if (contact.highlight.crn) {
        showDialog('Matched CRN');
      }
      // For chat matched, it will be highlighted already so no need to show extra dialog.
    }

    // Infinite loader will take care of loading more chat after setting an initial chat below.
    if (forceUseLastChat) {
      tick().then(() => {
        storeUpdateIfDifferent(selectedChatList, [get(selectedContact).lastChat]);
      });
    }
    // Contact is a search result and the search matches a chat. Jump to that chat.
    else if (isEmpty(get(selectedChatSearch)) && !isEmpty(contact.jumpToChat)) {
      tick().then(() => {
        selectedChatSearch.set(contact.jumpToChat);
      });
    }
    // Time range filter target chat, jump to that chat instead of lastChat.
    else if (
      (get(selectedDeskContactFilter).startAt || get(selectedDeskContactFilter).endAt) &&
      !isEmpty(contact.jumpToChat)
    ) {
      tick().then(() => {
        storeUpdateIfDifferent(selectedChatList, [contact.jumpToChat]);
      });
    }
    // Load last chat of contact.
    else {
      tick().then(() => {
        storeUpdateIfDifferent(selectedChatList, [get(selectedContact).lastChat]);
      });
    }
  } else {
    clearSelectedChat();
  }
}

/**
 * Handle all reactive global status changes that cannot be put into grouped into any proper component here.
 */
export function initStoreSubscribers() {
  // Switch to selected desk's filter when desk changes, and setup filter template if not already exist.
  selectedDesk.subscribe((desk) => {
    if (desk && desk._id && !isEmpty(get(userInfo))) {
      loadDeskScopedFilter(desk._id);
      loadDeskScopedReportSetting(desk._id);
    }
  });

  // On desk filter changes, save filter to both local and remote, then fetch filtered result.
  selectedDeskContactFilter.subscribe(async (deskContactFilter) => {
    if (deskContactFilter && deskContactFilter.deskId && !isEmpty(get(selectedDesk))) {
      deskContactFilter.lastAt = new Date();

      const filters = get(userInfo).filter || {};
      const { deskId } = deskContactFilter;

      // Only update if it is different.
      if (isEmpty(filters) || !isEqualContactFilter(filters[deskId], deskContactFilter)) {
        // Clone deep so it don't hold any reference back.
        filters[deskId] = cloneDeep(deskContactFilter);

        // Save to local.
        userInfo.update((u) => {
          u.filter = filters;
          return u;
        });

        // Sync filter with local and remote DB.
        db.user.update(get(userInfo)._id, get(userInfo));
        remoteDeskContactFilterUpdate(deskContactFilter);
      }

      // Sync contact list for this newly applied filter.
      // Filter may not had changed, but redraw anyway in case on multiple same user and they update filter,
      // filter will end up the same after it reaches here since filter is updated before coming in here.
      remoteCompositeSyncDesk(
        deskId,
        await generateDefaultCompositeFetchConfig(deskId, ['contact'], true),
        true
      );
    }
  });

  // Load single latest chat when user first select the contact OR clear chat when user unselect them.
  // If contact is a search result and it matches a chat, jump to that chat instead.
  // Put here instead of inside chat list component as for unknown reason if put there its $ reactive function will be call twice.
  selectedContact.subscribe((contact) => {
    // Request remote to release previously locked contact by me.
    // If is virgin release any previous locked contact, since virgin don't need any lock.
    if (isEmpty(contact) || contact._id === 'virgin') {
      remoteUnlockContact();
    }
    // Request remote to lock this contact.
    else {
      remoteLockContact(contact._id, get(selectedDesk)._id);
    }

    // User selected another contact, clear 'wait for virgin data to arrive and apply'
    // so it no longer auto select the virgin contact when it finally arrived at later date.
    if (!isEmpty(contact)) {
      lastVirginInfo = {};
    }

    // Reset last chat search result on contact changes (either unselect or select another contact)
    // Reset last chat search result on contact unselect.
    // If selected another contact, set current search term to that search term.
    if (get(contactSearchTerm) && !isEmpty(get(selectedContact))) {
      storeUpdateIfDifferent(chatSearchTerm, get(contactSearchTerm));
    } else if (get(contactAdvancedSearchTerm) && !isEmpty(get(selectedContact))) {
      storeUpdateIfDifferent(chatSearchTerm, get(contactAdvancedSearchTerm));
    } else {
      storeUpdateIfDifferent(chatSearchTerm, '');
    }
    storeUpdateIfDifferent(selectedChatSearch, {});

    // Close secondary view on contact changes.
    closeSecondaryView();

    contactOnSelectSetupChatList(contact);
  });
  // User clicked on searched chat result, jump to that chat in history.
  selectedChatSearch.subscribe(async (chat) => {
    if (!isEmpty(chat) && !isEmpty(get(selectedContact))) {
      // Clear chat first so infinite loader will reset.
      clearSelectedChat();

      // Check whether target chat search exist in local DB.
      const chatExist = !isEmpty(await db.chat.get(chat._id));

      // Check again to confirm targeted chat search had not changed while we were fetching from DB.
      if (get(selectedChatSearch)._id === chat._id) {
        storeUpdateIfDifferent(selectedChatSearchExistInLocalDb, chatExist);
        await tick();
        // Set last chat then infinite loader will attempt to load more chat on its own.
        storeUpdateIfDifferent(selectedChatList, [chat]);
      }
    }
    // Chat search result cleared, reset flags.
    else {
      storeUpdateIfDifferent(selectedChatSearchReachedBottom, false);
      storeUpdateIfDifferent(selectedChatSearchExistInLocalDb, false);
      storeUpdateIfDifferent(selectedChatSearchRemoteFetchedChatCache, []);
    }
  });

  // Set fullyLoaded if both script and f7 are fully loaded.
  scriptLoaded.subscribe(() => {
    if (get(scriptLoaded) && get(f7Loaded)) {
      storeUpdateIfDifferent(fullyLoaded, true);
    }
  });
  f7Loaded.subscribe(() => {
    if (get(scriptLoaded) && get(f7Loaded)) {
      storeUpdateIfDifferent(fullyLoaded, true);
    }
  });

  selectedDesk.subscribe((desk) => {
    // Send idle status to remote on desk change.
    if (!isEmpty(desk)) {
      remoteSetIdleStatus(get(selectedDesk)._id, get(isIdle));
    }
  });

  isIdle.subscribe((idle) => {
    // Send idle status to remote.
    remoteSetIdleStatus(get(selectedDesk)._id, idle);

    // On wake request contact lock if there is selected contact.
    if (!idle && !isEmpty(get(selectedContact))) {
      // Refresh lock if lock exist and near expiry.
      const lock = get(selectedDeskContactLockList).find(
        (l) => l.socketId === get(socketInfo).socketId
      );
      if (lock) {
        const isLockNearExpired =
          new Date(lock.expireAt).valueOf() - Date.now() < contactLockExtendGraceMs;
        if (isLockNearExpired) {
          remoteLockContact(get(selectedContact)._id, get(selectedDesk)._id);
        }
      }
      // Lock doesn't exist, lock it. (old lock most likely expired due to idle)
      else {
        remoteLockContact(get(selectedContact)._id, get(selectedDesk)._id);
      }
    }
  });

  // Save user preferred searchtype (basic/advanced) whenever user toggle it.
  contactSearchType.subscribe((searchType) => {
    if (searchType) {
      window.localStorage.setItem('contact_search_type', searchType);
    }
  });

  // Extend contact lock if near expiry.
  // Remove expired lock from lock list.
  selectedDeskContactLockList.subscribe((lockList) => {
    // Remove expired contact lock proactively.
    lockList.forEach((lock) => {
      setTimeout(() => {
        const currentLock = get(selectedDeskContactLockList).find((l) => l._id === lock._id);
        if (currentLock) {
          storeUpdateIfDifferent(
            selectedDeskContactLockList,
            get(selectedDeskContactLockList).filter((l) => new Date() <= new Date(l.expireAt))
          );
        }
      }, new Date(lock.expireAt).valueOf() - Date.now());

      // Extend selected contact lock expiry date.
      if (lock.socketId === get(socketInfo).socketId) {
        setTimeout(() => {
          const currentLock = get(selectedDeskContactLockList).find((l) => l._id === lock._id);
          if (currentLock) {
            if (
              !get(isIdle) &&
              get(selectedContact)._id === lock.contactId &&
              new Date(lock.expireAt) - new Date() <= contactLockExtendGraceMs
            ) {
              remoteLockContact(lock.contactId, lock.deskId);
            }
          }
        }, new Date(lock.expireAt).valueOf() - Date.now() - contactLockExtendGraceMs);
      }
    });
  });

  selectedDeskReportSetting.subscribe((reportSetting) => {
    // Only update if changed, no need to push to remote if is empty.
    if (!isEmpty(reportSetting)) {
      const reportSettings = get(userInfo).report || {};
      const currentReportSetting = reportSettings[get(selectedDesk)._id] || {};

      if (
        !isEmpty(currentReportSetting) &&
        (!isEqual(omit(currentReportSetting, ['filter']), omit(reportSetting, ['filter'])) ||
          !isEqualContactFilter(currentReportSetting.filter, reportSetting.filter))
      ) {
        remoteDeskReportSettingUpdate(reportSetting);
      }
    }
  });
}

// Check if this is a login redirect from remote login.ucc.chat by checking query param.
export function applyLoginRedirectIfAvailable(queryStr) {
  const urlParams = new URLSearchParams(queryStr);
  if (urlParams.get('loginSuccess')) {
    const newSessionId = urlParams.get('sessionId');

    // New sessionId exist, use it then refresh the page and remove it from query param.
    if (newSessionId) {
      const oldSessionId = window.localStorage.getItem('sessionId');

      if (newSessionId !== oldSessionId) {
        window.localStorage.setItem('sessionId', newSessionId);
        urlParams.delete('loginSuccess');
        urlParams.delete('sessionId');
        window.location.replace(`${window.location.origin}?${urlParams}${window.location.hash}`);
      }
    }
  }
}

export async function init() {
  applyLoginRedirectIfAvailable(window.location.search);

  // Setup idle detector.
  const idleJs = new IdleJs({
    idle: idleMs, // idle time in ms
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
    onIdle: () => {
      storeUpdateIfDifferent(isIdle, true);
    },
    onActive: () => {
      storeUpdateIfDifferent(isIdle, false);
    },
    // callback function to be executed when window become hidden
    onHide: () => {
      storeUpdateIfDifferent(isIdle, true);
    },
    // callback function to be executed when window become visible
    onShow: () => {
      storeUpdateIfDifferent(isIdle, false);
    },
  });
  idleJs.start();

  async function setupInitialDbStructure() {
    if (!(await db.preference.get('default'))) {
      db.preference.put({ _id: 'default' });
      console.log('Preference saved');
    }
  }

  // 5c90db5430673c0b65745528
  await setupInitialDbStructure();
  initStoreSubscribers();
  const lastDeskId = window.localStorage.getItem('last_desk_id') || '';

  // User either cleared cache or logged out, or never logged in before.
  // Set it to logged out to show login page immediately.
  if (!lastDeskId) {
    storeUpdateIfDifferent(loggedIn, false);
    storeUpdateIfDifferent(scriptLoaded, true);
  }
  // User has previous memory, either he had logged in or not in unknown until socket connection is established.
  // Load from local DB to show a snapshot first, in case he is just browsing offline.
  else {
    // Load user info, then initial desk info, then full desk with filter applied.
    loadUserInfoFromDb().then(async () => {
      if (!get(loggedIn)) {
        const applyDesk = (await loadDeskFromDb(lastDeskId, true)).apply;
        if (!get(loggedIn)) {
          await applyDesk();
        }
      }
      if (!get(loggedIn)) {
        const applyFullDeskState = await loadDeskStateFromDb(lastDeskId, true);
        if (!get(loggedIn)) {
          await applyFullDeskState();
        }
      }
      storeUpdateIfDifferent(loggedIn, true);
      storeUpdateIfDifferent(scriptLoaded, true);
      console.log('Loaded desk snapshot');
    });
  }

  if (!isNativeApp) {
    pushNotificationHandlerInit();
  }

  // User had logged in before, check whether they have valid credential by attempting to connect to remote server.
  sharedSocket = io(`${serverUrl}/chat`, {
    reconnection: true,
    reconnectionDelay: socketReconnectDelay,
    reconnectionDelayMax: socketReconnectDelayMax,
    // Only needed if using localhost, to login using sessionId instead of cookie.
    auth: { sessionId: window.localStorage.getItem('sessionId') },
  });
  // Setup and sync data on socket connect.
  sharedSocket.on('connect', async () => {
    storeUpdateIfDifferent(hasInternetConnection, true);
    try {
      // TODO: If there is a long gap between last sync, eg days, delete old data and start loading from scratch to avoid having to download days of delta.
      // Use switchDesk instead of vanilla sync to resubscribe to desk scoped socket update.
      const selectedDeskId = get(selectedDesk)._id;
      await switchDesk({ deskId: selectedDeskId || lastDeskId, clearHistory: false });
      storeUpdateIfDifferent(loggedIn, true);
      console.log('Socket login success');
    } catch (err) {
      console.log('Socket login unknown error', err);
    } finally {
      storeUpdateIfDifferent(scriptLoaded, true);
    }
  });

  sharedSocket.on('disconnect', () => {
    storeUpdateIfDifferent(hasInternetConnection, false);
  });

  // Server requested user to logout.
  sharedSocket.on('logout', () => {
    console.log('Server prompt logout');
    window.localStorage.setItem('sessionId', '');
    window.localStorage.setItem('last_desk_id', '');
    clearCookie(window.location.hostname);
    clearLocalDb().then(() => {
      storeUpdateIfDifferent(loggedIn, false);
    });
  });

  // Server will send back init info about this user on every connection.
  sharedSocket.on('init', ({ socketId, country, vapid, pushRegistered }) => {
    storeUpdateIfDifferent(socketInfo, {
      socketId,
      country,
      vapid,
      pushRegistered,
    });
  });

  // File upload progress event.
  sharedSocket.on('progress', ({ uploadId, recv, total }) => {
    // Custom callback.
    const cbObj = fileUploadProgressCbList.find((upload) => upload.uploadId === uploadId);
    if (cbObj.progressCb) {
      cbObj.progressCb(recv, total);
    }
    // Default overlay progress bar.
    else if (cbObj.showDefaultOverlayProgress) {
      let percentComplete = parseInt(recv) / parseInt(total);
      percentComplete = parseInt(percentComplete * 100);
      get(mainView).app.progressbar.show(percentComplete > 100 ? 100 : percentComplete);
    }
    console.log('Upload progress received', uploadId, recv, total);
  });
  sharedSocket.on('progress_error', ({ uploadId, err }) => {
    console.log('Upload progress error', uploadId, err);
    showDialog('Upload Failed');
  });
  sharedSocket.on('progress_aborted', ({ uploadId }) => {
    console.log('Upload progress aborted', uploadId);
  });
  sharedSocket.on('progress_end', ({ uploadId }) => {
    // Custom callback.
    const cbObj = fileUploadProgressCbList.find((upload) => upload.uploadId === uploadId);
    if (cbObj.progressCb) {
      cbObj.progressCb(100, 100);
    }
    // Default overlay progress bar.
    else if (cbObj.showDefaultOverlayProgress) {
      get(mainView).app.progressbar.hide();
    }
    console.log('Upload progress end', uploadId);
  });

  // Remote update, merge into local db then update relevant fields.
  sharedSocket.on('update', async ({ key, skip, data }) => {
    console.log('Remote update', key, data);
    if (!skip) {
      await mergeRemoteCompositeDataToLocalDb(data);
    }
    if (key === 'user_info_update') {
      await loadUserInfoFromDb();
      showDialog('Personal Info Updated');
    } else if (key === 'desk_sort_update') {
      await loadUserInfoFromDb();
      await loadDeskSummaryFromDb();
      showDialog('Desk Order Updated');
    } else if (key === 'member_sort_update') {
      await loadDeskStateFromDb(get(selectedDesk)._id);
      showDialog('Co-workers Order Updated');
    } else if (key === 'create_desk') {
      await loadUserInfoFromDb();
      await loadDeskSummaryFromDb();
      showDialog('Desk Created');
    } else if (key === 'quit_desk' || key === 'removed_from_desk') {
      await loadUserInfoFromDb();
      await loadDeskSummaryFromDb();

      // If is currently looking at the desk, change desk.
      const selectedDeskId = get(selectedDesk)._id;
      if (
        selectedDeskId &&
        !isEmpty(data.member) &&
        data.member[0].deskId === selectedDeskId &&
        data.member[0].userId === get(userInfo)._id
      ) {
        mainViewNavigateRoot();
        switchDesk({});
        if (key === 'quit_desk') {
          showDialog('Desk quitted, auto switch to next available desk');
        } else {
          showDialog('Removed from desk, auto switch to next available desk');
        }
      }
    } else if (key === 'delete_desk') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId && !isEmpty(data.desk) && data.desk[0]._id === selectedDeskId) {
        mainViewNavigateRoot();
        switchDesk({});
        showDialog('Desk deleted, auto switch to next available desk');
      }
    } else if (key === 'update_desk_info') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
      }
      showDialog('Desk Info Updated');
    } else if (key === 'update_desk_setting') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
        // Sync contact to include/exclude desk chat after setting changes.
        // Force refresh so anon number if changed will be reflected.
        await syncChatAndContactFromDb(true);
        storeUpdateIfDifferent(selectedContact, get(selectedContact), true);
      }
      showDialog('Desk Setting Updated');
    }
    //
    else if (key === 'update_anon_channel') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
        // Sync contact to include/exclude desk chat after setting changes.
        // Force refresh so anon number if changed will be reflected.
        await syncChatAndContactFromDb(true);
        storeUpdateIfDifferent(selectedContact, get(selectedContact), true);
      }
    } else if (key === 'add_member' || key === 'delete_member') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
      }
      // Navigate to newly added member's detail page
      if (key === 'add_member') {
        // Only navigate if user is the one initiated it.
        if (get(pendingEventQueue) === 'add_member') {
          mainViewNavigate('/desk/member-detail', { member: data.member[0] }, true);
          storeUpdateIfDifferent(pendingEventQueue, '');
        }
        showDialog('Co-worker Created');
      } else if (key === 'delete_member') {
        showDialog('Co-worker Deleted');
      }
    } else if (key === 'update_member_info') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
      }
      showDialog('Co-worker Info Updated');
    } else if (key === 'desk_report_setting_update') {
      loadUserInfoFromDb();
    } else if (
      key === 'invitation_add' ||
      key === 'invitation_reject' ||
      key === 'invitation_accept' ||
      key === 'invitation_duplicate'
    ) {
      await loadUserInfoFromDb();
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
      }
      if (key === 'invitation_add') {
        showDialog('New Desk Invitation Available');
      } else if (key === 'invitation_reject') {
        showDialog('Invitation Rejected');
      } else if (key === 'invitation_accept') {
        showDialog('Invitation Accepted');
      } else if (key === 'invitation_duplicate') {
        showDialog('Already member of desk');
      }
    } else if (key === 'contact_info_update') {
      await syncDeskContactFromDb();
      const updatedContactIsSelectedContact = data.contact.find(
        (c) => c._id === get(selectedContact)._id
      );
      if (updatedContactIsSelectedContact) {
        // Fetch from newly loaded instead of using remote data directly, since newly loaded contains parsed lastChat.
        const updatedContact = get(selectedContactList).find(
          (c) => c._id === get(selectedContact)._id
        );
        if (updatedContact) {
          storeUpdateIfDifferent(selectedContact, updatedContact);
        }
      }
    }
    // Navigate to newly created channel's detail page
    else if (key === 'add_channel') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
      }
      // Only navigate if user is the one initiated it.
      if (get(pendingEventQueue) === 'add_channel') {
        storeUpdateIfDifferent(selectedChannel, data.channel[0]);
        mainViewNavigate('/channel', {}, true);
        storeUpdateIfDifferent(pendingEventQueue, '');
      }
      showDialog('New Channel Added');
    } else if (key === 'delete_channel') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
      }
      showDialog('Channel Deleted');
    } else if (key === 'update_channel_info') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
        storeUpdateIfDifferent(selectedChannel, data.channel[0]);
      }
      showDialog('Channel Info Updated');
    }
    // Channel status update
    else if (key === 'driver_status') {
      if (get(selectedChannel) && get(selectedChannel)._id === data.channel[0]._id) {
        const selectedDeskId = get(selectedDesk)._id;
        if (selectedDeskId) {
          await loadDeskStateFromDb(selectedDeskId);
        }
        storeUpdateIfDifferent(selectedChannel, data.channel[0]);
      }
    }
    // New inbound message received.
    else if (key === 'new_message') {
      console.log('New message received');
      const { type } = data.chat[0];
      await syncChatAndContactFromDb();
      if (type === 'flash') {
        showFlashMsg(data.chat[0]);
      }
    }
    // Chat message ack.
    else if (key === 'ack') {
      console.log('New ack received', data.chat[0]);
      await syncChatAndContactFromDb();
    }
    // Virgin sent msg delivered and ack back, if user currently has this virgin contact selected,
    // replace it with this one off ack message and contact. It will not be saved to local db.
    // Instead of relying on normal message delivery and replace virgin contact via there (scoped by filter),
    // do it here instead since here will not be scoped by filter so user will always receive visual ack of sent succeeded,
    // then prompted with send bar locked due to filter mismatch if filter is not set correctly.
    else if (key === 'virgin') {
      // Replace virgin contact if it is currently selected and new message matches it.
      const currentContact = get(selectedContact);
      if (currentContact._id === 'virgin' && !isEmpty(data.contact)) {
        const newContact = data.contact.find(
          (c) =>
            c.route === currentContact.route &&
            c.channel === currentContact.channel &&
            c.deskId === currentContact.deskId
        );
        if (newContact) {
          // Reset search term so it get backs to normal contact list instead of search result after this is applied.
          storeUpdateIfDifferent(contactSearchTerm, '');
          tick().then(() => {
            const contactMatchesFilter = data.matchFilter;

            // Contact matches filter, wait for real data to arrive then select it, provided user doesn't select another contact in the mean time.
            if (contactMatchesFilter) {
              lastVirginInfo = newContact;
            }
            // Contact doesn't match filter, display as read only snapshot.
            else {
              newContact.lastChat = data.chat.find((c) => c._id === newContact.lastChatId);
              newContact.isVirgin = true;

              storeUpdateIfDifferent(selectedContact, newContact);
              storeUpdateIfDifferent(selectedChatList, data.chat);
            }
          });
        }
      }
    } else if (key === 'auto_reply_update') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
      }
      showDialog('Auto Reply Updated');
    } else if (key === 'filter_update') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId) {
        await loadDeskStateFromDb(selectedDeskId);
      }
      showDialog('Filter Updated');
    } else if (key === 'contact_case_update_full' || key === 'contact_case_update') {
      loadUserInfoFromDb();
    } else if (key === 'contact_lock_update') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId === data.deskId) {
        // Add new lock.
        if (data.add) {
          const existingLockIndex = get(selectedDeskContactLockList).findIndex(
            (l) => l._id === data.add._id
          );
          // Append new lock to queue.
          if (existingLockIndex === -1) {
            storeUpdateIfDifferent(selectedDeskContactLockList, [
              ...get(selectedDeskContactLockList),
              data.add,
            ]);
          }
          // Replace existing lock in queue.
          else {
            const lockList = get(selectedDeskContactLockList);
            lockList[existingLockIndex] = data.add;
            selectedDeskContactLockList.set(lockList);
          }
        }
        // Remove existing lock.
        else if (data.remove) {
          storeUpdateIfDifferent(
            selectedDeskContactLockList,
            get(selectedDeskContactLockList).filter((lock) => lock._id !== data.remove)
          );
        }
      }
    } else if (key === 'user_idle_update') {
      const selectedDeskId = get(selectedDesk)._id;
      if (selectedDeskId === data.deskId) {
        // Add new idle status.
        if (data.add) {
          const existingIdleIndex = get(selectedDeskMemberIdleList).findIndex(
            (l) => l._id === data.add._id
          );
          // Append new idle status to queue.
          if (existingIdleIndex === -1) {
            storeUpdateIfDifferent(selectedDeskMemberIdleList, [
              ...get(selectedDeskMemberIdleList),
              data.add,
            ]);
          }
          // Replace existing idle status in queue.
          else {
            const idleList = get(selectedDeskMemberIdleList);
            idleList[existingIdleIndex] = data.add;
            selectedDeskMemberIdleList.set(idleList);
          }
        }
        // Remove existing idle status.
        else if (data.remove) {
          storeUpdateIfDifferent(
            selectedDeskMemberIdleList,
            get(selectedDeskMemberIdleList).filter((idle) => idle._id !== data.remove)
          );
        }
      }
    }
  });
}

/**
 * @param {function} func
 * @param {string} loadingText
 * @param {object} payload
 * @param {boolean} payload.toastErr
 * @param {string} payload.customErrText
 */
export async function showLoadingDialog(
  func,
  loadingText = 'Loading',
  { toastErr = true, customErrText = '' } = {}
) {
  get(mainView).app.dialog.preloader(loadingText);
  try {
    await func();
  } catch (err) {
    console.log(err);
    let toastMsg = 'Unknown error, please check your internet connection and try again later';

    if (err) {
      toastMsg = err;
    }
    if (customErrText) {
      toastMsg = customErrText;
    }

    if (toastErr && toastMsg) {
      get(mainView)
        .app.toast.create({
          text: toastMsg,
          position: 'bottom',
          horizontalPosition: 'left',
          closeTimeout: 3000,
        })
        .open();
    }
  } finally {
    get(mainView).app.dialog.close();
  }
}

export function showDialog(text) {
  get(mainView)
    .app.toast.create({
      text,
      position: 'bottom',
      horizontalPosition: 'left',
      closeTimeout: 3000,
    })
    .open();
}

// Create a big window to display the flash msg.
export function showFlashMsg(content) {
  mainViewNavigate('/flash-popup', { content });
}
