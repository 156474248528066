<script>
  import { Preloader } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';
  import InfiniteLoading from './InfiniteLoading.svelte';
  import ContactListContent from './ContactListContent.svelte';
  import {
    selectedContactList,
    loadMoreContact,
    contactListContainer,
    contactSearchTerm,
    contactAdvancedSearchTerm,
    remoteCompositeSearch,
    remoteFetchVirginContact,
    selectedDeskContactFilter,
    selectedDesk,
    multiView,
    selectedContact,
    unselectContact,
    storeUpdateIfDifferent,
    contactOnSelectSetupChatList,
    mainViewNavigate,
  } from '../js/store';
  import { isPhoneNumber } from '../js/util';
  import { contactSearchSize, advancedSearchPreviewSize } from '../js/config';
  import { onMount } from 'svelte';

  let lastSearchId = 0;
  let initialSearchComplete = false;
  let contactSearchList = [];
  function remoteSearch(term) {
    const searchId = Date.now();
    initialSearchComplete = false;

    // Clear chat list on search term changes so cursor will always start from top.
    contactSearchList = [];

    if (term) {
      if (searchId > lastSearchId) {
        lastSearchId = searchId;
      }
      remoteCompositeSearch({
        deskId: $selectedDesk._id,
        field: { contact: { term, size: contactSearchSize } },
        filter: $selectedDeskContactFilter,
      }).then((result) => {
        // Check whether the searched term had changed, only show result if not changed.
        if ($contactSearchTerm) {
          if (lastSearchId === searchId) {
            contactSearchList = result.contact;
            initialSearchComplete = true;
          }
        } else {
          contactSearchList = [];
        }
      });
    }
  }

  let lastAdvancedSearchId = 0;
  let initialAdvancedSearchComplete = false;
  let contactAdvancedSearchList = {};
  let advancedSearchResultEmpty = true;
  function advancedRemoteSearch(term) {
    const searchId = Date.now();
    initialAdvancedSearchComplete = false;

    // Clear chat list on search term changes so cursor will always start from top.
    contactAdvancedSearchList = {};
    advancedSearchResultEmpty = true;

    if (term) {
      if (searchId > lastAdvancedSearchId) {
        lastAdvancedSearchId = searchId;
      }
      remoteCompositeSearch({
        deskId: $selectedDesk._id,
        field: { any: { term, size: advancedSearchPreviewSize } },
        filter: $selectedDeskContactFilter,
      }).then((result) => {
        // Check whether the searched term had changed, only show result if not changed.
        if (!isEmpty(contactAdvancedSearchTerm)) {
          if (lastAdvancedSearchId === searchId) {
            contactAdvancedSearchList = result;
            initialAdvancedSearchComplete = true;

            Object.keys(contactAdvancedSearchList).forEach((key) => {
              if (key !== 'count' && !isEmpty(contactAdvancedSearchList[key])) {
                advancedSearchResultEmpty = false;
              }
            });
          }
        } else {
          contactAdvancedSearchList = {};
          advancedSearchResultEmpty = true;
        }
      });
    }
  }

  let lastVirginFetchId = 0;
  let initialVirginFetchComplete = false;
  let searchTermMatchesVirginContactPattern = false;
  let virginContactList = [];
  function fetchVirginContact(term) {
    const fetchId = Date.now();
    initialVirginFetchComplete = false;
    searchTermMatchesVirginContactPattern = false;
    virginContactList = [];

    if (term) {
      if (fetchId > lastVirginFetchId) {
        lastVirginFetchId = fetchId;
      }

      // For new WhatsApp contact, check whether search term is a valid phone number.
      if (isPhoneNumber(term)) {
        searchTermMatchesVirginContactPattern = true;
        // Fetch list of virgin contact that matches the search term for each channel if applicable.
        remoteFetchVirginContact(term, $selectedDesk._id).then((result) => {
          virginContactList = result;
        });
      }
    }
  }

  onMount(() => {
    const unsubscribeFnList = [];

    // Trigger search again whenever search term or filter is modified.
    unsubscribeFnList.push(
      contactSearchTerm.subscribe((term) => {
        remoteSearch(term);
        fetchVirginContact(term);
      })
    );
    unsubscribeFnList.push(
      contactAdvancedSearchTerm.subscribe((term) => {
        advancedRemoteSearch(term);
      })
    );
    unsubscribeFnList.push(
      selectedDeskContactFilter.subscribe(() => {
        if ($contactSearchTerm) {
          remoteSearch($contactSearchTerm);
        } else {
          advancedRemoteSearch($contactAdvancedSearchTerm);
        }
      })
    );

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });

  function listItemOnClickSelectUnselectHandler(contact) {
    // Toggle contact select unselect.
    // If clicked contact is already the current selected contact.
    if ($selectedContact._id === contact._id) {
      // Jump to relevant chat on click, instead of unselecting the contact if it is a search result.
      if ($contactSearchTerm || $contactAdvancedSearchTerm) {
        contactOnSelectSetupChatList(contact);
      } else {
        // User clicked on normal contact, unselect it if user is on big screen.
        if ($multiView) {
          unselectContact();
        }
      }
    }
    // User clicked on a different contact, select it.
    else {
      storeUpdateIfDifferent(selectedContact, contact);
      // Navigate to chat page if on mobile. On desktop no navigation necessary.
      if (!$multiView) {
        mainViewNavigate('/chat');
      }
    }
  }
</script>

<!-- Make overflow start from within the container instead of including the whole page height -->
<div
  class="list media-list no-hairlines h-full overflow-auto overscroll-y-contain"
  bind:this="{$contactListContainer}"
>
  <ul>
    {#if isEmpty($contactAdvancedSearchTerm)}
      <!-- Display send to new virgin contact ui -->
      {#if !isEmpty(virginContactList)}
        <ContactListContent
          type="virgin"
          itemList="{virginContactList}"
          showTitle
          on:click="{(e) => {
            listItemOnClickSelectUnselectHandler(e.detail);
          }}"
        />
      {/if}
      <!-- Display either search result or normal as normal contact list, search result gets priority. -->
      <ContactListContent
        type="contact"
        itemList="{$contactSearchTerm ? contactSearchList : $selectedContactList}"
        on:click="{(e) => {
          listItemOnClickSelectUnselectHandler(e.detail);
        }}"
      />

      <!-- Draw placeholder loading icon on initial search, shared with loading virgin contact from whatsapp. -->
      <!-- Both search result and virgin number check must be completed before hiding this loader. -->
      <!-- If virgin number check is not needed, then can hide as soon as search result is loaded -->
      {#if $contactSearchTerm && !initialSearchComplete && (searchTermMatchesVirginContactPattern ? !initialVirginFetchComplete : true)}
        <li>
          <span class="flex w-full justify-center py-3">
            <Preloader />
          </span>
        </li>
      {:else if $contactSearchTerm && isEmpty(contactSearchList) && isEmpty(virginContactList)}
        <div class="flex w-full justify-center py-3">No Result</div>
        <!-- Only show loader if initial contact list had been drawn to improve perceived performance by showing loading icons less frequently -->
      {:else if $contactSearchTerm && !isEmpty(contactSearchList) ? contactSearchList.length : $selectedContactList.length}
        <InfiniteLoading
          distance="600"
          on:infinite="{(event) => {
            if ($contactSearchTerm) {
              // Already reached end.
              if (contactSearchList.length < contactSearchSize) {
                return event.detail.complete();
              }

              const searchId = lastSearchId;
              // Load more search result.
              remoteCompositeSearch({
                deskId: $selectedDesk._id,
                field: {
                  contact: {
                    term: $contactSearchTerm,
                    size: contactSearchList.length + contactSearchSize,
                  },
                },
                filter: $selectedDeskContactFilter,
              })
                .then((result) => {
                  // Check whether the searched term had changed, only show result if not changed.
                  if ($contactSearchTerm) {
                    if (lastSearchId === searchId) {
                      contactSearchList = result.contact;
                    }
                    if (result.contact.length % contactSearchSize) {
                      event.detail.complete();
                      console.log('Search reached end');
                    } else {
                      console.log('More search loaded');
                      event.detail.loaded();
                    }
                  }
                })
                .catch(() => {
                  event.detail.error();
                });
            } else {
              // Load more contact.
              loadMoreContact()
                .then((completed) => {
                  if (completed) {
                    event.detail.complete();
                    console.log('Contact reached end fully loaded');
                  } else {
                    event.detail.loaded();
                  }
                })
                .catch(() => {
                  event.detail.error();
                });
            }
          }}"
        >
          <span slot="noResults"></span>
          <span slot="noMore"></span>
          <!-- Framework7 default platform specific infinite load spinner -->
          <span slot="spinner" class="flex w-full justify-center py-3">
            <Preloader />
          </span>
          <span slot="error" let:attemptLoad>
            <span
              on:click="{attemptLoad}"
              class="flex justify-center cursor-pointer text-blue-primary font-medium py-3 px-3"
              >Retry</span
            >
          </span>
        </InfiniteLoading>
      {/if}
    {:else}
      <!-- Display advanced search result -->
      <!-- Draw placeholder loading icon on initial search -->
      {#if $contactAdvancedSearchTerm && !initialAdvancedSearchComplete}
        <li>
          <span class="flex w-full justify-center py-3">
            <Preloader />
          </span>
        </li>
      {:else if $contactAdvancedSearchTerm && advancedSearchResultEmpty}
        <div class="flex w-full justify-center py-3">No Result</div>
      {:else if $contactAdvancedSearchTerm && !advancedSearchResultEmpty}
        {#each Object.keys(contactAdvancedSearchList) as target}
          {#if target !== 'count' && target !== 'contact' && !isEmpty(contactAdvancedSearchList[target])}
            <ContactListContent
              type="{target}"
              count="{contactAdvancedSearchList.count[target]}"
              itemList="{contactAdvancedSearchList[target]}"
              showTitle
              showMore
              on:click="{(e) => {
                listItemOnClickSelectUnselectHandler(e.detail);
              }}"
            />
          {/if}
        {/each}
      {/if}
    {/if}
  </ul>
</div>
