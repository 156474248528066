<script>
  import { Page } from 'framework7-svelte';
  import { mainViewNavigateRoot } from '../js/store';
</script>

<Page
  on:pageAfterIn="{(e) => {
    console.log('Invalid navigation, 404, auto redirect back to root');
    mainViewNavigateRoot(true);
  }}"
/>
