<script>
  import { Page, Navbar, NavRight, Link, List } from 'framework7-svelte';
  import { cloneDeep } from 'lodash-es';
  import sanitizeHtml from 'sanitize-html';
  import ListItemLink from '../components/ListItemLink.svelte';
  import SquareImage from '../components/SquareImage.svelte';
  import { channelGetDisplayName, getPlatformThumbnail, channelStatusTextMarkup } from '../js/util';
  import {
    mainView,
    showLoadingDialog,
    selectedChannel,
    remoteWhatsAppPersonalUnlink,
    remoteWhatsAppPersonalRestart,
    mainViewNavigate,
    selectedDesk,
  } from '../js/store';
  import { deleteChannel } from '../js/channel';

  $: origin = cloneDeep($selectedChannel);
  $: channelName = channelGetDisplayName(origin);
  $: channelProfilePic = origin
    ? origin.profilePic || getPlatformThumbnail({ platform: origin.platform })
    : '';

  $: allowReboot = true; //Temporary allow reboot at any time.  $selectedChannel.linked;
</script>

<Page>
  <Navbar title="Channel" backLink>
    <NavRight>
      <Link
        iconF7="trash"
        on:click="{() => {
          deleteChannel(origin);
        }}"
      />
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <div class="list media-list chevron-center no-hairlines">
      <ul>
        <li>
          <span
            on:click="{() => {
              mainViewNavigate('edit');
            }}"
            class="item-link item-content"
          >
            <div class="item-media">
              <SquareImage src="{channelProfilePic}" rounded />
            </div>
            <div class="item-inner">
              <div class="item-title">{channelName}</div>
              <div class="item-subtitle">
                Status:
                {#if origin}
                  {@html sanitizeHtml(
                    channelStatusTextMarkup(origin.status, origin.mergeProgress).markup,
                    {
                      allowedTags: ['span', 'div'],
                      allowedAttributes: {
                        '*': ['class', 'style'],
                      },
                      disallowedTagsMode: 'recursiveEscape',
                    }
                  ) || ''}
                {/if}
              </div>
            </div>
          </span>
        </li>
      </ul>
    </div>
    <List>
      {#if origin.platform === 'whatsapp'}
        <ListItemLink
          title="{origin.linked ? 'Unlink WhatsApp' : 'Link WhatsApp'}"
          iconF7="qrcode"
          media
          on:click="{() => {
            // Channel linked, on click allow unlink.
            if (origin.linked) {
              $mainView.app.dialog
                .create({
                  title: '',
                  text: 'Unlink Channel',
                  closeByBackdropClick: true,
                  buttons: [
                    {
                      text: 'Cancel',
                      keyCodes: [27],
                    },
                    {
                      text: 'Unlink',
                      color: 'red',
                      bold: true,
                      keyCodes: [13],
                      onClick: () => {
                        showLoadingDialog(async () => {
                          await remoteWhatsAppPersonalUnlink(origin._id);
                        }, 'Processing');
                      },
                    },
                  ],
                })
                .open();
            }
            // Channel not linked, on click allow scan qr code to link channel.
            else {
              mainViewNavigate('scan');
            }
          }}"
        />
        <ListItemLink
          title="Reboot"
          disabled="{!allowReboot}"
          iconF7="return"
          media
          on:click="{() => {
            $mainView.app.dialog
              .create({
                title: '',
                text: 'Reboot Channel',
                closeByBackdropClick: true,
                buttons: [
                  {
                    text: 'Cancel',
                    keyCodes: [27],
                  },
                  {
                    text: 'Reboot',
                    bold: true,
                    keyCodes: [13],
                    onClick: () => {
                      showLoadingDialog(async () => {
                        await remoteWhatsAppPersonalRestart(origin._id);
                      }, 'Processing');
                    },
                  },
                ],
              })
              .open();
          }}"
        />
        <ListItemLink title="Queue" link="queue" iconF7="calendar" media />
        {#if $selectedDesk.autoReply}
          <ListItemLink
            title="Auto Replies"
            link="auto-reply"
            iconF7="arrowshape_turn_up_left"
            media
          />
        {/if}
      {/if}
    </List>
  </div>
</Page>
