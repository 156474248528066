<script>
  import { Page, Navbar, List, ListItem, BlockTitle, NavRight, Preloader } from 'framework7-svelte';
  import { upperFirst, isEmpty, cloneDeep } from 'lodash-es';
  import { writable } from 'svelte/store';
  import ListItemLink from '../components/ListItemLink.svelte';
  import AttachmentUploadPreview from '../components/AttachmentUploadPreview.svelte';
  import ContactFilterDetail from '../components/ContactFilterDetail.svelte';
  import {
    selectedDeskContactFilter,
    selectedBroadcast,
    showDialog,
    showLoadingDialog,
    selectedDeskChannelList,
    remoteBroadcastRecipientGenerate,
  } from '../js/store';
  import { onMount } from 'svelte';
  import { saveFile } from '../js/util';

  const contactFilter = writable($selectedBroadcast.filter);

  $: recipientAfter =
    $selectedBroadcast.recipient && $selectedBroadcast.recipient.length
      ? $selectedBroadcast.recipient.length
      : 'None';

  let fetchInProgress = false;
  function fetchRecipientFromRemote() {
    if ($selectedBroadcast.source === 'filter') {
      if (!$selectedBroadcast.channelId) {
        showDialog('Please select a target channel first');
      } else {
        if (!fetchInProgress) {
          fetchInProgress = true;

          showLoadingDialog(async () => {
            const filter = cloneDeep($contactFilter);
            filter.channel = [
              $selectedDeskChannelList.find((c) => c._id === $selectedBroadcast.channelId).route,
            ];

            const result = await remoteBroadcastRecipientGenerate(filter);
            $selectedBroadcast.recipient = result;
            fetchInProgress = false;
            showDialog(
              `${$selectedBroadcast.recipient.length} ${
                $selectedBroadcast.recipient.length > 1 ? 'contacts' : 'contact'
              } matched filter`
            );
          }, 'Fetching Recipient');
        }
      }
    }
  }

  onMount(() => {
    const unsubscribeFnList = [];
    // Fetch recipient from remote whenever filter changes.
    let skippedFirstLoad = false;
    unsubscribeFnList.push(
      contactFilter.subscribe((ss) => {
        if (skippedFirstLoad) {
          fetchRecipientFromRemote();
        }
        skippedFirstLoad = true;
      })
    );

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });

  let addSelectedFiles;
  let selectedFileList;

  // Extract contact list from file content.
  $: if (!isEmpty(selectedFileList)) {
    const targetFile = selectedFileList[0];
    selectedFileList = [];
    showLoadingDialog(() => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          // Convert all newline and spaces into comma,
          // remove all stray symbols and convert it into a local array variable separated by comma,
          // and remove empty entry.
          const fileContent = event.target.result;
          $selectedBroadcast.recipient = fileContent
            .replace(/(?:\r\n|\r|\n)/g, ',')
            .replace(/ /g, ',')
            .replace(/\+/g, '')
            .replace(/-/g, '')
            .split(',')
            .filter((f) => f);
          showDialog(
            `Found ${$selectedBroadcast.recipient.length} ${
              $selectedBroadcast.recipient.length > 1 ? 'contacts' : 'contact'
            } from file`
          );
          resolve();
        };
        reader.readAsText(targetFile);
      });
    }, 'Parsing Content');
  }
</script>

<Page>
  <Navbar title="Recipients" backLink>
    <NavRight>
      {#if fetchInProgress}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a class="link icon-only" href="#">
          <Preloader />
        </a>
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <List noHairlines>
      <ListItem
        title="Source"
        smartSelect
        smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
      >
        <!-- svelte-ignore a11y-no-onchange -->
        <select
          name="source"
          bind:value="{$selectedBroadcast.source}"
          on:change="{(e) => {
            // Auto fetch recipient from remote when selected to source from filter.
            if ($selectedBroadcast.source === 'filter') {
              fetchRecipientFromRemote();
            }
          }}"
        >
          {#each ['filter', 'custom'] as source}
            <option selected="{$selectedBroadcast.source === source}" value="{source}">
              {upperFirst(source)}
            </option>
          {/each}
        </select>
      </ListItem>
      <ListItemLink
        title="Matched Recipients"
        after="{recipientAfter}"
        on:click="{() => {
          if (!isEmpty($selectedBroadcast.recipient)) {
            saveFile({
              blob: new window.Blob([$selectedBroadcast.recipient.join(', ')], {
                type: 'text/plain;charset=utf-8',
              }),
              ext: 'csv',
              fileName: 'broadcast-list',
              autoBom: true,
            });
          } else {
            showDialog('No Matched Recipient');
          }
        }}"
      />
      {#if $selectedBroadcast.source === 'filter'}
        <BlockTitle>Filter</BlockTitle>
        <ContactFilterDetail
          contactFilter="{contactFilter}"
          showCaseStatusFilter
          showCopyFromOther="{{
            show: true,
            title: 'Copy From Desk Filter',
            confirmTitle: 'Replace current filter setting with desk contact filter?',
            source: selectedDeskContactFilter,
          }}"
        />
      {:else if $selectedBroadcast.source === 'custom'}
        <BlockTitle>Upload a contact list file</BlockTitle>
        <AttachmentUploadPreview
          bind:addSelectedFiles
          bind:selectedFileList
          limit="{1}"
          allowUnknownFileType
        />
      {/if}
    </List>
  </div>
</Page>
