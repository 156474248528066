<script>
  import ImageEditor from 'tui-image-editor';
  import { Page, Navbar, NavRight, Link } from 'framework7-svelte';
  import { createEventDispatcher, onMount } from 'svelte';

  export let selectedFile;

  const dispatch = createEventDispatcher();

  let imageEditorDiv;
  let instance;

  let allowUndo = false;
  let allowRedo = false;

  function updateTopBarBtn() {
    allowUndo = instance && !instance.isEmptyUndoStack();
    allowRedo = instance && !instance.isEmptyRedoStack();
  }

  onMount(() => {
    // Only support editing image.
    if (selectedFile && selectedFile.type.includes('image')) {
      instance = new ImageEditor(imageEditorDiv, {
        includeUI: {
          loadImage: {
            path: URL.createObjectURL(selectedFile),
            name: selectedFile.name,
          },
          theme: {
            'header.display': 'none',
          },
          menu: ['crop', 'flip', 'rotate', 'draw', 'text'],
          menuBarPosition: 'bottom',
        },
        cssMaxWidth: window.innerWidth,
        // Width - 60 (f7 top bar) - 150 (editor expanded ui) - 64 (editor bottom toolbar)
        cssMaxHeight: window.innerHeight - 60 - 150 - 64,
        usageStatistics: false,
      });

      // Refresh redo undo btn status if history had changed.
      instance.on('redoStackChanged undoStackChanged', updateTopBarBtn);
      updateTopBarBtn();
    }

    return () => {
      if (instance) {
        instance.destroy();
        instance = undefined;
      }
    };
  });
</script>

<style lang="scss">
  // Hide tui image editor top bar.
  :global(.tui-image-editor-help-menu) {
    display: none !important;
  }
  :global(.tui-image-editor-main) {
    top: 0px !important;
  }
  // Hide broken color picker.
  :global(div.tui-colorpicker-clearfix) {
    display: none;
  }
</style>

<Page>
  <Navbar title="Edit" backLink>
    <NavRight>
      <Link
        iconF7="arrowshape_turn_up_left"
        class="{allowUndo ? '' : 'disabled'}"
        on:click="{() => {
          instance.undo();
        }}"
      />
      <Link
        iconF7="arrowshape_turn_up_right"
        class="{allowRedo ? '' : 'disabled'}"
        on:click="{() => {
          instance.redo();
        }}"
      />
      <Link
        iconF7="checkmark_alt"
        on:click="{() => {
          // Save btn clicked.
          dispatch('save', instance.toDataURL('image/jpeg'));

          // Save btn clicked and there is changes.
          if (allowUndo) {
            dispatch('change', instance.toDataURL('image/jpeg'));
          }
        }}"
      />
    </NavRight>
  </Navbar>
  <div class="w-full h-full" bind:this="{imageEditorDiv}"></div>
</Page>
