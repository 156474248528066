<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import sanitizeHtml from 'sanitize-html';
  import { isEmpty } from 'lodash-es';
  import SquareImage from './SquareImage.svelte';
  import {
    selectedDeskMemberList,
    selectedContact,
    multiView,
    darkMode,
    selectedDeskContactLockList,
    selectedDeskChannelList,
  } from '../js/store';
  import {
    extractChatShortDescription,
    userIdToUserDeskScopedName,
    getPlatformThumbnail,
    getAckIcon,
    contactPickProfilePic,
    highlightText,
  } from '../js/util';
  import { uccPlaceholderLogo, enablePinnedChatFunction } from '../js/config';

  export let hoverHighlight = false;
  export let highlightSelectedContact = false;
  export let searchTerm = '';
  export let title = '';
  // If subtitle is not specified, will infer from chat and display alongside with additional metadata (ack, type)
  export let subtitle = '';
  export let after = '';
  export let contact = {};
  export let chat = {};
  export let highlightTitle = false;
  export let highlightSubtitle = false;
  export let showSender = false;
  export let showAck = false;
  export let showTypeIcon = false;
  export let showTypeText = false;
  export let showContactImage = false;
  export let showMetadataIcon = false;
  export let highlightSender = false;

  const dispatch = createEventDispatcher();

  const chatSanitizeOption = {
    allowedTags: ['mark', 'i'],
    allowedAttributes: {
      mark: ['style'],
      i: ['class'],
    },
    disallowedTagsMode: 'recursiveEscape',
  };

  let itemHoverBg = '';
  let itemBg = '';
  let textSelectColor = '';
  $: if (hoverHighlight && $multiView) {
    if ($darkMode) {
      itemHoverBg = 'hover:bg-gray-800';
    } else {
      itemHoverBg = 'hover:bg-gray-300';
    }
  } else {
    itemHoverBg = '';
  }
  $: if (highlightSelectedContact && $selectedContact._id === contact._id) {
    if ($darkMode) {
      itemBg = 'bg-gray-800';
      textSelectColor = 'text-gray-500';
    } else {
      itemBg = 'bg-gray-300';
      textSelectColor = 'text-gray-700';
    }
  } else {
    itemBg = '';
    textSelectColor = 'text-gray-600';
  }

  /**
   * Get list of lock for related to this contact and make sure they are not expired.
   * If exists then is means contact is locked.
   */
  function isContactLocked(c) {
    return !isEmpty(
      $selectedDeskContactLockList.filter(
        (lock) => lock.contactId === c._id && new Date(lock.expireAt) > Date.now()
      )
    );
  }
  function generateContactBadgeClass(c) {
    if (!c) {
      return '';
    }

    const contactLocked = isContactLocked(c);
    if (contactLocked) {
      return 'locked';
    }
    if (c.status === 'O') {
      return '';
    }
    return 'hidden';
  }
  // Update badge on lock changes.
  let badgeClass = generateContactBadgeClass(contact);

  onMount(() => {
    const unsubscribeFnList = [];

    unsubscribeFnList.push(
      selectedDeskContactLockList.subscribe(() => {
        badgeClass = generateContactBadgeClass(contact);
      })
    );

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });
</script>

<style lang="scss">
  .ucc-badge {
    color: #09d261;
    background-color: #09d261;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));

    &.locked {
      color: #fbc100;
      background-color: #fbc100;
    }
  }
</style>

<li
  class="cursor-pointer select-none {itemHoverBg} {itemBg}"
  on:click="{() => {
    dispatch('click');
  }}"
>
  <div class="item-content">
    {#if showContactImage}
      <div class="item-media">
        <SquareImage
          rounded
          src="{contactPickProfilePic(contact)}"
          srcFallback="{uccPlaceholderLogo}"
          bottomRight="{{
            src: getPlatformThumbnail({
              platform: contact.platform,
              route: contact.channel,
              channelList: $selectedDeskChannelList,
            }),
            border: true,
          }}"
          topRight="{{
            f7icon:
              Array.isArray(contact.tag) && contact.tag.includes('_archive') ? 'archivebox' : '',
          }}"
        />
      </div>
    {/if}
    <div class="item-inner">
      <div class="item-title-row">
        <div class="item-title select-none">
          {@html sanitizeHtml(highlightText(title, highlightTitle ? searchTerm : ''), {
            allowedTags: ['mark'],
            allowedAttributes: {
              mark: ['style'],
            },
            disallowedTagsMode: 'recursiveEscape',
          })}
        </div>
        <div class="item-after select-none">
          {after}
        </div>
      </div>
      <div class="flex">
        <div class="flex flex-grow truncate select-none space-x-1 {textSelectColor}">
          {#if subtitle}
            {@html sanitizeHtml(
              highlightSubtitle ? highlightText(subtitle, searchTerm) : subtitle,
              chatSanitizeOption
            )}
          {:else}
            {#if showAck && chat.direction === 'send' && getAckIcon(chat, $darkMode)}
              <img
                src="{getAckIcon(chat, $darkMode)}"
                class="inline"
                alt="ack"
                on:contextmenu="{(e) => {
                  e.preventDefault();
                }}"
              />
            {/if}
            {#if showSender && chat.direction === 'send'}
              <span class="font-semibold"
                >{@html sanitizeHtml(
                  highlightText(
                    userIdToUserDeskScopedName(chat.senderId, $selectedDeskMemberList),
                    highlightSender ? searchTerm : ''
                  ),
                  chatSanitizeOption
                )}:</span
              >
            {/if}
            <!-- Highlight matched chat only, ignore all miniapp content and media match -->
            <span class="truncate">
              {#if contact.highlight && contact.collection === 'message'}
                {@html sanitizeHtml(
                  extractChatShortDescription(
                    chat,
                    highlightSubtitle ? searchTerm : '',
                    showTypeIcon,
                    showTypeText
                  ),
                  chatSanitizeOption
                )}
              {:else}
                {@html sanitizeHtml(
                  extractChatShortDescription(
                    chat,
                    highlightSubtitle ? searchTerm : '',
                    showTypeIcon,
                    showTypeText
                  ),
                  chatSanitizeOption
                )}
              {/if}
            </span>
          {/if}
        </div>
        {#if showMetadataIcon}
          <div class="badge ucc-badge ml-1 {badgeClass}"></div>
          {#if Array.isArray(contact.tag) && contact.tag.includes('_sticky') && enablePinnedChatFunction}
            <div>
              <i class="f7-icons text-icon-primary text-sm absolute top-0 right-1">pin</i>
            </div>
          {/if}
        {/if}
      </div>
    </div>
  </div>
</li>
