<script>
  import { Page, Navbar, NavRight, Link } from 'framework7-svelte';
  import { cloneDeep } from 'lodash-es';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ProfileImage from '../components/ProfileImage.svelte';
  import { channelGetDisplayName, getPlatformThumbnail } from '../js/util';
  import {
    showLoadingDialog,
    fileUpload,
    selectedDesk,
    remoteChannelInfoUpdate,
    selectedChannel,
    mainViewNavigateBack,
  } from '../js/store';

  $: cloned = cloneDeep($selectedChannel);
  $: originChannelName = channelGetDisplayName(cloned);
  $: originProfilePic = cloned
    ? cloned.profilePic || getPlatformThumbnail({ platform: cloned.platform })
    : '';
  $: channelName = originChannelName;
  $: channelProfilePic = originProfilePic;
  let channelProfilePicFile;

  $: edited = channelName !== originChannelName || channelProfilePic !== originProfilePic;
</script>

<Page>
  <Navbar title="Profile" backLink>
    <NavRight>
      {#if edited}
        <Link
          iconF7="checkmark_alt"
          on:click="{() => {
            showLoadingDialog(async () => {
              const payload = {};

              // If using default profile pic, no need to upload the pic.
              if (channelProfilePic === getPlatformThumbnail({ platform: cloned.platform })) {
                payload.profilePic = '';
              } else if (channelProfilePicFile) {
                payload.profilePic = await fileUpload({
                  file: channelProfilePicFile,
                  deskId: $selectedDesk._id,
                  emitProgress: true,
                  showDefaultOverlayProgress: true,
                  convertToProfilePic: true,
                });
              }

              if (originChannelName !== channelName) {
                payload.name = channelName;
              }

              await remoteChannelInfoUpdate(cloned._id, payload);
              mainViewNavigateBack();
            }, 'Saving');
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <ProfileImage
      src="{channelProfilePic}"
      edit
      rounded
      on:change="{(event) => {
        channelProfilePicFile = event.detail.file;
        channelProfilePic = event.detail.base64;
      }}"
    />

    <div class="list no-hairlines">
      <ul>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media"><i class="icon demo-list-icon"></i></div>
          <div class="item-inner">
            <div class="item-title item-label">Name</div>
            <div class="item-input-wrap">
              <input
                type="text"
                placeholder="Channel Nickname"
                required
                validate
                bind:value="{channelName}"
              />
              <div class="item-input-info">Visible in filter and info page</div>
            </div>
          </div>
        </li>
        {#if cloned && channelProfilePic !== getPlatformThumbnail({ platform: cloned.platform })}
          <ListItemLink
            title="Restore default profile picture"
            iconF7="return"
            media
            on:click="{() => {
              channelProfilePic = getPlatformThumbnail({ platform: cloned.platform });
            }}"
          />
        {/if}
      </ul>
    </div>
  </div>
</Page>
