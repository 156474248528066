<script>
  import { Page, Navbar, List } from 'framework7-svelte';
  import { selectedDeskContactFilter } from '../js/store';
  import ContactFilterDetail from '../components/ContactFilterDetail.svelte';
</script>

<Page>
  <Navbar title="Contact Filter" backLink />

  <div class="h-full overflow-auto">
    <List>
      <ContactFilterDetail contactFilter="{selectedDeskContactFilter}" showChannelFilter />
    </List>
  </div>
</Page>
