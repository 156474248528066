<script>
  import { Page, Navbar, List, Button, NavRight, Link } from 'framework7-svelte';
  import {
    showLoadingDialog,
    remoteFetchPromoCode,
    remoteGeneratePromoCode,
    loggedIn,
    fullyLoaded,
  } from '../js/store';
  import { generateRandomString, mongoIdToTimestamp, dateToDayMonthYearHourMin } from '../js/util';

  $: code = '';
  $: edited = code;
  $: promoCodeList = [];
  $: if ($loggedIn && $fullyLoaded) {
    updatePromoCodeList();
  }

  function updatePromoCodeList() {
    remoteFetchPromoCode().then((result) => {
      promoCodeList = result;
    });
  }
</script>

<Page>
  <Navbar title="Promo Code" backLink>
    <NavRight>
      <Link
        class="{edited ? '' : 'disabled'}"
        iconF7="checkmark_alt"
        on:click="{() => {
          showLoadingDialog(async () => {
            await remoteGeneratePromoCode({
              code,
              discountPercent: 100,
              maxRedeemLimit: 1,
              allowReuse: false,
              expireAt: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
            });
          }, 'Processing').then(() => {
            updatePromoCodeList();
            code = '';
          });
        }}"
      />
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <List>
      <li class="item-content item-input item-input-with-info">
        <div class="item-inner">
          <div class="item-title item-label">Promo Code</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Code" bind:value="{code}" />
          </div>
        </div>
      </li>
      <Button
        on:click="{() => {
          code = generateRandomString(8, 'alpha').toUpperCase();
        }}"
      >
        Generate Random Code
      </Button>
    </List>

    <div class="data-table">
      <table>
        <thead>
          <tr>
            <th class="label-cell">Code</th>
            <th class="label-cell">Used</th>
            <th class="label-cell">Expiry</th>
            <th class="label-cell">Created</th>
          </tr>
        </thead>
        <tbody>
          {#each promoCodeList as promoCode}
            <tr>
              <td class="label-cell select-text">{promoCode.code}</td>
              <td class="label-cell">{promoCode.redeemedCount}</td>
              <td class="label-cell"
                >{dateToDayMonthYearHourMin(new Date(promoCode.expireAt), true)}</td
              >
              <td class="label-cell"
                >{dateToDayMonthYearHourMin(mongoIdToTimestamp(promoCode._id), true)}</td
              >
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
</Page>
