<script>
  import { Page } from 'framework7-svelte';
  import SortSearchList from '../components/SortSearchList.svelte';
  import {
    selectedDeskChannelList,
    selectedChannel,
    mainViewNavigate,
    storeUpdateIfDifferent,
  } from '../js/store';
  import { channelGetDisplayName, getPlatformThumbnail, channelStatusTextMarkup } from '../js/util';

  // Skip 'ucc' internal desk chat.
  $: channelList = $selectedDeskChannelList
    .filter((channel) => channel.platform !== 'ucc')
    .map((channel) => {
      return {
        title: channelGetDisplayName(channel),
        subtitle: `Status: ${
          channelStatusTextMarkup(channel.status, channel.mergeProgress).markup
        }`,
        image: channel.profilePic || getPlatformThumbnail({ platform: channel.platform }),
        data: channel,
      };
    });
</script>

<Page>
  <SortSearchList
    title="Channels"
    showCounter
    itemList="{channelList}"
    on:click="{(e) => {
      storeUpdateIfDifferent(selectedChannel, e.detail);
      mainViewNavigate('/channel');
    }}"
    addUrl="add"
    showSearchBtn
    showAddBtn
    rounded
    media
  />
</Page>
