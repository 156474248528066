<script>
  import { createEventDispatcher } from 'svelte';
  import { Swiper, SwiperSlide, Block } from 'framework7-svelte';
  import { chunk, isEmpty } from 'lodash-es';
  import QuadSquareGrid from './QuadSquareGrid.svelte';
  import { mainViewNavigate } from '../js/store';

  const dispatch = createEventDispatcher();

  export let paginationBreak = 8;
  export let maxPage = 5;
  export let itemList = [];
  export let expandUrl = '';
  export let expandProps = {};
  export let addUrl = '#';
  export let addProps = {};
  export let showAddBtn = false;
  export let noContentText = 'No content';
  export let rounded = false;
</script>

<div>
  <!-- Split input list into 8 per page -->
  {#if !isEmpty(itemList)}
    <Swiper pagination="{itemList.length > paginationBreak}" class="pb-8 pt-1">
      {#each chunk(itemList, paginationBreak).slice(0, maxPage) as paginatedItemList}
        <SwiperSlide>
          <QuadSquareGrid data="{paginatedItemList}" on:click rounded="{rounded}" />
        </SwiperSlide>
      {/each}
    </Swiper>
  {:else}
    <Swiper class="pb-8 pt-1">
      <Block>{noContentText}</Block>
    </Swiper>
  {/if}
  <span class="absolute bottom-0 px-5 z-10 w-full">
    {#if showAddBtn}
      <span
        class="f7-icons relative cursor-pointer float-left ripple opacity-75"
        on:click="{() => {
          dispatch('add');
          mainViewNavigate(addUrl, addProps);
        }}">plus</span
      >
    {/if}
    {#if expandUrl}
      <span
        class="f7-icons relative cursor-pointer float-right ripple opacity-75"
        on:click="{() => {
          dispatch('expand');
          mainViewNavigate(expandUrl, expandProps);
        }}">expand</span
      >
    {/if}
  </span>
</div>
