<script>
  import {
    Page,
    Navbar,
    List,
    ListItem,
    BlockTitle,
    NavRight,
    Link,
    Chip,
    Actions,
    ActionsGroup,
    Preloader,
  } from 'framework7-svelte';
  import { writable } from 'svelte/store';
  import { cloneDeep, isEmpty, upperFirst, omit } from 'lodash-es';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ActionsButtonIos from '../components/ActionButtonIos.svelte';
  import AttachmentUploadPreview from '../components/AttachmentUploadPreview.svelte';
  import {
    mainView,
    mainViewNavigateBack,
    showLoadingDialog,
    fileUpload,
    selectedDesk,
    remoteCreateBroadcast,
    remoteBroadcastUpdate,
    remoteFetchBroadcastDetail,
    mainViewNavigate,
    selectedDeskChannelList,
    generateDefaultDeskContactFilter,
    selectedBroadcast,
    showDialog,
    darkMode,
  } from '../js/store';
  import {
    openExternalUrl,
    channelGetDisplayName,
    channelGetDisplayRoute,
    getPlatformThumbnail,
    channelStatusTextMarkup,
    saveFile,
  } from '../js/util';
  import { fetchBroadcastDetailIntervalMs } from '../js/config';

  // Determine whether user want to edit or create new broadcast by checking whether it has _id broadcast id.
  // Only saved broadcast have _id.
  const add = isEmpty($selectedBroadcast) || !$selectedBroadcast._id;

  // Default template.
  if (isEmpty($selectedBroadcast)) {
    $selectedBroadcast = {
      text: '',
      media: '',
      channelId: '',
      paused: false,
      remark: '',
      recipient: [],
      filter: generateDefaultDeskContactFilter($selectedDesk._id),
      source: 'custom', // 'filter'|'custom'
      priority: 'low',
    };
  }
  // Clone origin to detect changes.
  const originBroadcast = cloneDeep($selectedBroadcast) || {};

  const originRemark = $selectedBroadcast.remark;

  $: edited = originRemark !== $selectedBroadcast.remark;
  $: addReady =
    ($selectedBroadcast.text || !isEmpty(selectedFileList)) &&
    $selectedBroadcast.channelId &&
    !isEmpty($selectedBroadcast.recipient) &&
    $selectedBroadcast.priority;

  $: if (!isEmpty(selectedFileList)) {
    $selectedBroadcast.media = 'edited';
  }

  let addSelectedFiles;
  let selectedFileList;

  let channelAfter = '';
  $: if ($selectedBroadcast.channelId) {
    const channel = $selectedDeskChannelList.find((c) => c._id === $selectedBroadcast.channelId);
    if (channel) {
      channelAfter = channelGetDisplayName(channel);
    } else {
      channelAfter = add ? 'None' : 'Unknown';
    }
  } else {
    channelAfter = 'None';
  }

  const selectedChannel = writable([$selectedBroadcast.channelId] || []);
  $: if (!isEmpty($selectedChannel)) {
    $selectedBroadcast.channelId = $selectedChannel[0];
  } else {
    $selectedBroadcast.channelId = '';
  }

  $: recipientAfter = !isEmpty($selectedBroadcast.recipient)
    ? `${upperFirst($selectedBroadcast.source)} (${$selectedBroadcast.recipient.length})`
    : 'None';

  // Periodically fetch updated broadcast detail.
  let fetchInProgress = false;
  let fetchInterval;
  function fetchFn() {
    if (!fetchInProgress) {
      fetchInProgress = true;
      remoteFetchBroadcastDetail($selectedBroadcast._id).then((result) => {
        // Exclude remark so user can edit remark without it getting replaced by this periodic update.
        $selectedBroadcast = { ...$selectedBroadcast, ...omit(result, ['remark']) };
        fetchInProgress = false;
      });
    }
  }

  let getProgressActionSheet;
</script>

<Page
  on:pageAfterIn="{() => {
    // Run once onmount so it don't have to wait until initial interval to start fetching.
    if (!add) {
      fetchFn();
      clearInterval(fetchInterval);
      fetchInterval = setInterval(fetchFn, fetchBroadcastDetailIntervalMs);
    }
  }}"
  on:pageAfterOut="{() => {
    clearInterval(fetchInterval);
  }}"
>
  <Navbar title="{add ? 'Create Broadcast' : 'Detail'}" backLink>
    <NavRight>
      {#if add}
        {#if addReady}
          <Link
            iconF7="checkmark_alt"
            on:click="{() => {
              showLoadingDialog(async () => {
                if (
                  $selectedBroadcast.media &&
                  originBroadcast.media !== $selectedBroadcast.media &&
                  !isEmpty(selectedFileList) &&
                  $selectedBroadcast.media === 'edited'
                ) {
                  const url = await fileUpload({
                    file: selectedFileList[0],
                    deskId: $selectedDesk._id,
                    emitProgress: true,
                    showDefaultOverlayProgress: true,
                  });
                  $selectedBroadcast.media = url;
                  $selectedBroadcast.mime = selectedFileList[0].type;
                }

                await remoteCreateBroadcast($selectedDesk._id, $selectedBroadcast);
                mainViewNavigateBack();
              }, 'Creating');
            }}"
          />
        {/if}
      {:else if !add}
        {#if edited}
          <Link
            iconF7="checkmark_alt"
            on:click="{() => {
              showLoadingDialog(async () => {
                await remoteBroadcastUpdate($selectedBroadcast);
                mainViewNavigateBack();
              }, 'Saving');
            }}"
          />
        {:else if !$selectedBroadcast.completed}
          {#if $selectedBroadcast.paused}
            <Link
              iconF7="play"
              on:click="{() => {
                $mainView.app.dialog
                  .create({
                    title: '',
                    text: 'Resume Broadcast?',
                    buttons: [
                      {
                        text: 'Cancel',
                        keyCodes: [27],
                      },
                      {
                        text: 'Resume',
                        bold: true,
                        keyCodes: [13],
                        onClick: (dialog, index) => {
                          showLoadingDialog(async () => {
                            await remoteBroadcastUpdate({
                              _id: $selectedBroadcast._id,
                              paused: false,
                            });
                            fetchFn();
                            showDialog('Broadcast Resumed');
                          }, 'Resuming');
                        },
                      },
                    ],
                  })
                  .open();
              }}"
            />
          {:else}
            <Link
              iconF7="pause"
              on:click="{() => {
                $mainView.app.dialog
                  .create({
                    title: '',
                    text: 'Pause Broadcast?',
                    buttons: [
                      {
                        text: 'Cancel',
                        keyCodes: [27],
                      },
                      {
                        text: 'Pause',
                        bold: true,
                        keyCodes: [13],
                        onClick: (dialog, index) => {
                          showLoadingDialog(async () => {
                            await remoteBroadcastUpdate({
                              _id: $selectedBroadcast._id,
                              paused: true,
                            });
                            fetchFn();
                            showDialog('Broadcast Paused');
                          }, 'Pausing');
                        },
                      },
                    ],
                  })
                  .open();
              }}"
            />
          {/if}
        {/if}
      {/if}
      {#if fetchInProgress}
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a class="link icon-only" href="#">
          <Preloader />
        </a>
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <List noHairlines>
      {#if add}
        <ListItemLink
          title="Channel"
          class="ucc-select"
          after="{channelAfter}"
          on:click="{() => {
            const itemList = $selectedDeskChannelList
              .filter((channel) => channel.platform !== 'ucc' && channel.platform !== 'voice')
              .map((channel) => {
                return {
                  title: channelGetDisplayName(channel),
                  subtitle: `Status: ${
                    channelStatusTextMarkup(channel.status, channel.mergeProgress).markup
                  }`,
                  text: `Route: ${channelGetDisplayRoute(channel)}`,
                  image: channel.profilePic || getPlatformThumbnail({ platform: channel.platform }),
                  data: channel,
                  value: channel._id,
                };
              });
            mainViewNavigate('select', {
              title: 'Channel',
              itemList,
              value: selectedChannel,
              initialValue: $selectedChannel,
              rounded: false,
              media: true,
              multiple: false,
              showSearchBtn: true,
              countSelected: true,
              returnOnSelect: true,
            });
          }}"
        />
      {:else}
        <ListItem title="Channel" after="{channelAfter}" />
      {/if}
      {#if add}
        <ListItem
          title="Priority"
          smartSelect
          smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
        >
          <select name="priority" bind:value="{$selectedBroadcast.priority}">
            {#each ['low', 'medium', 'high', 'urgent'] as priority}
              <option selected="{$selectedBroadcast.priority === priority}" value="{priority}">
                {upperFirst(priority)}
              </option>
            {/each}
          </select>
        </ListItem>
      {:else}
        <ListItem title="Priority" after="{upperFirst($selectedBroadcast.priority)}" />
      {/if}
      {#if add}
        <ListItemLink
          title="Recipients"
          after="{recipientAfter}"
          on:click="{() => {
            mainViewNavigate('recipient');
          }}"
        />
      {:else}
        <ListItem title="Priority" after="{recipientAfter}" />
      {/if}
      {#if !add}
        <ListItemLink
          title="Progress"
          after="{$selectedBroadcast.delivered} / {$selectedBroadcast.failed} / {$selectedBroadcast.read} / {$selectedBroadcast.total}"
          on:click="{() => {
            getProgressActionSheet().open();
          }}"
        />
      {/if}
      <BlockTitle>Content</BlockTitle>
      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-title item-label">Text</div>
          <div class="item-input-wrap">
            <textarea
              readonly="{add ? '' : 'readonly'}"
              placeholder="Text"
              bind:value="{$selectedBroadcast.text}"
              on:paste="{(e) => {
                addSelectedFiles(e.clipboardData.files);
              }}"></textarea>
          </div>
        </div>
      </li>
      {#if !add && $selectedBroadcast.media && $selectedBroadcast.media !== 'edited'}
        <li class="item-content">
          <div class="item-inner">
            <div class="item-input-wrap">
              <Chip
                text="Click here to view uploaded file"
                class="cursor-pointer mb-3"
                on:click="{() => {
                  openExternalUrl($selectedBroadcast.media);
                }}"
              />
            </div>
          </div>
        </li>
      {/if}
      {#if add}
        <AttachmentUploadPreview bind:addSelectedFiles bind:selectedFileList limit="{1}" />
      {/if}
      <BlockTitle>Optional</BlockTitle>
      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-title item-label">Notes</div>
          <div class="item-input-wrap">
            <textarea placeholder="Text" bind:value="{$selectedBroadcast.remark}" maxlength="600"
            ></textarea>
          </div>
        </div>
      </li>
    </List>
  </div>

  <!-- Force ios theme for action sheet as md theme is ugly -->
  <Actions
    bind:instance="{getProgressActionSheet}"
    convertToPopover
    backdrop
    closeByBackdropClick
    closeOnEscape
    class="ios {$darkMode ? 'theme-dark' : ''}"
  >
    <ActionsGroup>
      <ActionsButtonIos
        on:click="{() => {
          const deliveredList = $selectedBroadcast.detail.delivered.map((d) => d.route);
          if (isEmpty(deliveredList)) {
            showDialog('No delivered contact');
          } else {
            saveFile({
              blob: new window.Blob([deliveredList.join(', ')], {
                type: 'text/plain;charset=utf-8',
              }),
              ext: 'csv',
              fileName: 'broadcast-progress-delivered',
              autoBom: true,
            });
          }
        }}"
      >
        <span slot="icon">table</span>
        <span slot="text">Delivered (CSV)</span>
      </ActionsButtonIos>
      <ActionsButtonIos
        on:click="{() => {
          const failedList = $selectedBroadcast.detail.failed.map((d) => d.route);
          if (isEmpty(failedList)) {
            showDialog('No failed contact');
          } else {
            saveFile({
              blob: new window.Blob([failedList.join(', ')], {
                type: 'text/plain;charset=utf-8',
              }),
              ext: 'csv',
              fileName: 'broadcast-progress-failed',
              autoBom: true,
            });
          }
        }}"
      >
        <span slot="icon">table</span>
        <span slot="text">Failed (CSV)</span>
      </ActionsButtonIos>
      <ActionsButtonIos
        on:click="{() => {
          const deliveredList = $selectedBroadcast.detail.delivered.map((d) => d.route);
          const failedList = $selectedBroadcast.detail.failed.map((d) => d.route);
          const nonPendingList = [...deliveredList, ...failedList];

          // List of contact that are not delivered or failed.
          const pendingList = $selectedBroadcast.recipient.filter(
            (r) => !nonPendingList.includes(r)
          );

          if (isEmpty(pendingList)) {
            showDialog('No pending contact');
          } else {
            saveFile({
              blob: new window.Blob([pendingList.join(', ')], {
                type: 'text/plain;charset=utf-8',
              }),
              ext: 'csv',
              fileName: 'broadcast-progress-pending',
              autoBom: true,
            });
          }
        }}"
      >
        <span slot="icon">table</span>
        <span slot="text">Pending (CSV)</span>
      </ActionsButtonIos>
      <ActionsButtonIos
        on:click="{() => {
          saveFile({
            blob: new window.Blob([$selectedBroadcast.recipient.join(', ')], {
              type: 'text/plain;charset=utf-8',
            }),
            ext: 'csv',
            fileName: 'broadcast-recipient',
            autoBom: true,
          });
        }}"
      >
        <span slot="icon">table</span>
        <span slot="text">Total (CSV)</span>
      </ActionsButtonIos>
      <ActionsButtonIos
        on:click="{() => {
          const deliveredList = $selectedBroadcast.detail.delivered.map((d) => d.route);
          const failedList = $selectedBroadcast.detail.failed.map((d) => d.route);
          const nonPendingList = [...deliveredList, ...failedList];

          // List of contact that are not delivered or failed.
          const pendingList = $selectedBroadcast.recipient.filter(
            (r) => !nonPendingList.includes(r)
          );

          const detail = cloneDeep($selectedBroadcast.detail);
          detail.pending = pendingList;

          saveFile({
            blob: new window.Blob([JSON.stringify(detail, null, 2)], {
              type: 'text/plain;charset=utf-8',
            }),
            ext: 'json',
            fileName: 'broadcast-progress',
            autoBom: true,
          });
        }}"
      >
        <span slot="icon">table</span>
        <span slot="text">Full (JSON)</span>
      </ActionsButtonIos>
    </ActionsGroup>
    <ActionsGroup>
      <ActionsButtonIos center bold class="text-blue-primary">
        <span slot="text">Back</span>
      </ActionsButtonIos>
    </ActionsGroup>
  </Actions>
</Page>
