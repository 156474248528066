<script>
  // Implements email login only.
  // SSO are skeleton redirect which redirects to login.ucc.chat for further authentication.
  // SSO done externally so on native app we can redirect to there via secure browser
  // and pass back sessionId directly so it complies with user expectation of share SSO credentials.
  // So they can reuse logged in credential in external browser, instead of having to relogin
  // via UCC in app browser on native app.
  import ky from 'ky';
  import { onMount } from 'svelte';
  import { Page } from 'framework7-svelte';
  import sanitizeHtml from 'sanitize-html';
  import { isDevEnv, serverUrl, validateEmail, isNativeApp } from '../js/util';

  const kyApi = ky.extend({
    timeout: 1000 * 60 * 20,
  });

  let windowInnerWidth;
  let emailText = '';
  let emailStatusMsg = '';
  let emailStatusType = '';
  let emailInProgress = false;

  // Force use light theme for now.
  let darkTheme = false;

  // Clear status msg if email input is cleared.
  $: if (!emailText) {
    emailStatusMsg = '';
    emailStatusType = '';
  }

  const emailLogin = serverUrl + '/login/email';
  const pollLogin = serverUrl + '/login/poll';
  const impersonateLogin = serverUrl + '/login/impersonate';

  async function handleEmailOrImpersonateLogin() {
    if (validateEmail(emailText)) {
      initiateEmailLogin(emailText);
    } else {
      // If it is dev env, user may meant to input userId instead of email.
      // Send the string as userId to impersonate endpoint.
      if (isDevEnv()) {
        initiateImpersonatedLogin(emailText);
      } else {
        emailStatusMsg = 'Please enter a valid email';
        emailStatusType = 'error';
      }
    }
  }

  async function initiateEmailLogin(email) {
    if (emailInProgress) {
      return;
    }
    emailInProgress = true;

    emailStatusMsg = 'Sending email...';
    emailStatusType = 'normal';

    try {
      const token = await kyApi.post(emailLogin, { json: { email }, timeout: 30000 }).text();

      emailStatusMsg = `
        <div class="flex flex-col">
          <span>An email with further instructions had been sent to<span class="text-blue-primary pl-1 font-semibold select-text">${email}</span></span>
          <span>Please proceed to your email to continue</span>
        </div>
      `;
      emailStatusType = 'normal';

      window.localStorage.setItem('emailLoginToken', token);
      window.localStorage.setItem('emailTargetAddress', email);
      window.localStorage.setItem('pollLoginPending', 'true');

      loginTokenPoll(true);

      return token;
    } catch (err) {
      emailStatusMsg = 'Failed to send out email';
      emailStatusType = 'error';
    } finally {
      emailInProgress = false;
    }
  }

  // Impersonated login allow anyone to login to any user's account, enabled for dev environment only.
  async function initiateImpersonatedLogin(userId) {
    if (emailInProgress) {
      return;
    }
    emailInProgress = true;

    emailStatusMsg = 'Impersonating...';
    emailStatusType = 'normal';

    try {
      // Username and password are special format required by passport.js 'local' strategy.
      // For localhost, server will respond with a sessionId instead of cookie.
      // Use that to authenticate all future operation with socket.
      const sessionId = await kyApi
        .post(impersonateLogin, {
          // username and password is irrelevant as long as not empty string, no special side effect.
          json: { username: 'aaa', password: 'aaa', userId: userId },
        })
        .text();
      if (sessionId) {
        window.localStorage.setItem('sessionId', sessionId);
      }
      emailStatusMsg = 'Success';
      emailStatusType = 'success';

      // Login success, redirect.
      window.location.reload();
    } catch (err) {
      emailStatusMsg = 'Impersonation Failed';
      emailStatusType = 'error';
    } finally {
      emailInProgress = false;
    }
  }

  function handleLoginReply(sessionId, autoRedirect) {
    // For localhost, server will respond with a sessionId instead of cookie.
    if (sessionId) {
      window.localStorage.setItem('sessionId', sessionId);
    }
    window.localStorage.setItem('pollLoginPending', '');
    if (autoRedirect) {
      window.location.reload();
    }
    return sessionId ? true : false;
  }

  let loginTokenPollTimeout = false;
  async function loginTokenPoll(autoRedirect = false) {
    // Only allow 1 polling instance to save resources.
    if (loginTokenPollTimeout) return;

    // Keep asking server to validate our token, it can be validated at any moment after user click 'send' from their whatsapp.
    async function poll() {
      kyApi
        .post(pollLogin, {
          json: {
            username: 'aaa',
            password: 'aaa',
            token: window.localStorage.getItem('emailLoginToken'),
          },
        })
        .text()
        .then((sessionId) => {
          if (!handleLoginReply(sessionId, autoRedirect)) {
            // Continue to poll if login failed.
            setTimeout(poll, 2500);
          }
        })
        .catch((err) => {
          console.log('Is LoggedIn Poll Failed (Expected)');
          setTimeout(poll, 2500);
        });
    }
    loginTokenPollTimeout = setTimeout(poll, 2500);
  }

  /**
   * Handle SSO login by redirecting it to login.ucc.chat and wait for it to redirect back with sessionId
   * if login is successful.
   * For native mobile app, redirect using secure browser (Chrome Custom Tab / ASWebAuthenticationSession)
   */
  function handleSSO(platform) {
    const targetUrl = `https://login.ucc.chat?${new URLSearchParams({
      redirect: window.location.href,
      platform,
    })}`;
    if (isNativeApp) {
      if (isNativeAndroid) {
        window.cordova.plugins.browsertabs.openUrl(
          targetUrl,
          { scheme: window.location.origin, authSession: true },
          (e) => {
            console.log('Auth success', e);
          },
          (e) => {
            console.log('Auth err', e);
          }
        );
      } else if (isNativeIos) {
        window.plugins.ASWebAuthSession.start(window.location.origin, targetUrl,
          (msg) => {
            console.log("Success ", msg);
          }, 
          (err) => {
              console.log("Error " + msg);
          }
        );
      }
    } else {
      window.location.replace(targetUrl);
    }
  }

  onMount(() => {
    // If user is waiting to login via email, start the busy polling loop for verifying token.
    // Need to call again due to user switch app will refresh page in safari.
    if (window.localStorage.getItem('pollLoginPending')) {
      loginTokenPoll(true);
    }
  });
</script>

<style lang="scss">
  // https://stackoverflow.com/questions/21003535/anyway-to-prevent-the-blue-highlighting-of-elements-in-chrome-when-clicking-quic
  // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-tap-highlight-color
  // https://github.com/tailwindlabs/discuss/issues/396
  // Remove default blue glow on android on click.
  :global(*) {
    -webkit-tap-highlight-color: transparent;
  }
  // https://stackoverflow.com/questions/55131944/how-to-remove-blue-background-on-chrome-autocomplete
  :global(input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus
      textarea:-webkit-autofill, textarea:-webkit-autofill:hover
      textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus) {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  .login-container {
    &.dark {
      @apply text-white;
      background: linear-gradient(150deg, #292f38 0%, #21252b 50%, #1a1e24 50%, #0f1319 100%);
      // background: linear-gradient(150deg, #3c3c3c 0%, #333333 50%, #252525 50%, #1e1e1e 100%);
      &.large-bg {
        > div {
          background: rgba(26, 30, 34, 0.2);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
        }
      }
    }

    &.light {
      @apply text-black;
      background: white;
      &.large-bg {
        background: linear-gradient(150deg, #d3e6f9 0%, #d3eaf9 50%, #a0daf1 50%, #a0daf1 100%);
        > div {
          @apply bg-white;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
        }
      }
    }
  }

  .email-status {
    &.error {
      @apply text-red-primary;
    }
    &.success {
      @apply text-green-500;
    }
  }

  // Cloned from firebaseui.
  .sso-btn {
    @apply flex;
    @apply rounded-full;
    @apply space-x-4;
    @apply px-4;
    @apply py-2;
    @apply my-2;
    @apply w-60;
    @apply cursor-pointer;

    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
      0 1px 5px 0 rgb(0 0 0 / 12%);

    > span {
      @apply font-medium;
      @apply select-none;
      > img {
        @apply w-5;
        @apply h-5;
      }
    }

    &.google {
      background: white;
      color: #757575;
    }
    &.facebook {
      background: #3b5998;
      color: white;
    }
    &.apple {
      background: black;
      color: white;
    }
    &.phone {
      background: #02bd7e;
      color: white;
    }
  }
</style>

<svelte:window bind:innerWidth="{windowInnerWidth}" />

<Page class="no-navbar bg-white">
  <div class="h-full overflow-auto">
    <div
      class="flex w-full h-full justify-center items-center login-container {darkTheme
        ? 'dark'
        : 'light'} {windowInnerWidth > 640 ? 'large-bg' : ''}"
    >
      <div class="flex flex-col items-center rounded-lg pb-1 w-96">
        <div class="flex w-32 h-32 my-2">
          <img
            src="static/images/ucc.png"
            alt="logo"
            on:contextmenu="{(e) => {
              e.preventDefault();
            }}"
          />
        </div>
        <div class="flex w-full font-semibold justify-center text-lg select-none">
          Sign in or create an account
        </div>
        <div class="flex justify-center w-full py-2">
          <div class="flex w-full justify-center">
            <div
              class="flex border font-medium w-56 h-8 rounded-full {darkTheme
                ? 'border-gray-600'
                : 'border-gray-400'}"
            >
              <input
                bind:value="{emailText}"
                on:keydown="{(e) => {
                  if (e.key === 'Enter' || e.keycode === 13) {
                    handleEmailOrImpersonateLogin();
                  }
                }}"
                type="email"
                placeholder="{// Hint to user that we are on dev env and impersonation is supported.
                isDevEnv() ? 'Email / ID' : 'Email'}"
                class="text-gray-600 placeholder-gray-600 border-none text-center text-sm font-medium content-center flex-grow outline-none rounded-full {emailInProgress
                  ? 'disabled'
                  : ''}"
              />
              {#if emailText}
                <div
                  class="cursor-pointer w-6 h-6 self-center flex-shrink-0 mr-1"
                  on:click="{handleEmailOrImpersonateLogin}"
                >
                  <img
                    src="static/images/right-arrow.svg"
                    alt="email-confirm"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />
                </div>
              {/if}
            </div>
          </div>
        </div>
        {#if emailStatusMsg}
          <div
            class="flex w-full justify-center text-sm text-center pb-1 {emailStatusType} email-status"
          >
            {@html sanitizeHtml(emailStatusMsg, {
              allowedTags: ['span', 'div'],
              allowedAttributes: {
                div: ['class'],
                span: ['class'],
              },
              disallowedTagsMode: 'recursiveEscape',
            })}
          </div>
        {/if}
        {#if !emailText}
          <div class="flex w-full font-semibold justify-center text-sm select-none">OR</div>
          <div
            class="sso-btn google"
            on:click="{() => {
              handleSSO('google');
            }}"
          >
            <span>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="sso"
                on:contextmenu="{(e) => {
                  e.preventDefault();
                }}"
              />
            </span>
            <span>Continue with Google</span>
          </div>

          <div
            class="sso-btn facebook"
            on:click="{() => {
              handleSSO('facebook');
            }}"
          >
            <span>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg"
                alt="sso"
                on:contextmenu="{(e) => {
                  e.preventDefault();
                }}"
              />
            </span>
            <span>Continue with Facebook</span>
          </div>

          <div
            class="sso-btn apple"
            on:click="{() => {
              handleSSO('apple');
            }}"
          >
            <span>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/apple.png"
                alt="sso"
                on:contextmenu="{(e) => {
                  e.preventDefault();
                }}"
              />
            </span>
            <span>Continue with Apple</span>
          </div>

          <div
            class="sso-btn phone"
            on:click="{() => {
              handleSSO('phone');
            }}"
          >
            <span>
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/phone.svg"
                alt="sso"
                on:contextmenu="{(e) => {
                  e.preventDefault();
                }}"
              />
            </span>
            <span>Continue with phone</span>
          </div>
        {/if}
        <div class="text-gray-600 text-xs mx-12 text-center mt-2">
          <span>By continuing, you are indicating that you accept our</span>
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            class="font-medium text-blue-primary"
            href="#"
            on:click="{() => {
              window.open('static/eula/index.html');
            }}">Terms of Service</a
          >
          <span>and</span>
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            class="font-medium text-blue-primary"
            href="#"
            on:click="{() => {
              window.open('static/privacy/index.html');
            }}">Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </div>
</Page>
