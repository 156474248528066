<script>
  import { Page } from 'framework7-svelte';
  import { upperFirst } from 'lodash-es';
  import SortSearchList from '../components/SortSearchList.svelte';
  import {
    mainViewNavigate,
    storeUpdateIfDifferent,
    selectedBroadcast,
    remoteFetchBroadcast,
    selectedDesk,
    selectedDeskChannelList,
  } from '../js/store';
  import {
    channelGetDisplayRoute,
    mongoIdToTimestamp,
    dateToDayMonthYearHourMin,
  } from '../js/util';
  import { fetchBroadcastListIntervalMs, fetchBroadcastListLimit } from '../js/config';

  let broadcastList = [];
  let fetchInProgress = false;
  let fetchInterval;
  function fetchFn() {
    if (!fetchInProgress) {
      fetchInProgress = true;
      remoteFetchBroadcast($selectedDesk._id, fetchBroadcastListLimit).then((result) => {
        broadcastList = result;
        fetchInProgress = false;
      });
    }
  }

  function getBroadcastTitle(broadcast) {
    const channel = $selectedDeskChannelList.find((c) => c._id === broadcast.channelId);
    return channelGetDisplayRoute(channel);
  }
  function getBroadcastSubtitle(broadcast) {
    return `
    <span>${getBroadcastStatusIcon(broadcast)}</span>
    <span class="mr-1">Delivered: ${broadcast.delivered}</span>
    /
    <span class="text-red-primary mr-1">Failed: ${broadcast.failed}</span>
    /
    <span class="text-green-primary mr-1">Read: ${broadcast.read}</span>
    /
    <span class="text-blue-primary mr-1">Total: ${broadcast.total}</span>`;
  }
  function getBroadcastText(broadcast) {
    let result = '';
    if (broadcast.text) {
      result += `Text: ${broadcast.text}`;
    }
    if (broadcast.media) {
      if (result) {
        result += `<br>Media :[${upperFirst(broadcast.mime)}]`;
      } else {
        result += `Media :[${upperFirst(broadcast.mime)}]`;
      }
    }
    if (result) {
      result += `<br>${dateToDayMonthYearHourMin(mongoIdToTimestamp(broadcast._id), true)}`;
    } else {
      result += dateToDayMonthYearHourMin(mongoIdToTimestamp(broadcast._id), true);
    }
    return result;
  }
  function getBroadcastStatusIcon(broadcast) {
    if (broadcast.completed) {
      return '<i class="f7-icons text-base text-green-primary">checkmark_alt</i>';
    }
    if (broadcast.paused) {
      return '<i class="f7-icons text-base text-red-primary">pause</i>';
    }
    return '<i class="f7-icons text-base text-blue-primary">play</i>';
  }
</script>

<Page
  on:pageAfterIn="{() => {
    // Run once onmount so it don't have to wait until initial interval to start fetching.
    fetchFn();
    clearInterval(fetchInterval);
    fetchInterval = setInterval(fetchFn, fetchBroadcastListIntervalMs);
  }}"
  on:pageAfterOut="{() => {
    clearInterval(fetchInterval);
  }}"
>
  <SortSearchList
    title="Broadcasts"
    showCounter
    showLoadingIcon="{fetchInProgress}"
    itemList="{broadcastList.map((broadcast) => {
      return {
        title: getBroadcastTitle(broadcast),
        subtitle: getBroadcastSubtitle(broadcast),
        text: getBroadcastText(broadcast),
        data: broadcast,
      };
    })}"
    on:click="{(e) => {
      storeUpdateIfDifferent(selectedBroadcast, e.detail);
      mainViewNavigate('detail');
    }}"
    showAddBtn
    addUrl="add"
    on:add="{() => {
      // Reset selected broadcast so it start from scratch when first time navigated in.
      storeUpdateIfDifferent(selectedBroadcast, {});
    }}"
  />
</Page>
