<script>
  import { onMount } from 'svelte';
  import { writable, get } from 'svelte/store';
  import dayjs from 'dayjs';
  import { isEmpty, isEqual, upperFirst, pick, uniqBy } from 'lodash-es';
  import ListItemLink from './ListItemLink.svelte';
  import {
    selectedDesk,
    selectedDeskChannelListIncludeDeactivated,
    selectedDeskMemberList,
    remoteDeskTagDistinct,
    mainViewNavigate,
    storeUpdateIfDifferent,
    generateDefaultDeskContactFilter,
    mainView,
    isEqualContactFilter,
  } from '../js/store';
  import {
    channelGetDisplayRoute,
    getPlatformThumbnail,
    platformToOfficialPlatformName,
    mongoIdToTimestamp,
    splitTagIntoMemberTagAndNormalTagWithSelectUiItem,
  } from '../js/util';

  export let contactFilter = writable({});
  export let showChannelFilter = false;
  export let showCaseStatusFilter = false;
  export let showCopyFromOther = {
    show: false,
    title: '',
    confirmTitle: '',
    source: writable({}),
  };

  let loadedSelectItemTagList = [];
  let enableRestoreDefault = false;
  let enableCopyFromOther = false;

  // Only allow to filter channels that have valid route and remove duplicates.
  $: cleanedSelectedChannelList = !isEmpty($selectedDeskChannelListIncludeDeactivated)
    ? uniqBy(
        $selectedDeskChannelListIncludeDeactivated
          .filter((channel) => channel.route && channel.platform !== 'ucc')
          .map((channel) => {
            // Make sure channel is unique on the same platform, not cross platform.
            channel.platformRoute = channel.platform + channel.route;
            return channel;
          }),
        'platformRoute'
      )
    : [];

  // Sync select filter change with global filter.
  const channelFilter = writable($contactFilter.channel || []);
  const memberFilter = writable($contactFilter.member || []);
  const tagFilter = writable($contactFilter.tag || []);
  const archiveFilter = writable(
    $contactFilter.archive ? [$contactFilter.archive] : ['unarchived']
  );
  const statusFilter = writable(
    $contactFilter.status && $contactFilter.status !== 'A' ? [$contactFilter.status] : ['O', 'C']
  );

  // Set selected desk creation date max resolution to minute, since f7 calendar don't support date containing seconds.
  $: selectedDeskDate = dayjs(mongoIdToTimestamp($selectedDesk._id)).second(0).toDate();

  // Determine range filter preview text.
  let rangeFilterPreviewText = '';
  const rangeDateDisplayFormat = 'DD MMM';
  $: if ($contactFilter.startAt && $contactFilter.endAt) {
    if (new Date($contactFilter.startAt) > new Date($contactFilter.endAt)) {
      rangeFilterPreviewText = 'Invalid date range';
    } else {
      rangeFilterPreviewText = `${
        $contactFilter.startEndBy === 'creation' ? 'Created within' : 'Any message within'
      } ${dayjs($contactFilter.startAt).format(rangeDateDisplayFormat)} - ${dayjs(
        $contactFilter.endAt
      ).format(rangeDateDisplayFormat)}`;
    }
  }
  // startAt not specified thus use desk creation date as startAt.
  else if (
    !$contactFilter.startAt &&
    $contactFilter.endAt &&
    selectedDeskDate > new Date($contactFilter.endAt)
  ) {
    rangeFilterPreviewText = 'Invalid date range';
  } else if ($contactFilter.startAt) {
    rangeFilterPreviewText = `${
      $contactFilter.startEndBy === 'creation' ? 'Created after' : 'Any message after'
    } ${dayjs($contactFilter.startAt).format(rangeDateDisplayFormat)}`;
  } else if ($contactFilter.endAt) {
    rangeFilterPreviewText = `${
      $contactFilter.startEndBy === 'creation' ? 'Created before' : 'Any message before'
    } ${dayjs($contactFilter.endAt).format(rangeDateDisplayFormat)}`;
  } else {
    rangeFilterPreviewText = 'Any';
  }

  function updateRestoreDefaultBtn(filter) {
    // Enable restore default btn if current filter is not same as default filter.
    const fields = ['member', 'tag', 'startAt', 'endAt', 'startEndBy', 'archive'];
    if (showChannelFilter) {
      fields.push('channel');
    }
    if (showCaseStatusFilter) {
      fields.push('status');
    }
    const defaultFilter = pick(generateDefaultDeskContactFilter($selectedDesk._id), fields);
    const currentFilter = pick(filter, fields);
    enableRestoreDefault = !isEqualContactFilter(defaultFilter, currentFilter);
  }
  function updateCopyFromOtherBtn() {
    enableCopyFromOther = !isEqualContactFilter($contactFilter, get(showCopyFromOther.source));
  }

  onMount(() => {
    const unsubscribeFnList = [];

    remoteDeskTagDistinct($selectedDesk._id).then((tags) => {
      loadedSelectItemTagList = splitTagIntoMemberTagAndNormalTagWithSelectUiItem(
        tags,
        $selectedDeskMemberList
      ).tagList;
    });

    unsubscribeFnList.push(
      channelFilter.subscribe((filter) => {
        // Selected all treat as nothing selected.
        if (filter.length === cleanedSelectedChannelList.length) {
          filter = [];
        }

        if (!isEqual($contactFilter.channel, filter)) {
          $contactFilter.channel = filter;
        }

        updateRestoreDefaultBtn($contactFilter);
        updateCopyFromOtherBtn();
      })
    );
    unsubscribeFnList.push(
      memberFilter.subscribe((filter) => {
        // +1 to include 'All Unassigned' member tag.
        // Selected all treat as nothing selected.
        if (filter.length === $selectedDeskMemberList.length + 1) {
          filter = [];
        }

        if (!isEqual($contactFilter.member, filter)) {
          $contactFilter.member = filter;
        }

        updateRestoreDefaultBtn($contactFilter);
        updateCopyFromOtherBtn();
      })
    );
    unsubscribeFnList.push(
      tagFilter.subscribe((filter) => {
        // +1 to include 'All Untagged' tag.
        // Selected all treat as nothing selected.
        if (filter.length === loadedSelectItemTagList.length + 1) {
          filter = [];
        }

        if (!isEqual($contactFilter.tag, filter)) {
          $contactFilter.tag = filter;
        }

        updateRestoreDefaultBtn($contactFilter);
        updateCopyFromOtherBtn();
      })
    );
    unsubscribeFnList.push(
      archiveFilter.subscribe((filter) => {
        let archive = '';
        if (filter.includes('unarchived') && filter.includes('archived')) {
          archive = 'all';
        } else if (!isEmpty(filter)) {
          archive = filter[0];
        }

        if (!isEqual($contactFilter.archive, archive)) {
          $contactFilter.archive = archive;
        }

        updateRestoreDefaultBtn($contactFilter);
        updateCopyFromOtherBtn();
      })
    );
    unsubscribeFnList.push(
      statusFilter.subscribe((filter) => {
        let status = '';
        if (filter.includes('O') && filter.includes('C')) {
          status = 'A';
        } else if (!isEmpty(filter)) {
          status = filter[0];
        }

        if (!isEqual($contactFilter.status, status)) {
          $contactFilter.status = status;
        }

        updateRestoreDefaultBtn($contactFilter);
        updateCopyFromOtherBtn();
      })
    );

    // Check again after desk is fully loaded.
    unsubscribeFnList.push(
      selectedDesk.subscribe(() => {
        updateRestoreDefaultBtn($contactFilter);
        updateCopyFromOtherBtn();
      })
    );
    // Check again if whole contact filter is updated.
    unsubscribeFnList.push(
      contactFilter.subscribe(() => {
        updateRestoreDefaultBtn($contactFilter);
        updateCopyFromOtherBtn();
      })
    );

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });
</script>

{#if showChannelFilter}
  <ListItemLink
    title="Channel"
    class="ucc-select"
    iconF7="captions_bubble"
    media
    after="{isEmpty($channelFilter) || $channelFilter.length === cleanedSelectedChannelList.length
      ? 'Any'
      : $channelFilter.length}"
    on:click="{() => {
      const itemList = cleanedSelectedChannelList.map((channel) => {
        return {
          title: platformToOfficialPlatformName(channel.platform),
          subtitle: `Route: ${channelGetDisplayRoute(channel)}`,
          image: channel.profilePic || getPlatformThumbnail({ platform: channel.platform }),
          data: channel,
          value: channel.route,
        };
      });
      mainViewNavigate('select', {
        title: 'Channel',
        itemList,
        value: channelFilter,
        initialValue: !isEmpty($channelFilter)
          ? $channelFilter
          : itemList.map((item) => item.value),
        rounded: false,
        media: true,
        multiple: true,
        showSearchBtn: true,
        countSelected: true,
        indeterminate: {
          enable: true,
        },
        restore: {
          enable: true,
          hideIfDefault: true,
        },
        validate: {
          enable: true,
          ignoreError: false,
        },
      });
    }}"
  />
{/if}
<ListItemLink
  title="Assign"
  class="ucc-select"
  iconF7="person"
  media
  after="{isEmpty($memberFilter) || $memberFilter.length - 1 === $selectedDeskMemberList.length
    ? 'Any'
    : $memberFilter.length}"
  on:click="{() => {
    const itemList = [
      ...[{ title: 'All Unassigned', value: '_unassigned' }],
      ...$selectedDeskMemberList.map((member) => {
        return {
          title: member.name,
          image: member.profilePic,
          data: member,
          value: member._id,
        };
      }),
    ];
    mainViewNavigate('select', {
      title: 'Assign',
      itemList,
      value: memberFilter,
      initialValue: !isEmpty($memberFilter) ? $memberFilter : itemList.map((item) => item.value),
      rounded: true,
      media: true,
      multiple: true,
      centerText: true,
      showSearchBtn: true,
      countSelected: true,
      indeterminate: {
        enable: true,
      },
      restore: {
        enable: true,
        hideIfDefault: true,
      },
      validate: {
        enable: true,
        ignoreError: false,
      },
    });
  }}"
/>
<ListItemLink
  title="Tag"
  class="ucc-select"
  iconF7="tag"
  media
  after="{isEmpty($tagFilter) || $tagFilter.length - 1 === loadedSelectItemTagList.length
    ? 'Any'
    : $tagFilter.length}"
  on:click="{() => {
    const itemList = [
      ...[{ title: 'All Untagged', value: '_untagged' }],
      ...loadedSelectItemTagList.map((tag) => {
        return {
          title: tag,
          data: tag,
          value: tag,
        };
      }),
    ];
    mainViewNavigate('select', {
      title: 'Tag',
      itemList,
      value: tagFilter,
      initialValue: !isEmpty($tagFilter) ? $tagFilter : itemList.map((item) => item.value),
      multiple: true,
      centerText: true,
      showSearchBtn: true,
      countSelected: true,
      indeterminate: {
        enable: true,
      },
      restore: {
        enable: true,
        hideIfDefault: true,
      },
      validate: {
        enable: true,
        ignoreError: false,
      },
    });
  }}"
/>
<ListItemLink
  title="Archive"
  class="ucc-select"
  iconF7="archivebox"
  media
  after="{$contactFilter.archive
    ? upperFirst($contactFilter.archive).replace('All', 'Any')
    : 'Not selected'}"
  on:click="{() => {
    const itemList = [
      { title: 'Archived', value: 'archived' },
      { title: 'Unarchived', value: 'unarchived' },
    ];
    let initialValue;
    if ($contactFilter.archive === 'all') {
      initialValue = ['archived', 'unarchived'];
    } else if ($contactFilter.archive === 'archived') {
      initialValue = ['archived'];
    } else if ($contactFilter.archive === 'unarchived') {
      initialValue = ['unarchived'];
    }
    mainViewNavigate('select', {
      title: 'Archive',
      itemList,
      value: archiveFilter,
      initialValue,
      multiple: true,
      indeterminate: {
        enable: true,
      },
      restore: {
        enable: true,
        hideIfDefault: true,
        restoreDefault: async (itemList, localValue, parentValue) => {
          return ['unarchived'];
        },
        isDefault: async (itemList, localValue, parentValue) => {
          return (
            Array.isArray(localValue) && localValue.length === 1 && localValue[0] === 'unarchived'
          );
        },
      },
      validate: {
        enable: true,
        ignoreError: false,
      },
    });
  }}"
/>
{#if showCaseStatusFilter}
  <ListItemLink
    title="Status"
    class="ucc-select"
    iconF7="briefcase"
    media
    after="{$contactFilter.status
      ? upperFirst($contactFilter.status)
          .replace('A', 'Any')
          .replace('O', 'Open')
          .replace('C', 'Closed')
      : 'Not selected'}"
    on:click="{() => {
      const itemList = [
        { title: 'Open', value: 'O' },
        { title: 'Closed', value: 'C' },
      ];
      let initialValue;
      if ($contactFilter.status === 'A') {
        initialValue = ['O', 'C'];
      } else if ($contactFilter.status === 'O') {
        initialValue = ['O'];
      } else if ($contactFilter.status === 'C') {
        initialValue = ['C'];
      }
      mainViewNavigate('select', {
        title: 'Status',
        itemList,
        value: statusFilter,
        initialValue,
        multiple: true,
        indeterminate: {
          enable: true,
        },
        restore: {
          enable: true,
          hideIfDefault: true,
          restoreDefault: async (itemList, localValue, parentValue) => {
            return ['O', 'C'];
          },
          isDefault: async (itemList, localValue, parentValue) => {
            return (
              Array.isArray(localValue) &&
              localValue.length === 2 &&
              localValue.includes('O') &&
              localValue.includes('C')
            );
          },
        },
        validate: {
          enable: true,
          ignoreError: false,
        },
      });
    }}"
  />
{/if}
<ListItemLink
  title="Range"
  iconF7="calendar"
  media
  after="{rangeFilterPreviewText}"
  on:click="{() => {
    mainViewNavigate('range', { contactFilter });
  }}"
/>
<ListItemLink
  title="Restore Default"
  iconF7="arrow_2_squarepath"
  media
  disabled="{!enableRestoreDefault}"
  on:click="{() => {
    $mainView.app.dialog
      .create({
        title: '',
        text: 'Restore all filter settings to default?',
        buttons: [
          {
            text: 'Cancel',
            keyCodes: [27],
          },
          {
            text: 'Restore',
            bold: true,
            keyCodes: [13],
            onClick: (dialog, index) => {
              const defaultContactFilter = generateDefaultDeskContactFilter($selectedDesk._id);
              // Preserve case status filter if user doesn't enable status filter in this component.
              if (!showCaseStatusFilter) {
                defaultContactFilter.status = $contactFilter.status;
              } else {
                storeUpdateIfDifferent(statusFilter, ['O', 'C']);
              }

              // Preserve channel filter if user doesn't enable channel filter in this component.
              if (!showChannelFilter) {
                defaultContactFilter.channel = $contactFilter.channel;
              }

              // Preserve reverse filter, it is not part of the filter ui.
              defaultContactFilter.reverse = $contactFilter.reverse;

              // Reset all to default values, except case status.
              storeUpdateIfDifferent(contactFilter, defaultContactFilter);
              storeUpdateIfDifferent(
                channelFilter,
                cleanedSelectedChannelList.map((channel) => channel.route)
              );
              storeUpdateIfDifferent(memberFilter, [
                '_unassigned',
                ...$selectedDeskMemberList.map((member) => member._id),
              ]);
              storeUpdateIfDifferent(tagFilter, [
                '_untagged',
                ...loadedSelectItemTagList.map((tag) => tag),
              ]);

              updateRestoreDefaultBtn(defaultContactFilter);
              updateCopyFromOtherBtn();
            },
          },
        ],
      })
      .open();
  }}"
/>
{#if showCopyFromOther.show}
  <ListItemLink
    title="{showCopyFromOther.title}"
    iconF7="doc_on_doc"
    media
    disabled="{!enableCopyFromOther}"
    on:click="{() => {
      $mainView.app.dialog
        .create({
          title: '',
          text: showCopyFromOther.confirmTitle,
          buttons: [
            {
              text: 'Cancel',
              keyCodes: [27],
            },
            {
              text: 'Replace',
              bold: true,
              keyCodes: [13],
              onClick: (dialog, index) => {
                $contactFilter = get(showCopyFromOther.source);
                updateRestoreDefaultBtn($contactFilter);
                updateCopyFromOtherBtn();
              },
            },
          ],
        })
        .open();
    }}"
  />
{/if}
