<script>
  import { Page, Navbar, NavRight, Link, List } from 'framework7-svelte';
  import qrcode from 'qrcode-generator';
  import {
    selectedDesk,
    fileUpload,
    remoteMemberDetailUpdate,
    showLoadingDialog,
    mainViewNavigateBack,
    selectedDeskUserInfo,
    userInfo,
  } from '../js/store';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ListItemToggle from '../components/ListItemToggle.svelte';
  import ProfileImage from '../components/ProfileImage.svelte';
  import { deleteMember } from '../js/member';

  export let props = {};
  let { member } = props;
  member = member || {};
  let memberName = member.name;
  let memberEmail = member.email || '';
  let memberProfilePicFile;
  let memberProfilePic = member.profilePic;
  let isAdmin = member.role === 'admin';
  const url = `${window.location.origin}?deskInviteToken=${member.token}`;
  $: role = isAdmin ? 'admin' : 'user';
  $: edited =
    memberName !== member.name ||
    (memberEmail !== member.email && !(!memberEmail && !member.email)) ||
    role !== member.role ||
    memberProfilePicFile ||
    memberProfilePic !== member.profilePic;

  $: isMemberHimself = $userInfo._id === member.userId;
  $: isDeskAdmin = $selectedDeskUserInfo.role === 'admin';
  $: canEdit = isMemberHimself || isDeskAdmin;
</script>

<Page>
  <Navbar title="Detail{!member.verified ? ' (Pending)' : ''}" backLink>
    <NavRight>
      {#if edited}
        <Link
          iconF7="checkmark_alt"
          on:click="{() => {
            showLoadingDialog(async () => {
              const payload = {};

              if (memberProfilePic && memberProfilePicFile) {
                payload.profilePic = await fileUpload({
                  file: memberProfilePicFile,
                  deskId: $selectedDesk._id,
                  emitProgress: true,
                  showDefaultOverlayProgress: true,
                  convertToProfilePic: true,
                });
              } else {
                payload.profilePic = '';
              }

              if (memberName !== member.name) {
                payload.name = memberName;
              }

              if (memberEmail !== member.email) {
                payload.email = memberEmail;
              }

              if (role !== member.role) {
                payload.role = role;
              }

              await remoteMemberDetailUpdate($selectedDesk._id, member._id, payload);
              mainViewNavigateBack();
            }, 'Saving');
          }}"
        />
      {:else if canEdit}
        <Link
          iconF7="trash"
          on:click="{() => {
            deleteMember($selectedDesk._id, member);
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    {#if !member.verified}
      <div class="block">
        <span class="select-none mr-2">Invitation code: </span>
        <span>{member.token}</span>
        {#if member.email}
          <div class="select-none">An invitation email had been sent to this address</div>
          <div>{member.email}</div>
        {:else if url}
          <div class="select-none">
            Please pass this QR or URL to the person you wish to invite.
          </div>
          <div class="flex flex-col w-full items-center mt-2">
            <div>
              {@html (() => {
                const qr = qrcode(0, 'H');
                qr.addData(url);
                qr.make();
                return qr.createImgTag();
              })()}
            </div>
            <div class="text-blue-primary mt-2">{url}</div>
          </div>
        {/if}
      </div>
    {/if}

    <ProfileImage
      src="{memberProfilePic}"
      edit="{canEdit}"
      rounded
      on:change="{(event) => {
        memberProfilePicFile = event.detail.file;
        memberProfilePic = event.detail.base64;
      }}"
    />

    <List noHairlines>
      <li class="item-content item-input item-input-with-info">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Display Name</div>
          <div class="item-input-wrap">
            <input
              type="text"
              placeholder="Co-worker's nickname"
              required
              validate
              readonly="{!canEdit}"
              bind:value="{memberName}"
            />
            <div class="item-input-info">Co-worker's display name in desk</div>
          </div>
        </div>
      </li>
      <li class="item-content item-input item-input-with-info">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Email (Optional)</div>
          <div class="item-input-wrap">
            <input
              type="email"
              placeholder="Email"
              validate
              bind:value="{memberEmail}"
              readonly="{!canEdit}"
            />
            <div class="item-input-info">Desk related notifications will be sent to this email</div>
          </div>
        </div>
      </li>
      <ListItemToggle
        title="Admin"
        iconF7="star"
        bind:checked="{isAdmin}"
        disabled="{!isDeskAdmin}"
        media
      />
      {#if canEdit && memberProfilePic}
        <ListItemLink
          title="Restore default profile picture"
          iconF7="return"
          media
          on:click="{() => {
            memberProfilePicFile = undefined;
            memberProfilePic = '';
          }}"
        />
      {/if}
    </List>
  </div>
</Page>
