import { get } from 'svelte/store';
import { mainView, remoteCreateDesk, remoteQuitDesk, showLoadingDialog } from './store';

export function createDesk() {
  // https://forum.framework7.io/t/solved-dialog-prompt-with-input-number-or-tel/4299/2
  // https://forum.framework7.io/t/how-to-prevent-prompt-dialog-to-close-if-not-right-value/4919
  get(mainView)
    .app.dialog.create({
      title: '',
      text: 'Create New Desk',
      content: `
        <div class="dialog-input-field input">
          <input class="dialog-input" type="text" placeholder="Desk Name" required validate>
        </div>`,
      buttons: [
        {
          text: 'Cancel',
          keyCodes: [27],
        },
        {
          text: 'Create',
          bold: true,
          keyCodes: [13],
          close: false,
          onClick: (dialog, index) => {
            const deskName = dialog.$el.find('.dialog-input').val();
            if (deskName) {
              // Close fetch desk name dialog then show creating dialog.
              get(mainView).app.dialog.close();
              showLoadingDialog(async () => {
                await remoteCreateDesk(deskName, Intl.DateTimeFormat().resolvedOptions().timeZone);
              }, 'Creating');
            }
          },
        },
      ],
      // Focus on text input upon dialog open.
      on: {
        opened(dialog) {
          dialog.$el.find('.dialog-input').eq(0).focus();
        },
      },
    })
    .open();
}

export function quitDesk(event) {
  const desk = event.detail;
  get(mainView)
    .app.dialog.create({
      title: '',
      text: `Quit Desk - ${desk.name}`,
      closeByBackdropClick: true,
      buttons: [
        {
          text: 'Cancel',
          keyCodes: [27],
        },
        {
          text: 'Quit',
          bold: true,
          color: 'red',
          keyCodes: [13],
          onClick: () => {
            showLoadingDialog(async () => {
              await remoteQuitDesk(desk._id);
            }, 'Processing');
          },
        },
      ],
    })
    .open();
}
