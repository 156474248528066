<script>
  import { ActionsButton } from 'framework7-svelte';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  export let center = false;
  export let bold = false;
  let clazz;
  export { clazz as class };
</script>

<style lang="scss">
  .action-btn {
    @apply flex;
    @apply items-center;
    @apply h-full;

    i {
      @apply ml-3;
      @apply mr-5;
      @apply text-3xl;
      @apply h-full;
      @apply flex;
      @apply items-center;
      @apply text-icon-primary;
    }

    span {
      @apply text-xl;
      @apply flex;
      @apply h-full;
      @apply items-center;
      @apply text-gray-900;
    }
  }

  .theme-dark .action-btn span {
    @apply text-gray-400;
  }
</style>

<ActionsButton
  on:click="{() => {
    dispatch('click');
  }}"
  bold="{bold}"
  class="{clazz}"
>
  <span class="{center ? '' : 'action-btn'}">
    <i class="f7-icons text-icon-primary">
      <slot name="icon" />
    </i>

    <span>
      <slot name="text" />
    </span>
  </span>
</ActionsButton>
