<script>
  import { Page, List, ListItem } from 'framework7-svelte';
  import { onMount } from 'svelte';
  import { upperFirst } from 'lodash-es';
  import SelectSearchList from '../components/SelectSearchList.svelte';
  import {
    mainView,
    showLoadingDialog,
    remoteCancelOutboundMsg,
    remoteResendTimeoutMsg,
    remoteFetchQueuedOutboundMsg,
    selectedChannel,
    selectedDeskMemberList,
    userInfo,
    selectedDeskUserInfo,
  } from '../js/store';
  import {
    chatMsgTypeToHumanReadable,
    mongoIdToTimestamp,
    dateToDayMonthYearHourMin,
    contactRoutePrimaryName,
    userIdToUserDeskScopedProfilePic,
    userIdToUserDeskScopedName,
  } from '../js/util';
  import { fetchQueuedOutboundMsgIntervalMs } from '../js/config';

  let queuedContactMsgList = [];
  let fetchInProgress = false;
  let fetchType = 'timeout';

  function fetchFn() {
    if (!fetchInProgress) {
      fetchInProgress = true;
      remoteFetchQueuedOutboundMsg($selectedChannel._id, fetchType).then((contactMsgList) => {
        queuedContactMsgList = contactMsgList;
        fetchInProgress = false;
      });
    }
  }
  onMount(() => {
    // Run once onmount so it don't have to wait until initial interval to start fetching.
    fetchFn();
    const interval = setInterval(fetchFn, fetchQueuedOutboundMsgIntervalMs);

    return () => {
      clearInterval(interval);
    };
  });
</script>

<Page>
  <SelectSearchList
    title="Queue"
    multiple
    countSelected
    indeterminate="{{
      enable: true,
    }}"
    showSaveBtn
    showLoadingIcon="{fetchInProgress}"
    itemList="{queuedContactMsgList
      .filter(
        // User initiated the msg or if user is desk admin.
        (contact) => contact.msg.userId === $userInfo._id || $selectedDeskUserInfo.role === 'admin'
      )
      .map((contact) => {
        const { msg } = contact;
        return {
          title: contactRoutePrimaryName(contact),
          subtitle: `
          <span>${dateToDayMonthYearHourMin(mongoIdToTimestamp(msg._id), true)}</span>
          <span class="flex mt-1 space-x-1">
            <img
              src="${userIdToUserDeskScopedProfilePic(msg.senderId, $selectedDeskMemberList)}"
              class="w-4 h-4 rounded-full"
              alt="user-profile"
            />
            <span class="text-xs opacity-50">
              ${userIdToUserDeskScopedName(msg.senderId, $selectedDeskMemberList)}
            </span>
          </span>
        `,
          text: `${
            msg.msgType === 'chat'
              ? `Text: ${msg.msg}`
              : `${chatMsgTypeToHumanReadable(msg.msgType)}`
          }`,
          data: contact,
          value: msg._id,
        };
      })}"
    on:save="{(e) => {
      const selectedMsgIdList = e.detail;

      let dialogOption = {};
      // Pending msg allow cancel msg.
      if (fetchType === 'pending') {
        dialogOption = {
          title: '',
          text: selectedMsgIdList.length > 1 ? `Cancel queued messages?` : `Cancel queued message?`,
          closeByBackdropClick: true,
          buttons: [
            {
              text: 'Back',
              keyCodes: [27],
            },
            {
              text: 'Cancel',
              bold: true,
              color: 'red',
              keyCodes: [13],
              onClick: () => {
                showLoadingDialog(async () => {
                  await remoteCancelOutboundMsg(selectedMsgIdList);
                  fetchFn();
                }, 'Processing');
              },
            },
          ],
        };
      }
      // Timed out message allow resend / delete.
      else if (fetchType === 'timeout') {
        dialogOption = {
          title: '',
          text:
            selectedMsgIdList.length > 1
              ? `Cancel / Resend timed out messages?`
              : `Cancel / Resend timed out message?`,
          closeByBackdropClick: true,
          buttons: [
            {
              text: 'Back',
              keyCodes: [27],
            },
            {
              text: 'Cancel',
              bold: true,
              color: 'red',
              onClick: () => {
                showLoadingDialog(async () => {
                  await remoteCancelOutboundMsg(selectedMsgIdList);
                  fetchFn();
                }, 'Processing');
              },
            },
            {
              text: 'Resend',
              bold: true,
              onClick: () => {
                showLoadingDialog(async () => {
                  await remoteResendTimeoutMsg(selectedMsgIdList);
                  fetchFn();
                }, 'Processing');
              },
            },
          ],
        };
      }

      $mainView.app.dialog.create(dialogOption).open();
    }}"
  >
    <div slot="beforeList">
      <List>
        <ListItem
          title="Type"
          smartSelect
          smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
        >
          <!-- svelte-ignore a11y-no-onchange -->
          <select
            name="type"
            bind:value="{fetchType}"
            on:change="{() => {
              fetchFn();
            }}"
          >
            {#each ['timeout', 'pending'] as type}
              <option selected="{fetchType === type}" value="{type}">
                {upperFirst(type)}
              </option>
            {/each}
          </select>
        </ListItem>
      </List>
    </div>
  </SelectSearchList>
</Page>
