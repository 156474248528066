<script>
  import {
    Navbar,
    NavRight,
    Link,
    Searchbar,
    List,
    ListItem,
    Fab,
    Icon,
    Preloader,
  } from 'framework7-svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import { fly } from 'svelte/transition';
  import { cloneDeep, isEmpty, isEqual } from 'lodash-es';
  import sanitizeHtml from 'sanitize-html';
  import Sortable from 'sortablejs';
  import SquareImage from './SquareImage.svelte';
  import { mainViewNavigate } from '../js/store';

  export let title = '';
  export let itemList = [];
  export let rounded = false;
  export let addUrl = '#';
  export let addProps = {};
  export let showAddBtn = false;
  export let showDeleteBtn = false;
  export let showSortBtn = false;
  export let showSearchBtn = false;
  export let showCounter = false;
  export let centerText = false;
  export let media = false;
  export let showLoadingIcon = false;
  export let emptyText = 'Empty';

  const sanitizeOption = {
    allowedTags: ['span', 'div', 'br', 'img', 'i'],
    allowedAttributes: {
      '*': ['class', 'style'],
      img: ['src', 'alt'],
    },
    disallowedTagsMode: 'recursiveEscape',
  };

  const dispatch = createEventDispatcher();
  let sortInProgress = false;
  let deleteInProgress = false;
  let searchInProgress = false;
  let sortedList = [];
  let edited = false;

  let sortableListEl;
  let sortableObj;

  $: if (sortableObj) {
    edited = false;
    sortableObj.option('disabled', !sortInProgress);
    if (sortInProgress) {
      sortedList = cloneDeep(itemList);
    } else {
      sortedList = [];
    }
  }

  // Reset sort when item list change.
  $: {
    itemList;
    sortInProgress = false;
  }

  onMount(() => {
    sortableObj = Sortable.create(sortableListEl, {
      // https://github.com/SortableJS/Sortable#options
      animation: 50, // ms, animation speed moving items when sorting, `0` — without animation
      easing: 'cubic-bezier(1, 0, 0, 1)', // Easing for animation. Defaults to null. See https://easings.net/ for examples.
      onUpdate: (evt) => {
        // Take out selected item then insert it into new position.
        const spliced = sortedList.splice(evt.oldIndex, 1);
        // https://stackoverflow.com/questions/586182/how-to-insert-an-item-into-an-array-at-a-specific-index-javascript
        sortedList.splice(evt.newIndex, 0, spliced[0]);
        edited = !isEmpty(sortedList) && !isEqual(itemList, sortedList);
      },
    });

    return () => {
      if (sortableObj) {
        sortableObj.destroy();
      }
    };
  });
</script>

<Navbar title="{title}{showCounter && itemList.length ? ` (${itemList.length})` : ''}" backLink>
  <NavRight>
    {#if !sortInProgress && !deleteInProgress && showSearchBtn}
      <Link searchbarEnable=".searchbar-sort-search" iconF7="search" />
    {/if}
    <!-- Use vanilla <a> tag instead of <Link> class to allow changing icon without redrawing ripple, which will cause ripple effect to break. -->
    {#if showDeleteBtn && !sortInProgress}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a
        class="link"
        href="#"
        on:click="{() => {
          deleteInProgress = !deleteInProgress;
        }}"
      >
        <i class="f7-icons" style="{deleteInProgress ? '' : 'font-size:24px;'}"
          >{deleteInProgress ? 'arrow_uturn_left' : 'trash'}</i
        >
      </a>
    {/if}
    {#if showSortBtn && !deleteInProgress}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a
        class="link icon-only"
        href="#"
        on:click="{() => {
          sortInProgress = !sortInProgress;
        }}"
      >
        <i class="f7-icons">{sortInProgress ? 'arrow_uturn_left' : 'bars'}</i>
      </a>
      {#if edited}
        <Link
          iconF7="checkmark_alt"
          on:click="{async () => {
            dispatch('save', sortedList);
          }}"
        />
      {/if}
    {/if}
    {#if showLoadingIcon}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a class="link icon-only" href="#">
        <Preloader />
      </a>
    {/if}
    <slot name="nav" />
  </NavRight>
  <Searchbar
    class="searchbar-sort-search"
    expandable
    searchContainer=".searchlist-sort-search"
    searchIn=".item-title"
    disableButton
    on:searchbarEnable="{() => {
      searchInProgress = true;
    }}"
    on:searchbarDisable="{() => {
      searchInProgress = false;
    }}"
  />
</Navbar>
<div class="h-full overflow-auto">
  <slot name="beforeList" />
  <List class="searchbar-not-found">
    <ListItem title="Nothing found" />
  </List>
  <div class="list media-list searchlist-sort-search searchbar-found">
    <ul bind:this="{sortableListEl}">
      {#if isEmpty(itemList)}
        <ListItem title="{emptyText || 'Empty'}" />
      {/if}
      <!-- Redraw list when user toggle sort, so if there is unsaved sort, on toggle back those sort progress will be reset. -->
      {#key sortInProgress}
        {#each itemList as item}
          <li
            class="max-w-full"
            on:click="{() => {
              if (deleteInProgress) {
                dispatch('delete', item.data);
              } else if (!sortInProgress) {
                dispatch('click', item.data);
              }
            }}"
          >
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label class="item-content cursor-pointer">
              {#if media}
                <div class="item-media" style="align-self: center;">
                  <SquareImage src="{item.image}" rounded="{rounded}" topRight="{item.topRight}" />
                </div>
              {/if}
              <div class="item-inner" style="display: flex;">
                <div class="truncate">
                  {#if centerText}
                    <div class="item-title flex h-full items-center select-none">
                      {@html sanitizeHtml(item.title || '', sanitizeOption) || ''}
                    </div>
                  {:else}
                    <div class="item-title-row">
                      <div class="item-title select-none">
                        {@html sanitizeHtml(item.title, sanitizeOption) || ''}
                      </div>
                    </div>
                    <div class="item-subtitle select-none">
                      {@html sanitizeHtml(item.subtitle, sanitizeOption) || ''}
                    </div>
                    {#if item.text}
                      <div class="item-text select-none">
                        {@html sanitizeHtml(item.text, sanitizeOption) || ''}
                      </div>
                    {/if}
                  {/if}
                </div>
                {#if deleteInProgress}
                  <div class="item-after">
                    <i
                      class="f7-icons select-none"
                      style="font-size: var(--f7-list-chevron-icon-font-size); color: var(--f7-list-chevron-icon-color);"
                      >trash</i
                    >
                  </div>
                {:else if sortInProgress}
                  <div class="item-after" in:fly="{{ x: 50, duration: 50 }}">
                    <i
                      class="f7-icons select-none"
                      style="font-size: var(--f7-list-chevron-icon-font-size); color: var(--f7-list-chevron-icon-color);"
                      >bars</i
                    >
                  </div>
                {:else}
                  <div class="item-after" in:fly="{{ x: 50, duration: 50 }}">
                    <i
                      class="f7-icons select-none"
                      style="font-size: var(--f7-list-chevron-icon-font-size); color: var(--f7-list-chevron-icon-color); font-family: 'framework7-core-icons';"
                      >chevron_right</i
                    >
                  </div>
                {/if}
              </div>
            </label>
          </li>
        {/each}
      {/key}
    </ul>
  </div>
  {#if !sortInProgress && !deleteInProgress && !searchInProgress && showAddBtn}
    <Fab
      position="right-bottom"
      on:click="{() => {
        dispatch('add');
        mainViewNavigate(addUrl, addProps);
      }}"
    >
      <Icon f7="plus" />
    </Fab>
  {/if}
</div>
