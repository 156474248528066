<script>
  import { Toolbar, Actions, ActionsGroup } from 'framework7-svelte';
  import ActionsButtonIos from './ActionButtonIos.svelte';
  import StatusBtn from './StatusBtn.svelte';
  import BigIcon from './BigIcon.svelte';
  import {
    darkMode,
    mainViewNavigate,
    selectedDesk,
    selectedDeskContactFilter,
    userInfo,
    remoteCountCaseForAllDesk,
    hasInternetConnection,
    loggedIn,
    fullyLoaded,
  } from '../js/store';
  import { isEmpty } from 'lodash-es';

  $: deskScopedCaseCounter =
    $userInfo && $userInfo.caseCounter && !isEmpty($userInfo.caseCounter[$selectedDesk._id])
      ? $userInfo.caseCounter[$selectedDesk._id]
      : { all: 0, open: 0, close: 0 };

  // Ask server to generate case counter for all desks.
  let countCaseInitiated = false;
  $: if (
    $hasInternetConnection &&
    $loggedIn &&
    $fullyLoaded &&
    !isEmpty($selectedDesk) &&
    (!$userInfo.caseCounter || isEmpty($userInfo.caseCounter[$selectedDesk._id]))
  ) {
    if (!countCaseInitiated) {
      countCaseInitiated = true;
      remoteCountCaseForAllDesk();
    }
  }

  // Count how many filters are currently active, +1 for each type.
  let filterBadgeCount = 0;
  $: if (!isEmpty($selectedDeskContactFilter)) {
    let activeFilterCount = 0;
    if ($selectedDeskContactFilter.archive !== 'unarchived') {
      activeFilterCount++;
    }
    if (!isEmpty($selectedDeskContactFilter.channel)) {
      activeFilterCount++;
    }
    if (!isEmpty($selectedDeskContactFilter.member)) {
      activeFilterCount++;
    }
    if (!isEmpty($selectedDeskContactFilter.tag)) {
      activeFilterCount++;
    }
    if ($selectedDeskContactFilter.startAt || $selectedDeskContactFilter.endAt) {
      activeFilterCount++;
    }
    filterBadgeCount = activeFilterCount;
  }

  let getViewSwitchSheet;
</script>

<Toolbar bottom noShadow>
  <span class="flex flex-grow flex-row h-full space-x-3">
    <span
      class="flex self-center relative"
      on:click="{() => {
        if (!isEmpty($selectedDeskContactFilter) && $selectedDeskContactFilter.status) {
          $selectedDeskContactFilter.status = 'A';
        }
      }}"
    >
      <StatusBtn
        color="blue"
        text="{!isEmpty(deskScopedCaseCounter) && deskScopedCaseCounter.all
          ? deskScopedCaseCounter.all
          : 0}"
        selected="{!isEmpty($selectedDeskContactFilter) &&
          $selectedDeskContactFilter.status === 'A'}"
      />
    </span>
    <span
      class="flex self-center relative"
      on:click="{() => {
        if (!isEmpty($selectedDeskContactFilter) && $selectedDeskContactFilter.status) {
          $selectedDeskContactFilter.status = 'O';
        }
      }}"
    >
      <StatusBtn
        color="green"
        text="{!isEmpty(deskScopedCaseCounter) && deskScopedCaseCounter.open
          ? deskScopedCaseCounter.open
          : 0}"
        selected="{!isEmpty($selectedDeskContactFilter) &&
          $selectedDeskContactFilter.status === 'O'}"
      />
    </span>
    <span
      class="flex self-center relative"
      on:click="{() => {
        if (!isEmpty($selectedDeskContactFilter) && $selectedDeskContactFilter.status) {
          $selectedDeskContactFilter.status = 'C';
        }
      }}"
    >
      <StatusBtn
        color="white"
        dark="{$darkMode}"
        text="{!isEmpty(deskScopedCaseCounter) && deskScopedCaseCounter.close
          ? deskScopedCaseCounter.close
          : 0}"
        selected="{!isEmpty($selectedDeskContactFilter) &&
          $selectedDeskContactFilter.status === 'C'}"
      />
    </span>
  </span>
  <span class="flex space-x-6 h-9">
    <span
      class="flex items-center cursor-pointer ripple relative"
      on:click="{() => {
        mainViewNavigate('filter');
      }}"
    >
      <BigIcon
        md
        icon="filter_alt"
        style="font-size: 1.5rem;"
        showBadge="{filterBadgeCount > 0}"
        badgeCount="{filterBadgeCount}"
      />
    </span>
    <span
      class="flex items-center cursor-pointer ripple relative"
      on:click="{() => {
        if (!isEmpty($selectedDeskContactFilter)) {
          $selectedDeskContactFilter.reverse = !$selectedDeskContactFilter.reverse;
          // getViewSwitchSheet().open();
        }
      }}"
    >
      {#if $selectedDeskContactFilter.reverse}
        <BigIcon f7 icon="sort_up" style="font-size: 1.6rem;" />
      {:else}
        <BigIcon f7 icon="sort_down" style="font-size: 1.6rem;" />
      {/if}
    </span>
  </span>
</Toolbar>

<!-- Force ios theme for action sheet as md theme is ugly -->
<Actions
  bind:instance="{getViewSwitchSheet}"
  convertToPopover
  backdrop
  closeByBackdropClick
  closeOnEscape
  class="ios {$darkMode ? 'theme-dark' : ''}"
>
  <ActionsGroup>
    <ActionsButtonIos on:click="{() => {}}">
      <span slot="icon">checkmark_alt</span>
      <span slot="text">Default</span>
    </ActionsButtonIos>
    <ActionsButtonIos on:click="{() => {}}">
      <span slot="icon">checkmark_alt</span>
      <span slot="text">Contact</span>
    </ActionsButtonIos>
  </ActionsGroup>
  <ActionsGroup>
    <ActionsButtonIos
      on:click="{() => {
        $selectedDeskContactFilter.reverse = false;
      }}"
    >
      <span slot="icon">{$selectedDeskContactFilter.reverse ? 'checkmark_alt' : ''}</span>
      <span slot="text">Ascending</span>
    </ActionsButtonIos>
    <ActionsButtonIos
      on:click="{() => {
        $selectedDeskContactFilter.reverse = true;
      }}"
    >
      <span slot="icon">{$selectedDeskContactFilter.reverse ? '' : 'checkmark_alt'}</span>
      <span slot="text">Descending</span>
    </ActionsButtonIos>
  </ActionsGroup>
  <ActionsGroup>
    <ActionsButtonIos center bold class="text-blue-primary">
      <span slot="text">Back</span>
    </ActionsButtonIos>
  </ActionsGroup>
</Actions>
