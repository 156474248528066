<script>
  import { Page } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';
  import ChatTopBar from '../components/ChatTopBar.svelte';
  import ChatList from '../components/ChatList.svelte';
  import ChatBottomBar from '../components/ChatBottomBar.svelte';
  import { mainView, multiView, selectedContact, mainViewNavigateBack } from '../js/store';

  // If contact is unselected, go back to last page if is on mobile.
  // On desktop no action necessary.
  if (!$multiView && isEmpty($selectedContact)) {
    mainViewNavigateBack();
  }
</script>

<Page
  on:pageBeforeOut="{() => {
    // Close any currently open action sheets, eg miniApp and attachment sheet.
    $mainView.app.actions.close();
  }}"
>
  <ChatTopBar />
  <ChatList />
  <ChatBottomBar />
</Page>
