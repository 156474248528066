export const uccPlaceholderLogo = 'static/images/logo.png';
export const uccPlaceholderAlpha = 'static/images/none.png';
export const deskAdminIcon = 'static/images/yellow-star.svg';
export const chatLockedMsg = 'In use by ';
export const chatTimestampIntervalTreatAsSame = 2500;
export const whatsAppChatAckImplementedMs = 1580389200000; // Only WhatsApp chat after this time support WhatsApp ack status.
export const contactSearchSize = 30;
export const chatSearchSize = 30;
export const advancedSearchPreviewSize = 3;
export const advancedSearchFullSize = 30;
export const searchDebounceMs = 250;
export const contactLoadPerRequest = 30;
export const chatLoadPerRequest = 30;
export const highlightColor = '#09d261';
export const contactLockExtendGraceMs = 10000; // Ask remote to extend contact lock X ms before expiry.
export const chatSendBarDefaultPlaceholder = 'Type a message';
export const idleMs = 30000; // How long without activity to deem as idle.
export const mobileNavigateDelayMs = 0;
export const chatScrollMarginTreatAsZero = -10;
export const fetchQueuedOutboundMsgIntervalMs = 5000;
export const fetchBroadcastListIntervalMs = 5000;
export const fetchBroadcastDetailIntervalMs = 5000;
export const fetchBroadcastListLimit = 50;
export const androidPushNotificationChannelId = 'ucc';
export const enablePinnedChatFunction = false;
export const enableIdleStatusView = false;
export const enableLoginIdEditView = false;
export const anonymizeNumberShowDigit = 4;
export const bigScreenLeftTabWidth = '370px';
export const bigScreenMaxWidth = '1396px';
export const bigScreenMaxHeight = 'calc(100% - 38px)';
export const enableBigScreenBorder = true;
export const maxUploadFileSize = 1024 * 1024 * 10;
export const enableBiometric = false;
export const enableWhatsappForwardFunction = false;
export const socketReconnectDelay = 1000;
export const socketReconnectDelayMax = socketReconnectDelay * 2;
export const firebaseLiveConfig = {
  apiKey: 'AIzaSyDByUVy3ZmYR1MU4-e81BmNetPiki-sPsw',
  authDomain: 'ucc-live.firebaseapp.com',
  projectId: 'ucc-live',
  messagingSenderId: '888938340110',
  appId: '1:888938340110:web:ca3addafd2d3a721fc415f',
};
export const firebaseDevConfig = {
  apiKey: 'AIzaSyBBVdgU8zXRsmm8gatE3do63d3ST54Di_c',
  authDomain: 'ucc-login-dev-1576566663776.firebaseapp.com',
  projectId: 'ucc-login-dev-1576566663776',
  messagingSenderId: '1018351520025',
  appId: '1:1018351520025:web:9394ec55c6927ddd889d88',
};
