<script>
  import { Page, Navbar, Block, Preloader } from 'framework7-svelte';
  import {
    mainViewNavigateBack,
    selectedChannel,
    remoteWhatsAppPersonalFetchQr,
  } from '../js/store';

  let qrBase64 = '';
  $: if ($selectedChannel.linked) {
    mainViewNavigateBack();
  }

  // Use timestamp as id to avoid multiple concurrent polling event at any time.
  let pollId = '';
  async function pollQr(id) {
    if (pollId && pollId === id) {
      let result = { success: false };
      try {
        result = await remoteWhatsAppPersonalFetchQr($selectedChannel._id);
      } finally {
        // Only process response if the poll has not be deactivated by changed pollId
        if (pollId && pollId === id) {
          if (result.success && result.data) {
            qrBase64 = result.data;
          }
          setTimeout(() => {
            pollQr(id);
          }, 1500);
        }
      }
    }
  }

  function onPageMount() {
    pollId = Date.now();
    pollQr(pollId);
  }
  function onPageUnmount() {
    pollId = '';
  }
</script>

<Page on:pageBeforeIn="{onPageMount}" on:pageBeforeOut="{onPageUnmount}">
  <Navbar title="Scan WhatsApp Web QR" backLink />

  <div class="h-full overflow-auto">
    <div class="flex flex-col w-full h-56 justify-center items-center">
      {#if !$selectedChannel.status || $selectedChannel.status === 'unlinked' || $selectedChannel.status === 'halted' || $selectedChannel.status === 'booting'}
        <Preloader />
        <div class="pt-4">Booting (It may take a while)</div>
      {:else if $selectedChannel.status === 'waiting_qr'}
        {#if qrBase64}
          <img src="{qrBase64}" alt="qr" class="h-40 w-40 p-1 bg-white" />
        {:else}
          <Preloader />
          <div class="pt-4">Fetching QR Code</div>
        {/if}
      {:else if $selectedChannel.status === 'merging_history' || $selectedChannel.status === 'active'}
        <img
          src="static/images/pass.svg"
          alt="success"
          class="h-40 w-40"
          on:contextmenu="{(e) => {
            e.preventDefault();
          }}"
        />
        <div class="pt-4">Channel Linked</div>
      {/if}
    </div>
    <Block class="mt-0">
      <div>Scan QR Code via WhatsApp App on your phone to link</div>
      <div class="text-xs">
        Note: Do not open any new WhatsApp Web instance on your PC after linking else this channel
        will be unlinked as WhatsApp only allow 1 WhatsApp Web instance at any time.
      </div>
    </Block>
  </div>
</Page>
