<script>
  import { onMount } from 'svelte';
  import { isEqual } from 'lodash-es';
  import { Page, Navbar, List } from 'framework7-svelte';
  import SquareImage from '../components/SquareImage.svelte';
  import PaginatedSwiper from '../components/PaginatedSwiper.svelte';
  import ListItemLink from '../components/ListItemLink.svelte';
  import {
    selectedDesk,
    selectedDeskMemberList,
    selectedDeskChannelList,
    selectedDeskUserInfo,
    mainViewNavigate,
    selectedDeskMemberIdleList,
    fullyLoaded,
  } from '../js/store';
  import { deskAdminIcon } from '../js/config';

  $: isDeskAdmin = $selectedDeskUserInfo.role === 'admin';

  function updateMemberItemList() {
    if (!$fullyLoaded) {
      return;
    }
    const updatedMemberList = $selectedDeskMemberList.map((member) => {
      let memberStatus = '';
      // There can be multiple socket of the same user, each with different idle status.
      // If any one of them is 'active', show active, else fallback to 'idle'.
      $selectedDeskMemberIdleList
        .filter((idle) => idle.userId === member.userId)
        .forEach((memberIdleStatus) => {
          // 'active' overrides all other status.
          // eg 2 idle, 1 active, display as active regardless of its ordering.
          if (memberStatus !== 'active' && memberIdleStatus) {
            if (memberIdleStatus.idle) {
              // Show idle icon before member name.
              memberStatus = 'idle';
            } else {
              // Show active icon before member name.
              memberStatus = 'active';
            }
          }
        });

      if (member.status === 'pending') {
        memberStatus = member.status;
      }

      return {
        name: member.name,
        src: member.profilePic,
        detail: member,
        status: memberStatus,
        topRight: member.role === 'admin' ? { src: deskAdminIcon, small: true, shadow: true } : {},
      };
    });

    if (!isEqual(paginatedMemberList, updatedMemberList)) {
      paginatedMemberList = updatedMemberList;
    }
  }

  let paginatedMemberList;
  onMount(() => {
    const unsubscribeFnList = [];

    unsubscribeFnList.push(selectedDeskMemberList.subscribe(updateMemberItemList));
    unsubscribeFnList.push(selectedDeskMemberIdleList.subscribe(updateMemberItemList));
    unsubscribeFnList.push(fullyLoaded.subscribe(updateMemberItemList));

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });
</script>

<Page>
  <Navbar title="Desk" backLink />

  <div class="h-full overflow-auto">
    <div class="list media-list chevron-center no-hairlines">
      <ul>
        <li
          on:click="{() => {
            mainViewNavigate('edit');
          }}"
        >
          <span class="item-link item-content">
            <div class="item-media">
              <SquareImage src="{$selectedDesk.profilePic}" />
            </div>
            <div class="item-inner">
              <div class="item-title">{$selectedDesk.name}</div>
              <div class="item-subtitle">{$selectedDesk.slogan || ''}</div>
            </div>
          </span>
        </li>
      </ul>
    </div>
    <List>
      <ListItemLink
        title="Co-workers"
        link="member-sort"
        iconF7="person"
        after="{$selectedDeskMemberList.length ? $selectedDeskMemberList.length.toString() : ''}"
        media
      />
      <div class="h-auto relative">
        <PaginatedSwiper
          rounded
          expandUrl="member-sort"
          showAddBtn="{isDeskAdmin}"
          addUrl="member-add"
          itemList="{paginatedMemberList}"
          on:click="{(e) => {
            mainViewNavigate('member-detail', { member: e.detail });
          }}"
          noContentText="No co-worker found"
        />
      </div>

      <ListItemLink
        title="Channels"
        link="channel-sort"
        iconF7="captions_bubble"
        media
        after="{$selectedDeskChannelList.filter(
          (channel) => !channel.deactivatedAt && channel.platform !== 'ucc'
        ).length || ''}"
      />

      {#if $selectedDesk.autoReply}
        <ListItemLink
          title="Auto Reply Library"
          link="auto-reply"
          iconF7="arrowshape_turn_up_left"
          media
        />
      {/if}

      <ListItemLink
        title="Broadcasts"
        link="broadcast"
        iconF7="antenna_radiowaves_left_right"
        media
      />

      {#if isDeskAdmin}
        <ListItemLink title="Exports" link="report" iconF7="table" media />
      {/if}

      <ListItemLink title="Settings" link="setting" iconF7="gear_alt" media />
    </List>
  </div>
</Page>
