<script>
  import { Page, Navbar, List, Block } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';

  export let content;
  const testSuccess = content.success;
</script>

<Page>
  <Navbar title="Test Result" backLink />
  <div class="flex flex-col w-full items-center text-center py-2 space-y-2">
    {#if testSuccess}
      <div class="text-green-primary font-bold">SUCCESS</div>
      <div>
        Bot First Reply: <span>{isEmpty(content.msgList) ? 'EMPTY' : content.msgList[0].msg}</span>
      </div>
    {:else}
      <div class="text-red-primary font-bold">FAILED</div>
    {/if}
  </div>
  <List accordionList>
    <li class="accordion-item {testSuccess ? '' : 'accordion-item-opened'}">
      <span class="item-link item-content">
        <div class="item-inner">
          <div class="item-title">Detail</div>
        </div>
      </span>
      <div class="accordion-item-content">
        <Block>
          <p>{JSON.stringify(content, null, 4)}</p>
        </Block>
      </div>
    </li>
  </List>
</Page>
