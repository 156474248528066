<script>
  import { Page, Navbar, List, Block } from 'framework7-svelte';
  import ListItemLink from '../components/ListItemLink.svelte';
</script>

<Page>
  <Navbar title="Auto Reply Library" backLink />

  <div class="h-full overflow-auto">
    <List>
      <ListItemLink title="Greetings" link="greeting-sort" iconF7="rocket" media />
      <ListItemLink title="Bots" link="bot-sort" iconMd="motion_photos_pause" media />
    </List>
    <Block strong>
      <p class="pb-2">Create, edit, delete all auto replies here.</p>
      <p>Channels can subscribe to each / mixture of these auto replies anytime.</p>
    </Block>
  </div>
</Page>
