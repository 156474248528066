<script>
  import { createEventDispatcher } from 'svelte';
  import Dropzone from 'svelte-file-dropzone';
  import { uccPlaceholderLogo } from '../js/config';
  import { showDialog, darkMode, mainViewNavigate } from '../js/store';
  import { dataURLtoFile } from '../js/util';

  export let rounded = false;
  export let edit = false;
  export let src;
  export let srcFallback = uccPlaceholderLogo;
  let fileInput;

  const dispatch = createEventDispatcher();

  let dragDropShow = false;

  // Resize supplied image, display then return it via change handler
  function processSelectedImage(file, skipEdit = false) {
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (readerEvent) => {
      // https://stackoverflow.com/questions/23945494/use-html5-to-resize-an-image-before-upload
      const image = new Image();
      image.onload = (imageEvent) => {
        // Resize the image
        const canvas = document.createElement('canvas');
        const width = 200;
        const height = 200;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        canvas.toBlob((blob) => {
          const thumbnail = URL.createObjectURL(blob);
          const base64 = canvas.toDataURL('image/jpeg');

          dispatch('change', { file, base64, thumbnail });
          src = base64;

          if (!skipEdit) {
            mainViewNavigate('/image-editor', {
              selectedFile: file,
              onChange: (e) => {
                processSelectedImage(dataURLtoFile(e.detail), true);
              },
            });
          }
        });
      };
      image.src = readerEvent.target.result;
    };
  }
</script>

<div class="flex justify-center w-full mt-5 mb-5 relative">
  <div
    class="absolute z-10 w-48 h-48 {edit ? 'cursor-pointer' : ''}"
    on:dragenter="{() => {
      dragDropShow = true;
    }}"
    on:click="{() => {
      if (edit) {
        fileInput.click();
      }
    }}"
  >
    <Dropzone
      on:drop="{(e) => {
        // Pass drag drop file to outbound send handler.
        const { acceptedFiles } = e.detail;
        dragDropShow = false;
        if (acceptedFiles && acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          if (!file.type.includes('image')) {
            showDialog('Accept image only');
          } else {
            processSelectedImage(file);
          }
        }
      }}"
      containerClasses="h-full w-full {$darkMode ? 'bg-gray-900' : 'bg-gray-200'} {dragDropShow &&
      edit
        ? ''
        : 'hidden'} {rounded ? 'rounded-full' : ''}"
      containerStyles="{rounded ? '' : 'outline: 2px dashed #92b0b3; outline-offset: -20px;'}"
      disableDefaultStyles
      on:dragleave="{() => {
        dragDropShow = false;
      }}"
    >
      <div class="flex h-full w-full items-center place-content-center">Drag file here</div>
    </Dropzone>
  </div>

  <input
    type="file"
    accept="image/*"
    hidden
    bind:this="{fileInput}"
    on:change="{(event) => {
      if (event.target.files.length) {
        const file = event.target.files[0];
        processSelectedImage(file);

        // Clear selected file so user can reselect the same file again if needed.
        fileInput.value = null;
      }
    }}"
  />
  <div class="w-48 h-48 relative">
    {#if edit && !dragDropShow}
      <i
        class="f7-icons absolute flex justify-center items-center w-10 h-10 bottom-0 right-0 rounded-full bg-blue-primary text-2xl text-white"
        >camera_fill</i
      >
    {/if}
    <img
      alt="square"
      src="{src || uccPlaceholderLogo}"
      onerror="this.onerror=null;this.src='{srcFallback}';"
      class="{rounded ? 'rounded-full' : 'rounded-lg'} h-full w-full"
    />
  </div>
</div>
