<script>
  import { Page, Navbar } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';
  import ChatAdditionalInfo from './ChatAdditionalInfo.svelte';
  import { deskInfoList } from '../js/store';

  export let content;

  let deskName = '';
</script>

<Page
  on:pageBeforeIn="{() => {
    if (!isEmpty(content) && !isEmpty(content.content)) {
      const desk = $deskInfoList.find((d) => d._id === content.deskId);
      if (desk) {
        deskName = desk.name;
      }
    }
  }}"
>
  <Navbar title="Flash" backLink />
  <div class="flex flex-col w-full h-full items-center pt-2">
    <div class="w-64 text-center">
      <div class="font-bold">{deskName}</div>
      <ChatAdditionalInfo additionalInfoList="{content.content}" hideDate />
    </div>
  </div>
</Page>
