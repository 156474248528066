module.exports = {
  halted: 'halted',
  booting: 'booting',
  waiting_qr: 'waiting_qr',
  logging_in: 'logging_in',
  merging_history: 'merging_history',
  resending_messages: 'resending_messages',
  active: 'active',
  logged_in_from_another_wa_web: 'logged_in_from_another_wa_web',
  logged_out_from_physical_device: 'logged_out_from_physical_device',
  host_phone_unreachable: 'host_phone_unreachable',
  wa_update_available: 'wa_update_available',
  banned: 'banned',
  unlinked: 'unlinked',
  proxy_block: 'proxy_block',
  deactivated: 'deactivated',
  invalid_credential: 'invalid_credential',
  unknown_err: 'unknown_err',
};
