<script>
  import { Page, Navbar, List } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';
  import SquareImage from '../components/SquareImage.svelte';
  import PaginatedSwiper from '../components/PaginatedSwiper.svelte';
  import ListItemLink from '../components/ListItemLink.svelte';
  import {
    userInfo,
    deskInfoList,
    clearLocalDb,
    switchDesk,
    mainView,
    mainViewNavigateRoot,
    mainViewNavigate,
  } from '../js/store';
  import { userLogout } from '../js/util';
  import { createDesk } from '../js/desk';

  $: paginatedDeskList = $deskInfoList.map((desk) => {
    return {
      name: desk.name,
      src: desk.profilePic,
      detail: desk,
      showOpenCaseCounter: true,
      openCaseCount:
        $userInfo &&
        $userInfo.caseCounter &&
        $userInfo.caseCounter[desk._id] &&
        $userInfo.caseCounter[desk._id].open
          ? $userInfo.caseCounter[desk._id].open
          : 0,
    };
  });
</script>

<Page>
  <Navbar title="Account" backLink />

  <div class="h-full overflow-auto">
    <div class="list media-list chevron-center no-hairlines">
      <ul>
        <li
          on:click="{() => {
            mainViewNavigate('edit');
          }}"
        >
          <span class="item-link item-content">
            <div class="item-media">
              <SquareImage src="{$userInfo.profilePic}" rounded />
            </div>
            <div class="item-inner">
              <div class="item-title">{$userInfo.name}</div>
              <div class="item-subtitle">
                {$userInfo && !isEmpty($userInfo.invitation)
                  ? `You have ${$userInfo.invitation.length} pending desk invitation${
                      $userInfo.invitation.length > 1 ? 's' : ''
                    }`
                  : 'No new notification'}
              </div>
            </div>
          </span>
        </li>
      </ul>
    </div>
    <List>
      <ListItemLink
        title="Desks"
        link="desk-sort"
        iconF7="bubble_right"
        after="{$deskInfoList.length ? $deskInfoList.length.toString() : ''}"
        media
      />
      <div class="h-auto relative">
        <PaginatedSwiper
          expandUrl="desk-sort"
          itemList="{paginatedDeskList}"
          on:click="{(e) => {
            switchDesk({ deskId: e.detail._id, clearSearchTerm: true }).then(() => {
              mainViewNavigateRoot();
            });
          }}"
          on:add="{createDesk}"
          showAddBtn
          noContentText="No Desk Found"
        />
      </div>
      <ListItemLink
        title="Invitations"
        link="/invitation"
        after="{$userInfo && Array.isArray($userInfo.invitation) && !isEmpty($userInfo.invitation)
          ? $userInfo.invitation.length
          : ''}"
        iconF7="ticket"
        media
      />
      <ListItemLink title="Settings" link="setting" iconF7="gear_alt" media />
      <ListItemLink
        title="Logout"
        iconF7="square_arrow_left"
        media
        mediaStyle="transform: scaleX(-1);"
        on:click="{() => {
          $mainView.app.dialog
            .create({
              title: '',
              text: 'Logout From This Device',
              closeByBackdropClick: true,
              buttons: [
                {
                  text: 'Cancel',
                  keyCodes: [27],
                },
                {
                  text: 'Logout',
                  color: 'red',
                  bold: true,
                  keyCodes: [13],
                  onClick: () => {
                    userLogout();
                  },
                },
              ],
            })
            .open();
        }}"
      />
      <ListItemLink
        title="Clear Cache"
        iconF7="trash"
        media
        on:click="{() => {
          $mainView.app.dialog
            .create({
              title: '',
              text: 'Clear Local Cache',
              closeByBackdropClick: true,
              buttons: [
                {
                  text: 'Cancel',
                  keyCodes: [27],
                },
                {
                  text: 'Clear',
                  keyCodes: [13],
                  bold: true,
                  color: 'red',
                  onClick: () => {
                    clearLocalDb().then(() => {
                      mainViewNavigateRoot(true);
                    });
                  },
                },
              ],
            })
            .open();
        }}"
      />
      {#if $userInfo.role === 'admin' || $userInfo.role === 'superadmin'}
        <ListItemLink title="Promo Code" link="promocode" iconF7="gift" media />
      {/if}
    </List>
    <div class="flex justify-end w-full select-none">
      <span
        class="items-center tooltip-init mr-1 select-none"
        data-tooltip="{new Date(window.buildInfo.ts)}"
      >
        Build
        {window.buildInfo.ver}
      </span>
    </div>
  </div>
</Page>
