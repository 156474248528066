<script>
  import { Page, Popup } from 'framework7-svelte';
  import ChatUploadPreview from '../components/ChatUploadPreview.svelte';
  import { mainViewNavigateBack, multiView } from '../js/store';

  export let props = {};
  const {
    selectedFile,
    selectedFileType,
    selectedFileUrl,
    sendInProgress,
    lockIfSendInProgress,
  } = props;

  let getPopupInstance;

  function sendHandler() {
    mainViewNavigateBack();
  }
</script>

<Page
  on:pageBeforeIn="{() => {
    if (getPopupInstance) {
      getPopupInstance().open();
    }
  }}"
  on:pageBeforeOut="{() => {
    if (getPopupInstance) {
      getPopupInstance().close();
    }
  }}"
>
  <!-- Only use popup on big screen, normal page on mobile -->
  {#if $multiView}
    <Popup bind:instance="{getPopupInstance}" tabletFullscreen="{true}">
      <ChatUploadPreview
        selectedFile="{selectedFile}"
        selectedFileType="{selectedFileType}"
        selectedFileUrl="{selectedFileUrl}"
        sendInProgress="{sendInProgress}"
        lockIfSendInProgress="{lockIfSendInProgress}"
        on:send="{sendHandler}"
      />
    </Popup>
  {:else}
    <ChatUploadPreview
      selectedFile="{selectedFile}"
      selectedFileType="{selectedFileType}"
      selectedFileUrl="{selectedFileUrl}"
      sendInProgress="{sendInProgress}"
      lockIfSendInProgress="{lockIfSendInProgress}"
      on:send="{sendHandler}"
    />
  {/if}
</Page>
