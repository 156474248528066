<script>
  import { writable } from 'svelte/store';
  import { Page, Navbar, List, ListItem, NavRight, Link, BlockTitle } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';
  import {
    showLoadingDialog,
    remoteGenerateReport,
    selectedDesk,
    selectedDeskReportSetting,
    selectedDeskContactFilter,
    generateDefaultDeskContactFilter,
  } from '../js/store';
  import { saveFile } from '../js/util';
  import ContactFilterDetail from '../components/ContactFilterDetail.svelte';

  $: contactFilter = $selectedDeskReportSetting.filter
    ? writable($selectedDeskReportSetting.filter)
    : writable(generateDefaultDeskContactFilter($selectedDesk._id));

  // Force filter reload on change. Nested object on change will not trigger reload so we trigger it by assigning it to itself.
  $: if (!isEmpty($contactFilter) && !isEmpty($selectedDeskReportSetting)) {
    $selectedDeskReportSetting.filter = $contactFilter;
  }

  $: edited =
    $selectedDeskReportSetting.type !== 'case' ||
    ($selectedDeskReportSetting.fromCase && $selectedDeskReportSetting.toCase);
</script>

<Page>
  <Navbar title="Exports" backLink>
    <NavRight>
      <Link
        class="{edited ? '' : 'disabled'}"
        iconF7="checkmark_alt"
        on:click="{() => {
          showLoadingDialog(async () => {
            const reportFormat = $selectedDeskReportSetting.format;
            const report = await remoteGenerateReport($selectedDeskReportSetting);
            const fileName = `${$selectedDeskReportSetting.type}-report`;

            if (reportFormat === 'csv') {
              saveFile({
                blob: new window.Blob([report], { type: 'text/plain;charset=utf-8' }),
                ext: reportFormat,
                fileName,
                autoBom: true,
              });
            } else if (reportFormat === 'json') {
              saveFile({
                blob: new window.Blob([report], { type: 'text/plain;charset=utf-8' }),
                ext: reportFormat,
                fileName,
                autoBom: true,
              });
            } else if (reportFormat === 'xlsx') {
              // https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob
              saveFile({
                blob: new window.Blob([report], { type: 'application/octet-stream' }),
                ext: reportFormat,
                fileName,
                autoBom: true,
              });
            }
          }, 'Generating');
        }}"
      />
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    {#if !isEmpty($selectedDeskReportSetting)}
      <List noHairlines>
        <ListItem
          title="Export"
          smartSelect
          smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
        >
          <select name="report-type" bind:value="{$selectedDeskReportSetting.type}">
            <option value="contact">Contact</option>
            <option value="message">Message</option>
            <option value="case">Case</option>
          </select>
        </ListItem>
        <ListItem
          title="Format"
          smartSelect
          smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
        >
          <select name="report-format" bind:value="{$selectedDeskReportSetting.format}">
            <option value="csv">CSV</option>
            <option value="json">JSON</option>
            <option value="xlsx">EXCEL</option>
          </select>
        </ListItem>

        {#if $selectedDeskReportSetting.type === 'case'}
          <BlockTitle>Case</BlockTitle>
          <li class="item-content item-input inline-label input-title-color">
            <div class="item-inner">
              <div class="item-title item-label">From</div>
              <div class="item-input-wrap">
                <input
                  type="text"
                  placeholder="Case #"
                  required
                  validate
                  pattern="[0-9]*"
                  data-error-message="Numbers only"
                  value="{$selectedDeskReportSetting.fromCase}"
                  on:input="{(e) => {
                    const value = Number.parseInt(e.target.value);
                    if (Number.isFinite(value)) {
                      $selectedDeskReportSetting.fromCase = Number.parseInt(value);
                    }
                  }}"
                />
              </div>
            </div>
          </li>
          <li class="item-content item-input inline-label input-title-color">
            <div class="item-inner">
              <div class="item-title item-label">To</div>
              <div class="item-input-wrap">
                <input
                  type="text"
                  placeholder="Case #"
                  required
                  validate
                  pattern="[0-9]*"
                  data-error-message="Numbers only"
                  value="{$selectedDeskReportSetting.toCase}"
                  on:input="{(e) => {
                    const value = Number.parseInt(e.target.value);
                    if (Number.isFinite(value)) {
                      $selectedDeskReportSetting.toCase = Number.parseInt(value);
                    }
                  }}"
                />
              </div>
            </div>
          </li>
        {/if}

        <BlockTitle>Filter</BlockTitle>
        <ContactFilterDetail
          contactFilter="{contactFilter}"
          showChannelFilter
          showCaseStatusFilter
          showCopyFromOther="{{
            show: true,
            title: 'Copy From Desk Filter',
            confirmTitle: 'Replace current filter setting with desk contact filter?',
            source: selectedDeskContactFilter,
          }}"
        />
      </List>
    {/if}
  </div>
</Page>
