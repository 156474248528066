<script>
  import {
    Page,
    ListItem,
    SwipeoutActions,
    PhotoBrowser,
    Popover,
    List,
    Actions,
    ActionsGroup,
    Preloader,
  } from 'framework7-svelte';
  import { afterUpdate, beforeUpdate, onMount, tick } from 'svelte';
  import { fade } from 'svelte/transition';
  import { isEmpty, throttle } from 'lodash-es';
  import dayjs from 'dayjs';
  import Dropzone from 'svelte-file-dropzone';
  import sanitizeHtml from 'sanitize-html';
  import SquareImage from './SquareImage.svelte';
  import ListItemLink from './ListItemLink.svelte';
  import ActionsButtonIos from './ActionButtonIos.svelte';
  import InfiniteLoading from './InfiniteLoading.svelte';
  import { longpress } from '../js/actions';
  import {
    selectedChatList,
    selectedDeskMemberList,
    selectedContact,
    loadMoreChat,
    darkMode,
    quotedMsgId,
    openSecondaryView,
    showDialog,
    storeUpdateIfDifferent,
    selectedChatSearch,
    chatSearchTerm,
    selectedDeskContactFilter,
    remoteRecallOutboundMsg,
    showLoadingDialog,
    contactOnSelectSetupChatList,
    contactSearchTerm,
    contactAdvancedSearchTerm,
    selectedDeskUserInfo,
    userInfo,
    remoteResendTimeoutMsg,
  } from '../js/store';
  import {
    isMobileBrowser,
    msToMinuteSeconds,
    msToDayHourMinuteSecond,
    contactRoutePrimaryName,
    hyperlinkUrlGivenText,
    highlightText,
    openExternalUrl,
    convertMongoIdToHourTimestamp,
    getAckIcon,
    userIdToUserDeskScopedProfilePic,
    userIdToUserDeskScopedName,
    convertMongoIdToDateTimestamp,
    sameDay,
    mongoIdToTimestamp,
    splitTagIntoMemberTagAndNormalTagWithSelectUiItem,
    isValidDate,
    getWhatsAppGroupMember,
    isNativeApp,
    saveFile,
    chatMsgTypeToHumanReadable,
  } from '../js/util';
  import ReadMoreTextMsg from './ReadMoreTextMsg.svelte';
  import ChatAdditionalInfo from './ChatAdditionalInfo.svelte';
  import { chatScrollMarginTreatAsZero, enableWhatsappForwardFunction } from '../js/config';
  import { handleUploadDetermineType } from '../js/chat-upload-preview';

  let photoBrowser;
  let selectedPhoto = '';

  let chatDropDownTarget = {};
  let showExtraMemberInfo = true;
  let getChatDropDownDesktop;
  let getChatDropDownMobile;
  let chatDropDownVisible = false;

  let caseCloseDropDownTarget = '';
  let caseCloseDropDownExistingRemarkCount;
  let getCaseCloseDropDownDesktop;
  let getCaseCloseDropDownMobile;
  let caseCloseDropDownVisible = false;

  // Only show chat dropdown if there is option to be shown.
  function showDropDownReply(chat) {
    return chat.id;
  }
  function showDropDownForward(chat) {
    return enableWhatsappForwardFunction && chat.platform === 'whatsapp';
  }
  function showDropDownCancelSent(chat) {
    return (
      chat.platform === 'whatsapp' &&
      chat.direction === 'send' &&
      !chat.cancelledAt &&
      !chat.ack &&
      (chat.userId === $userInfo._id || $selectedDeskUserInfo.role === 'admin')
    );
  }
  function showDropDownRecallSent(chat) {
    return (
      chat.platform === 'whatsapp' &&
      chat.direction === 'send' &&
      !chat.recalledAt &&
      (chat.userId === $userInfo._id || $selectedDeskUserInfo.role === 'admin')
    );
  }
  function showDropDownResend(chat) {
    return (
      chat.platform === 'whatsapp' &&
      chat.direction === 'send' &&
      !chat.cancelledAt &&
      chat.timeoutAt &&
      chat.senderId !== 'external' &&
      (chat.userId === $userInfo._id || $selectedDeskUserInfo.role === 'admin')
    );
  }
  function showDropDownMsgInfo(chat) {
    return chat.platform === 'whatsapp' && chat.direction === 'send';
  }
  function showDropDownCopy(chat) {
    return chat.msgType === 'chat' || (chat.caption && chat.caption.type === 'chat');
  }
  function showDropDownDownload(chat) {
    return chat.msgType !== 'chat' || (chat.caption && chat.caption.type !== 'chat');
  }
  function hasValidDropDownOption(chat) {
    return (
      showDropDownReply(chat) ||
      showDropDownForward(chat) ||
      showDropDownCancelSent(chat) ||
      showDropDownRecallSent(chat) ||
      showDropDownResend(chat) ||
      showDropDownMsgInfo(chat) ||
      showDropDownCopy(chat) ||
      showDropDownDownload(chat)
    );
  }

  $: isVirginContact = $selectedContact._id === 'virgin';

  // Calculate floating date display depending on scroll position.
  // User scroll-able container position.
  let containerScrollPosition = 0;
  let scrollContainer;
  let isScrollContainerOverflown = false;

  // List of drawn date element, where key = actual index as in chat list.
  let dateDivList = {};
  let floatingDateText = '';

  $: if (isScrollContainerOverflown) {
    // Look from bottom to top, match the first date element that appears above scroll horizon,
    // if none just pick the first date.
    for (let chatIndex of Object.keys(dateDivList)) {
      const dateDiv = dateDivList[chatIndex];
      if (dateDiv) {
        if (dateDiv.offsetTop < containerScrollPosition) {
          floatingDateText = dateDiv.innerText;
          break;
        }
      }
    }
  } else {
    floatingDateText = '';
  }

  // Hide floating date after no user interaction for X sec.
  let showFloatingDate = false;
  let floatingDateTimeout;
  function toggleFloatingDate() {
    showFloatingDate = true;
    clearTimeout(floatingDateTimeout);
    floatingDateTimeout = setTimeout(() => {
      showFloatingDate = false;
    }, 2000);
  }

  // https://stackoverflow.com/questions/46104897/how-to-debounce-throttle-with-svelte
  let throttleScrollEvent = throttle((scrollEvent) => {
    containerScrollPosition = scrollEvent.target.scrollTop;
    // Show floating date whenever user scroll.
    toggleFloatingDate();
  }, 50);

  let showScrollLoader = false;
  // Load newer chat loader, only show if initial chat is fully drawn and there are more chat to fetch.
  // Also check if chat jump search is in progress OR contact msg time range filter is set.
  $: showReverseScrollLoader =
    showScrollLoader &&
    // Drawn chat had not reached bottom, eg by jumping to bottom.
    !$selectedChatList.find((c) => c._id === $selectedContact.lastChatId) &&
    (!isEmpty($selectedChatSearch) ||
      $selectedDeskContactFilter.startAt ||
      $selectedDeskContactFilter.endAt);

  // Store scroll position before update, so after update can revert back to last position.
  // https://stackoverflow.com/questions/9834143/jquery-keep-window-from-changing-scroll-position-while-prepending-items-to-a-l
  let lastScrollHeight = 0;
  let lastChatItem = {};
  beforeUpdate(() => {
    if (scrollContainer) {
      lastScrollHeight = scrollContainer.scrollHeight;
    }
  });

  afterUpdate(() => {
    // Only show top loader after initial chat is fully drawn and if there is more chat to load.
    showScrollLoader = !isEmpty($selectedChatList);

    // Do not show loader at all if is a virgin contact.
    if (isVirginContact) {
      showScrollLoader = false;
    }

    // Virgin contact will only have a snapshot of the chat being forwarded to dashboard,
    // it will not support load more from remote, if it matches filter, then those chat
    // will be received soon after and replaces this.
    if ($selectedContact.isVirgin) {
      showScrollLoader = false;
    }

    // After chat done drawing see if it fills up the whole window height which demands a scroll bar.
    isScrollContainerOverflown =
      scrollContainer && scrollContainer.scrollHeight > scrollContainer.clientHeight;

    // Revert back to last scroll position after append.
    // https://stackoverflow.com/questions/9834143/jquery-keep-window-from-changing-scroll-position-while-prepending-items-to-a-l
    if (
      scrollContainer &&
      // Not at bottom, if at bottom no need to update scroll, it will auto scroll to bottom.
      // OR if using search, which will show bottom loader, if so revert to last scroll position even tho at bottom
      // to preserve initial on search result click in position.
      (containerScrollPosition <= chatScrollMarginTreatAsZero ||
        $contactSearchTerm ||
        $contactAdvancedSearchTerm ||
        $chatSearchTerm) &&
      !isEmpty($selectedChatList) &&
      // Last item had changed, means it is an append, update scroll to revert the updated view back to last scroll position.
      lastChatItem._id !== $selectedChatList[0]._id
    ) {
      const scrollDiff = scrollContainer.scrollHeight - lastScrollHeight;
      scrollContainer.scrollTop -= scrollDiff;
    }

    lastChatItem = $selectedChatList[0] || {};
  });

  function generateCaseCloseMetadata(chat) {
    // Find open case entry.
    let openCaseDate = '-';
    let openCaseTime = '-';
    let closeCaseDate = '-';
    let closeCaseTime = '-';
    let firstHumanReplyTime = '-';
    let turnaroundTime = '-';

    let openCaseDateObj = mongoIdToTimestamp(log.openCaseId);
    let openmm = openCaseDateObj.getMonth();
    let opendd = openCaseDateObj.getDate();
    let openyy = openCaseDateObj.getFullYear().toString().substring(2);

    openCaseDate = `${opendd} ${monthNames[openmm]} ${openyy}`;
    openCaseTime = openCaseDateObj.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });

    let closeCaseDateObj = mongoIdToTimestamp(log._id);
    let closemm = closeCaseDateObj.getMonth();
    let closedd = closeCaseDateObj.getDate();
    let closeyy = closeCaseDateObj.getFullYear().toString().substring(2);

    closeCaseDate = `${closedd} ${monthNames[closemm]} ${closeyy}`;
    closeCaseTime = closeCaseDateObj.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });

    // Calculate turnaround time (time took from open to close case)
    turnaroundTime = msToMinuteSeconds(timestampObj.getTime() - openCaseDateObj.getTime(), true);

    return {
      openCaseDate,
      openCaseTime,
      closeCaseDate,
      closeCaseTime,
      firstHumanReplyTime,
      turnaroundTime,
    };
  }

  let dragDropShow = false;

  // Display divider inside chat view for date range filter's starting and ending point.
  let chatDateRangeTopDividerTargetChatId;
  let chatDateRangeBottomDividerTargetChatId;

  onMount(() => {
    const unsubscribeFnList = [];
    unsubscribeFnList.push(
      selectedChatList.subscribe((chatList) => {
        if (!isEmpty(chatList) && !isEmpty($selectedContact.jumpToChat)) {
          // Draw startAt, endAt filter's border if that filter is enabled.
          const isValidStartAt = isValidDate(new Date($selectedDeskContactFilter.startAt));
          const isValidEndAt = isValidDate(new Date($selectedDeskContactFilter.endAt));
          if (isValidStartAt && isValidEndAt) {
            chatDateRangeTopDividerTargetChatId = $selectedContact.jumpToChat._id;

            // Find the last message before endAt date.
            // Determine it by checking whether there is newer msg after the last msg that is older than endAt date.
            // Usually it exist, but not loaded into store yet, thus wait until we see it before displaying the bottom divider.
            const endAtDate = new Date($selectedDeskContactFilter.endAt);
            let lastValidEndChatId;
            let chatOlderThanEndAtDateFound = false;
            chatList.forEach((chat) => {
              if (mongoIdToTimestamp(chat._id) < endAtDate) {
                lastValidEndChatId = chat._id;
              } else {
                chatOlderThanEndAtDateFound = true;
              }
            });
            if (chatOlderThanEndAtDateFound) {
              chatDateRangeBottomDividerTargetChatId = lastValidEndChatId;
            }
          } else if (isValidStartAt) {
            chatDateRangeTopDividerTargetChatId = $selectedContact.jumpToChat._id;
          } else if (isValidEndAt) {
            chatDateRangeBottomDividerTargetChatId = $selectedContact.jumpToChat._id;
          }
        }
      })
    );

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });

  function generateContactInfoUpdatedText(chat, highlightTerm) {
    function highlight(term) {
      return sanitizeHtml(highlightText(term, highlightTerm), sanitizeOption);
    }
    let updatedTextList = [];
    if (chat.delta.new.name) {
      if (chat.delta.origin.name) {
        updatedTextList.push(
          `Changed name from '${highlight(chat.delta.origin.name)}' to '${highlight(
            chat.delta.new.name
          )}'`
        );
      } else {
        updatedTextList.push(`Set name to '${highlight(chat.delta.new.name)}'`);
      }
    }

    if (chat.delta.new.crn) {
      if (chat.delta.origin.crn) {
        updatedTextList.push(
          `Changed CRN from '${highlight(chat.delta.origin.crn)}' to '${highlight(
            chat.delta.new.crn
          )}'`
        );
      } else {
        updatedTextList.push(`Set CRN to '${highlight(chat.delta.new.crn)}'`);
      }
    }

    if (chat.delta.new.remark) {
      if (chat.delta.origin.remark) {
        updatedTextList.push(
          `Changed remark from '${highlight(chat.delta.origin.remark)}' to '${highlight(
            chat.delta.new.remark
          )}'`
        );
      } else {
        updatedTextList.push(`Set remark to '${highlight(chat.delta.new.remark)}'`);
      }
    }

    if (chat.delta.new.tag) {
      updatedTextList.push('Tag updated');
      // if (!isEmpty(chat.delta.origin.tag)) {
      //   updatedTextList.push(
      //     `Updated tag from '${highlight(
      //       splitTagIntoMemberTagAndNormalTagWithSelectUiItem(
      //         chat.delta.origin.tag,
      //         $selectedDeskMemberList
      //       ).tagListStr
      //     )}' to '${highlight(
      //       splitTagIntoMemberTagAndNormalTagWithSelectUiItem(
      //         chat.delta.new.tag,
      //         $selectedDeskMemberList
      //       ).tagListStr
      //     )}'`
      //   );
      // } else {
      //   updatedTextList.push(
      //     `Set tag to '${highlight(
      //       splitTagIntoMemberTagAndNormalTagWithSelectUiItem(
      //         chat.delta.new.tag,
      //         $selectedDeskMemberList
      //       ).tagListStr
      //     )}'`
      //   );
      // }
    }

    return updatedTextList;
  }

  /**
   * All ucc desk chat initiated by members had their direction set to 'send'
   * Invert them as needed depending on whether this user is the sender.
   */
  function uccGroupChatInvertSendRecvIfNeeded(chatList) {
    if ($selectedContact.platform === 'ucc') {
      chatList = chatList.map((c) => {
        if (c.type === 'chat' && c.senderId !== $userInfo._id) {
          c.direction = 'recv';
        }
        return c;
      });
    }
    return chatList;
  }

  const sanitizeOption = {
    allowedTags: ['mark'],
    allowedAttributes: {
      mark: ['style'],
    },
    disallowedTagsMode: 'recursiveEscape',
  };
</script>

<style lang="scss">
  .chat {
    @apply max-w-full;
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
    &.send {
      @apply bg-green-secondary;
      @apply ml-10;
    }
    &.recv {
      @apply bg-white;
      @apply mr-10;
    }
  }
  .dark .chat {
    &.send {
      @apply bg-green-dark;
    }
    &.recv {
      @apply bg-green-dark-secondary;
    }
  }

  // On hover display dropdown btn
  .chat:hover .chat-dropdown-btn,
  .chat-dropdown-btn:hover {
    @apply opacity-100;
    @apply duration-100;
  }

  .chat-dropdown-btn {
    @apply transition-opacity;
    @apply ease-linear;
    @apply opacity-0;
    @apply duration-100;

    // Gradient blur text, semi transparent
    &.send {
      @apply from-transparent;
      @apply via-green-secondary;
      @apply to-green-secondary;
    }
    &.recv {
      @apply from-transparent;
      @apply via-white;
      @apply to-white;
    }
  }
  .dark .chat-dropdown-btn {
    // Gradient blur text, semi transparent
    &.send {
      @apply from-transparent;
      @apply via-green-dark;
      @apply to-green-dark;
    }
    &.recv {
      @apply from-transparent;
      @apply via-green-dark-secondary;
      @apply to-green-dark-secondary;
    }
  }

  .floating-date {
    @apply transition-opacity;
    @apply ease-linear;
    @apply opacity-0;
    @apply duration-100;
  }
  .floating-date.show {
    @apply opacity-100;
    @apply duration-100;
  }

  .platform-notification {
    @apply text-center;
    @apply bg-blue-date;
    @apply text-gray-700;
  }
  .case-open {
    @apply w-3/4;
    @apply text-center;
    background-color: #4572c5;
  }
  .case-close {
    @apply w-3/4;
    @apply text-center;
    background-color: #4572c5;
  }
  .memo {
    @apply w-3/4;
    @apply text-center;
    background-color: darkorange;
  }
  .flash {
    @apply w-3/4;
    @apply text-center;
    background-color: #03dac6;
    color: black;
  }
  .dark {
    .platform-notification {
      .timestamp {
        @apply opacity-75;
      }
    }
    .case-open {
      background-color: #3700b3;
    }
    .case-close {
      background-color: #3700b3;
    }
    .memo {
      background-color: #bb86fc;
    }
    .flash {
      background-color: #03dac6;
    }
  }

  // Fix scrollbar width causing floating date to misalign with actual date container.
  .hide-scrollbar {
    @apply overflow-y-scroll;
  }
  .hide-scrollbar::-webkit-scrollbar {
    @apply opacity-0;
  }

  // https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css
  .clamp-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  // https://stackoverflow.com/questions/23501130/change-background-color-once-after-few-seconds
  @keyframes bg-change {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: rgba(190, 190, 190, 0.3);
    }
    100% {
      background-color: transparent;
    }
  }
  .highlight-once {
    animation: bg-change 1.5s;
  }

  // https://stackoverflow.com/questions/5214127/css-technique-for-a-horizontal-line-with-words-in-the-middle
  .divider {
    min-height: 1.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    overflow: hidden;
    text-align: center;
  }
  .divider:before,
  .divider:after {
    background-color: var(--f7-list-item-border-color);
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  .divider:before {
    right: 0.5em;
    margin-left: -50%;
  }
  .divider:after {
    left: 0.5em;
    margin-right: -50%;
  }

  .jump-bottom-fab div {
    background-color: var(--f7-toolbar-bg-color, var(--f7-bars-bg-color));
    width: 42px;
    height: 42px;
  }
</style>

<Page>
  <PhotoBrowser
    photos="{selectedPhoto}"
    theme="dark"
    bind:this="{photoBrowser}"
    type="standalone"
    navbar="{true}"
    toolbar="{false}"
    swipeToClose
  />
  <div
    class="{dragDropShow ? 'absolute w-full h-full z-10' : ''}"
    style="max-height: calc(100% - var(--f7-navbar-height) - var(--f7-safe-area-top) - var(--f7-safe-area-bottom) - var(--f7-toolbar-height))"
  >
    <Dropzone
      on:drop="{(e) => {
        // Pass drag drop file to outbound send handler.
        const { acceptedFiles } = e.detail;
        dragDropShow = false;
        if (acceptedFiles && acceptedFiles.length > 0) {
          handleUploadDetermineType({ file: acceptedFiles[0] });
        }
      }}"
      containerClasses="h-full w-full {$darkMode ? 'bg-gray-900' : 'bg-gray-200'} {dragDropShow &&
      !isEmpty($selectedContact)
        ? ''
        : 'hidden'}"
      containerStyles="outline: 2px dashed #92b0b3; outline-offset: -20px;"
      disableDefaultStyles
      on:dragleave="{() => {
        dragDropShow = false;
      }}"
    >
      <div class="flex h-full w-full items-center place-content-center">Drag file here</div>
    </Dropzone>
  </div>

  <div
    class="flex flex-col-reverse w-full h-full select-none overflow-auto overscroll-y-contain {$darkMode
      ? 'dark'
      : ''}"
    style="background-image: url('{$darkMode
      ? 'static/images/chat-background-dark.png'
      : 'static/images/chat-background.png'}'); touch-action: pan-y; -webkit-user-drag: none;"
    on:scroll="{throttleScrollEvent}"
    bind:this="{scrollContainer}"
    on:dragenter="{() => {
      if (!isEmpty($selectedContact)) {
        dragDropShow = true;
      }
    }}"
  >
    <!-- Need an extra div wrapper around the main scroll-able container so floating date header can float via absolute positioning. -->
    <!-- Sticky floating date header -->
    <!-- Only display if there is enough content to fill the scroll screen and make the scroll bar visible, -->
    <!-- without this it will yield a floating date with large empty spaces as chat draw from bottom to up. -->
    {#if floatingDateText && isScrollContainerOverflown}
      <div
        class="hide-scrollbar absolute flex w-full py-2 justify-center z-10 floating-date flex-shrink-0"
        class:show="{showFloatingDate}"
        style="top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top))"
      >
        <div class="chat px-3 py-1 rounded-lg bg-blue-date">
          <span class="text-gray-700">{floatingDateText}</span>
        </div>
      </div>
    {/if}

    <!-- Position relative is critical here, without it floating date offsetTop will include parent element, leading to wrong offsetTop value -->
    <!-- https://stackoverflow.com/questions/34213227/scrollable-div-to-stick-to-bottom-when-outer-div-changes-in-size -->
    <!-- Use column reverse instead of append from top so on div resize scroll position will not jump around. WaWeb also uses the same design -->
    <!-- Use min-h-full instead of h-full so each element can expand freely -->
    <!-- https://stackoverflow.com/questions/19757951/div-100-height-scroll -->
    <div class="flex flex-col-reverse w-full min-h-full select-none relative">
      <!-- Show initial spinner when a contact is selected and no chat is shown yet. -->
      {#if !isEmpty($selectedContact) && isEmpty($selectedChatList) && !isVirginContact}
        <span class="absolute flex w-full justify-center py-3">
          <Preloader />
        </span>
      {/if}

      <!-- Load newer chat loader, only show if initial chat is fully drawn -->
      {#if showReverseScrollLoader}
        <InfiniteLoading
          distance="600"
          direction="bottom"
          reverse
          on:infinite="{(event) => {
            loadMoreChat($selectedContact._id, 'bottom')
              .then((completed) => {
                if (completed) {
                  event.detail.complete();
                  console.log('Chat reached bottom fully loaded');
                } else {
                  event.detail.loaded();
                }
              })
              .catch(() => {
                event.detail.error();
              });
          }}"
        >
          <span slot="noResults"></span>
          <span slot="noMore"></span>
          <!-- Framework7 default platform specific infinite load spinner -->
          <span slot="spinner" class="flex w-full justify-center py-3">
            <Preloader />
          </span>
          <span slot="error" let:attemptLoad>
            <span
              on:click="{attemptLoad}"
              class="flex justify-center cursor-pointer text-blue-primary font-medium py-3 px-3"
              >Retry</span
            >
          </span>
        </InfiniteLoading>
      {/if}

      {#each uccGroupChatInvertSendRecvIfNeeded($selectedChatList) as chat, i (chat._id)}
        {#if chat._id === chatDateRangeBottomDividerTargetChatId}
          <div class="divider">Newer chat below outside of set range filter</div>
        {/if}
        {#if chat.type === 'chat'}
          {#if chat.isNotification}
            {#if chat.msgType === 'call_log' && chat.subtype === 'miss'}
              <List noHairlines>
                <ListItem>
                  <div class="flex flex-col w-full place-items-center">
                    <div class="chat platform-notification px-3 py-1 rounded-lg">
                      <span>Missed voice call</span>
                      <span class="flex justify-end">
                        <small class="timestamp">{convertMongoIdToHourTimestamp(chat._id)}</small>
                      </span>
                    </div>
                  </div>
                </ListItem>
              </List>
            {:else}
              <List noHairlines>
                <ListItem>
                  <div class="flex flex-col w-full place-items-center">
                    <div class="chat platform-notification px-3 py-1 rounded-lg">
                      <span>WhatsApp Notification</span>
                      <span class="flex justify-end">
                        <small class="timestamp">{convertMongoIdToHourTimestamp(chat._id)}</small>
                      </span>
                    </div>
                  </div>
                </ListItem>
              </List>
            {/if}
          {:else}
            <div
              class="list no-hairlines {chatDropDownVisible && chatDropDownTarget._id === chat._id
                ? 'bg-gray-800 ' + ($darkMode ? 'bg-opacity-50' : 'bg-opacity-10')
                : ''} {chat._id === $selectedChatSearch._id ? 'highlight-once' : ''}"
            >
              <ul style="background: transparent;">
                <ListItem
                  swipeout
                  on:swipeoutOpen="{() => {
                    chatDropDownTarget = chat;
                    storeUpdateIfDifferent(quotedMsgId, chatDropDownTarget.id);
                  }}"
                >
                  <SwipeoutActions left />
                  <div
                    slot="inner-start"
                    class="flex {chat.direction === 'send'
                      ? 'justify-end pl-2'
                      : 'justify-start pr-2'} w-full"
                    use:longpress
                    on:longpress="{(e) => {
                      // Mobile only on long click open chat additional menu.
                      if (isMobileBrowser && hasValidDropDownOption(chat)) {
                        chatDropDownTarget = chat;
                        getChatDropDownMobile().open();
                      }
                    }}"
                  >
                    <div class="chat px-3 py-1 rounded-lg relative {chat.direction || ''}">
                      {#if typeof chat.caption === 'object' && !isEmpty(chat.caption) && chat.msgType !== 'document' && !chat.caption.isText}
                        <div
                          class="flex rounded-md cursor-pointer {chat.caption.isLinkPreview
                            ? ''
                            : 'border-l-4 border-green-primary'} bg-opacity-25 bg-gray-600"
                          on:click="{() => {
                            if (chat.caption.isLinkPreview) {
                              openExternalUrl(chat.caption.matchedUrl);
                            } else {
                              console.log('Jump to chat', chat.caption);
                            }
                          }}"
                        >
                          <div
                            class="mx-2 flex-grow rounded-r-md max-w-full {chat.caption.type ===
                            'chat'
                              ? 'break-words clamp-text'
                              : ''}"
                          >
                            {#if chat.caption.isQuote}
                              {#if chat.caption.type === 'chat'}
                                {chat.caption.msg}
                              {:else if chat.caption.type === 'image' || chat.caption.type === 'sticker'}
                                <div class="flex flex-row">
                                  <div class="flex flex-col h-full self-center mr-2">
                                    <div>
                                      {chat.caption.direction === 'recv'
                                        ? contactRoutePrimaryName($selectedContact)
                                        : 'You'}
                                    </div>
                                    <div class="opacity-75">Photo</div>
                                  </div>
                                  <SquareImage src="{chat.caption.msg}" alt="caption" />
                                </div>
                              {:else if chat.caption.type === 'ptt'}
                                <div class="flex flex-row">
                                  <div class="flex flex-col h-full self-center mr-2">
                                    <div>
                                      {chat.caption.direction === 'recv'
                                        ? contactRoutePrimaryName($selectedContact)
                                        : 'You'}
                                    </div>
                                    <div class="opacity-75">
                                      Voice ({msToMinuteSeconds(chat.caption.duration * 1000)})
                                    </div>
                                  </div>
                                </div>
                              {:else if chat.caption.type === 'audio'}
                                <div class="flex flex-row">
                                  <div class="flex flex-col h-full self-center mr-2">
                                    <div>
                                      {chat.caption.direction === 'recv'
                                        ? contactRoutePrimaryName($selectedContact)
                                        : 'You'}
                                    </div>
                                    <div class="opacity-75">
                                      Audio ({msToMinuteSeconds(chat.caption.duration * 1000)})
                                    </div>
                                  </div>
                                </div>
                              {:else if chat.caption.type === 'video'}
                                <div class="flex flex-row">
                                  <div class="flex flex-col h-full self-center mr-2">
                                    <div>
                                      {chat.caption.direction === 'recv'
                                        ? contactRoutePrimaryName($selectedContact)
                                        : 'You'}
                                    </div>
                                    <div class="opacity-75">
                                      Video ({msToMinuteSeconds(chat.caption.duration * 1000)})
                                    </div>
                                  </div>
                                </div>
                              {:else if chat.caption.type === 'document'}
                                <div class="flex flex-row">
                                  <div class="flex flex-col h-full self-center mr-2">
                                    <div>
                                      {chat.caption.direction === 'recv'
                                        ? contactRoutePrimaryName($selectedContact)
                                        : 'You'}
                                    </div>
                                    <div class="opacity-75">Document</div>
                                  </div>
                                </div>
                              {/if}
                            {:else if chat.caption.isLinkPreview}
                              <div class="flex flex-row items-center">
                                <div class="flex-shrink-0 w-12 h-12 mr-2">
                                  {#if chat.caption.thumbnail}
                                    <SquareImage
                                      src="data:image/png;base64, {chat.caption.thumbnail}"
                                      alt="thumbnail"
                                    />
                                  {/if}
                                </div>
                                <div class="max-w-full min-w-0 pr-5">
                                  <div class="truncate">
                                    {chat.caption.title || chat.caption.description || 'Untitled'}
                                  </div>
                                  <div class="opacity-75 truncate">{chat.caption.matchedUrl}</div>
                                </div>
                              </div>
                            {:else}Unsupported caption{/if}
                          </div>
                        </div>
                      {/if}
                      {#if chat.msgType == 'chat'}
                        <ReadMoreTextMsg
                          class="select-text mobile-select-none break-words whitespace-pre-wrap"
                        >
                          {@html sanitizeHtml(
                            highlightText(
                              hyperlinkUrlGivenText(chat.msg ? chat.msg.trim() : ''),
                              $chatSearchTerm
                            ),
                            {
                              allowedTags: ['a', 'mark'],
                              allowedAttributes: {
                                mark: ['style'],
                                a: ['href', 'style', 'onclick'],
                              },
                              disallowedTagsMode: 'recursiveEscape',
                            }
                          )}
                        </ReadMoreTextMsg>
                      {:else if chat.msgType === 'image' || chat.msgType === 'sticker'}
                        <div class="flex items-center justify-center">
                          <img
                            class="cursor-pointer max-h-64 max-w-64"
                            src="{chat.msg}"
                            alt="thumbnail"
                            on:click="{() => {
                              selectedPhoto = [chat.msg];
                              tick().then(() => {
                                photoBrowser.open();
                              });
                            }}"
                          />
                        </div>
                      {:else if chat.msgType === 'audio' || chat.msgType === 'ptt'}
                        <div>
                          <!-- svelte-ignore a11y-media-has-caption -->
                          <audio
                            controls
                            preload="metadata"
                            src="{chat.msg}"
                            class="focus:outline-none"></audio>
                        </div>
                      {:else if chat.msgType === 'video'}
                        <!-- Migrate to vime player in future after this issue is fixed, now it will fail due to dynamic load 404 webpack file not found, we using rollup. -->
                        <!-- https://github.com/vime-js/vime/issues/82 -->
                        <div>
                          <!-- svelte-ignore a11y-media-has-caption -->
                          <video controls preload="metadata" class="max-h-64 max-w-64">
                            <source src="{chat.msg}#t=0.1" />
                            Sorry, your browser doesn't support embedded videos.
                          </video>
                        </div>
                      {:else if chat.msgType === 'document'}
                        <a href="{chat.msg}">
                          <i class="f7-icons text-base">doc</i>
                          <span>{chat.fileName || 'Document'}</span>
                        </a>
                      {:else}
                        <div>Unknown</div>
                      {/if}

                      {#if typeof chat.caption === 'object' && !isEmpty(chat.caption) && chat.caption.isText}
                        <div>{chat.caption.msg}</div>
                      {/if}

                      {#if (chat.direction === 'send' || chat.platform === 'ucc') && chat.senderId && showExtraMemberInfo}
                        <div class="flex flex-col space-x-1 select-none items-end justify-end">
                          <span class="flex mt-1 space-x-1">
                            <img
                              src="{userIdToUserDeskScopedProfilePic(
                                chat.senderId,
                                $selectedDeskMemberList
                              )}"
                              class="w-4 h-4 rounded-full"
                              alt="user-profile"
                              on:contextmenu="{(e) => {
                                e.preventDefault();
                              }}"
                            />
                            <span class="text-xs opacity-50"
                              >{userIdToUserDeskScopedName(
                                chat.senderId,
                                $selectedDeskMemberList
                              )}</span
                            >
                          </span>
                          <span class="flex mt-1 space-x-1">
                            <span class="text-xs opacity-75"
                              >{convertMongoIdToHourTimestamp(chat._id)}</span
                            >
                            {#if getAckIcon(chat, $darkMode)}
                              <img
                                src="{getAckIcon(chat, $darkMode)}"
                                class=""
                                alt="ack"
                                on:contextmenu="{(e) => {
                                  e.preventDefault();
                                }}"
                              />
                            {/if}
                          </span>
                        </div>
                      {:else}
                        <div class="flex flex-row space-x-1 select-none items-end justify-end">
                          <span class="text-xs opacity-75 mt-1"
                            >{convertMongoIdToHourTimestamp(chat._id)}</span
                          >
                          {#if chat.direction === 'recv' && chat.meta && chat.meta.isGroupMsg}
                            <span class="flex mt-1 space-x-1">
                              <span class="text-xs opacity-50"
                                >{getWhatsAppGroupMember($selectedContact, chat.meta.sender).name ||
                                  ''}</span
                              >
                            </span>
                          {/if}
                          {#if chat.direction === 'send'}
                            {#if chat.senderId}
                              <img
                                src="{userIdToUserDeskScopedProfilePic(
                                  chat.senderId,
                                  $selectedDeskMemberList
                                )}"
                                class="w-4 h-4 rounded-full"
                                alt="user-profile"
                                on:contextmenu="{(e) => {
                                  e.preventDefault();
                                }}"
                              />
                            {/if}
                            {#if getAckIcon(chat, $darkMode)}
                              <img
                                src="{getAckIcon(chat, $darkMode)}"
                                class=""
                                alt="ack"
                                on:contextmenu="{(e) => {
                                  e.preventDefault();
                                }}"
                              />
                            {/if}
                          {/if}
                        </div>
                      {/if}
                      <!-- Extra padding to increase dropdown blur range. -->
                      {#if hasValidDropDownOption(chat)}
                        <span
                          class="absolute top-0 right-2 chat-dropdown-btn {chat.direction} bg-gradient-to-tr pl-2 pb-1 rounded-bl-full
                      {isMobileBrowser ? 'hidden' : ''}"
                          on:click="{(e) => {
                            // Desktop only dropdown on chat rhs chevron click.
                            if (!isMobileBrowser) {
                              chatDropDownTarget = chat;
                              getChatDropDownDesktop().open(e.currentTarget, true);
                            }
                          }}"
                        >
                          <i class="f7-icons opacity-25 select-none cursor-pointer text-base"
                            >chevron_down</i
                          >
                        </span>
                      {/if}
                    </div>
                  </div>
                </ListItem>
              </ul>
            </div>
          {/if}
        {:else if chat.type === 'case_open'}
          <List noHairlines>
            <ListItem>
              <div
                class="flex flex-col w-full place-items-center
              {chat._id === $selectedChatSearch._id ? 'highlight-once' : ''}"
              >
                <div class="chat case-open px-3 py-1 rounded-lg">
                  <span>
                    {@html sanitizeHtml(
                      highlightText(
                        `Case #${chat.caseNum.toString().padStart(7, '0')}`,
                        $chatSearchTerm
                      ),
                      sanitizeOption
                    )}</span
                  >
                  <span class="flex justify-end">
                    <small class="timestamp">{convertMongoIdToHourTimestamp(chat._id)}</small>
                    <img
                      class="rounded-full w-4 h-4"
                      src="static/images/system.png"
                      alt="profile"
                      on:contextmenu="{(e) => {
                        e.preventDefault();
                      }}"
                    />
                  </span>
                </div>
              </div>
            </ListItem>
          </List>
        {:else if chat.type === 'case_close'}
          <List noHairlines>
            <ListItem>
              <div
                class="flex flex-col w-full place-items-center {caseCloseDropDownVisible &&
                caseCloseDropDownTarget._id === chat._id
                  ? 'bg-gray-800 ' + ($darkMode ? 'bg-opacity-50' : 'bg-opacity-10')
                  : ''}
                {chat._id === $selectedChatSearch._id ? 'highlight-once' : ''}"
              >
                <div
                  class="chat case-close px-3 py-1 rounded-lg relative"
                  use:longpress
                  on:longpress="{(e) => {
                    // Mobile only on long click open case close additional menu.
                    if (isMobileBrowser) {
                      caseCloseDropDownTarget = chat._id;
                      caseCloseDropDownExistingRemarkCount =
                        chat.content && Array.isArray(chat.content) ? chat.content.length : 0;
                      getCaseCloseDropDownMobile().open();
                    }
                  }}"
                >
                  <!-- Drop down top right on big screen -->
                  <span
                    class="absolute top-0 right-2 chat-dropdown-btn pl-2 pb-1 rounded-bl-full {isMobileBrowser
                      ? 'hidden'
                      : ''}"
                    on:click="{(e) => {
                      // Desktop only dropdown on chat rhs chevron click.
                      if (!isMobileBrowser) {
                        caseCloseDropDownTarget = chat._id;
                        caseCloseDropDownExistingRemarkCount =
                          chat.content && Array.isArray(chat.content) ? chat.content.length : 0;
                        getCaseCloseDropDownDesktop().open(e.currentTarget, true);
                      }
                    }}"
                  >
                    <i class="f7-icons opacity-25 select-none cursor-pointer text-base"
                      >chevron_down</i
                    >
                  </span>

                  <span>
                    {@html sanitizeHtml(
                      highlightText(
                        `Closed #${chat.caseNum.toString().padStart(7, '0')}`,
                        $chatSearchTerm
                      ),
                      sanitizeOption
                    )}</span
                  >
                  <div class="flex flex-col text-select ucc-fsz-small">
                    <table>
                      <tbody class="text-left">
                        <tr>
                          <td class="px-4">Open Date</td>
                          <td>: {dayjs(mongoIdToTimestamp(chat.openId)).format('D MMM YYYY')}</td>
                        </tr>
                        <tr>
                          <td class="px-4">Open Time</td>
                          <td>: {dayjs(mongoIdToTimestamp(chat.openId)).format('HH:mm')}</td>
                        </tr>
                        <tr>
                          <td class="px-4">Close Date</td>
                          <td>: {dayjs(mongoIdToTimestamp(chat._id)).format('D MMM YYYY')}</td>
                        </tr>
                        <tr>
                          <td class="px-4">Close Time</td>
                          <td>: {dayjs(mongoIdToTimestamp(chat._id)).format('HH:mm')}</td>
                        </tr>
                        <tr>
                          <td class="px-4">AFR Time</td>
                          <td>
                            :
                            {chat.firstHumanChatId
                              ? msToDayHourMinuteSecond(
                                  mongoIdToTimestamp(chat.firstHumanChatId).valueOf() -
                                    mongoIdToTimestamp(chat.openId).valueOf(),
                                  true
                                )
                              : '-'}
                          </td>
                        </tr>
                        <tr>
                          <td class="px-4">Turnaround</td>
                          <td>
                            :
                            {msToDayHourMinuteSecond(
                              mongoIdToTimestamp(chat._id).valueOf() -
                                mongoIdToTimestamp(chat.openId).valueOf(),
                              true
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {#if !isEmpty(chat.content) && (chat.content[0].text || !isEmpty(chat.content[0].media))}
                      <div>Remark</div>
                    {/if}
                    <ChatAdditionalInfo additionalInfoList="{chat.content}" />
                  </div>
                </div>
              </div>
            </ListItem>
          </List>
        {:else if chat.type === 'memo'}
          <List noHairlines>
            <ListItem>
              <div
                class="flex flex-col w-full place-items-center
              {chat._id === $selectedChatSearch._id ? 'highlight-once' : ''}"
              >
                <div class="chat memo px-3 py-1 rounded-lg relative">
                  <span>Memo</span>
                  <ChatAdditionalInfo additionalInfoList="{chat.content}" />
                </div>
              </div>
            </ListItem>
          </List>
        {:else if chat.type === 'flash'}
          <List noHairlines>
            <ListItem>
              <div
                class="flex flex-col w-full place-items-center 
                {chat._id === $selectedChatSearch._id ? 'highlight-once' : ''}"
              >
                <div class="chat flash px-3 py-1 rounded-lg relative">
                  <span>Flash</span>
                  <ChatAdditionalInfo additionalInfoList="{chat.content}" />
                </div>
              </div>
            </ListItem>
          </List>
        {:else if chat.type === 'edit_info'}
          <List noHairlines>
            <ListItem>
              <div
                class="flex flex-col w-full place-items-center
              {chat._id === $selectedChatSearch._id ? 'highlight-once' : ''}"
              >
                <div class="chat flash px-3 py-1 rounded-lg">
                  <span>Contact Info Updated</span>
                  <div>
                    {#each generateContactInfoUpdatedText(chat, $chatSearchTerm) as contactInfoText}
                      <div>
                        {@html contactInfoText}
                      </div>
                    {/each}
                  </div>
                  <span class="flex justify-end">
                    <small class="timestamp">{convertMongoIdToHourTimestamp(chat._id)}</small>
                    <img
                      class="rounded-full w-4 h-4"
                      src="static/images/system.png"
                      alt="profile"
                      on:contextmenu="{(e) => {
                        e.preventDefault();
                      }}"
                    />
                  </span>
                </div>
              </div>
            </ListItem>
          </List>
        {:else}
          <div>Unknown chat type {chat.type}</div>
        {/if}
        <!-- Insert date header above chat as needed -->
        <!-- date div needed to calculate floating chat date -->
        <!-- If it is the last chat in the list, insert date stamp so it will always has minimum 1 date stamp at all time ||
        if next chat and this chat has different date, insert date stamp -->
        {#if (!isEmpty($selectedChatList) && i == $selectedChatList.length - 1) || ($selectedChatList[i + 1] && !sameDay(mongoIdToTimestamp(chat._id), mongoIdToTimestamp($selectedChatList[i + 1]._id)))}
          <div class="flex w-full py-2 justify-center flex-shrink-0" bind:this="{dateDivList[i]}">
            <div class="chat px-3 py-1 rounded-lg bg-blue-date">
              <span class="text-gray-700">{convertMongoIdToDateTimestamp(chat._id)}</span>
            </div>
          </div>
        {/if}
        {#if chat._id === chatDateRangeTopDividerTargetChatId}
          <div class="divider">Older chat above outside of set range filter</div>
        {/if}
      {/each}

      <!-- Load older chat loader, only show if initial chat is fully drawn and there are more chat to fetch -->
      {#if showScrollLoader}
        <InfiniteLoading
          distance="600"
          direction="top"
          reverse
          on:infinite="{(event) => {
            loadMoreChat($selectedContact._id, 'top')
              .then((completed) => {
                if (completed) {
                  event.detail.complete();
                  console.log('Chat reached top fully loaded');
                } else {
                  event.detail.loaded();
                }
              })
              .catch(() => {
                event.detail.error();
              });
          }}"
        >
          <span slot="noResults"></span>
          <span slot="noMore"></span>
          <!-- Framework7 default platform specific infinite load spinner -->
          <span slot="spinner" class="flex w-full justify-center py-3">
            <Preloader />
          </span>
          <span slot="error" let:attemptLoad>
            <span
              on:click="{attemptLoad}"
              class="flex justify-center cursor-pointer text-blue-primary font-medium py-3 px-3"
              >Retry</span
            >
          </span>
        </InfiniteLoading>
      {/if}
    </div>
  </div>

  <Actions
    bind:instance="{getChatDropDownMobile}"
    class="ios {$darkMode ? 'theme-dark' : ''}"
    on:actionsOpen="{() => {
      chatDropDownVisible = true;
    }}"
    on:actionsClose="{() => {
      chatDropDownVisible = false;
    }}"
  >
    <ActionsGroup>
      {#if showDropDownReply(chatDropDownTarget)}
        <ActionsButtonIos
          on:click="{() => {
            storeUpdateIfDifferent(quotedMsgId, chatDropDownTarget.id);
          }}"
        >
          <span slot="icon">arrowshape_turn_up_left</span>
          <span slot="text">Reply</span>
        </ActionsButtonIos>
      {/if}
      {#if showDropDownForward(chatDropDownTarget)}
        <ActionsButtonIos
          on:click="{() => {
            showDialog('Coming Soon');
          }}"
        >
          <span slot="icon">arrowshape_turn_up_right</span>
          <span slot="text">Forward</span>
        </ActionsButtonIos>
      {/if}
      {#if showDropDownRecallSent(chatDropDownTarget)}
        <ActionsButtonIos
          on:click="{() => {
            showLoadingDialog(async () => {
              await remoteRecallOutboundMsg({
                msgId: chatDropDownTarget._id,
              });
            }, 'Processing');
          }}"
        >
          <span slot="icon">backward_end_alt</span>
          <span slot="text">Recall</span>
        </ActionsButtonIos>
      {/if}
      {#if showDropDownResend(chatDropDownTarget)}
        <ActionsButtonIos
          on:click="{() => {
            showLoadingDialog(async () => {
              await remoteResendTimeoutMsg([chatDropDownTarget._id]);
            }, 'Processing');
          }}"
        >
          <span slot="icon">arrow_clockwise</span>
          <span slot="text">Resend</span>
        </ActionsButtonIos>
      {/if}
      {#if showDropDownMsgInfo(chatDropDownTarget)}
        <ActionsButtonIos
          on:click="{() => {
            openSecondaryView('/msg-info', { chat: chatDropDownTarget }, !isMobileBrowser);
          }}"
        >
          <span slot="icon">info</span>
          <span slot="text">Info</span>
        </ActionsButtonIos>
      {/if}
      {#if showDropDownCopy(chatDropDownTarget)}
        <ActionsButtonIos
          on:click="{() => {
            // Copy text to clipboard
            const targetText = chatDropDownTarget.msgType
              ? chatDropDownTarget.msg
              : chatDropDownTarget.caption.msg;
            if (isNativeApp) {
              window.cordova.plugins.clipboard.copy(targetText);
            } else {
              // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
              navigator.clipboard.writeText(targetText).then(
                () => {},
                (err) => {
                  showDialog('Copy Failed');
                }
              );
            }
          }}"
        >
          <span slot="icon">doc_on_doc</span>
          <span slot="text">Copy</span>
        </ActionsButtonIos>
      {/if}
      {#if showDropDownDownload(chatDropDownTarget)}
        <ActionsButtonIos
          on:click="{() => {
            saveFile({
              url:
                chatDropDownTarget.msgType !== 'chat'
                  ? chatDropDownTarget.msg
                  : chatDropDownTarget.caption.msg,
              fileName: chatMsgTypeToHumanReadable(
                chatDropDownTarget.msgType !== 'chat'
                  ? chatDropDownTarget.msgType
                  : chatDropDownTarget.caption.type
              ),
              autoBom: true,
            });
          }}"
        >
          <span slot="icon">cloud_download</span>
          <span slot="text">Download</span>
        </ActionsButtonIos>
      {/if}
    </ActionsGroup>
    <ActionsGroup>
      <ActionsButtonIos center bold class="text-blue-primary">
        <span slot="text">Back</span>
      </ActionsButtonIos>
    </ActionsGroup>
  </Actions>

  <Popover
    bind:instance="{getChatDropDownDesktop}"
    on:popoverOpen="{() => {
      chatDropDownVisible = true;
    }}"
    on:popoverClose="{() => {
      chatDropDownVisible = false;
    }}"
  >
    <List>
      {#if showDropDownReply(chatDropDownTarget)}
        <ListItemLink
          popoverClose
          title="Reply"
          iconF7="arrowshape_turn_up_left"
          media
          on:click="{() => {
            storeUpdateIfDifferent(quotedMsgId, chatDropDownTarget.id);
          }}"
        />
      {/if}
      {#if showDropDownForward(chatDropDownTarget)}
        <ListItemLink
          popoverClose
          title="Forward"
          iconF7="arrowshape_turn_up_right"
          media
          on:click="{() => {
            showDialog('Coming Soon');
          }}"
        />
      {/if}
      {#if showDropDownRecallSent(chatDropDownTarget)}
        <ListItemLink
          popoverClose
          title="Recall"
          iconF7="backward_end_alt"
          media
          on:click="{() => {
            showLoadingDialog(async () => {
              await remoteRecallOutboundMsg({
                msgId: chatDropDownTarget._id,
              });
            }, 'Processing');
          }}"
        />
      {/if}
      {#if showDropDownResend(chatDropDownTarget)}
        <ListItemLink
          popoverClose
          title="Resend"
          iconF7="arrow_clockwise"
          media
          on:click="{() => {
            showLoadingDialog(async () => {
              await remoteResendTimeoutMsg([chatDropDownTarget._id]);
            }, 'Processing');
          }}"
        />
      {/if}
      {#if showDropDownMsgInfo(chatDropDownTarget)}
        <ListItemLink
          popoverClose
          title="Info"
          iconF7="info"
          media
          on:click="{() => {
            openSecondaryView('/msg-info', { chat: chatDropDownTarget }, !isMobileBrowser);
          }}"
        />
      {/if}
      {#if showDropDownCopy(chatDropDownTarget)}
        <ListItemLink
          popoverClose
          title="Copy"
          iconF7="doc_on_doc"
          media
          on:click="{() => {
            // Copy text to clipboard
            const targetText = chatDropDownTarget.msgType
              ? chatDropDownTarget.msg
              : chatDropDownTarget.caption.msg;
            if (isNativeApp) {
              window.cordova.plugins.clipboard.copy(targetText);
            } else {
              // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
              navigator.clipboard.writeText(targetText).then(
                () => {},
                (err) => {
                  showDialog('Copy Failed');
                }
              );
            }
          }}"
        />
      {/if}
      {#if showDropDownDownload(chatDropDownTarget)}
        <ListItemLink
          popoverClose
          title="Download"
          iconF7="cloud_download"
          media
          on:click="{() => {
            saveFile({
              url:
                chatDropDownTarget.msgType !== 'chat'
                  ? chatDropDownTarget.msg
                  : chatDropDownTarget.caption.msg,
              fileName: chatMsgTypeToHumanReadable(
                chatDropDownTarget.msgType !== 'chat'
                  ? chatDropDownTarget.msgType
                  : chatDropDownTarget.caption.type
              ),
              autoBom: true,
            });
          }}"
        />
      {/if}
    </List>
  </Popover>

  <Actions
    bind:instance="{getCaseCloseDropDownMobile}"
    class="ios {$darkMode ? 'theme-dark' : ''}"
    on:actionsOpen="{() => {
      caseCloseDropDownVisible = true;
    }}"
    on:actionsClose="{() => {
      caseCloseDropDownVisible = false;
    }}"
  >
    <ActionsGroup>
      <ActionsButtonIos
        on:click="{() => {
          if (caseCloseDropDownExistingRemarkCount >= 3) {
            showDialog('Can only append at max 3 remark');
          } else {
            openSecondaryView(
              '/miniapp-close',
              { caseCloseId: caseCloseDropDownTarget, append: true },
              !isMobileBrowser
            );
          }
        }}"
      >
        <span slot="icon">bandage</span>
        <span slot="text">Add Remark</span>
      </ActionsButtonIos>
    </ActionsGroup>
    <ActionsGroup>
      <ActionsButtonIos center bold class="text-blue-primary">
        <span slot="text">Back</span>
      </ActionsButtonIos>
    </ActionsGroup>
  </Actions>

  <Popover
    bind:instance="{getCaseCloseDropDownDesktop}"
    on:popoverOpen="{() => {
      caseCloseDropDownVisible = true;
    }}"
    on:popoverClose="{() => {
      caseCloseDropDownVisible = false;
    }}"
  >
    <List>
      <ListItemLink
        popoverClose
        title="Add Remark"
        iconF7="bandage"
        media
        on:click="{() => {
          if (caseCloseDropDownExistingRemarkCount >= 3) {
            showDialog('Can only append at max 3 remark');
          } else {
            openSecondaryView(
              '/miniapp-close',
              { caseCloseId: caseCloseDropDownTarget, append: true },
              !isMobileBrowser
            );
          }
        }}"
      />
    </List>
  </Popover>

  <!-- Use -10 instead of 0 as sometime even tho scrolled to bottom, it will not end up at perfect 0, but may sometime end up as -0.65 etc -->
  {#if !isEmpty($selectedChatList) && $selectedContact.lastChatId && $selectedContact.lastChat && containerScrollPosition <= chatScrollMarginTreatAsZero}
    <div
      class="fab fab-right-bottom jump-bottom-fab"
      transition:fade="{{ duration: 150 }}"
      on:click="{() => {
        // Jump to bottom without redrawing if lastChat is already drawn, meaning bottom is already fully in view.
        if ($selectedChatList.find((c) => c._id === $selectedContact.lastChatId)) {
          scrollContainer.scrollTop = 0;
        }
        // Clear drawn chat list and jump to latest chat, then infinite loader will do its job and fetch more chat.
        else {
          // Ignore jumpToChat and use lastChat instead.
          contactOnSelectSetupChatList($selectedContact, true);
        }
      }}"
    >
      <div class="flex items-center justify-center rounded-full cursor-pointer">
        <i class="icon f7-icons text-lg leading-5 text-icon-f7">chevron_down</i>
      </div>
    </div>
  {/if}
</Page>
