import { showDialog, mainViewNavigate } from './store';
import { maxUploadFileSize } from './config';

export async function handleUploadDetermineType({ file, sendInProgress, lockIfSendInProgress }) {
  const { type } = file;

  if (!type) {
    showDialog('Unsupported type');
  } else if (type.includes('image')) {
    handleUpload({ file, type: 'image', sendInProgress, lockIfSendInProgress });
  } else if (type.includes('audio')) {
    handleUpload({ file, type: 'audio', sendInProgress, lockIfSendInProgress });
  } else if (type.includes('video')) {
    handleUpload({ file, type: 'video', sendInProgress, lockIfSendInProgress });
  } else {
    handleUpload({ file, type: 'file', sendInProgress, lockIfSendInProgress });
  }
}

export async function handleUploadFromInput({ input, type, sendInProgress, lockIfSendInProgress }) {
  handleUpload({ file: input.files[0], type, sendInProgress, lockIfSendInProgress });
  // Reset selected file so same file can be selected again.
  input.value = null;
}

export async function handleUpload({
  file,
  type,
  blob,
  sendInProgress,
  lockIfSendInProgress,
  openPreview = true,
}) {
  const selectedFile = file;
  const selectedFileType = type;

  // For client side early error only, server will double check again.
  if (selectedFile.size > maxUploadFileSize) {
    showDialog('File cannot be larger than 10MB');
    return;
  }

  // https://stackoverflow.com/questions/9802452/play-video-from-data-in-file-object
  // Some file like aac will fail when using createObjectURL, thus support blob.
  const URL = window.URL || window.webkitURL;
  const selectedFileUrl = URL.createObjectURL(blob || selectedFile);

  // Open preview page.
  if (openPreview) {
    mainViewNavigate('/chat-upload-preview', {
      selectedFile,
      selectedFileType,
      selectedFileUrl,
      sendInProgress,
      lockIfSendInProgress,
    });
  }

  return {
    selectedFile,
    selectedFileType,
    selectedFileUrl,
    sendInProgress,
    lockIfSendInProgress,
  };
}
