<script>
  import { onMount } from 'svelte';
  import readmore from 'readmore-js';

  let clazz;
  export { clazz as class };
  let msg;
  onMount(async () => {
    new readmore(msg, {
      speed: 100,
      collapsedHeight: 200,
      moreLink: '<div class="cursor-pointer">Read more</div>',
      lessLink: '<div class="cursor-pointer">Less</div>',
    });
  });
</script>

<style>
  /* https://github.com/jedfoster/Readmore.js/issues/154 */
  :global([data-readmore-toggle]) {
    @apply text-blue-primary;
  }
</style>

<span bind:this="{msg}" class="{clazz}"><slot /></span>
