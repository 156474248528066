<script>
  import { Page, Navbar, NavRight, Link } from 'framework7-svelte';
  import { cloneDeep } from 'lodash-es';
  import ProfileImage from '../components/ProfileImage.svelte';
  import { userInfo, fileUpload, remoteUserDetailUpdate, showLoadingDialog } from '../js/store';

  $: cloned = cloneDeep($userInfo);
  $: originUserName = cloned.name || '';
  $: originProfilePic = cloned.profilePic || '';
  $: userName = originUserName;
  $: userProfilePic = originProfilePic;
  let userProfilePicFile;
  $: edited = originUserName !== userName || originProfilePic !== userProfilePic;
</script>

<Page>
  <Navbar title="Profile" backLink>
    <NavRight>
      {#if edited}
        <Link
          iconF7="checkmark_alt"
          on:click="{() => {
            showLoadingDialog(async () => {
              const payload = {};
              if (originUserName !== userName) {
                payload.name = userName;
              }
              if (originProfilePic !== userProfilePic) {
                payload.profilePic = await fileUpload({
                  file: userProfilePicFile,
                  emitProgress: true,
                  showDefaultOverlayProgress: true,
                  convertToProfilePic: true,
                });
              }
              await remoteUserDetailUpdate(payload);
            }, 'Saving');
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <ProfileImage
      src="{userProfilePic}"
      rounded
      edit
      on:change="{(event) => {
        userProfilePicFile = event.detail.file;
        userProfilePic = event.detail.base64;
      }}"
    />

    <div class="list no-hairlines">
      <ul>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media"><i class="icon demo-list-icon"></i></div>
          <div class="item-inner">
            <div class="item-title item-label">Name</div>
            <div class="item-input-wrap">
              <input
                type="text"
                placeholder="Display name"
                required
                validate
                bind:value="{userName}"
              />
              <div class="item-input-info">Only visible to you</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</Page>
