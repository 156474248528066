<script>
  /** @type {('blue'|'green'|'white')} */
  export let color = 'white';
  export let text = '';
  export let disabled = false;
  export let dark = false;
  export let selected = false;
</script>

<style lang="scss">
  .blue {
    @apply text-white;
    border-color: theme('colors.blue.primary');
    background-color: theme('colors.blue.primary');
  }
  .green {
    @apply text-white;
    border-color: theme('colors.green.primary');
    background-color: theme('colors.green.primary');
  }
  .white {
    @apply text-black;
    border-color: theme('colors.blue.secondary');
    background-color: white;
  }
  .white.dark {
    @apply text-white;
    background-color: transparent;
  }

  .status-btn {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.active {
      -webkit-appearance: none;
      box-shadow: 0 0 0 2.5px #2f8bfb;
    }
  }
</style>

<div
  class="ripple status-btn inline-flex justify-center items-center text-sm {disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
w-13 h-7 rounded border {color} select-none {selected ? 'active font-bold' : ''}"
  class:dark
>
  {text}
</div>
