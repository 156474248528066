<script>
  // Mimic f7 smart select, but provide multi line content support.
  // Since our select cross pages, modification must be returned via a store, cannot use variable binding.
  import { Navbar, NavRight, Link, Searchbar, List, ListItem, Preloader } from 'framework7-svelte';
  import { writable } from 'svelte/store';
  import { onMount, tick, createEventDispatcher } from 'svelte';
  import { isEmpty } from 'lodash-es';
  import sanitizeHtml from 'sanitize-html';
  import SquareImage from './SquareImage.svelte';
  import { mainViewNavigateBack, showDialog, storeUpdateIfDifferent } from '../js/store';
  import { setupBeforeLeave } from '../js/back-navigation';

  /**
   * @type {string} Title to display in navbar
   */
  export let title;
  /**
   * @type {Array} List of options
   */
  /**
   * @type {import('svelte/store').Writable} Selected options, on change this value will be modified, pass in a svelte store to ensure on value change binding work.
   */
  export let value = writable([]);
  /**
   * @type {Array<string>} Initial startup value. If not provided, will use value store's initial value instead.
   */
  export let initialValue;
  /**
   * @type {boolean} Make image edge rounded.
   */
  export let rounded;
  /**
   * @type {boolean} Show inline navbar search btn.
   */
  export let showSearchBtn;
  /**
   * @type {boolean} Show inline navbar save btn that emit 'save' event with all the selected item.
   */
  export let showSaveBtn;
  /**
   * @type {boolean} Show loading icon in navbar
   */
  export let showLoadingIcon;
  /**
   * @type {boolean} Is media list, true to display image.
   */
  export let media;
  /**
   * @type {boolean} Select multiple, if multiple, provide checkboxes to select multiple, else provide radio btn and limit to select 1 only.
   */
  export let multiple;
  /**
   * @type {boolean} Center title when media is displayed, else it will to aligned to top, leaving subtitle empty space alone.
   */
  export let centerText;
  /**
   * @type {boolean} Auto return to last page on select.
   */
  export let returnOnSelect;
  /**
   * @type {boolean} Show counter in title for selected options excluding select all toggle.
   */
  export let countSelected;
  /**
   * @typedef {object} indeterminate
   * @prop {boolean} indeterminate.enable Enable toggle btn to toggle select/deselect all.
   * @prop {string} indeterminate.text Text to show for the indeterminate toggle.
   */
  /**
   * @type {indeterminate}
   */
  export let indeterminate;
  /**
   * @typedef {object} restore
   * @prop {boolean} restore.enable Enable restore default btn on navbar.
   * @prop {boolean} restore.hideIfDefault Hide restore btn if current value is same as default.
   * @prop {function} restore.restoreDefault Callback when restore default btn is clicked. If unspecified, defaults to select all.
   * @prop {function} restore.isDefault Callback to validate whether provided value is default value. If unspecified, defaults to all selected is default.
   */
  /**
   * @type {restore}
   */
  export let restore;
  /**
   * @typedef {object} validate
   * @prop {boolean} validate.enable Enable validation for each value changes and prevent navigating back if validation failed.
   * @prop {function} validate.validateValue Callback when validation is needed, return falsy value to treat as pass, any non empty string to treat as failed and show it as error msg. If unspecified, treat non empty as failed.
   * @prop {boolean} validate.ignoreError Pass value back to parent anyway on value change, even if the value failed validation. Default false.
   */
  export let validate;
  /**
   * @type {function} Allow parent to process item list status (toggling / enabling / disabling of items), expect parent to return updated item list.
   */
  export let editOnChange;
  /**
   * @type {boolean} Trigger on change edit trigger once onMount.
   */
  export let triggerEditOnChangeOnMount;
  /**
   * @type {string} Display this text if no valid option is found.
   */
  export let emptyText;
  /**
   * @type {Array<object>} List of items to select from.
   */
  export let itemList;

  const sanitizeOption = {
    allowedTags: ['span', 'div', 'br', 'img', 'i'],
    allowedAttributes: {
      '*': ['class', 'style'],
      img: ['src', 'alt'],
    },
    disallowedTagsMode: 'recursiveEscape',
  };

  const dispatch = createEventDispatcher();

  // Local copy of parent value.
  let localValue = initialValue || [];

  // Extract and set default values if not provided.
  // Indeterminate btn related.
  const showIndeterminateToggle = !isEmpty(indeterminate) && indeterminate.enable;
  const indeterminateText =
    !isEmpty(indeterminate) && indeterminate.text ? indeterminate.text : 'All';

  // Default btn related.
  $: showDefaultBtn =
    !isEmpty(restore) && restore.enable && (restore.hideIfDefault ? !isDefaultSetting : true);
  const restoreDefault =
    !isEmpty(restore) && typeof restore.restoreDefault === 'function'
      ? restore.restoreDefault
      : async (itemListInner, localValueInner, parentValue) => {
          return itemListInner.map((item) => item.value);
        };
  const isDefault =
    !isEmpty(restore) && typeof restore.isDefault === 'function'
      ? restore.isDefault
      : async (itemListInner, localValueInner, parentValue) => {
          return localValueInner.length === itemListInner.length;
        };
  let isDefaultSetting = false;

  // Value validation related.
  const validateEnabled = !isEmpty(validate) && validate.enable;
  const validateIgnoreError = !isEmpty(validate) && validate.ignoreError;
  const validateValue =
    !isEmpty(validate) && typeof validate.validateValue === 'function'
      ? validate.validateValue
      : async (itemListInner, localValueInner, parentValue) => {
          if (isEmpty(localValueInner)) {
            return 'Please select at least 1 option';
          }
          return '';
        };

  let selectEl;
  async function extractThenUpdateSelectedValue() {
    await tick();
    window.selectEl = selectEl;
    const updatedValue = Array.from(selectEl.children).reduce((arr, current) => {
      const checkBox = current.querySelector('label input');
      if (checkBox.checked) {
        arr.push(checkBox.value);
      }
      return arr;
    }, []);

    // Update local copy of the parent value in order to show immediate feedback to user, without any validation.
    localValue = updatedValue;

    // Check is value still default on every value update.
    isDefaultSetting = await isDefault(itemList, localValue, $value);

    // Update toggle all checkbox on value update.
    updateToggleAllCheckboxStatus();

    // Enable or disable back btn.
    storeUpdateIfDifferent(showConfirmPrompt, isEmpty(localValue));

    // Skip validation if validation is not enabled or user request to skip validation, simply forward updated value without any validation.
    if (!validateEnabled || validateIgnoreError) {
      $value = updatedValue;
    } else {
      // Only update parent remote value if validation pass.
      const errMsg = await validateValue(itemList, localValue, $value);
      // Validation failed, do not forward updated value.
      if (errMsg) {
        showDialog(errMsg);
        return;
      }
      // Validation passed, forward updated value.
      $value = updatedValue;
    }

    if (returnOnSelect) {
      mainViewNavigateBack();
    }

    if (editOnChange) {
      itemList = editOnChange(localValue, itemList);
    }
  }

  let checkboxToggleEl;
  // Update toggle all checkbox status on selected value update.
  let checkboxToggleStatus = false;
  function updateToggleAllCheckboxStatus() {
    if (checkboxToggleEl && showIndeterminateToggle) {
      // Everything is selected, set master checkbox to checked.
      if (localValue.length === itemList.length) {
        checkboxToggleEl.indeterminate = false;
        checkboxToggleStatus = true;
      }
      // Nothing is selected, set master checkbox to unchecked.
      else if (isEmpty(localValue)) {
        checkboxToggleEl.indeterminate = false;
        checkboxToggleStatus = false;
      }
      // Something is selected, set master checkbox to indeterminate.
      else {
        checkboxToggleEl.indeterminate = true;
        checkboxToggleStatus = true;
      }
    }
  }

  // User clicked toggle all btn.
  function toggleAllCheckboxClickHandler() {
    if (checkboxToggleStatus) {
      if (checkboxToggleEl && !checkboxToggleEl.indeterminate) {
        // Check all.
        localValue = itemList.map((item) => item.value);
        extractThenUpdateSelectedValue();
      }
    } else {
      if (checkboxToggleEl && !checkboxToggleEl.indeterminate) {
        // Uncheck all.
        localValue = [];
        extractThenUpdateSelectedValue();
      }
    }
  }

  // Setup validation.
  // Validation enabled, call the provided validation function whenever value changes.
  const showConfirmPrompt = writable(false);
  if (validateEnabled) {
    // Forward value without caring whether validation succeeded.
    if (validateIgnoreError) {
      setupBeforeLeave();
    }
    // Do not forward value update back to parent if validation failed.
    else {
      setupBeforeLeave(showConfirmPrompt, async () => {
        const errMsg = await validateValue(itemList, localValue, $value);
        // Validation failed, do not forward updated value.
        if (errMsg) {
          showDialog(errMsg);
        }
      });
    }
  }
  // Validation not enabled, allow leave page without checking.
  else {
    setupBeforeLeave();
  }

  onMount(() => {
    updateToggleAllCheckboxStatus();
    isDefault(itemList, localValue, $value).then((result) => {
      isDefaultSetting = result;
    });

    // Use value store initial value as default value.
    if (!initialValue) {
      localValue = $value || [];
    }

    if (triggerEditOnChangeOnMount) {
      itemList = editOnChange(localValue, itemList);
    }
  });
</script>

<Navbar
  title="{title} {countSelected && localValue.length ? `(${localValue.length})` : ''}"
  backLink
>
  <NavRight>
    {#if showLoadingIcon}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a class="link icon-only" href="#">
        <Preloader />
      </a>
    {/if}
    {#if showDefaultBtn}
      <Link
        iconF7="arrow_2_squarepath"
        on:click="{async () => {
          const defaultValue = await restoreDefault(itemList, localValue, $value);
          localValue = defaultValue;
          extractThenUpdateSelectedValue();
        }}"
      />
    {/if}
    {#if showSearchBtn}
      <Link searchbarEnable=".searchbar-select-search" iconF7="search" />
    {/if}
    {#if showSaveBtn && !isEmpty($value)}
      <Link
        iconF7="checkmark_alt"
        on:click="{async () => {
          dispatch('save', $value);
        }}"
      />
    {/if}
  </NavRight>
  <Searchbar
    class="searchbar-select-search"
    expandable
    searchContainer=".searchlist-select-search"
    searchIn=".item-title"
    disableButton
  />
</Navbar>
<div class="h-full overflow-auto">
  <slot name="beforeList" />
  {#if isEmpty(itemList)}
    <List>
      <ListItem title="{emptyText || 'Empty'}" />
    </List>
  {:else}
    <List noHairlines>
      {#if showIndeterminateToggle && multiple}
        <li>
          <label class="item-checkbox item-content">
            <input
              bind:this="{checkboxToggleEl}"
              type="checkbox"
              name="checkbox-input"
              bind:checked="{checkboxToggleStatus}"
              on:change="{() => {
                toggleAllCheckboxClickHandler();
              }}"
            />
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
              <div class="item-title flex h-full items-center select-none">
                {@html sanitizeHtml(indeterminateText, sanitizeOption) || ''}
              </div>
            </div>
          </label>
        </li>
      {/if}
    </List>
    <div class="list media-list searchlist-select-search searchbar-found">
      <ul bind:this="{selectEl}">
        {#each itemList as item}
          <li>
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label
              class="{multiple ? 'item-checkbox' : 'item-radio'} item-content {item.disabled
                ? 'disabled'
                : ''}"
              on:click="{() => {
                if (item.disabled && item.disabledOnClick) {
                  item.disabledOnClick(item);
                }
              }}"
            >
              {#if multiple}
                <input
                  type="checkbox"
                  name="checkbox-input"
                  value="{item.value}"
                  disabled="{item.disabled}"
                  checked="{localValue.includes(item.value) ? 'checked' : ''}"
                  on:change="{extractThenUpdateSelectedValue}"
                />
                <i class="icon icon-checkbox"></i>
              {:else}
                <input
                  type="radio"
                  name="radio-input"
                  value="{item.value}"
                  disabled="{item.disabled}"
                  checked="{localValue.includes(item.value) ? 'checked' : ''}"
                  on:change="{extractThenUpdateSelectedValue}"
                />
                <i class="icon icon-radio"></i>
              {/if}
              {#if media}
                <div class="item-media">
                  <SquareImage src="{item.image}" rounded="{rounded}" />
                </div>
              {/if}
              <div class="item-inner">
                {#if centerText}
                  <div class="item-title flex h-full items-center select-none">
                    {@html sanitizeHtml(item.title || '', sanitizeOption) || ''}
                  </div>
                {:else}
                  <div class="item-title-row">
                    <div class="item-title select-none">
                      {@html sanitizeHtml(item.title || '', sanitizeOption) || ''}
                    </div>
                    <div class="item-after">
                      <span
                        class="link tooltip-init pl-2 pointer-events-auto {(item.disabled &&
                          item.disabledTooltip) ||
                        item.tooltip
                          ? ''
                          : 'hidden'}"
                        data-tooltip="{item.disabled && item.disabledTooltip
                          ? item.disabledTooltip
                          : item.tooltip}"
                      >
                        <i class="icon f7-icons text-base">info_circle_fill</i>
                      </span>
                    </div>
                  </div>
                  {#if item.subtitle}
                    <div class="item-subtitle select-none">
                      {@html sanitizeHtml(item.subtitle || '', sanitizeOption) || ''}
                    </div>
                  {/if}
                  {#if item.text}
                    <div class="item-text select-none">
                      {@html sanitizeHtml(item.text, sanitizeOption) || ''}
                    </div>
                  {/if}
                {/if}
              </div>
            </label>
          </li>
        {/each}
      </ul>
    </div>
  {/if}
  <List class="searchbar-not-found">
    <ListItem title="Nothing found" />
  </List>
</div>
