<script>
  import { Page, Navbar, NavRight, Link, List } from 'framework7-svelte';
  import { selectedDesk, fileUpload, remoteAddMember, showLoadingDialog } from '../js/store';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ListItemToggle from '../components/ListItemToggle.svelte';
  import ProfileImage from '../components/ProfileImage.svelte';

  let memberName;
  let memberEmail;
  let memberProfilePicFile;
  let memberProfilePic;
  let isAdmin = false;
  $: edited = memberName;
</script>

<Page>
  <Navbar title="Add co-worker" backLink>
    <NavRight>
      <Link
        class="{edited ? '' : 'disabled'}"
        iconF7="checkmark_alt"
        on:click="{() => {
          showLoadingDialog(async () => {
            // Upload image first if available.
            if (memberProfilePic) {
              memberProfilePic = await fileUpload({
                file: memberProfilePicFile,
                emitProgress: true,
                showDefaultOverlayProgress: true,
                deskId: $selectedDesk._id,
                convertToProfilePic: true,
              });
            }
            await remoteAddMember(
              $selectedDesk._id,
              memberName,
              memberEmail,
              memberProfilePic,
              isAdmin ? 'admin' : 'user'
            );
          }, 'Processing');
        }}"
      />
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <ProfileImage
      src="{memberProfilePic}"
      edit
      rounded
      on:change="{(event) => {
        memberProfilePicFile = event.detail.file;
        memberProfilePic = event.detail.base64;
      }}"
    />

    <List noHairlines>
      <li class="item-content item-input item-input-with-info">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Display Name</div>
          <div class="item-input-wrap">
            <!-- svelte-ignore a11y-autofocus -->
            <input
              type="text"
              placeholder="Co-worker's nickname"
              required
              validate
              autofocus
              bind:value="{memberName}"
            />
            <div class="item-input-info">Co-worker's display name in desk</div>
          </div>
        </div>
      </li>
      <li class="item-content item-input item-input-with-info">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Email (Optional)</div>
          <div class="item-input-wrap">
            <input type="email" placeholder="Email" validate bind:value="{memberEmail}" />
            <div class="item-input-info">Invitation email will be sent to this address</div>
          </div>
        </div>
      </li>
      <ListItemToggle title="Admin" iconF7="star" bind:checked="{isAdmin}" media />
      {#if memberProfilePic}
        <ListItemLink
          title="Restore default profile picture"
          iconF7="return"
          media
          on:click="{() => {
            memberProfilePicFile = undefined;
            memberProfilePic = '';
          }}"
        />
      {/if}
    </List>
  </div>
</Page>
