<script>
  import { debounce } from 'lodash-es';
  import { onMount, createEventDispatcher } from 'svelte';
  import { contactSearchType, storeUpdateIfDifferent } from '../js/store';
  import BigIcon from './BigIcon.svelte';

  // Custom advanced, normal search combination searchbar.
  export let focusOnMount = false;
  export let debounceMs = 0;
  export let advancedDebounceMs = debounceMs;
  export let placeholder = 'Search';
  export let advancedPlaceholder = 'Advanced Search';
  export let initialValue = '';
  export let advancedInitialValue;
  export let showSearchToggleBtn = false;
  export let searchBarHeight = 0;

  const searchDebounce = debounce(() => {
    dispatch('search', {
      query: searchInput && searchInput.value ? searchInput.value : '',
      isAdvanced: false,
    });
  }, debounceMs);
  const advancedSearchDebounce = debounce(() => {
    dispatch('search', {
      query: advancedSearchInput && advancedSearchInput.value ? advancedSearchInput.value : '',
      isAdvanced: true,
    });
  }, advancedDebounceMs);

  const dispatch = createEventDispatcher();
  let searchInput;
  let advancedSearchInput;
  let isAdvancedSearch = $contactSearchType === 'advanced';

  $: searchBarShowClearBtn = initialValue;
  $: advancedSearchBarShowClearBtn = advancedInitialValue;

  // Autofocus search field on mount.
  onMount(() => {
    if (focusOnMount) {
      if (isAdvancedSearch) {
        advancedSearchInput.focus();
      } else {
        searchInput.focus();
      }
    }
  });

  // Only show search toggle btn if search term is specified.
  let showToggleBtn = false;
  function refreshShowToggleBtn() {
    if (showSearchToggleBtn && (initialValue || advancedInitialValue)) {
      showToggleBtn = true;
    } else {
      showToggleBtn = false;
    }
  }
</script>

<style lang="scss">
  .search-input::placeholder {
    color: var(--f7-searchbar-placeholder-color);
  }
  // https://stackoverflow.com/questions/9421551/how-do-i-remove-all-default-webkit-search-field-styling
  .search-input::-webkit-search-decoration,
  .search-input::-webkit-search-cancel-button,
  .search-input::-webkit-search-results-button,
  .search-input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .advanced-search-container {
    margin-right: calc(var(--f7-toolbar-inner-padding-right) + var(--f7-safe-area-right));
    margin-left: calc(var(--f7-toolbar-inner-padding-left) + var(--f7-safe-area-left));

    .inner {
      background-color: var(--f7-navbar-bg-color, var(--f7-bars-bg-color));
      min-height: 2.1rem;
    }

    // Container bottom hairline f7.
    &:after {
      content: '';
      position: absolute;
      background-color: var(--f7-list-border-color);
      display: block;
      height: 1px;
      width: 100%;
      transform-origin: 50% 0%;
      transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }
  }
</style>

<div class="advanced-search-container" bind:clientHeight="{searchBarHeight}">
  <div class="flex items-center rounded-full my-2 px-5 inner">
    <div>
      <BigIcon f7 icon="search" />
    </div>
    <!-- Absolute margin left to ensure it aligns with f7 list on contact page perfectly -->
    <div class="flex flex-grow" style="margin-left: 30px;">
      {#if isAdvancedSearch}
        <input
          class="flex-grow search-input"
          type="search"
          placeholder="{advancedPlaceholder}"
          bind:this="{advancedSearchInput}"
          bind:value="{advancedInitialValue}"
          on:input="{advancedSearchDebounce}"
          on:input="{refreshShowToggleBtn}"
        />
        {#if advancedSearchBarShowClearBtn}
          <div
            class="ml-2 {showToggleBtn ? 'mr-4' : ''} cursor-pointer"
            on:click="{() => {
              // Reset via binded value else 'X' clear btn store won't be updated.
              advancedInitialValue = '';
              // Call debounce manually since cleared value via store, debounce won't be called.
              advancedSearchDebounce();
              refreshShowToggleBtn();
            }}"
          >
            <BigIcon f7 icon="xmark" />
          </div>
        {/if}
      {:else}
        <input
          class="flex-grow search-input"
          type="search"
          placeholder="{placeholder}"
          bind:this="{searchInput}"
          bind:value="{initialValue}"
          on:input="{searchDebounce}"
          on:input="{refreshShowToggleBtn}"
        />
        {#if searchBarShowClearBtn}
          <div
            class="ml-2 {showToggleBtn ? 'mr-4' : ''} cursor-pointer"
            on:click="{() => {
              // Reset via binded value else 'X' clear btn store won't be updated.
              initialValue = '';
              // Call debounce manually since cleared value via store, debounce won't be called.
              searchDebounce();
              refreshShowToggleBtn();
            }}"
          >
            <BigIcon f7 icon="xmark" />
          </div>
        {/if}
      {/if}
    </div>
    {#if showToggleBtn}
      <div
        class="relative cursor-pointer ripple py-1 pl-1 z-10"
        on:click="{() => {
          isAdvancedSearch = !isAdvancedSearch;

          // Save current search preference (basic/advanced)
          // Retain search term after toggling between basic and advanced search.
          if (isAdvancedSearch) {
            advancedInitialValue = initialValue;
            initialValue = '';
            storeUpdateIfDifferent(contactSearchType, 'advanced');
          } else {
            initialValue = advancedInitialValue;
            advancedInitialValue = '';
            storeUpdateIfDifferent(contactSearchType, 'basic');
          }

          searchDebounce();
          advancedSearchDebounce();
          refreshShowToggleBtn();
        }}"
      >
        {#if isAdvancedSearch}
          <BigIcon f7 icon="zoom_out" />
        {:else}
          <BigIcon f7 icon="zoom_in" />
        {/if}
      </div>
    {/if}
  </div>
</div>
