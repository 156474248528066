<script>
  import { isEmpty } from 'lodash-es';
  import sanitizeHtml from 'sanitize-html';
  import SquareImage from './SquareImage.svelte';
  import { selectedDeskMemberList, chatSearchTerm } from '../js/store';
  import {
    userIdToUserDeskScopedProfilePic,
    userIdToUserDeskScopedName,
    convertMongoIdToHourTimestamp,
    dateToDayMonthYearHourMin,
    mongoIdToTimestamp,
    openExternalUrl,
    highlightText,
    hyperlinkUrlGivenText,
  } from '../js/util';

  export let additionalInfoList;
  export let hideDate = false;
</script>

<div class="flex flex-col text-select ucc-fsz-small">
  {#if !isEmpty(additionalInfoList)}
    {#each additionalInfoList as info, i}
      <div class="{i !== 0 ? 'border-t' : ''}">
        {#if info.text}
          <div class="px-4 py-1 select-text mobile-select-none">
            <!-- Highlight miniapp content text. -->
            {@html sanitizeHtml(highlightText(hyperlinkUrlGivenText(info.text), $chatSearchTerm), {
              allowedTags: ['a', 'mark'],
              allowedAttributes: {
                mark: ['style'],
                a: ['href', 'style', 'onclick'],
              },
              disallowedTagsMode: 'recursiveEscape',
            })}
          </div>
        {/if}

        {#if !isEmpty(info.media)}
          <div class="flex justify-center">
            {#each info.media as media}
              <div
                class="flex flex-col relative w-12 h-12 items-center justify-center float-left my-2 mr-2 rounded"
              >
                {#if media.type.includes('image')}
                  <SquareImage
                    src="{media.url}"
                    alt="upload"
                    on:click="{() => {
                      openExternalUrl(media.url);
                    }}"
                  />
                {:else if media.type.includes('audio')}
                  <div
                    class="max-w-full"
                    on:click="{(e) => {
                      openExternalUrl(media.url);
                    }}"
                  >
                    <div>Audio</div>
                    <div class="truncate">{media.name}</div>
                  </div>
                {:else if media.type.includes('video')}
                  <div
                    class="max-w-full"
                    on:click="{(e) => {
                      openExternalUrl(media.url);
                    }}"
                  >
                    <div>Video</div>
                    <div class="truncate">{media.name}</div>
                  </div>
                {:else}
                  <div
                    class="max-w-full"
                    on:click="{(e) => {
                      openExternalUrl(media.url);
                    }}"
                  >
                    <div>Document</div>
                    <div class="truncate">{media.name}</div>
                  </div>
                {/if}
              </div>
            {/each}
          </div>
        {/if}
      </div>

      {#if !hideDate}
        <div class="flex flex-col space-x-1 select-none items-end justify-end">
          <span class="flex mt-1 space-x-1">
            <img
              src="{userIdToUserDeskScopedProfilePic(info.userId, $selectedDeskMemberList)}"
              class="w-4 h-4 rounded-full"
              alt="user-profile"
              on:contextmenu="{(e) => {
                e.preventDefault();
              }}"
            />
            <span class="text-xs"
              >{userIdToUserDeskScopedName(info.userId, $selectedDeskMemberList)}</span
            >
          </span>
          <span class="flex mt-1 space-x-1">
            <span class="text-xs"
              >{i === 0
                ? convertMongoIdToHourTimestamp(info._id)
                : dateToDayMonthYearHourMin(mongoIdToTimestamp(info._id))}</span
            >
          </span>
        </div>
      {/if}
    {/each}
  {/if}
</div>
