import { get } from 'svelte/store';
import { mainView, mainViewNavigateBack, remoteDeleteChannel, showLoadingDialog } from './store';

export function deleteChannel(channel) {
  get(mainView)
    .app.dialog.create({
      title: '',
      text: `Delete Channel - ${channel.name || 'Untitled'}`,
      closeByBackdropClick: true,
      buttons: [
        {
          text: 'Cancel',
          keyCodes: [27],
        },
        {
          text: 'Delete',
          bold: true,
          color: 'red',
          keyCodes: [13],
          onClick: () => {
            showLoadingDialog(async () => {
              await remoteDeleteChannel(channel._id);
              mainViewNavigateBack();
            }, 'Processing');
          },
        },
      ],
    })
    .open();
}
