<script>
  import { createEventDispatcher } from 'svelte';
  import { uccPlaceholderLogo, uccPlaceholderAlpha } from '../js/config';
  /** @type {string} */
  export let src = '';
  export let srcFallback = uccPlaceholderAlpha;
  /** @type {boolean} */
  export let rounded = false;
  export let width = 'w-12';
  export let height = 'h-12';
  export let topRight = { src: '', shadow: false, border: false, small: false, f7icon: '' } || {};
  export let topLeft = { src: '', shadow: false, border: false, small: false, f7icon: '' } || {};
  export let bottomLeft = { src: '', shadow: false, border: false, small: false, f7icon: '' } || {};
  export let bottomRight =
    { src: '', shadow: false, border: false, small: false, f7icon: '' } || {};
  export let showOpenCaseCounter = false;
  export let openCaseCount = 0;
  export let alt = 'square';

  const dispatch = createEventDispatcher();

  $: topRightClass = `absolute ${topRight && topRight.small ? 'w-3 h-3' : 'w-5 h-5'} ${
    topRight && topRight.f7icon ? '-top-3 -right-3' : '-top-1 -right-1'
  } ${topRight && topRight.shadow ? 'drop-shadow' : ''}${
    topRight && topRight.border ? 'bg-white rounded-full' : ''
  }`;

  $: topLeftClass = `absolute ${topLeft && topLeft.small ? 'w-3 h-3' : 'w-5 h-5'} ${
    topLeft && topLeft.f7icon ? '-top-3 -left-3' : '-top-1 -left-1'
  }  ${topLeft && topLeft.shadow ? 'drop-shadow' : ''}${
    topLeft && topLeft.border ? 'bg-white rounded-full' : ''
  }`;

  $: bottomLeftClass = `absolute ${bottomLeft && bottomLeft.small ? 'w-3 h-3' : 'w-5 h-5'} ${
    bottomLeft && bottomLeft.f7icon ? '-bottom-3 -left-3' : '-bottom-1 -left-1'
  } ${bottomLeft && bottomLeft.shadow ? 'drop-shadow' : ''}${
    bottomLeft && bottomLeft.border ? 'bg-white rounded-full' : ''
  }`;

  $: bottomRightClass = `absolute ${bottomRight && bottomRight.small ? 'w-3 h-3' : 'w-5 h-5'} ${
    bottomRight && bottomRight.f7icon ? '-bottom-3 -right-3' : '-bottom-1 -right-1'
  } ${bottomRight && bottomRight.shadow ? 'drop-shadow' : ''}${
    bottomRight && bottomRight.border ? 'bg-white rounded-full' : ''
  }`;
</script>

<style lang="scss">
  .drop-shadow {
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
  }
</style>

<div
  class="{width} {height} relative select-none"
  on:click="{() => {
    dispatch('click');
  }}"
>
  <img
    alt="{alt}"
    src="{src || uccPlaceholderLogo}"
    class="{rounded ? 'rounded-full' : 'rounded-lg'} h-full w-full"
    onerror="this.onerror=null;this.src='{srcFallback}';"
    on:contextmenu="{(e) => {
      e.preventDefault();
    }}"
  />
  {#if topRight}
    {#if topRight.f7icon}
      <div class="{topRightClass}">
        <i class="f7-icons text-icon-primary text-base">{topRight.f7icon}</i>
      </div>
    {:else}
      <img
        class="{topRightClass}"
        src="{topRight.src || uccPlaceholderAlpha}"
        alt="top-right-icon"
        on:contextmenu="{(e) => {
          e.preventDefault();
        }}"
      />
    {/if}
  {/if}

  {#if topLeft}
    {#if topLeft.f7icon}
      <div class="{topLeftClass}">
        <i class="f7-icons text-icon-primary text-base">{topLeft.f7icon}</i>
      </div>
    {:else}
      <img
        class="{topLeftClass}"
        src="{topLeft.src || uccPlaceholderAlpha}"
        alt="top-left-icon"
        on:contextmenu="{(e) => {
          e.preventDefault();
        }}"
      />
    {/if}
  {/if}

  {#if bottomRight}
    {#if showOpenCaseCounter}
      <div
        class="flex absolute h-4 px-1 bottom-0 right-0 rounded justify-center items-center drop-shadow bg-green-primary"
        style="min-width: 2rem;"
      >
        {openCaseCount}
      </div>
    {:else if bottomRight.f7icon}
      <div class="{bottomRightClass}">
        <i class="f7-icons text-icon-primary text-base">{bottomRight.f7icon}</i>
      </div>
    {:else}
      <img
        class="{bottomRightClass}"
        src="{bottomRight.src || uccPlaceholderAlpha}"
        alt="bottom-right-icon"
        on:contextmenu="{(e) => {
          e.preventDefault();
        }}"
      />
    {/if}
  {/if}

  {#if bottomLeft}
    {#if bottomLeft.f7icon}
      <div class="{bottomLeftClass}">
        <i class="f7-icons text-icon-primary text-base">{bottomLeft.f7icon}</i>
      </div>
    {:else}
      <img
        class="{bottomLeftClass}"
        src="{bottomLeft.src || uccPlaceholderAlpha}"
        alt="bottom-left-icon"
        on:contextmenu="{(e) => {
          e.preventDefault();
        }}"
      />
    {/if}
  {/if}
</div>
