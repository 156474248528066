import ContactPage from '../pages/contact.svelte';
import ContactFilterPage from '../pages/contact-filter.svelte';
import ContactFilterRange from '../pages/contact-filter-range.svelte';
import ChatPage from '../pages/chat.svelte';
import ContactDetailPage from '../pages/contact-detail.svelte';
import SearchDetailPage from '../pages/search-detail.svelte';

import DeskPage from '../pages/desk.svelte';
import DeskInfoEditPage from '../pages/desk-info-edit.svelte';
import MemberSortPage from '../pages/member-sort.svelte';
import MemberAddPage from '../pages/member-add.svelte';
import MemberDetailPage from '../pages/member-detail.svelte';
import ChannelAddPage from '../pages/channel-add.svelte';
import ChannelSortPage from '../pages/channel-sort.svelte';
import ChannelDetailPage from '../pages/channel-detail.svelte';
import ChannelInfoEditPage from '../pages/channel-info-edit.svelte';
import ChannelAutoReplyPage from '../pages/channel-auto-reply.svelte';
import WhatsAppScanPage from '../pages/whatsapp-scan.svelte';
import OutboundQueuePage from '../pages/outbound-queue.svelte';
import AutoReplyPage from '../pages/auto-reply.svelte';
import GreetingPage from '../pages/greeting.svelte';
import GreetingSortPage from '../pages/greeting-sort.svelte';
import BotPage from '../pages/bot.svelte';
import BotSortPage from '../pages/bot-sort.svelte';
import BroadcastSortPage from '../pages/broadcast-sort.svelte';
import BroadcastDetailPage from '../pages/broadcast-detail.svelte';
import BroadcastRecipientPage from '../pages/broadcast-recipient.svelte';
import DeskReportPage from '../pages/desk-report.svelte';
import DeskSettingPage from '../pages/desk-setting.svelte';

import UserPage from '../pages/user.svelte';
import UserInfoEditPage from '../pages/user-info-edit.svelte';
import DeskSortPage from '../pages/desk-sort.svelte';
import LoginSortPage from '../pages/loginid-sort.svelte';
import LoginAddPage from '../pages/loginid-add.svelte';
import LoginDetailPage from '../pages/loginid-detail.svelte';
import UserSettingPage from '../pages/user-setting.svelte';
import PromoCodePage from '../pages/promocode.svelte';

import InvitationPage from '../pages/invitation.svelte';

import MiniAppClose from '../pages/miniapp-close.svelte';
import MiniAppMemo from '../pages/miniapp-memo.svelte';
import MiniAppFlash from '../pages/miniapp-flash.svelte';

import MsgInfo from '../pages/msg-info.svelte';

import LoginPage from '../pages/login.svelte';

import NotFoundPage from '../pages/404.svelte';

import SelectSearchList from '../pages/select-search-list.svelte';
import ImageEditor from '../pages/image-editor.svelte';
import ChatUploadPreview from '../pages/chat-upload-preview.svelte';
import FlashPopupPage from '../pages/flash-popup.svelte';
import BotSendResultPage from '../pages/bot-test-send.svelte';

import { beforeLeave } from './back-navigation';

const routes = [
  {
    path: '/',
    component: ContactPage,
    routes: [
      {
        path: 'filter',
        component: ContactFilterPage,
        routes: [
          { path: 'select', component: SelectSearchList, beforeLeave },
          {
            path: 'range',
            component: ContactFilterRange,
            beforeLeave,
          },
        ],
      },
      {
        path: 'detail',
        component: SearchDetailPage,
      },
    ],
  },
  {
    path: '/desk',
    component: DeskPage,
    routes: [
      { path: 'edit', component: DeskInfoEditPage },
      {
        path: 'member-sort',
        component: MemberSortPage,
        routes: [
          { path: 'add', component: MemberAddPage },
          { path: 'detail', component: MemberDetailPage },
        ],
      },
      { path: 'member-add', component: MemberAddPage },
      { path: 'member-detail', component: MemberDetailPage },
      {
        path: 'channel-sort',
        component: ChannelSortPage,
        routes: [{ path: 'add', component: ChannelAddPage }],
      },
      {
        path: 'auto-reply',
        component: AutoReplyPage,
        routes: [
          {
            path: 'greeting-sort',
            component: GreetingSortPage,
            routes: [
              {
                path: 'add',
                component: GreetingPage,
                routes: [{ path: 'select', component: SelectSearchList, beforeLeave }],
              },
              {
                path: 'detail',
                component: GreetingPage,
                routes: [{ path: 'select', component: SelectSearchList, beforeLeave }],
              },
            ],
          },
          {
            path: 'bot-sort',
            component: BotSortPage,
            routes: [
              {
                path: 'add',
                component: BotPage,
                routes: [{ path: 'select', component: SelectSearchList, beforeLeave }],
              },
              {
                path: 'detail',
                component: BotPage,
                routes: [{ path: 'select', component: SelectSearchList, beforeLeave }],
              },
            ],
          },
        ],
      },
      {
        path: 'broadcast',
        component: BroadcastSortPage,
        routes: [
          {
            path: 'add',
            component: BroadcastDetailPage,
            routes: [
              {
                path: 'recipient',
                component: BroadcastRecipientPage,
                routes: [
                  { path: 'select', component: SelectSearchList, beforeLeave },
                  {
                    path: 'range',
                    component: ContactFilterRange,
                    beforeLeave,
                  },
                ],
              },
              { path: 'select', component: SelectSearchList, beforeLeave },
            ],
          },
          {
            path: 'detail',
            component: BroadcastDetailPage,
            routes: [
              { path: 'recipient', component: BroadcastRecipientPage },
              {
                path: 'range',
                component: ContactFilterRange,
                beforeLeave,
              },
            ],
          },
        ],
      },
      {
        path: 'report',
        component: DeskReportPage,
        routes: [
          { path: 'select', component: SelectSearchList, beforeLeave },
          {
            path: 'range',
            component: ContactFilterRange,
            beforeLeave,
          },
        ],
      },
      {
        path: 'setting',
        component: DeskSettingPage,
        routes: [{ path: 'select', component: SelectSearchList }],
      },
    ],
  },
  {
    path: '/invitation',
    component: InvitationPage,
  },
  {
    path: '/channel',
    component: ChannelDetailPage,
    routes: [
      { path: 'edit', component: ChannelInfoEditPage },
      { path: 'scan', component: WhatsAppScanPage },
      {
        path: 'queue',
        component: OutboundQueuePage,
        routes: [{ path: 'select', component: SelectSearchList, beforeLeave }],
      },
      {
        path: 'auto-reply',
        component: ChannelAutoReplyPage,
        routes: [{ path: 'select', component: SelectSearchList }],
      },
    ],
  },
  {
    path: '/user',
    component: UserPage,
    routes: [
      { path: 'edit', component: UserInfoEditPage },
      { path: 'desk-sort', component: DeskSortPage },
      {
        path: 'setting',
        component: UserSettingPage,
        routes: [{ path: 'loginid', component: LoginSortPage }],
      },
      { path: 'promocode', component: PromoCodePage },
    ],
  },
  {
    path: '/chat',
    component: ChatPage,
    transition: 'f7-parallax',
    browserHistory: false,
  },
  {
    path: '/contact',
    component: ContactDetailPage,
    browserHistory: false,
  },
  {
    path: '/chat-search',
    component: SearchDetailPage,
    browserHistory: false,
  },
  {
    path: '/miniapp-close',
    component: MiniAppClose,
    browserHistory: false,
  },
  {
    path: '/miniapp-memo',
    component: MiniAppMemo,
    browserHistory: false,
  },
  {
    path: '/miniapp-flash',
    component: MiniAppFlash,
    browserHistory: false,
  },
  {
    path: '/msg-info',
    component: MsgInfo,
    browserHistory: false,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/image-editor',
    component: ImageEditor,
  },
  {
    path: '/chat-upload-preview',
    component: ChatUploadPreview,
  },
  {
    path: '/flash-popup',
    component: FlashPopupPage,
  },
  {
    path: '/bot-test-send',
    component: BotSendResultPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
