<script>
  import { Page } from 'framework7-svelte';
  import ContactTopBar from '../components/ContactTopBar.svelte';
  import SearchBar from '../components/SearchBar.svelte';
  import ContactList from '../components/ContactList.svelte';
  import ContactBottomBar from '../components/ContactBottomBar.svelte';
  import {
    unselectContact,
    multiView,
    contactSearchTerm,
    contactAdvancedSearchTerm,
    storeUpdateIfDifferent,
  } from '../js/store';
  import { searchDebounceMs } from '../js/config';

  let searchBarHeight = 0;
</script>

<Page
  on:pageBeforeIn="{() => {
    // Unselect selected contact on navigate out from chat page on mobile only.
    // On desktop retain selected contact at all time.
    if (!$multiView) {
      unselectContact();
    }
  }}"
>
  <ContactTopBar />
  <div class="h-full overflow-hidden">
    <SearchBar
      bind:searchBarHeight
      placeholder="Search or start new chat"
      advancedPlaceholder="Advanced Search"
      debounceMs="{searchDebounceMs}"
      initialValue="{$contactSearchTerm || ''}"
      advancedInitialValue="{$contactAdvancedSearchTerm || ''}"
      showSearchToggleBtn
      on:search="{({ detail }) => {
        const { query, isAdvanced } = detail;
        if (isAdvanced) {
          storeUpdateIfDifferent(contactAdvancedSearchTerm, query);
        } else {
          storeUpdateIfDifferent(contactSearchTerm, query);
        }
      }}"
    />
    <!-- Dynamically recalculate remaining available height -->
    <!-- Additional -1rem for internal padding of search bar, the rounded style -->
    <div style="height: calc(100% - {searchBarHeight}px - 1rem);">
      <ContactList />
    </div>
  </div>
  <ContactBottomBar />
</Page>
