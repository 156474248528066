<script>
  import { Page, Popup } from 'framework7-svelte';
  import BotTestSendPopup from '../components/BotTestSendPopup.svelte';
  import { multiView } from '../js/store';

  export let props = {};
  const { content } = props;

  let getPopupInstance;
</script>

<Page
  on:pageBeforeIn="{() => {
    if (getPopupInstance) {
      getPopupInstance().open();
    }
  }}"
  on:pageBeforeOut="{() => {
    if (getPopupInstance) {
      getPopupInstance().close();
    }
  }}"
>
  <!-- Only use popup on big screen, normal page on mobile -->
  {#if $multiView}
    <Popup bind:instance="{getPopupInstance}" tabletFullscreen="{true}">
      <BotTestSendPopup content="{content}" />
    </Popup>
  {:else}
    <BotTestSendPopup content="{content}" />
  {/if}
</Page>
