<script>
  import { Page, Navbar, NavRight, Link, List } from 'framework7-svelte';
  import { createEventDispatcher, onDestroy } from 'svelte';
  import BigIcon from './BigIcon.svelte';
  import {
    remoteSendOutboundMsg,
    fileUpload,
    selectedContact,
    mainViewNavigate,
  } from '../js/store';
  import { dataURLtoFile } from '../js/util';
  import { handleUpload } from '../js/chat-upload-preview';

  export let selectedFile;
  export let selectedFileType;
  export let selectedFileUrl;
  export let sendInProgress;
  export let lockIfSendInProgress = false;

  const dispatch = createEventDispatcher();

  const sendPreviewContentHeight =
    'max-height: calc(100vh - var(--f7-navbar-height) - var(--f7-safe-area-top) - 150px)';
  let previewPlayer;

  let sendAsUrl = false;
  let captionInput;

  $: platform = $selectedContact.platform;
  $: route = $selectedContact.route;
  $: channel = $selectedContact.channel;
  $: deskId = $selectedContact.deskId;

  $: captionReadOnly =
    selectedFileType === 'document' || selectedFileType === 'audio' || platform !== 'whatsapp';

  async function confirmSend() {
    async function send() {
      const caption = captionInput.value;

      const url = await fileUpload({
        file: selectedFile,
        deskId,
        emitProgress: true,
        showDefaultOverlayProgress: true,
        // Shorten reply url as receiving end's user will have to access this link from blocked country.
        shortenUrl: true,
      });
      console.log('Upload success', url);

      // Send media as plain text.
      if (sendAsUrl) {
        await remoteSendOutboundMsg({
          msg: url,
          msgType: 'chat',
          route,
          channel,
          deskId,
          platform,
          linkPreview: true,
        });
      }
      // Send as media.
      else {
        await remoteSendOutboundMsg({
          msg: url,
          msgType: selectedFileType,
          route,
          channel,
          deskId,
          platform,
          caption,
        });
      }
      dispatch('send');
    }
    // Check using send in progress store if it is enabled.
    if (lockIfSendInProgress && sendInProgress) {
      // Only send if not send in progress.
      if (!$sendInProgress) {
        $sendInProgress = true;
        await send();
        $sendInProgress = false;
      }
    } else {
      await send();
    }
  }

  onDestroy(() => {
    // Kill player to stop any running playback.
    if (selectedFileType === 'video' || selectedFileType === 'audio') {
      previewPlayer.pause();
      previewPlayer.removeAttribute('src'); // empty source
      previewPlayer.load();
    }
  });
</script>

<Page>
  <Navbar title="Preview" backLink>
    <NavRight>
      {#if selectedFile && selectedFile.type && selectedFile.type.includes('image')}
        <Link
          iconF7="wand_stars"
          on:click="{() => {
            mainViewNavigate('/image-editor', {
              selectedFile,
              onSave: (e) => {
                handleUpload({
                  file: dataURLtoFile(e.detail),
                  type: 'image',
                  sendInProgress,
                  lockIfSendInProgress,
                  openPreview: false,
                }).then((result) => {
                  selectedFile = result.selectedFile;
                  selectedFileType = result.selectedFileType;
                  selectedFileUrl = result.selectedFileUrl;
                  sendInProgress = result.sendInProgress;
                  lockIfSendInProgress = result.lockIfSendInProgress;
                });
              },
            });
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <div class="flex flex-col w-full h-full items-center">
    <div class="flex flex-grow flex-shrink items-center">
      {#if selectedFileType === 'image'}
        <img
          src="{selectedFileUrl}"
          class="object-contain"
          style="{sendPreviewContentHeight}"
          alt="preview"
        />
      {:else if selectedFileType === 'audio'}
        <!-- svelte-ignore a11y-media-has-caption -->
        <audio
          controls
          src="{selectedFileUrl}"
          class="focus:outline-none"
          style="{sendPreviewContentHeight}"
          bind:this="{previewPlayer}"></audio>
      {:else if selectedFileType === 'video'}
        <!-- svelte-ignore a11y-media-has-caption -->
        <video controls style="{sendPreviewContentHeight}" bind:this="{previewPlayer}">
          <source src="{selectedFileUrl}" />
        </video>
      {:else if selectedFileType === 'document'}
        <div class="flex" style="{sendPreviewContentHeight}">
          <i class="f7-icons text-6xl">doc</i>
        </div>
      {/if}
    </div>

    <List noHairlines>
      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-input-wrap">
            <input
              type="text"
              bind:this="{captionInput}"
              readonly="{captionReadOnly}"
              disabled="{platform === 'whatsapp' ? '' : 'disabled'}"
              placeholder="Caption"
              value="{captionReadOnly &&
              platform === 'whatsapp' &&
              selectedFile &&
              selectedFile.name
                ? selectedFile.name
                : ''}"
            />
          </div>
        </div>
        <div class="flex items-center w-8 h-8 cursor-pointer" on:click="{confirmSend}">
          <BigIcon f7 icon="paperplane" />
        </div>
      </li>
      <li>
        <label class="item-checkbox item-content">
          <input type="checkbox" bind:checked="{sendAsUrl}" />
          <i class="icon icon-checkbox"></i>
          <div class="item-inner">
            <div class="item-title">Send as url</div>
          </div>
        </label>
      </li>
    </List>
  </div>
</Page>
