<script>
  import { Badge } from 'framework7-svelte';
  import { darkMode } from '../js/store';

  export let f7 = false;
  export let md = false;
  export let icon = '';
  export let style = '';
  export let showBadge = false;
  export let badgeCount = '';
</script>

<style lang="scss">
  .icon-font {
    font-size: 1.35rem;
  }
</style>

<i
  class="{f7 ? 'f7-icons' : ''} {md ? 'material-icons' : ''} select-none icon-font text-icon-f7"
  style="{style}"
  >{icon}
  {#if showBadge}
    <Badge color="red">{badgeCount || ''}</Badge>
  {/if}
</i>
