<script>
  import { Page, Navbar, NavRight, Link } from 'framework7-svelte';
  import { cloneDeep } from 'lodash-es';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ProfileImage from '../components/ProfileImage.svelte';
  import {
    selectedDesk,
    fileUpload,
    remoteDeskInfoUpdate,
    showLoadingDialog,
    selectedDeskUserInfo,
  } from '../js/store';

  $: cloned = cloneDeep($selectedDesk);
  $: originName = cloned.name;
  $: originSlogan = cloned.slogan || '';
  $: originProfilePic = cloned.profilePic;
  $: deskName = originName;
  $: slogan = originSlogan;
  $: deskProfilePic = cloned.profilePic;
  let deskProfilePicFile;
  $: edited =
    originName !== deskName || originSlogan !== slogan || originProfilePic !== deskProfilePic;
</script>

<Page>
  <Navbar title="Profile" backLink>
    <NavRight>
      {#if edited}
        <Link
          iconF7="checkmark_alt"
          on:click="{() => {
            showLoadingDialog(async () => {
              // Only send updated field
              const payload = {};
              if (deskName !== originName) {
                payload.name = deskName;
              }
              if (slogan !== originSlogan) {
                payload.slogan = slogan;
              }
              if (deskProfilePic !== originProfilePic) {
                if (deskProfilePic && deskProfilePicFile) {
                  payload.profilePic = await fileUpload({
                    file: deskProfilePicFile,
                    deskId: cloned._id,
                    emitProgress: true,
                    showDefaultOverlayProgress: true,
                    convertToProfilePic: true,
                  });
                } else {
                  payload.profilePic = '';
                }
              }
              remoteDeskInfoUpdate(cloned._id, payload);
            }, 'Saving');
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <ProfileImage
      src="{deskProfilePic}"
      edit
      on:change="{(event) => {
        deskProfilePicFile = event.detail.file;
        deskProfilePic = event.detail.base64;
      }}"
    />

    <div class="list no-hairlines">
      <ul>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media"><i class="icon demo-list-icon"></i></div>
          <div class="item-inner">
            <div class="item-title item-label">Name</div>
            <div class="item-input-wrap">
              <input
                type="text"
                placeholder="Desk name"
                required
                validate
                bind:value="{deskName}"
              />
              <div class="item-input-info">Visible to all co-workers</div>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media"><i class="icon demo-list-icon"></i></div>
          <div class="item-inner">
            <div class="item-title item-label">Status</div>
            <div class="item-input-wrap">
              <input
                type="text"
                placeholder="Short status message"
                validate
                bind:value="{slogan}"
              />
              <div class="item-input-info">Optional</div>
            </div>
          </div>
        </li>
        {#if $selectedDeskUserInfo.role === 'admin' && (originProfilePic || deskProfilePic)}
          <ListItemLink
            title="Restore default profile picture"
            iconF7="return"
            media
            on:click="{() => {
              deskProfilePicFile = undefined;
              deskProfilePic = '';
            }}"
          />
        {/if}
      </ul>
    </div>
  </div>
</Page>
