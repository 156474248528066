<script>
  import { isEmpty } from 'lodash-es';
  import { onMount } from 'svelte';
  import { mainView } from '../js/store';

  export let picker;
  export let title;
  export let placeholder;
  export let value = {};
  export let setTitleColor = false;
  export let end = false;
  let pickerEl;

  const initialValue =
    !isEmpty(value) && typeof value === 'object' && value.day && value.hour && value.minute
      ? [value.day, value.hour, value.minute]
      : end
      ? ['Sunday', '23', '59']
      : ['Monday', '00', '00'];

  onMount(() => {
    if (isEmpty($mainView)) {
      return;
    }

    picker = $mainView.app.picker.create({
      inputEl: pickerEl,
      rotateEffect: true,
      routableModals: false,
      momentumRatio: 1,
      closeByOutsideClick: false,
      value: initialValue,
      formatValue: (values, displayValues) => {
        return displayValues[0] + ' ' + values[1] + ':' + values[2];
      },
      cols: [
        {
          values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        },
        {
          values: (() => {
            const arr = [];
            for (let i = 0; i <= 23; i++) {
              arr.push(i < 10 ? '0' + i : i);
            }
            return arr;
          })(),
        },
        {
          values: (() => {
            const arr = [];
            for (let i = 0; i <= 59; i++) {
              arr.push(i < 10 ? '0' + i : i);
            }
            return arr;
          })(),
        },
      ],
      on: {
        change: function (p, values, displayValues) {
          value = {
            day: values[0],
            hour: values[1],
            minute: values[2],
          };
        },
      },
    });
  });
</script>

<li class="item-content item-input {setTitleColor ? 'input-title-color' : ''}">
  <div class="item-inner">
    <div class="item-title item-label">{title}</div>
    <div class="item-input-wrap">
      <input
        type="text"
        placeholder="{placeholder}"
        readonly="readonly"
        bind:this="{pickerEl}"
        on:focus="{() => {
          picker.open();
        }}"
      />
    </div>
  </div>
</li>
