<script>
  import Dropzone from 'svelte-file-dropzone';
  import { isEmpty } from 'lodash-es';
  import SquareImage from './SquareImage.svelte';
  import { showDialog, darkMode, mainViewNavigate } from '../js/store';
  import { fileToObjectUrl, dataURLtoFile } from '../js/util';

  export let selectedFileList = [];
  export let limit = 8;
  export let allowUnknownFileType = false;

  export function addSelectedFiles(files) {
    if (files && files.length > 0) {
      // Convert fileList into a proper array.
      [...files].forEach((newFile) => {
        // Skip duplicate files.
        let isDuplicate = false;
        selectedFileList.forEach((selectedFile) => {
          if (
            selectedFile.name === newFile.name &&
            selectedFile.lastModified === newFile.lastModified &&
            selectedFile.size === newFile.size &&
            selectedFile.type === newFile.type
          ) {
            isDuplicate = true;
          }
        });
        // Some file may have no type, discard those file.
        if (!isDuplicate && (newFile.type || allowUnknownFileType)) {
          if (selectedFileList.length === limit) {
            showDialog(`Max ${limit} files allowed`);
          } else {
            selectedFileList.push(newFile);
          }
        }
        if (isDuplicate) {
          showDialog('Skipped duplicate file');
        }
        if (!newFile.type && !allowUnknownFileType) {
          showDialog('Skipped unsupported format');
        }
      });
      // Assignment to trigger reactive statement.
      selectedFileList = selectedFileList;
    }
  }

  function removeFileFromSelectedFileList(e, file) {
    e.preventDefault();
    e.stopPropagation();

    selectedFileList = selectedFileList.filter(
      (selectedFile) =>
        !(
          selectedFile.name === file.name &&
          selectedFile.lastModified === file.lastModified &&
          selectedFile.size === file.size &&
          selectedFile.type === file.type
        )
    );
  }
</script>

<style lang="scss">
</style>

<div class="flex flex-grow justify-center relative h-48">
  <Dropzone
    on:drop="{(e) => {
      // Pass drag drop file to outbound send handler.
      const { acceptedFiles } = e.detail;
      addSelectedFiles(acceptedFiles);
    }}"
    containerClasses="h-full w-full {$darkMode ? 'bg-gray-900' : 'bg-gray-200'} cursor-pointer"
    containerStyles="{isEmpty(selectedFileList)
      ? 'outline: 2px dashed #92b0b3; outline-offset: -20px;'
      : ''}"
    disableDefaultStyles
  >
    {#if isEmpty(selectedFileList)}
      <div class="flex h-full w-full items-center place-content-center">
        Drop, paste or click to browse file here
      </div>
    {:else}
      <div class="px-4">
        {#each selectedFileList as file, i}
          <div
            class="flex flex-col relative w-1/4 h-12 items-center justify-center float-left mt-5 rounded"
            on:click="{(e) => {
              if (file.type.includes('image')) {
                mainViewNavigate('/image-editor', {
                  selectedFile: file,
                  onSave: (ee) => {
                    selectedFileList[i] = dataURLtoFile(ee.detail);
                  },
                });
              } else {
                removeFileFromSelectedFileList(e, file);
              }
              e.stopPropagation(); // Stop propagating up parent (which triggers select image)
            }}"
          >
            {#if file.type.includes('image')}
              <SquareImage src="{fileToObjectUrl(file)}" alt="upload" />
            {:else}
              <div class="max-w-full">
                {#if file.type.includes('audio')}
                  <div>Audio</div>
                {:else if file.type.includes('video')}
                  <div>Video</div>
                {:else}
                  <div>Document</div>
                {/if}
                <div class="truncate">{file.name}</div>
              </div>
            {/if}
            <div
              class="absolute -top-3 right-4"
              on:click="{(e) => {
                removeFileFromSelectedFileList(e, file);
              }}"
            >
              <i class="f7-icons text-2xl leading-none">xmark_circle_fill</i>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </Dropzone>
</div>
