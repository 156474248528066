<script>
  import SquareImage from './SquareImage.svelte';
  import { createEventDispatcher } from 'svelte';
  import { enableIdleStatusView } from '../js/config';

  const dispatch = createEventDispatcher();

  // { img: 'image url', name: 'text name' }
  export let data = [];
  export let rounded = false;
</script>

<style lang="scss">
  .ucc-badge {
    color: #09d261;
    background-color: #09d261;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
  }
</style>

<div class="w-full px-4">
  {#each data as { src, name, status, detail, showOpenCaseCounter, openCaseCount, topRight }}
    <div
      on:click="{dispatch('click', detail)}"
      class="flex flex-col relative w-1/4 mb-1 items-center justify-center float-left box-border text-center cursor-pointer ripple"
    >
      <SquareImage
        src="{src}"
        rounded="{rounded}"
        showOpenCaseCounter="{showOpenCaseCounter}"
        openCaseCount="{openCaseCount}"
        topRight="{topRight}"
      />
      <div class="flex w-full min-w-0 items-center justify-center text-base px-4">
        {#if status === 'pending'}
          <span class="f7-icons mr-1" style="font-size: 12px;">clock</span>
        {:else if enableIdleStatusView}
          {#if status === 'idle'}
            <span class="f7-icons mr-1" style="font-size: 12px;">moon_zzz</span>
          {:else if status === 'active'}
            <span class="badge ucc-badge mr-1"></span>
          {/if}
        {/if}

        <span class="truncate">{name || '-'}</span>
      </div>
    </div>
  {/each}
</div>
