import firebase from 'firebase/app';
import 'firebase/messaging';
import { isEmpty, upperFirst, escapeRegExp } from 'lodash-es';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import ky from 'ky';
import { get } from 'svelte/store';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import { saveAs } from 'file-saver';
import mimeDb from 'mime-db';
import {
  uccPlaceholderLogo,
  uccPlaceholderAlpha,
  highlightColor,
  androidPushNotificationChannelId,
  anonymizeNumberShowDigit,
  firebaseDevConfig,
  firebaseLiveConfig,
} from './config';
import {
  clearLocalDb,
  remotePushNotificationRegister,
  remotePushNotificationUnregister,
  socketInfo,
  mainViewNavigateRoot,
  showDialog,
  selectedDesk,
} from './store';
import driverStatus from './driver-status';

const kyApi = ky.extend({
  timeout: 1000 * 60 * 20,
});

// https://day.js.org/docs/en/plugin/timezone
dayjs.extend(utc);
dayjs.extend(tz);

// https://stackoverflow.com/questions/43855166/how-to-tell-if-two-dates-are-in-the-same-day
export function sameDay(d1, d2) {
  if (d1 && d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }
  return false;
}

// Convert last chat id into human readable timestamp.
// eg if yesterday, display yesterday instead of 11/11/2018, if same day, display time instead of datestamp, etc.
// https://stackoverflow.com/questions/1643320/get-month-name-from-date
export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// Hello https://www.ucc.chat World => Hello <a href='https://www.ucc.chat'>https://www.ucc.chat</a>
// https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
export function hyperlinkUrlGivenText(originText) {
  if (!originText) {
    return '';
  }

  // Set the regex string
  const regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  // Replace plain text links by hyperlinks
  return originText.replace(
    regex,
    "<a style='color: #3278cd !important;' href='javascript:void(0)' onclick=\"window.openExternalUrl('$1');\">$1</a>"
  );
}
export function highlightText(originText, highlight) {
  if (!originText || typeof originText !== 'string') {
    return '';
  }

  if (highlight) {
    // Use text that contains html tags as breakpoint and split them.
    // https://stackoverflow.com/questions/61049576/javascript-regex-how-to-split-html-string-into-array-of-html-elements-and-text
    const doc = new DOMParser().parseFromString(originText, 'text/html');
    let splittedList = [...doc.body.childNodes].reduce((arr, child) => {
      if (child.outerHTML) {
        arr.push({ text: child.outerHTML, html: true });
      } else {
        arr.push({ text: child.textContent, html: false });
      }
      return arr;
    }, []);

    // Only highlight those that are not enclosed inside any html tags.
    splittedList = splittedList.map((s) => {
      if (!s.html) {
        // https://stackoverflow.com/questions/3294576/javascript-highlight-substring-keeping-original-case-but-searching-in-case-inse
        // https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript/3561711#3561711
        s.text = originText.replace(
          new RegExp(escapeRegExp(highlight), 'gi'),
          (str) =>
            `<mark style="color: ${highlightColor}; background-color: transparent; font-weight: 500;">${str}</mark>`
        );
      }
      return s;
    });

    // Merge all back into a single string.
    return splittedList.map((s) => s.text).join();
  }
  return originText;
}

export function openExternalUrl(url) {
  console.log('Open external url', url);
  window.open(url, '_blank');
}
window.openExternalUrl = openExternalUrl;

/**
 * 'https://abc.com adaweqeqwr' -> ['https://abc.com']
 * https://stackoverflow.com/questions/33211233/how-to-detect-and-get-url-on-string-javascript
 * @param {string} str
 */
export function extractUrlFromString(str) {
  return str.match(/\bhttps?:\/\/\S+/gi) || [];
}

/**
 * 'abc.com adaweqeqwr' -> ['abc.com']
 * https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
 * @param {string} str
 */
export function extractUrlFromStringWithoutProtocol(str) {
  return (
    str.match(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    ) || []
  );
}

export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

// https://stackoverflow.com/questions/3971841/how-to-resize-images-proportionally-keeping-the-aspect-ratio
export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

export function deduceDimensionFromFileUrl(fileUrl) {
  if (!fileUrl) {
    return;
  }

  let dimension;

  // https://storage.googleapis.com/ducc-resource/chat/5e3259a972c1c44133b96055/image/1582810748582/0X2Ddgx3Jz18a_3000_4000.jpeg -> /0X2Ddgx3Jz18a_3000_4000.jpeg
  fileUrl = fileUrl.substring(fileUrl.lastIndexOf('/'));
  // /0X2Ddgx3Jz18a_3000_4000.jpeg -> 4000
  const height = fileUrl.substring(fileUrl.lastIndexOf('_')).substring(1);
  height.substring(0, height.indexOf('.'));
  // /0X2Ddgx3Jz18a_3000_4000.jpeg -> 3000
  let width = fileUrl.substring(0, fileUrl.lastIndexOf('_'));
  width = width.substring(width.lastIndexOf('_')).substring(1);

  if (width && height) {
    dimension = calculateAspectRatioFit(
      Number.parseInt(width, 10),
      Number.parseInt(height, 10),
      convertRemToPixels(15),
      convertRemToPixels(15)
    );

    if (
      Number.isNaN(dimension.width) ||
      Number.isNaN(dimension.height) ||
      dimension.width === 0 ||
      dimension.height === 0
    ) {
      dimension = undefined;
    }
  }
  return dimension;
}

// https://stackoverflow.com/questions/784539/how-do-i-replace-all-line-breaks-in-a-string-with-br-tags
export function textNewlineToBr(str) {
  if (str) {
    return str.replace(/(?:\r\n|\\n|\r|\n)/g, '<br>');
  }
  return str;
}

// https://faq.whatsapp.com/general/chats/how-to-format-your-messages/
// https://stackoverflow.com/questions/10168285/markdown-to-convert-double-asterisks-to-bold-text-in-javascript
export function whatsappTextSpecialCharFormat(text) {
  // Only 'mono' type support text containing preceding and succeeding spaces, other do not apply. EG. *bold* -> <strong>bold</strong>, * bold* -> * bold*
  if (text) {
    // Demo str:
    // ~strike thru~ ~ no strike~ ~strike~ *bold 22* * no bold* * no bold 2 * *bold* _italic 11_ _ no italaitc_ _italic22_
    // Convert to unguessable char, then detect whether it contains preceding spaces, if so revert it back to origin, else make it into relevant style.
    const uniqueWrap = 'uccSpecialCharAnyStrWillDoJustBeLongAndUniqueAndWithoutSymbol';
    text = text
      .replace(/\_(.*?)\_/gm, `${uniqueWrap}i$1i${uniqueWrap}`)
      .replace(/\*(.*?)\*/gm, `${uniqueWrap}b$1b${uniqueWrap}`)
      .replace(/\~(.*?)\~/gm, `${uniqueWrap}s$1s${uniqueWrap}`)
      .replace(
        /\```(.*?)\```/gm,
        '<span class="text-select" style="font-family: monospace !important;">$1</span>'
      );

    // Revert all with preceding spaces back to origin text then remaining to relevant style.
    text = text
      .replace(new RegExp(`${uniqueWrap}i (.*?)i${uniqueWrap}`, 'gm'), '_ $1_')
      .replace(
        new RegExp(`${uniqueWrap}i(.*?)i${uniqueWrap}`, 'gm'),
        '<em class="text-select">$1</em>'
      );
    text = text
      .replace(new RegExp(`${uniqueWrap}b (.*?)b${uniqueWrap}`, 'gm'), '* $1*')
      .replace(
        new RegExp(`${uniqueWrap}b(.*?)b${uniqueWrap}`, 'gm'),
        '<strong class="text-select">$1</strong>'
      );
    text = text
      .replace(new RegExp(`${uniqueWrap}s (.*?)s${uniqueWrap}`, 'gm'), '~ $1~')
      .replace(
        new RegExp(`${uniqueWrap}s(.*?)s${uniqueWrap}`, 'gm'),
        '<strike class="text-select">$1</strike>'
      );

    return text;
    // return text.replace(/\_(.*?)\_/gm, '<em class="text-select">$1</em>').replace(/\*(.*?)\*/gm, '<strong class="text-select">$1</strong>').replace(/\~(.*?)\~/gm, '<strike class="text-select">$1</strike>').replace(/\```(.*?)\```/gm, '<span class="text-select" style="font-family: monospace !important;">$1</span>');
  }
  return text;
}

/**
 * https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
 */
export function dateDiffInDays(a, b) {
  const msPerDay = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / msPerDay);
}

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export function generateRandomString(length, type = 'alphanumeric') {
  let result = '';
  let characters = '';
  if (type === 'alphanumeric') {
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  } else if (type === 'numeric') {
    characters = '0123456789';
  } else if (type === 'alpha') {
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  } else {
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  }
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function isLocalHost() {
  return (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('10.0.') ||
    window.location.hostname.includes('192.168.') ||
    window.location.hostname.includes('172.16.')
  );
}

export function isDevEnv() {
  return window.location.host.includes('dev.') || isLocalHost();
}

export function isLiveEnv() {
  return window.location.host.includes('app.');
}

// www.ucc.chat -> ucc.chat,  dev.iota.ooo -> iota.ooo
export function getHostName() {
  if (isLocalHost()) {
    return 'ucc.chat';
  }
  return window.location.host.replace('www.', '').replace('dev.', '').replace('app.', '');
}

export function clearCookie(domain) {
  // https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
  const cookies = document.cookie.split('; ');
  for (let c = 0; c < cookies.length; c++) {
    const d = domain.split('.');
    while (d.length > 0) {
      const cookieBase = `${encodeURIComponent(
        cookies[c].split(';')[0].split('=')[0]
      )}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${d.join('.')} ;path=`;
      const p = window.location.pathname.split('/');
      document.cookie = `${cookieBase}/`;
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}

export async function clearLocalStoragePartial(preserveLocalSession = false) {
  // Keep iphone model so we don't prompt user for it again on next login since it is bound to device thus no harm.
  const iphoneModel = window.localStorage.getItem('iphoneModel');

  // Keep dark mode setting so user don't have to re-enable it on every login.
  const darkMode = window.localStorage.getItem('darkMode');

  // Keep deskInviteTokenList so user don't get re-prompted again with error message for the token they already accepted or declined on re-login.
  const deskInviteTokenList = window.localStorage.getItem('deskInviteTokenList');

  window.localStorage.clear();
  window.localStorage.setItem('iphoneModel', iphoneModel);
  window.localStorage.setItem('darkMode', darkMode);
  window.localStorage.setItem('deskInviteTokenList', deskInviteTokenList);
  console.log('Local storage cleared');
}

export async function userLogout(clearLocalStorage = true, clearInviteToken = true) {
  // Unsubscribe from push messaging for this device.
  await disablePushNotification();

  // Logout from server side.
  // await kyApi.get('signoutwtf').json();
  // Clear remaining user side stuff.
  clearCookie(window.location.hostname);
  clearCookie(`api.${getHostName()}`);
  clearCookie(`dapi.${getHostName()}`);
  clearCookie(`www.${getHostName()}`);
  clearCookie(`dev.${getHostName()}`);
  clearCookie('localhost:3000');

  // Retain referer for next login needs.
  const referrer = window.localStorage.getItem('referrer');

  if (clearLocalStorage) {
    await clearLocalStoragePartial();
    await clearLocalDb();
  }

  // Remove last logged in userId so when user logout and in with a different id it will load new data.
  window.localStorage.setItem('loggedInUserId', '');
  window.localStorage.setItem('referrer', referrer);

  // Init first, if init already done before it will throw error, ignore it.
  try {
    setupFirebase();
  } catch (err) {}
  // Actual logout.
  try {
    await firebase.auth().signOut();
    console.log('Firebase logout success');
  } catch (err) {
    console.log('Firebase logout error', err);
  }

  // Redirect back to home, which will then auto redirect to login page.
  mainViewNavigateRoot(true);
}

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const serverUrl = (() => {
  if (isLocalHost()) {
    return 'http://192.168.49.2:30001';
  } else if (window.location.host.includes('dev')) {
    return `https://dapi.${getHostName()}`;
  }
  return `https://api.${getHostName()}`;
})();

// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
// Opera 8.0+
export const isOpera =
  (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification));

// Anything with screen larger than 750 is treated as tablet, and tablet is treated as desktop.
export function isTablet() {
  return window.matchMedia('(min-width: 750px)').matches;
}

// https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari
export const isMobileSafari =
  navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);

export const isMacSafari = isSafari && !isMobileSafari;

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
export const isChrome =
  (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) ||
  navigator.userAgent.indexOf('Chrome') != -1;

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS;

export const isOggSupported = isChrome || isFirefox;

// 'cordova/ios' and 'cordova/android' are hardcoded user agent, overriden by cordova webview
// in config.xml in order for external site (ucc.chat dashboard) to know it is coming from native app.
export const isNativeIos = navigator.userAgent.includes('cordova/ios');
export const isNativeAndroid = navigator.userAgent.includes('cordova/android');
export const isNativeApp = isNativeIos || isNativeAndroid;

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
function mobileBrowserCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export const isMobileBrowser = mobileBrowserCheck() || isNativeApp;
export const isDesktopBrowser = !isMobileBrowser;

// https://gist.github.com/kumarharsh/5608030
export function mongoIdToTimestamp(mongoId) {
  if (mongoId) {
    const timestamp = parseInt(mongoId.substring(0, 8), 16) * 1000;
    return new Date(timestamp);
  }
}

// https://stackoverflow.com/questions/11985228/mongodb-node-check-if-objectid-is-valid
export function isValidMongoId(str) {
  const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
  return str && checkForHexRegExp.test(str);
}

// https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
export function isPhoneNumber(number) {
  const phoneNumPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/im;
  return phoneNumPattern.test(number);
}

export function fileToObjectUrl(file) {
  // https://stackoverflow.com/questions/9802452/play-video-from-data-in-file-object
  const URL = window.URL || window.webkitURL;
  return URL.createObjectURL(file);
}

// -------------------------------
// App Scoped

export function extractChatShortDescription(
  chat,
  highlightTerm = '',
  showIcon = true,
  showType = true
) {
  const { type, msgType, msg, content, caseNum, media, isNotification } = chat;

  let duration = '';
  let fileName = '';
  if (media) {
    if (media.duration) {
      duration = msToMinuteSeconds(media.duration);
    }
    if (media.fileName) {
      fileName = media.fileName;
    }
  }

  const metadataFirstContentMsg =
    !isEmpty(content) && Array.isArray(content) && content[0].text ? content[0].text : '';

  if (type === 'flash') {
    return `${showIcon ? `<i class="f7-icons text-sm mr-1">bolt</i>` : ''}${
      showType ? '[Flash]' : ''
    } ${highlightText(metadataFirstContentMsg, highlightTerm)}`;
  }
  if (type === 'memo') {
    return `${showIcon ? '<i class="f7-icons text-sm mr-1">doc</i>' : ''}${
      showType ? '[Memo]' : ''
    } ${highlightText(metadataFirstContentMsg, highlightTerm)}`;
  }
  if (type === 'case_open') {
    return `${showIcon ? '<i class="f7-icons text-sm mr-1">book</i>' : ''}${
      showType ? '[Case]' : ''
    } ${highlightText(`#${caseNum.toString().padStart(7, '0')}`, highlightTerm) || ''}`;
  }
  if (type === 'case_close') {
    return `${showIcon ? '<i class="f7-icons text-sm mr-1">bookmark</i>' : ''}${
      showType ? '[Closed]' : ''
    } ${highlightText(
      `#${caseNum.toString().padStart(7, '0')}` || '',
      highlightTerm
    )} ${highlightText(metadataFirstContentMsg, highlightTerm)}`;
  }
  if (type === 'chat') {
    if (msgType === 'chat') {
      return highlightText(msg, highlightTerm);
    }
    if (msgType === 'image') {
      return `${showIcon ? '<i class="f7-icons text-sm mr-1">photo</i>' : ''}${
        showType ? '[Photo]' : ''
      }`;
    }
    if (msgType === 'audio') {
      return `${showIcon ? '<i class="f7-icons text-sm mr-1">music_note_2</i>' : ''}${
        showType ? '[Audio]' : ''
      } ${duration}`;
    }
    if (msgType === 'ptt') {
      return `${showIcon ? '<i class="f7-icons text-sm mr-1">mic</i>' : ''}${
        showType ? '[Voice]' : ''
      } ${duration}`;
    }
    if (msgType === 'video') {
      return `${showIcon ? '<i class="f7-icons text-sm mr-1">play_rectangle</i>' : ''}${
        showType ? '[Video]' : ''
      } ${duration}`;
    }
    if (msgType === 'document') {
      return `${showIcon ? '<i class="f7-icons text-sm mr-1">doc</i>' : ''}${
        showType ? '[Document]' : ''
      } ${fileName}`;
    }
    if (isNotification) {
      return `${showIcon ? '<i class="f7-icons text-sm mr-1">bell</i>' : ''}${
        showType ? '[Notification]' : ''
      }`;
    }
  }
  if (type === 'edit_info') {
    return `${showIcon ? '<i class="f7-icons text-sm mr-1">person</i>' : ''}${
      showType ? '[Edit Info]' : ''
    }`;
  }
  return `${showIcon ? '<i class="f7-icons text-sm mr-1">xmark_octagon</i>' : ''}${
    showType ? '[Unknown]' : ''
  }`;
}

export function chatMsgTypeToHumanReadable(msgType) {
  let parsed = msgType;
  if (msgType === 'ptt') {
    parsed = 'voice';
  }
  return upperFirst(parsed);
}

export function platformToOfficialPlatformName(platform) {
  if (platform === 'whatsapp') {
    return 'WhatsApp';
  }
  if (platform === 'messenger') {
    return 'Messenger';
  }
  if (platform === 'wechat') {
    return 'WeChat';
  }
  if (platform === 'line') {
    return 'LINE';
  }
  if (platform === 'ucc') {
    return 'UCC';
  }
  if (platform === 'sms') {
    return 'SMS';
  }
  if (platform && typeof platform === 'string') {
    if (platform.length > 1) {
      return platform.charAt(0).toUpperCase() + platform.slice(1);
    }
    return platform.toUpperCase();
  }
  return '';
}

export function channelGetDisplayName(channel, appendPlatformInfo = false) {
  if (!channel) {
    return '';
  }

  let channelDisplayName = '';

  if (channel.name) {
    channelDisplayName = channel.name;
  } else {
    if (channel.platform === 'whatsapp') {
      if (channel.linked) {
        channelDisplayName = phoneNumberPrependPlusAndRemoveWhatsAppSuffix(channel.route);
      }
    } else if (channel.status === driverStatus.active) {
      if (channel.platform === 'phone' || channel.platform === 'voice') {
        channelDisplayName = `+${channel.route}`;
      } else {
        channelDisplayName = channel.route;
      }
    } else if (channel.platform === 'ucc') {
      channelDisplayName = 'Desk Chat';
    }
    if (!channelDisplayName) {
      channelDisplayName = `Unlinked ${platformToOfficialPlatformName(channel.platform)} Channel`;
    }
  }

  if (appendPlatformInfo) {
    channelDisplayName = `${channelDisplayName} (${platformToOfficialPlatformName(
      channel.platform
    )})`;
  }

  return channelDisplayName;
}

export function channelGetDisplayRoute(channel) {
  if ((channel.platform === 'whatsapp' || channel.platform === 'voice') && channel.route) {
    return phoneNumberPrependPlusAndRemoveWhatsAppSuffix(channel.route);
  }
  if (channel.platform === 'ucc') {
    return 'Desk Chat';
  }
  if (channel.name) {
    return channel.name;
  }

  return '-';
}

export function contactPickProfilePic(contact) {
  if (contact) {
    return contact.profilePic || contact.pfProfilePic || uccPlaceholderLogo;
  }
  return uccPlaceholderLogo;
}

export function convertMongoIdToDateTimestamp(
  chatId,
  displayHourIfAvailable = false,
  displayUpToSecond = false
) {
  if (!chatId) {
    return '';
  }
  const chatDate = mongoIdToTimestamp(chatId);
  return convertDateToHumanTimestamp(chatDate, displayHourIfAvailable, displayUpToSecond);
}

export function convertDateToHumanTimestamp(
  date,
  displayHourIfAvailable = false,
  displayUpToSecond = false
) {
  let lastChatTime = '';

  // Convert from date to weekdays if same week,
  const today = new Date();
  const dayDiff = dateDiffInDays(date, today);

  // Today in time, Yesterday and whole week as weekdays name.
  if (dayDiff === 1) {
    lastChatTime = 'Yesterday';
  } else if (dayDiff === 0) {
    // Display in time.
    if (displayHourIfAvailable) {
      if (displayUpToSecond) {
        lastChatTime = date.toLocaleTimeString(navigator.language, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      } else {
        lastChatTime = date.toLocaleTimeString(navigator.language, {
          hour: '2-digit',
          minute: '2-digit',
        });
      }
    } else {
      lastChatTime = 'Today';
    }
  } else if (dayDiff < 6) {
    lastChatTime = date.toLocaleDateString(navigator.language, { weekday: 'short' });
  } else {
    const mm = date.getMonth();
    const dd = date.getDate();
    const yy = date.getFullYear().toString().substring(2);

    lastChatTime = `${dd} ${monthNames[mm]} ${yy}`;
  }
  return lastChatTime;
}

export function dateToHourTimestamp(date, displayUpToSecond) {
  return displayUpToSecond
    ? date.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
    : date.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      });
}

export function convertMongoIdToHourTimestamp(chatId, displayUpToSecond = false) {
  const date = mongoIdToTimestamp(chatId);
  return dateToHourTimestamp(date, displayUpToSecond);
}

export function dateToDayMonthYearHourMin(date, displayUpToSecond) {
  return dayjs(date).format(`D MMM YY hh:mm${displayUpToSecond ? ':ss' : ''}`);
}

/**
 * Is valid date object and is valid js date.
 * https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
 * @param {Date} d
 */
export function isValidDate(d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    if (Number.isNaN(d.getTime())) {
      return false;
    }
    return true;
  }
  return false;
}

export function isEpochZero(d) {
  return new Date(d).valueOf() === 0;
}

/**
 * Get user set channel thumbnail if possible, else get default thumbnail for that channel.
 * @param {object} payload
 * @param {string} payload.platform
 * @param {string} payload.route Channel's route to get its custom icon if set.
 * @param {Array<object>} payload.channelList List of channels to find the target route from.
 * @param {boolean} payload.square Whether to return squared version of platform icon, if false returns rounded version.
 */
export function getPlatformThumbnail({ platform, route, channelList, square = false }) {
  if (route && !isEmpty(channelList)) {
    const targetChannel = channelList.find((c) => c.route === route);
    if (targetChannel && targetChannel.profilePic) {
      return targetChannel.profilePic;
    }
  }

  if (square) {
    if (platform === 'whatsapp') {
      return 'static/images/platform/whatsapp-logo-square.png';
    }
    if (platform === 'messenger') {
      return 'static/images/platform/messenger-logo-square.png';
    }
    if (platform === 'facebook') {
      return 'static/images/platform/facebook-logo-square.png';
    }
    if (platform === 'wechat') {
      return 'static/images/platform/wechat-logo-square.png';
    }
    if (platform === 'line') {
      return 'static/images/platform/line-logo-square.png';
    }
  }

  if (platform === 'whatsapp') {
    return 'static/images/platform/whatsapp-logo.png';
  }
  if (platform === 'messenger') {
    return 'static/images/platform/messenger-logo.png';
  }
  if (platform === 'facebook') {
    return 'static/images/platform/facebook-logo-square.png';
  }
  if (platform === 'wechat') {
    return 'static/images/platform/wechat-logo.png';
  }
  if (platform === 'line') {
    return 'static/images/platform/line-logo.png';
  }
  if (platform === 'jabber') {
    return 'static/images/platform/jabber-logo.png';
  }
  if (platform === 'voice') {
    return 'static/images/platform/voice-logo.png';
  }
  if (platform === 'sms') {
    return 'static/images/platform/sms-logo.png';
  }
  if (platform === 'ucc') {
    return 'static/images/logo.png';
  }
  if (platform === 'email') {
    return 'static/images/platform/email-logo-square.png';
  }
  if (platform === 'phone') {
    return 'static/images/platform/voice-logo.png';
  }
  if (platform === 'apple') {
    return 'static/images/platform/apple-logo.png';
  }
  if (platform === 'google') {
    return 'static/images/platform/google-logo-square.png';
  }
  if (platform === 'dialogflow') {
    return 'static/images/platform/dialogflow.png';
  }
  return uccPlaceholderAlpha;
}

export function getAckIcon(chat, darkMode) {
  const { ack } = chat;

  if (chat.recalledAt) {
    return 'static/images/wa-tick/delete.svg';
  }
  if (chat.cancelledAt) {
    return 'static/images/wa-tick/no-entry.svg';
  }
  if (chat.failedAt) {
    return 'static/images/wa-tick/red-cross.svg';
  }

  if (Number.isInteger(Number.parseInt(ack))) {
    if (ack === 0) {
      return darkMode
        ? 'static/images/wa-tick/pending-dark.svg'
        : 'static/images/wa-tick/pending.svg';
    }
    if (ack === 1) {
      return darkMode
        ? 'static/images/wa-tick/single-tick-grey-dark.svg'
        : 'static/images/wa-tick/single-tick-grey.svg';
    }
    if (ack === 2) {
      return darkMode
        ? 'static/images/wa-tick/double-tick-grey-dark.svg'
        : 'static/images/wa-tick/double-tick-grey.svg';
    }
    if (ack === 3) {
      return 'static/images/wa-tick/double-tick-blue.svg';
    }
    if (ack === 4) {
      return 'static/images/wa-tick/red-cross.svg';
    }
  }

  if (chat.timeoutAt) {
    return 'static/images/wa-tick/clock.svg';
  }

  return '';
}

export function contactRoutePrimaryName(contact) {
  if (isEmpty(contact)) {
    return '';
  }
  return contact.name || contact.pfName || contactRouteSecondaryName(contact);
}
export function contactRouteSecondaryName(contact) {
  if (isEmpty(contact)) {
    return '';
  }
  if (contact.platform === 'whatsapp' && contact.group && contact.group.description) {
    return contact.group.description;
  }
  // Do not display internal unreadable UCC desk chat id to user.
  if (contact.platform === 'ucc') {
    return '';
  }
  return anonymizeContactNumber(contact);
}
export function phoneNumberPrependPlusAndRemoveWhatsAppSuffix(str) {
  str = str.replace('@c.us', '');
  if (isPhoneNumber(str)) {
    return `+${str}`;
  }
  return str;
}

/**
 * Anonymize phone number.
 * Eg 60112223345 -> +XXXXXXX3345
 */
export function anonymizeContactNumber(contact) {
  let number = phoneNumberPrependPlusAndRemoveWhatsAppSuffix(contact.route);

  // Check whether this contact needs anonymizing.
  const desk = get(selectedDesk);
  if (
    number.length > 4 &&
    !isEmpty(desk) &&
    Array.isArray(desk.anonChannel) &&
    !isEmpty(desk.anonChannel)
  ) {
    const channelList = desk.anonChannel;

    if (channelList.find((c) => c === contact.channel)) {
      let prefix = '+';
      // Additional -1 to skip leading +.
      for (let i = 0; i < number.length - anonymizeNumberShowDigit - 1; i++) {
        prefix += 'X';
      }
      number = prefix + number.substring(number.length - anonymizeNumberShowDigit, number.length);
    }
  }

  return number;
}

export function getWhatsAppGroupMember(contact, id) {
  if (contact && contact.group && contact.group.participants) {
    return contact.group.participants.find((c) => c.id === id) || {};
  }
  return {};
}

function userIdToDeskMember(userId, deskMemberList) {
  return deskMemberList.find((member) => member.userId === userId);
}

export function userIdToUserDeskScopedProfilePic(userId, deskMemberList) {
  // Non human sender.
  if (userId === 'bot' || userId === 'api' || userId === 'greeting') {
    return 'static/images/bot.png';
  }
  if (userId === 'system' || userId === 'resend') {
    return 'static/images/system.png';
  }
  if (userId === 'broadcast') {
    return 'static/images/speaker.svg';
  }
  if (userId === 'external') {
    return 'static/images/platform/whatsapp-logo.png';
  }

  // Actual human sender, fetch its profile pic if available.
  const member = userIdToDeskMember(userId, deskMemberList);
  if (member && member.profilePic) {
    return member.profilePic;
  }
  return uccPlaceholderLogo;
}

export function userIdToUserDeskScopedName(userId, deskMemberList) {
  // Non human sender.
  if (
    userId === 'bot' ||
    userId === 'api' ||
    userId === 'greeting' ||
    userId === 'system' ||
    userId === 'resend' ||
    userId === 'broadcast' ||
    userId === 'external'
  ) {
    return upperFirst(userId);
  }

  // Actual human sender, fetch its project scoped name.
  const member = userIdToDeskMember(userId, deskMemberList);
  if (member && member.name) {
    return member.name;
  }
  return 'Unknown';
}

export function generateGreetingSecondaryText(greeting) {
  let result = '';
  if (greeting.recurring) {
    result = `${greeting.start.day} ${greeting.start.hour}:${greeting.start.minute} to ${greeting.end.day} ${greeting.end.hour}:${greeting.end.minute}`;
  } else {
    result = `${dayjs(greeting.start.at).format('D MMM YYYY, hh:mm')} to ${dayjs(
      greeting.end.at
    ).format('D MMM YYYY, hh:mm')}`;
  }
  return result;
}

// https://stackoverflow.com/questions/21294302/converting-milliseconds-to-minutes-and-seconds-with-javascript
export function msToMinuteSeconds(ms) {
  const minutes = Math.floor(ms / 60000);
  const seconds = ((ms % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

// https://stackoverflow.com/questions/8528382/javascript-show-milliseconds-as-dayshoursmins-without-seconds
export function msToDayHourMinuteSecond(ms, humanReadable = false) {
  const day = Math.floor(ms / (24 * 60 * 60 * 1000));
  const dayMs = ms % (24 * 60 * 60 * 1000);
  const hour = Math.floor(dayMs / (60 * 60 * 1000));
  const hourMs = ms % (60 * 60 * 1000);
  const minute = Math.floor(hourMs / (60 * 1000));
  const minuteMs = ms % (60 * 1000);
  const sec = Math.floor(minuteMs / 1000);
  let finalStr = '';

  // Display up to 2 unit of date time.
  if (humanReadable) {
    if (day) {
      finalStr = `${day}d ${hour}h`;
    } else if (hour) {
      finalStr = `${hour}h ${minute}m`;
    } else if (minute) {
      finalStr = `${minute}m ${sec}s`;
    } else {
      finalStr = `${sec}s`;
    }
  } else {
    if (day) {
      finalStr += `${day}:`;
    } else {
      finalStr += `0:`;
    }
    if (hour) {
      finalStr += `${hour < 10 ? '0' : ''}${hour}:`;
    } else {
      finalStr += `00:`;
    }
    if (minute) {
      finalStr += `${minute < 10 ? '0' : ''}${minute}:`;
    } else {
      finalStr += `00:`;
    }
    if (sec) {
      finalStr += `${sec < 10 ? '0' : ''}${sec}`;
    } else {
      finalStr += `00`;
    }
  }
  return finalStr;
}

function dayNameToDayjsNumberDay(day) {
  if (day === 'Sunday') {
    return 0;
  }
  if (day === 'Monday') {
    return 1;
  }
  if (day === 'Tuesday') {
    return 2;
  }
  if (day === 'Wednesday') {
    return 3;
  }
  if (day === 'Thursday') {
    return 4;
  }
  if (day === 'Friday') {
    return 5;
  }
  if (day === 'Saturday') {
    return 6;
  }
}

function convertRecurringIntervalToDate(start, end, timezone) {
  const startDate = dayjs()
    .day(dayNameToDayjsNumberDay(start.day))
    .hour(start.hour)
    .minute(start.minute)
    .second(0)
    .tz(timezone, true)
    .toDate();
  let endDate = dayjs()
    .day(dayNameToDayjsNumberDay(end.day))
    .hour(end.hour)
    .minute(end.minute + 1)
    .second(0)
    .tz(timezone, true)
    .toDate();

  // Move to next week if end ts is smaller than starting ts.
  if (endDate < startDate) {
    endDate = dayjs()
      .day(dayNameToDayjsNumberDay(end.day) + 7)
      .hour(end.hour)
      .minute(end.minute + 1)
      .second(0)
      .tz(timezone, true)
      .toDate();
  }
  return {
    start: startDate,
    end: endDate,
  };
}

/**
 * Check if recurring greeting date overlaps with one another.
 * @param {object} startA
 * @param {object} endA
 * @param {object} startB
 * @param {object} endB
 * @param {string} timezone
 */
export function recurringIntervalOverlap(startA, endA, startB, endB, timezone) {
  const intervalA = convertRecurringIntervalToDate(startA, endA, timezone);
  const intervalB = convertRecurringIntervalToDate(startB, endB, timezone);

  // Calculate extra increment and decrement a week to make sure it covers all range.
  const intervalBIncrementWeek = {
    start: new Date(new Date(intervalB.start).setDate(intervalB.start.getDate() + 7)),
    end: new Date(new Date(intervalB.end).setDate(intervalB.end.getDate() + 7)),
  };
  const intervalBDecrementWeek = {
    start: new Date(new Date(intervalB.start).setDate(intervalB.start.getDate() - 7)),
    end: new Date(new Date(intervalB.end).setDate(intervalB.end.getDate() - 7)),
  };

  return (
    areIntervalsOverlapping(intervalA, intervalB) ||
    areIntervalsOverlapping(intervalA, intervalBIncrementWeek) ||
    areIntervalsOverlapping(intervalA, intervalBDecrementWeek)
  );
}

export function convertChannelStatusToHumanReadableStatus(channelStatusText, mergeProgress) {
  if (!channelStatusText) {
    return channelStatusText;
  }

  if (
    channelStatusText === driverStatus.merging_history &&
    mergeProgress &&
    mergeProgress.fetched &&
    mergeProgress.total
  ) {
    return `${upperFirst(channelStatusText.replace(/_/g, ' '))} ${mergeProgress.fetched}/${
      mergeProgress.total
    }`;
  }
  return upperFirst(channelStatusText.replace(/_/g, ' '));
}

export function channelStatusTextMarkup(statusText, mergeProgress) {
  let statusColor = 'text-red-primary';
  let icon = 'static/images/none.png';
  if (statusText) {
    statusText = statusText.toLowerCase();
    if (statusText === driverStatus.active) {
      statusColor = 'text-green-primary';
      icon = 'static/images/green-circle.svg';
    } else if (statusText === driverStatus.halted) {
      statusColor = 'text-gray-500';
      icon = 'static/images/broken-chain.svg';
    } else if (
      statusText === driverStatus.unlinked ||
      statusText === driverStatus.expired ||
      statusText === driverStatus.invalid_credential ||
      statusText === driverStatus.logged_in_from_another_wa_web ||
      statusText === driverStatus.logged_out_from_physical_device
    ) {
      statusColor = 'text-red-primary';
      icon = 'static/images/broken-chain.svg';
    } else {
      statusColor = 'text-yellow-500';
      icon = 'static/images/yellow-circle.svg';
    }
  }

  if (statusText) {
    return {
      text: convertChannelStatusToHumanReadableStatus(statusText, mergeProgress),
      color: statusColor,
      markup: `<span class="${statusColor}">${convertChannelStatusToHumanReadableStatus(
        statusText,
        mergeProgress
      )}</span>`,
      icon,
    };
  }
  return {
    text: 'Unlinked',
    color: statusColor,
    markup: `<span class="${statusColor}">Unlinked</span>`,
    icon: 'static/images/broken-chain.svg',
  };
}

/**
 * Split tag list into member tag and normal tag.
 * @param {Array<string>} givenTagList List of tag combining both member and normal tag.
 * @param {Array<object>} memberList List of desk members.
 */
export function splitTagIntoMemberTagAndNormalTagWithSelectUiItem(givenTagList, memberList) {
  const selectItemMemberList = [];
  const selectItemTagList = [];
  const memberTagList = [];
  const tagList = [];

  // Exclude system private '_' tag and members.
  givenTagList
    .filter((tag) => tag.charAt(0) !== '_')
    .map((tag) => {
      // Extract member tag.
      if (isValidMongoId(tag)) {
        const member = memberList.find((m) => m._id === tag);
        if (member) {
          selectItemMemberList.push({
            value: tag,
            label: `
            <span class="flex items-center">
              <img class="w-6 h-6 mr-2 rounded-full" src="${
                member.profilePic || uccPlaceholderLogo
              }">
              <span>${member.name}</span>
            </span>
          `,
            name: member.name,
            image: member.profilePic || uccPlaceholderLogo,
          });
          memberTagList.push(tag);
          return;
        }
      } else {
        // Extract normal tag.
        selectItemTagList.push({
          value: tag,
          label: tag,
        });
      }

      tagList.push(tag);
    });

  const tagListStr = [];
  selectItemMemberList.forEach((item) => {
    tagListStr.push(item.name);
  });
  selectItemTagList.forEach((item) => {
    tagListStr.push(item.value);
  });

  return {
    selectItemMemberList,
    selectItemTagList,
    memberTagList,
    tagList,
    tagListStr,
  };
}

let firebaseSetupDone = false;
export function setupFirebase() {
  if (!firebaseSetupDone) {
    firebase.initializeApp(isDevEnv() ? firebaseDevConfig : firebaseLiveConfig);
    firebaseSetupDone = true;
    console.log('Firebase setup complete');
  }
}

export async function waitUntilSocketInfoAvailable() {
  while (!get(socketInfo).vapid) {
    await sleep(500);
  }
}

/**
 * Request for push notification permission if not granted,
 * then send generated subscription to remote so it can start sending push notification.
 * @param {boolean} [askPermission]
 */
export async function setupPushNotification(askPermission) {
  // Macos Safari doesn't support push.
  if (isMacSafari) {
    return;
  }

  // Implementation For:
  // All Windows and linux web browser.
  // Android TWA, add to homescreen app and all android web browser.
  if (!isSafari && !isNativeApp && window.Notification) {
    let status;
    if (askPermission) {
      status = await window.Notification.requestPermission();
    } else {
      status = window.Notification.permission;
    }
    console.log('Notification permission status:', status);

    if (status === 'granted') {
      setupFirebase();
      await waitUntilSocketInfoAvailable();
      const messaging = firebase.messaging();
      const registration = await messaging.getToken({
        vapidKey: get(socketInfo).vapid,
      });
      await remotePushNotificationRegister({ registration, platform: 'browser' });
      console.log('Push notification setup success');
    }
  }
  // Implementation for:
  // iOS and android native cordova app.
  else if (isNativeApp) {
    try {
      // Ask for push permission if not already done so.
      let hasPushPermission = await new Promise((resolve) => {
        window.FirebasePlugin.hasPermission((hasPermission) => {
          resolve(hasPermission);
        });
      });
      if (askPermission) {
        // Ask for permission.
        if (!hasPushPermission) {
          console.log('Request push permission');
          hasPushPermission = await new Promise((resolve) => {
            window.FirebasePlugin.grantPermission((hasPermission) => {
              resolve(hasPermission);
            });
          });
        }
      }

      if (!hasPushPermission) {
        console.log('Push permission denied');
        return;
      }

      console.log('Push permission granted');

      // Create ucc push notification channel if not exist.
      if (isNativeAndroid) {
        const channels = await getNotificationChannelList();
        if (!channels.find((c) => c.id === androidPushNotificationChannelId)) {
          // https://github.com/dpa99c/cordova-plugin-firebasex#createchannel
          const channel = {
            id: androidPushNotificationChannelId,
            description: 'Main Notification Channel',
            name: 'UCC',
            sound: 'default',
            vibration: true,
            light: true,
            lightColor: -1,
            importance: 4,
            badge: true,
            visibility: 1,
          };

          // Create android push channel
          await new Promise((resolve, reject) => {
            window.FirebasePlugin.createChannel(
              channel,
              () => {
                console.log('Notification channel created', channel);
                resolve();
              },
              (err) => {
                console.log('Notification create channel error', err);
                reject(err);
              }
            );
          });
        }
      }

      // Fetch firebase registration and pass it to remote server to complete push registration.
      const registration = await new Promise((resolve, reject) => {
        window.FirebasePlugin.getToken(
          (fcmToken) => {
            console.log('Cordova FCM token', fcmToken);
            resolve(fcmToken);
          },
          (err) => {
            console.log('Cordova FCM token failed', err);
            reject(err);
          }
        );
      });

      await remotePushNotificationRegister({
        registration,
        platform: isNativeIos ? 'iosNative' : 'androidNative',
      });

      console.log('Native push registration setup success');
    } catch (e) {
      console.log('Push registrartion failed', e);
    }
  }
}

let pushNotificationInitDone = false;
/**
 * Init push notification handlers.
 */
export async function pushNotificationHandlerInit() {
  // Macos Safari doesn't support push.
  if (isMacSafari || pushNotificationInitDone) {
    return;
  }
  pushNotificationInitDone = true;

  await setupPushNotification();

  if (isNativeApp) {
    // Clear all notification on launch.
    window.FirebasePlugin.clearAllNotifications();

    window.FirebasePlugin.onMessageReceived(
      (message) => {
        console.log(`Message type: ${message.messageType}`);
        if (message.messageType === 'notification') {
          showDialog('Notification received');
          console.log('Notification message received');
          if (message.tap) {
            console.log(`Tapped in ${message.tap}`);
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );

    // Listen for new FCM token update
    window.FirebasePlugin.onTokenRefresh(
      (fcmToken) => {
        console.log('FCM token update', fcmToken);
      },
      (err) => {
        console.log('FCM token update failed', err);
      }
    );
  } else {
    setupFirebase();
    try {
      await waitUntilSocketInfoAvailable();
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        console.log('Push Message received. ', payload);
        showDialog('Notification received');
      });
    } catch (err) {
      console.log('Firebase messaging not supported');
    }
  }
}

async function getNotificationChannelList() {
  if (isNativeAndroid) {
    return new Promise((resolve) => {
      window.FirebasePlugin.listChannels(
        (channels) => {
          if (!channels) {
            return [];
          }
          resolve(channels);
        },
        (error) => {
          console.log('List channels error', error);
          resolve([]);
        }
      );
    });
  }
  return [];
}

/**
 * Ask remote server to disable push notification for all of this user's devices.
 */
async function disablePushNotification() {
  await remotePushNotificationUnregister();
  // Delete all android push channels.
  // iOS don't have the concept of channels.
  if (isNativeAndroid) {
    const channels = await getNotificationChannelList();
    channels.forEach((channel) => {
      window.FirebasePlugin.deleteChannel(
        channel._id,
        () => {
          console.log('Channel deleted');
        },
        (error) => {
          console.log('Delete channel error', error);
        }
      );
    });
  }
}

function cordovaGetFileEntry(cordovaFilePath) {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(
      cordovaFilePath,
      (entry) => {
        resolve(entry);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * Read a cordova file into html5 file
 * https://stackoverflow.com/questions/33528160/given-the-full-path-to-a-file-how-you-use-the-new-cordova-file-plugin
 * @param {string} cordovaFilePath Sample path 'file:///storage/emulated/0/Android/data/chat.ucc.dev/1614699117688.wav'
 */
export function cordovaReadFile(cordovaFilePath) {
  return new Promise((resolve, reject) => {
    cordovaGetFileEntry(cordovaFilePath).then((entry) => {
      entry.file(
        (file) => {
          resolve(file);
        },
        (err) => {
          reject(err);
        }
      );
    });
  });
}

export function fileToBlob(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(new Blob([reader.result], { type: 'application/octet-stream' }));
    };
    reader.readAsArrayBuffer(file);
  });
}

/**
 * Convert dataUrl to File object.
 * https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
 */
export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

/**
 * Extract f7 #! style url into path only.
 * http://localhost:3000/#!/login-via-email?token=aa&secret=bb -> /login-via-email?token=aa&secret=bb
 * @param {string} href
 */
export function extractPathFromHref(href) {
  if (href.lastIndexOf('#!/') === -1) {
    return '';
  }
  return href.substring(href.lastIndexOf('#!/') + 2);
}

let cordovaDeviceReadyListenerSet = false;
let cordovaDeviceReadyPromise;
export async function waitUntilCordovaDeviceReady() {
  if (!cordovaDeviceReadyListenerSet) {
    cordovaDeviceReadyListenerSet = true;
    cordovaDeviceReadyPromise = new Promise((resolve, reject) => {
      document.addEventListener('deviceready', () => {
        console.log('Cordova Device Ready');
        resolve();
      });
    });
  }
  return cordovaDeviceReadyPromise;
}

// https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-file/index.html
function writeFile(fileEntry, dataObj) {
  return new Promise((resolve, reject) => {
    fileEntry.createWriter((fileWriter) => {
      fileWriter.onwriteend = () => {
        resolve(fileEntry.nativeURL);
        showDialog(`Saved to ${fileEntry.nativeURL}`);
      };

      fileWriter.onerror = (e) => {
        reject(e);
      };
      fileWriter.write(dataObj);
    });
  });
}

export async function saveFile({ url, blob, fileName, ext = '', autoBom = true }) {
  if (isNativeApp) {
    let persistentDir = '';

    // Android persistent path.
    if (isNativeAndroid) {
      persistentDir =
        window.cordova.file.externalDataDirectory ||
        window.cordova.file.externalApplicationStorageDirectory;
    }
    // iOS persistent path.
    else if (isNativeIos) {
      persistentDir = window.cordova.file.documentsDirectory;
    }

    // Download from remote and get its mime.
    if (url) {
      const result = await kyApi.get(url);
      blob = await result.blob();

      // Get extension from mime.
      const mime = result.headers.get('content-type');
      if (mimeDb[mime] && !isEmpty(mimeDb[mime].extensions)) {
        ext = mimeDb[mime].extensions[0];
      }
    }

    // Auto append timestamp to filename since native don't auto rename duplicate file.
    fileName = `${fileName ? `${fileName}-` : fileName}${dayjs().format('D-MMM-YYYY_hh-mm-ss')}${
      ext ? `.${ext}` : ''
    }`;

    // Create the file first.
    // Go to target dir, then create the file.
    // https://stackoverflow.com/questions/41044974/cordova-file-is-not-creating-directory
    try {
      const targetDir = await cordovaGetFileEntry(persistentDir);
      const targetFileEntry = await new Promise((resolve, reject) => {
        targetDir.getFile(
          fileName,
          { create: true },
          (fileEntry) => {
            resolve(fileEntry);
          },
          (err) => {
            reject(err);
          }
        );
      });

      // Save blob to local directly.
      await writeFile(targetFileEntry, blob);
      showDialog(`Saved to ${targetFileEntry.fullPath}`);
    } catch (err) {
      console.log('Save failed', err);
      showDialog('Save Failed');
    }
  } else {
    fileName = `${fileName}${ext ? `.${ext}` : ''}`;
    saveAs(blob || url, fileName, {
      autoBom,
    });
  }
}
