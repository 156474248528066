<script>
  import { Page } from 'framework7-svelte';
  import SortSearchList from '../components/SortSearchList.svelte';
  import {
    selectedDeskMemberList,
    selectedDeskUserInfo,
    remoteMemberSortUpdate,
    selectedDesk,
    showLoadingDialog,
    mainViewNavigate,
  } from '../js/store';
  import { deskAdminIcon } from '../js/config';
</script>

<Page>
  <SortSearchList
    title="Co-workers"
    showCounter
    itemList="{$selectedDeskMemberList.map((member) => {
      return {
        title: member.name,
        subtitle: '',
        image: member.profilePic,
        data: member,
        topRight: member.role === 'admin' ? { src: deskAdminIcon, small: true, shadow: true } : {},
      };
    })}"
    rounded
    showAddBtn="{$selectedDeskUserInfo.role === 'admin'}"
    addUrl="add"
    showSortBtn
    showSearchBtn
    centerText
    media
    on:click="{(e) => {
      mainViewNavigate('detail', { member: e.detail });
    }}"
    on:save="{(e) => {
      showLoadingDialog(async () => {
        // Extract list of new preferred memberId ordering.
        const memberList = e.detail.map((member) => member.data._id) || [];
        await remoteMemberSortUpdate($selectedDesk._id, memberList);
      }, 'Saving');
    }}"
  />
</Page>
