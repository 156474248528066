<script>
  import { Page } from 'framework7-svelte';
  import SelectSearchList from '../components/SelectSearchList.svelte';

  export let props = {};
</script>

<Page>
  <SelectSearchList {...props} />
</Page>
