<script>
  import { Page, Navbar, List, NavRight } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';
  import { closeSecondaryView, selectedDeskMemberList, darkMode } from '../js/store';
  import {
    mongoIdToTimestamp,
    convertDateToHumanTimestamp,
    dateToHourTimestamp,
    userIdToUserDeskScopedProfilePic,
    userIdToUserDeskScopedName,
    getAckIcon,
  } from '../js/util';

  export let props = {};
  const { chat } = props;

  function msgInfoTimestamp(date) {
    return `${convertDateToHumanTimestamp(date, false, true)} at ${dateToHourTimestamp(
      date,
      true
    )}`;
  }

  const createdAt = msgInfoTimestamp(mongoIdToTimestamp(chat._id));
  const initiatedAt = chat.at ? msgInfoTimestamp(new Date(chat.at)) : undefined;
  const deliveredAt = chat.deliveredAt ? msgInfoTimestamp(new Date(chat.deliveredAt)) : undefined;
  const readAt = chat.readAt ? msgInfoTimestamp(new Date(chat.readAt)) : undefined;
  const playedAt = chat.playedAt ? msgInfoTimestamp(new Date(chat.playedAt)) : undefined;
  const recalledAt = chat.recalledAt ? msgInfoTimestamp(new Date(chat.recalledAt)) : undefined;
  const cancelledAt = chat.cancelledAt ? msgInfoTimestamp(new Date(chat.cancelledAt)) : undefined;
  const timeoutAt = chat.timeoutAt ? msgInfoTimestamp(new Date(chat.timeoutAt)) : undefined;
  const failedAt = chat.failedAt ? msgInfoTimestamp(new Date(chat.failedAt)) : undefined;
  const { resendLog } = chat;
</script>

<Page>
  <Navbar
    title="Info"
    backLink
    on:clickBack="{() => {
      closeSecondaryView();
    }}"
  >
    <NavRight />
  </Navbar>

  <div class="h-full overflow-auto">
    <List mediaList>
      {#if createdAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">Created</div>
              </div>
              <div class="item-subtitle">{createdAt}</div>
              <span class="flex mt-1 space-x-1">
                <img
                  src="{userIdToUserDeskScopedProfilePic(chat.senderId, $selectedDeskMemberList)}"
                  class="w-4 h-4 rounded-full"
                  alt="user-profile"
                  on:contextmenu="{(e) => {
                    e.preventDefault();
                  }}"
                />
                <span class="text-xs opacity-50"
                  >{userIdToUserDeskScopedName(chat.senderId, $selectedDeskMemberList)}</span
                >
              </span>
            </div>
          </div>
        </li>
      {/if}
      {#if initiatedAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <img
                    src="{getAckIcon(chat.ack ? { ack: 1 } : { ack: 0 }, $darkMode)}"
                    class="inline mr-1"
                    alt="ack"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />Initiated
                </div>
              </div>
              <div class="item-subtitle">{initiatedAt}</div>
            </div>
          </div>
        </li>
      {/if}
      {#if deliveredAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <img
                    src="{getAckIcon({ ack: 2 }, $darkMode)}"
                    class="inline mr-1"
                    alt="ack"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />Delivered
                </div>
              </div>
              <div class="item-subtitle">{deliveredAt}</div>
            </div>
          </div>
        </li>
      {/if}
      {#if readAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <img
                    src="{getAckIcon({ ack: 3 }, $darkMode)}"
                    class="inline mr-1"
                    alt="ack"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />
                  Read
                </div>
              </div>
              <div class="item-subtitle">{readAt}</div>
            </div>
          </div>
        </li>
      {/if}
      {#if playedAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <img
                    src="{getAckIcon({ ack: 3 }, $darkMode)}"
                    class="inline mr-1"
                    alt="ack"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />Played
                </div>
              </div>
              <div class="item-subtitle">{playedAt}</div>
            </div>
          </div>
        </li>
      {/if}
      {#if recalledAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <img
                    src="{getAckIcon(chat, $darkMode)}"
                    class="inline mr-1"
                    alt="ack"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />Recalled
                </div>
              </div>
              <div class="item-subtitle">{recalledAt}</div>
              <span class="flex mt-1 space-x-1">
                <img
                  src="{userIdToUserDeskScopedProfilePic(chat.recalledBy, $selectedDeskMemberList)}"
                  class="w-4 h-4 rounded-full"
                  alt="user-profile"
                  on:contextmenu="{(e) => {
                    e.preventDefault();
                  }}"
                />
                <span class="text-xs opacity-50"
                  >{userIdToUserDeskScopedName(chat.recalledBy, $selectedDeskMemberList)}</span
                >
              </span>
            </div>
          </div>
        </li>
      {/if}
      {#if cancelledAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <img
                    src="{getAckIcon(chat, $darkMode)}"
                    class="inline mr-1"
                    alt="ack"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />Cancelled
                </div>
              </div>
              <div class="item-subtitle">{cancelledAt}</div>
              <span class="flex mt-1 space-x-1">
                <img
                  src="{userIdToUserDeskScopedProfilePic(
                    chat.cancelledBy,
                    $selectedDeskMemberList
                  )}"
                  class="w-4 h-4 rounded-full"
                  alt="user-profile"
                  on:contextmenu="{(e) => {
                    e.preventDefault();
                  }}"
                />
                <span class="text-xs opacity-50"
                  >{userIdToUserDeskScopedName(chat.cancelledAt, $selectedDeskMemberList)}</span
                >
              </span>
            </div>
          </div>
        </li>
      {/if}
      {#if timeoutAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <img
                    src="{getAckIcon(chat, $darkMode)}"
                    class="inline mr-1"
                    alt="ack"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />Timeout
                </div>
              </div>
              <div class="item-subtitle">{timeoutAt}</div>
            </div>
          </div>
        </li>
      {/if}
      {#if failedAt}
        <li>
          <div class="item-content">
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  <img
                    src="{getAckIcon(chat, $darkMode)}"
                    class="inline mr-1"
                    alt="ack"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />Failed
                </div>
              </div>
              <div class="item-subtitle">{failedAt}</div>
            </div>
          </div>
        </li>
      {/if}
      {#if !isEmpty(resendLog)}
        {#each resendLog as log}
          <li>
            <div class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">
                    <img
                      src="{getAckIcon(chat, $darkMode)}"
                      class="inline mr-1"
                      alt="ack"
                      on:contextmenu="{(e) => {
                        e.preventDefault();
                      }}"
                    />Resent
                  </div>
                </div>
                <div class="item-subtitle">{msgInfoTimestamp(new Date(log.at))}</div>
                <span class="flex mt-1 space-x-1">
                  <img
                    src="{userIdToUserDeskScopedProfilePic(log.by, $selectedDeskMemberList)}"
                    class="w-4 h-4 rounded-full"
                    alt="user-profile"
                    on:contextmenu="{(e) => {
                      e.preventDefault();
                    }}"
                  />
                  <span class="text-xs opacity-50"
                    >{userIdToUserDeskScopedName(log.by, $selectedDeskMemberList)}</span
                  >
                </span>
              </div>
            </div>
          </li>
        {/each}
      {/if}
    </List>
  </div>
</Page>
