<script>
  import { Toolbar, Actions, ActionsGroup } from 'framework7-svelte';
  import { isEmpty, debounce } from 'lodash-es';
  import { onMount, tick } from 'svelte';
  import { writable } from 'svelte/store';
  import ActionsButtonIos from './ActionButtonIos.svelte';
  import StatusBtn from './StatusBtn.svelte';
  import BigIcon from './BigIcon.svelte';
  import {
    selectedContact,
    selectedChatList,
    darkMode,
    openSecondaryView,
    remoteSendOutboundMsg,
    quotedMsgId,
    remoteOpenGraphPreview,
    showLoadingDialog,
    remoteContactDetailUpdate,
    storeUpdateIfDifferent,
    selectedDeskUserInfo,
    selectedDeskContactLockList,
    selectedDeskChannelList,
    selectedContactList,
    isIdle,
    userInfo,
    multiView,
    showDialog,
    selectedDesk,
  } from '../js/store';
  import {
    chatMsgTypeToHumanReadable,
    extractUrlFromStringWithoutProtocol,
    isDesktopBrowser,
    isMobileBrowser,
    isNativeApp,
    isNativeIos,
    mongoIdToTimestamp,
    userIdToUserDeskScopedName,
    cordovaReadFile,
    fileToBlob,
  } from '../js/util';
  import { chatSendBarDefaultPlaceholder } from '../js/config';
  import driverStatus from '../js/driver-status';
  import {
    handleUploadDetermineType,
    handleUploadFromInput,
    handleUpload,
  } from '../js/chat-upload-preview';

  const referWindowHeight = '80px';

  let getMiniAppSheet;
  let getAttachmentSheet;

  const sendInProgress = writable(false);

  let fileInput;
  let videoInput;
  let audioInput;
  let imageInput;

  let textInput;
  let textInputHeight;
  let textInputPlaceholder = chatSendBarDefaultPlaceholder;
  const defaultTextInputHeight = 36;
  let text = '';

  let disabled = true;

  $: isVirginContact = $selectedContact._id === 'virgin';
  $: lockIfSendInProgress = isVirginContact;

  $: statusBtnDisabled = disabled || isVirginContact;
  $: chatSendBarDisabled = disabled;
  $: attachmentBtnDisabled = disabled;
  $: sendBtnDisabled = disabled;

  /**
   * Send feature will only be enabled if all are true, by order:
   * - A contact is selected.
   * - User is active and verified member of this desk.
   * - Contact's channel is a channel capable of initiating outbound message (Any channel that is not voice, voice is read only)
   * - Channel status is active
   * - Contact lock is acquired for this channel.
   */
  function updateSendBarStatus() {
    // Disable outbound send function and wait for last msg to complete first.
    // For virgin contact only, to prevent them from spamming new msg to undefined contact.
    if ($sendInProgress && isVirginContact) {
      disabled = true;
      textInputPlaceholder = 'Sending';
      return;
    }

    // UCC desk chat will never block.
    // Send to virgin number require no checking or lock.
    if ($selectedContact.platform === 'ucc' || isVirginContact) {
      disabled = false;
      textInputPlaceholder = chatSendBarDefaultPlaceholder;
      return;
    }

    const selectedContactChannelList =
      isEmpty($selectedContact) || isEmpty($selectedDeskChannelList)
        ? undefined
        : $selectedDeskChannelList.filter(
            (c) =>
              c.route === $selectedContact.channel &&
              c.platform === $selectedContact.platform &&
              !c.deactivatedAt
          );

    const currentContactLock =
      isEmpty($selectedContact) || isEmpty($selectedDeskContactLockList)
        ? undefined
        : $selectedDeskContactLockList
            .filter((l) => l.contactId === $selectedContact._id)
            .map((l) => {
              l.orderMs = mongoIdToTimestamp(l._id);
              return l;
            })
            .sort((a, b) => {
              return a.orderMs - b.orderMs;
            });

    // Check if contact list no longer contains selected contact.
    const contactNoLongerMatchesFilter =
      !isEmpty($selectedContact) &&
      !isEmpty($selectedContactList) &&
      !$selectedContactList.find((contact) => contact._id === $selectedContact._id);

    disabled = true;

    if (isEmpty($selectedContact)) {
      textInputPlaceholder = chatSendBarDefaultPlaceholder;
    } else if (isEmpty($selectedDeskUserInfo)) {
      textInputPlaceholder = 'Not a member of this desk';
    } else if (!$selectedDeskUserInfo.verified) {
      textInputPlaceholder = 'Not a verified desk member';
    } else if (!$selectedDeskUserInfo.active) {
      textInputPlaceholder = 'Desk member status inactive';
    } else if (contactNoLongerMatchesFilter) {
      textInputPlaceholder = 'Contact no longer matches filter (Read only snapshot)';
    } else if (isEmpty(selectedContactChannelList)) {
      textInputPlaceholder = 'Linked channel not found';
    } else if (!selectedContactChannelList.find((c) => c.status === driverStatus.active)) {
      textInputPlaceholder = 'Linked channel not active';
    } else if (selectedContactChannelList[0].platform === 'voice') {
      textInputPlaceholder = 'Read only channel (Voice)';
    } else if (isEmpty(currentContactLock)) {
      if ($isIdle) {
        textInputPlaceholder = 'Idle';
      } else {
        textInputPlaceholder = 'Acquiring lock';
      }
    } else if (currentContactLock[0].userId !== $userInfo._id) {
      textInputPlaceholder = `In use by ${userIdToUserDeskScopedName(
        currentContactLock[0].userId
      )}`;
    } else {
      disabled = false;
      textInputPlaceholder = chatSendBarDefaultPlaceholder;
    }
  }

  // Update chat send bar status on contact, desk membership, channel, lock change.
  onMount(() => {
    const unsubscribeFnList = [];
    unsubscribeFnList.push(
      selectedContact.subscribe(() => {
        $sendInProgress = false;
        // Wait for isVirginContact to resolve.
        tick().then(() => {
          updateSendBarStatus();
        });
      })
    );
    unsubscribeFnList.push(selectedDeskUserInfo.subscribe(updateSendBarStatus));
    unsubscribeFnList.push(selectedDeskChannelList.subscribe(updateSendBarStatus));
    unsubscribeFnList.push(selectedDeskContactLockList.subscribe(updateSendBarStatus));
    unsubscribeFnList.push(selectedContactList.subscribe(updateSendBarStatus));
    unsubscribeFnList.push(sendInProgress.subscribe(updateSendBarStatus));

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });

  $: platform = $selectedContact.platform;
  $: status = $selectedContact.status;
  $: route = $selectedContact.route;
  $: channel = $selectedContact.channel;
  $: deskId = $selectedContact.deskId;
  $: tag = $selectedContact.tag || [];

  // Clear all input upon contact unselect.
  $: if (isEmpty($selectedContact)) {
    clearAllInput();
  }

  let linkPreview = {};
  let linkPreviewCancelled = false;
  let lastLinkPreviewFetchPromise;
  let referThumbnail;
  let referTitle;
  let referDescription;
  let showReferWindow;

  const fetchLinkPreview = debounce(
    async () => {
      // Only fetch after last fetch had finished, else wait until last finished first.
      await lastLinkPreviewFetchPromise;

      lastLinkPreviewFetchPromise = new Promise((resolve, reject) => {
        const urls = extractUrlFromStringWithoutProtocol(text);
        // Check whether url exist, and also if the matched url same as the url to be fetched.
        if (!isEmpty(urls) && linkPreview.source !== urls[0]) {
          remoteOpenGraphPreview(urls[0])
            .then((result) => {
              if (
                // Verify text is not emptied and the matched url exist within current text.
                text &&
                text.includes(urls[0]) &&
                // Only save the result if the current preview is the latest one in case multi fire.
                (!linkPreview.ts || linkPreview.ts < Date.now())
              ) {
                linkPreview = result;
                linkPreview.ts = Date.now();
                linkPreview.source = urls[0];
                resolve();
              }
            })
            .catch(() => {
              resolve();
            });
        } else {
          resolve();
        }
      });
    },
    500,
    { trailing: true }
  );

  // Extract quoted msg data.
  $: quotedMsg =
    $quotedMsgId && !isEmpty($selectedChatList)
      ? $selectedChatList.find((chat) => chat.id === $quotedMsgId) || {}
      : {};

  // Detect link inside user typed text.
  $: {
    // When user clears text input, allow checking for link preview again.
    if (!text) {
      linkPreviewCancelled = false;
      linkPreview = {};
    } else if (!linkPreviewCancelled) {
      fetchLinkPreview();
    }
    // Clear link preview if the current matched url no longer exist in text field.
    if (linkPreview && linkPreview.url && !text.includes(linkPreview.source)) {
      linkPreview = {};
    }
  }

  // Handle referrer window upon link preview available OR quoted message.
  $: {
    referThumbnail = '';
    referTitle = '';
    referDescription = '';

    if (!isEmpty(quotedMsg)) {
      if (quotedMsg.msgType === 'image') {
        referThumbnail = quotedMsg.msg;
      }
      referTitle = quotedMsg.direction === 'send' ? 'You' : quotedMsg.from.replace('@c.us', '');
      referDescription =
        quotedMsg.msgType === 'chat'
          ? quotedMsg.msg
          : chatMsgTypeToHumanReadable(quotedMsg.msgType);
      showReferWindow = true;
    } else if (!isEmpty(linkPreview)) {
      if (linkPreview.image) {
        referThumbnail = linkPreview.image;
      }
      if (linkPreview.title) {
        referTitle = linkPreview.title;
      }
      // Use description as title if title is not available.
      if (!referTitle && linkPreview.description) {
        referTitle = linkPreview.description;
        referDescription = linkPreview.url;
      }
      if (!referDescription && linkPreview.description) {
        referDescription = linkPreview.description;
      }
      showReferWindow = true;
    } else {
      showReferWindow = false;
    }
  }
  $: sendLinkPreview = showReferWindow && !isEmpty(linkPreview);

  function clearLinkPreview() {
    storeUpdateIfDifferent(quotedMsgId, '');
    quotedMsg = {};
    linkPreview = {};
  }

  function clearAllInput() {
    text = '';
    clearLinkPreview();
  }

  function initiateTextOutboundSend() {
    if (!chatSendBarDisabled) {
      if (text) {
        $sendInProgress = true;
        const payload = { msg: text, msgType: 'chat', platform, route, channel, deskId };

        if ($quotedMsgId) {
          payload.quote = $quotedMsgId;
        }

        if (sendLinkPreview) {
          payload.linkPreview = true;
        } else {
          payload.linkPreview = false;
        }

        // Pass userId to remote so on msg ack it will send it back to us, bypassing filter setting,
        // so user will always get some visual ack if they are still looking at this virgin contact after initiating send.
        if (isVirginContact) {
          payload.virginHandler = $userInfo._id;
        }

        // Resize textarea window since it doesn't resize after clearing multi line text.
        clearAllInput();
        textInputHeight = defaultTextInputHeight;
        textInput.style.height = `${defaultTextInputHeight.toString()}px`;

        remoteSendOutboundMsg(payload).finally(() => {
          $sendInProgress = false;
        });
      }
    }
  }

  let recordingAudio = false;
  let checkingCanRecord = false;
  let nativeAudioRecordFileName = '';
  let nativeAudioRecordMedia;
  let nativeAudioRecordStopped = true;
  /**
   * Start recording audio until recordingAudio flag is set to false, then auto open preview screen.
   * https://developers.google.com/web/fundamentals/media/recording-audio
   */
  function startRecordAudio(native = false) {
    if (recordingAudio || checkingCanRecord) {
      return;
    }

    checkingCanRecord = true;

    // Native iOS or android app.
    if (native) {
      if (nativeAudioRecordStopped) {
        try {
          nativeAudioRecordStopped = false;

          nativeAudioRecordFileName = `${
            window.cordova.file.cacheDirectory
          }${Date.now().toString()}.${isNativeIos ? 'wav' : 'aac'}`;
          nativeAudioRecordMedia = new window.Media(
            nativeAudioRecordFileName,
            () => {
              cordovaReadFile(nativeAudioRecordFileName).then((file) => {
                // Have to convert it to blob for them as aac file (android) on createObjectURL will fail.
                fileToBlob(file).then((blob) => {
                  handleUpload({ file, type: 'audio', blob, sendInProgress, lockIfSendInProgress });
                  console.log('Native record success', blob);
                });
              });
            },
            (err) => {
              console.log('Native record err', err);
            }
          );

          nativeAudioRecordMedia.startRecord();
          recordingAudio = true;
          checkingCanRecord = false;
        } catch (err) {
          console.log(err);
          showDialog('Audio Record Failed');
          checkingCanRecord = false;
        }
      }
    }
    // Normal desktop / mobile browser.
    else {
      try {
        let stopped = false;

        navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
          const mime = 'audio/webm;codecs=opus';
          const recordedChunks = [];

          const mediaRecorder = new window.MediaRecorder(stream, {
            mimeType: mime,
          });
          mediaRecorder.addEventListener('dataavailable', (e) => {
            if (e.data.size > 0) {
              recordedChunks.push(e.data);
            }

            // No longer recording or user has lifted their finger.
            if (!recordingAudio || (!isDesktopBrowser && lastTouchStartTs <= lastTouchEndTs)) {
              if (!stopped) {
                // Stop recording data.
                mediaRecorder.stop();
                // Stop actual stream recording.
                // https://stackoverflow.com/questions/11642926/stop-close-webcam-stream-which-is-opened-by-navigator-mediadevices-getusermedia
                stream.getTracks().forEach((track) => {
                  track.stop();
                });
                stopped = true;
              }

              // Mark as done if not desktop due to it will only get marked as true after a delay,
              // between that delay user may had already lifted their finger on mobile.
              if (!isDesktopBrowser) {
                recordingAudio = false;
              }
            }
          });
          mediaRecorder.addEventListener('stop', () => {
            handleUpload({
              file: new window.File([new window.Blob(recordedChunks)], '', { type: mime }),
              type: 'audio',
              sendInProgress,
              lockIfSendInProgress,
            });
          });
          // Each chunk duration.
          mediaRecorder.start(250);

          recordingAudio = true;
          checkingCanRecord = false;
        });
      } catch (err) {
        console.log(err);
        showDialog('Need access to microphone to send voice message');
        checkingCanRecord = false;
      }
    }
  }

  let lastTouchStartTs = Date.now();
  let lastTouchEndTs = Date.now();
  async function micBtnHandler(e) {
    if (sendBtnDisabled) {
      return;
    }

    if (text) {
      if (e.type === 'click') {
        initiateTextOutboundSend();
      }
    } else {
      // Desktop click to start and stop.
      if (isDesktopBrowser) {
        if (e.type === 'click') {
          if (recordingAudio) {
            recordingAudio = false;
          } else {
            startRecordAudio();
          }
        }
      }
      // Mobile long hold the btn to record.
      else {
        if (e.type === 'touchstart' || e.type === 'mousedown') {
          startRecordAudio(isNativeApp);
          lastTouchStartTs = Date.now();
        }
        if (e.type === 'touchend' || e.type === 'mouseup') {
          recordingAudio = false;
          lastTouchEndTs = Date.now();

          // Stop the recording and show recorded file.
          if (isNativeApp && nativeAudioRecordMedia && !nativeAudioRecordStopped) {
            nativeAudioRecordStopped = true;
            nativeAudioRecordMedia.stopRecord();
            nativeAudioRecordMedia.release();
            console.log('Native stop recording', nativeAudioRecordFileName);
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  .chat-bottom-textarea {
    @apply bg-white;
  }

  .dark.chat-bottom-textarea {
    background-color: #373737;
  }

  .toolbar-item {
    height: var(--f7-toolbar-height);
  }
</style>

<!-- Programmatically increase toolbar height depending on chat bar height -->
<!-- 36px is the default height of chat bar -->
<Toolbar
  bottom
  noShadow
  style="
    height: calc(var(--f7-safe-area-bottom) + 
    {textInputHeight &&
  textInputHeight !== defaultTextInputHeight
    ? `${textInputHeight}px + 12px`
    : 'var(--f7-toolbar-height)'} + 
    {showReferWindow ? referWindowHeight : '0px'}
    )
  "
>
  <div class="flex flex-col w-full h-full select-none">
    <div class="flex w-full {showReferWindow ? '' : 'hidden'}" style="height: {referWindowHeight}">
      <div class="flex flex-grow rounded-md bg-opacity-25 bg-gray-600 my-1 items-center max-w-full">
        <div class="mx-2 flex-grow rounded-r-md max-w-full">
          <div class="flex flex-row items-center">
            <div class="flex-shrink-0 w-12 h-12 mr-2">
              {#if referThumbnail}
                <SquareImage src="{referThumbnail}" alt="thumbnail" />
              {/if}
            </div>
            <div class="max-w-full min-w-0 ml-5 mr-10">
              <div class="truncate">{referTitle || 'Untitled'}</div>
              <div class="opacity-75 truncate">{referDescription}</div>
            </div>
          </div>
        </div>
        <div
          class="absolute top-2 right-3 cursor-pointer text-gray-600"
          on:click="{() => {
            clearLinkPreview();
            linkPreviewCancelled = true;
          }}"
        >
          <BigIcon f7 icon="xmark" />
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full h-full items-end">
      <span
        class="flex relative toolbar-item items-center"
        on:click="{() => {
          if (!statusBtnDisabled) {
            getMiniAppSheet().open();
          }
        }}"
      >
        <span class="flex self-center relative mr-1">
          <StatusBtn
            color="{statusBtnDisabled ? 'white' : status === 'O' ? 'green' : 'white'}"
            disabled="{statusBtnDisabled}"
            dark="{$darkMode}"
          />
        </span>
      </span>
      <span class="flex flex-grow relative h-full items-center">
        <!-- Resizable class is from f7 input -->
        <textarea
          class="resizable chat-bottom-textarea {$darkMode
            ? 'dark'
            : ''} max-h-32 flex-grow cursor-text outline-none border border-gray-400 mx-4
          py-2 px-4 h-9 rounded-2xl resize-none break-words overflow-x-hidden placeholder-gray-600 text-sm
          {$multiView
            ? 'leading-4'
            : 'leading-5'} hover:ring focus:border-blue-secondary focus:ring {chatSendBarDisabled
            ? 'bg-gray-300 cursor-not-allowed'
            : ''}"
          placeholder="{textInputPlaceholder}"
          disabled="{chatSendBarDisabled}"
          bind:clientHeight="{textInputHeight}"
          bind:this="{textInput}"
          bind:value="{text}"
          on:paste="{(e) => {
            // https://stackoverflow.com/questions/50427513/html-paste-clipboard-image-to-file-input
            const { files } = e.clipboardData;
            if (!isEmpty(files)) {
              handleUploadDetermineType({ file: files[0], sendInProgress, lockIfSendInProgress });
            }
          }}"
          on:keypress="{(e) => {
            if (isMobileBrowser) {
              // Do not allow enter new line if textarea is empty.
              if (e.key === 'enter' && !text) {
                e.preventDefault();
              }
            } else {
              // Do not allow enter new line if textarea is empty.
              if (e.key === 'Enter' && (e.shiftKey || e.metaKey) && !text) {
                e.preventDefault();
              }

              // On 'enter' trigger send, exclude shift + enter and mac meta + enter, those combination allows entering newline.
              if (e.key === 'Enter' && !e.shiftKey && !e.metaKey) {
                // Discard newline created by this enter key.
                e.preventDefault();
                initiateTextOutboundSend();
              }
            }
          }}"></textarea>
      </span>
      <span
        class="flex w-8 h-8 justify-center items-center ripple relative mr-3 toolbar-item {attachmentBtnDisabled
          ? 'cursor-not-allowed'
          : 'cursor-pointer'}"
        on:click="{() => {
          if (!attachmentBtnDisabled) {
            getAttachmentSheet().open();
          }
        }}"
      >
        <BigIcon f7 icon="paperclip" />
      </span>
      <span
        class="flex w-8 h-8 justify-center items-center ripple relative toolbar-item {sendBtnDisabled
          ? 'cursor-not-allowed'
          : 'cursor-pointer'}"
        on:click="{micBtnHandler}"
        on:mousedown="{micBtnHandler}"
        on:mouseup="{micBtnHandler}"
        on:touchstart="{micBtnHandler}"
        on:touchend="{micBtnHandler}"
      >
        <BigIcon f7 icon="{text ? 'paperplane' : recordingAudio ? 'stop' : 'mic'}" />
      </span>
    </div>
  </div>

  <!-- Force ios theme for action sheet as md theme is ugly -->
  <Actions
    bind:instance="{getMiniAppSheet}"
    convertToPopover
    backdrop
    closeByBackdropClick
    closeOnEscape
    class="ios {$darkMode ? 'theme-dark' : ''}"
  >
    <ActionsGroup>
      <ActionsButtonIos
        on:click="{() => {
          openSecondaryView('/contact', {}, !isMobileBrowser);
        }}"
      >
        <span slot="icon">person</span>
        <span slot="text">Profile</span>
      </ActionsButtonIos>
      {#if platform !== 'ucc'}
        {#if status === 'C' && !tag.includes('_archive')}
          <ActionsButtonIos
            on:click="{() => {
              showLoadingDialog(async () => {
                const updatedTagList = $selectedContact.tag || [];
                if (!updatedTagList.includes('_archive')) {
                  updatedTagList.push('_archive');
                  await remoteContactDetailUpdate($selectedContact._id, { tag: updatedTagList });
                }
              }, 'Saving');
            }}"
          >
            <span slot="icon">archivebox</span>
            <span slot="text">Archive</span>
          </ActionsButtonIos>
        {:else if tag.includes('_archive')}
          <ActionsButtonIos
            on:click="{() => {
              showLoadingDialog(async () => {
                const updatedTagList = $selectedContact.tag || [];
                if (updatedTagList.includes('_archive')) {
                  await remoteContactDetailUpdate($selectedContact._id, {
                    tag: updatedTagList.filter((t) => t !== '_archive'),
                  });
                }
              }, 'Saving');
            }}"
          >
            <span slot="icon">rectangle_on_rectangle_angled</span>
            <span slot="text">Unarchive</span>
          </ActionsButtonIos>
        {/if}
      {/if}
      {#if platform === 'ucc' && $selectedDesk.flash}
        <ActionsButtonIos
          on:click="{() => {
            openSecondaryView('/miniapp-flash', {}, !isMobileBrowser);
          }}"
        >
          <span slot="icon">bolt</span>
          <span slot="text">Flash</span>
        </ActionsButtonIos>
      {/if}
      <ActionsButtonIos
        on:click="{() => {
          openSecondaryView('/miniapp-memo', {}, !isMobileBrowser);
        }}"
      >
        <span slot="icon">doc</span>
        <span slot="text">Memo</span>
      </ActionsButtonIos>
      {#if status === 'O'}
        <ActionsButtonIos
          on:click="{() => {
            openSecondaryView('/miniapp-close', { append: false }, !isMobileBrowser);
          }}"
        >
          <span slot="icon">power</span>
          <span slot="text">Close</span>
        </ActionsButtonIos>
      {/if}
    </ActionsGroup>
    <ActionsGroup>
      <ActionsButtonIos center bold class="text-blue-primary">
        <span slot="text">Back</span>
      </ActionsButtonIos>
    </ActionsGroup>
  </Actions>

  <!-- Force ios theme for action sheet as md theme is ugly -->
  <Actions
    bind:instance="{getAttachmentSheet}"
    convertToPopover
    backdrop
    closeByBackdropClick
    closeOnEscape
    class="ios {$darkMode ? 'theme-dark' : ''}"
  >
    <ActionsGroup>
      <ActionsButtonIos
        on:click="{() => {
          fileInput.click();
        }}"
      >
        <span slot="icon">doc</span>
        <span slot="text">Document</span>
      </ActionsButtonIos>
      <ActionsButtonIos
        on:click="{() => {
          videoInput.click();
        }}"
      >
        <span slot="icon">play_rectangle</span>
        <span slot="text">Video</span>
      </ActionsButtonIos>
      <ActionsButtonIos
        on:click="{() => {
          audioInput.click();
        }}"
      >
        <span slot="icon">music_note_2</span>
        <span slot="text">Audio</span>
      </ActionsButtonIos>
      <ActionsButtonIos
        on:click="{() => {
          imageInput.click();
        }}"
      >
        <span slot="icon">photo</span>
        <span slot="text">Photo</span>
      </ActionsButtonIos>
    </ActionsGroup>
    <ActionsGroup>
      <ActionsButtonIos center bold class="text-blue-primary">
        <span slot="text">Back</span>
      </ActionsButtonIos>
    </ActionsGroup>
  </Actions>

  <input
    type="file"
    accept="*"
    hidden
    bind:this="{fileInput}"
    on:change="{(event) => {
      handleUploadFromInput({
        input: fileInput,
        type: 'document',
        sendInProgress,
        lockIfSendInProgress,
      });

      // Clear selected file so user can reselect the same file again if needed.
      fileInput.value = null;
    }}"
  />
  <input
    type="file"
    accept="video/*"
    hidden
    bind:this="{videoInput}"
    on:change="{(event) => {
      handleUploadFromInput({
        input: videoInput,
        type: 'video',
        sendInProgress,
        lockIfSendInProgress,
      });

      // Clear selected file so user can reselect the same file again if needed.
      videoInput.value = null;
    }}"
  />
  <input
    type="file"
    accept="audio/*"
    hidden
    bind:this="{audioInput}"
    on:change="{(event) => {
      handleUploadFromInput({
        input: audioInput,
        type: 'audio',
        sendInProgress,
        lockIfSendInProgress,
      });

      // Clear selected file so user can reselect the same file again if needed.
      audioInput.value = null;
    }}"
  />
  <input
    type="file"
    accept="image/*"
    hidden
    bind:this="{imageInput}"
    on:change="{async (event) => {
      handleUploadFromInput({
        input: imageInput,
        type: 'image',
        sendInProgress,
        lockIfSendInProgress,
      });

      // Clear selected file so user can reselect the same file again if needed.
      imageInput.value = null;
    }}"
  />
</Toolbar>
