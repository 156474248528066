<script>
  // UCC custom f7 list item that support toggle btn.
  export let title;
  export let disabled = false;
  export let checked;
  export let onChange;
  export let iconF7;
  export let iconMd;
  export let media;
</script>

<li class="select-none {disabled ? 'disabled' : ''}">
  <div class="item-content">
    {#if media}
      <div class="item-media">
        <i class="{iconF7 ? 'f7-icons' : 'md-icons'} text-icon-primary {media ? '' : 'hidden'}">
          {iconF7 || iconMd || ''}
        </i>
      </div>
    {/if}
    <div class="item-inner">
      <div class="item-title">
        {title}
      </div>
      <slot name="after-title" />
      <div class="item-after">
        <label class="toggle toggle-init {disabled ? 'disabled' : ''}">
          <input type="checkbox" bind:checked disabled="{disabled}" on:change="{onChange}" />
          <span class="toggle-icon"></span>
        </label>
      </div>
    </div>
  </div>
</li>
