<script>
  import { Page, Popup } from 'framework7-svelte';
  import ImageEditor from '../components/ImageEditor.svelte';
  import { mainViewNavigateBack, multiView } from '../js/store';

  export let props = {};

  const { selectedFile, onChange, onSave } = props;

  let getPopupInstance;

  function saveHandler(e) {
    if (onSave) {
      onSave(e);
    }
    mainViewNavigateBack();
  }
  function changeHandler(e) {
    if (onChange) {
      onChange(e);
    }
  }
</script>

<!-- Only use popup on big screen, normal page on mobile -->
<Page
  on:pageBeforeIn="{() => {
    if (getPopupInstance) {
      getPopupInstance().open();
    }
  }}"
  on:pageBeforeOut="{() => {
    if (getPopupInstance) {
      getPopupInstance().close();
    }
  }}"
>
  {#if $multiView}
    <Popup bind:instance="{getPopupInstance}" tabletFullscreen="{true}">
      <ImageEditor
        selectedFile="{selectedFile}"
        on:change="{changeHandler}"
        on:save="{saveHandler}"
      />
    </Popup>
  {:else}
    <ImageEditor
      selectedFile="{selectedFile}"
      on:change="{changeHandler}"
      on:save="{saveHandler}"
    />
  {/if}
</Page>
