import { get } from 'svelte/store';
import {
  mainView,
  remoteDeleteMember,
  remoteAcceptDesk,
  remoteRejectDesk,
  showLoadingDialog,
  mainViewNavigateBack,
  userInfo,
} from './store';

export function deleteMember(deskId, member) {
  const isMemberHimself = member.userId === get(userInfo)._id;
  get(mainView)
    .app.dialog.create({
      title: '',
      text: isMemberHimself ? 'Leave desk?' : `Delete Co-worker - ${member.name}?`,
      closeByBackdropClick: true,
      buttons: [
        {
          text: 'Cancel',
          keyCodes: [27],
        },
        {
          text: isMemberHimself ? 'Leave' : 'Delete',
          bold: true,
          color: 'red',
          keyCodes: [13],
          onClick: () => {
            showLoadingDialog(async () => {
              await remoteDeleteMember(deskId, member._id);
              mainViewNavigateBack();
            }, 'Processing');
          },
        },
      ],
    })
    .open();
}

export function memberInvitationProcess(invitation) {
  get(mainView)
    .app.dialog.create({
      title: '',
      text: `Join Desk - ${invitation.deskName}`,
      closeByBackdropClick: true,
      buttons: [
        {
          text: 'Later',
          color: 'gray',
          keyCodes: [27],
        },
        {
          text: 'Reject',
          color: 'red',
          onClick: () => {
            showLoadingDialog(async () => {
              await remoteRejectDesk(invitation._id);
            }, 'Processing');
          },
        },
        {
          text: 'Join',
          bold: true,
          keyCodes: [13],
          onClick: () => {
            showLoadingDialog(async () => {
              await remoteAcceptDesk(invitation._id);
            }, 'Processing');
          },
        },
      ],
    })
    .open();
}
