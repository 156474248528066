<script>
  import { Page, Navbar, List, NavRight, Link, ListItem } from 'framework7-svelte';
  import {
    showLoadingDialog,
    closeSecondaryView,
    remoteCloseCase,
    selectedContact,
    fileUpload,
    selectedDesk,
  } from '../js/store';
  import AttachmentUploadPreview from '../components/AttachmentUploadPreview.svelte';
  import { isEmpty } from 'lodash-es';

  export let props = {};
  const { caseCloseId } = props;
  const appendRemark = props.append;

  let text = '';
  let addSelectedFiles;
  let selectedFileList;

  $: edited = text || !isEmpty(selectedFileList);
</script>

<Page>
  <Navbar
    title="{appendRemark ? 'Add Remark' : 'Close Case'}"
    backLink
    on:clickBack="{() => {
      closeSecondaryView();
    }}"
  >
    <NavRight>
      <Link
        iconF7="checkmark_alt"
        class="{!appendRemark || (appendRemark && edited) ? '' : 'disabled'}"
        on:click="{async () => {
          showLoadingDialog(async () => {
            const uploadedMedia = await Promise.all(
              selectedFileList.map(async (file) => {
                return {
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  url: await fileUpload({
                    file,
                    deskId: $selectedDesk._id,
                    emitProgress: false,
                    showDefaultOverlayProgress: false,
                    convertToProfilePic: false,
                  }),
                };
              })
            );

            const payload = { contactId: $selectedContact._id, text, media: uploadedMedia };
            if (appendRemark) {
              payload.append = true;
              payload.caseCloseId = caseCloseId;
            }
            await remoteCloseCase(payload);
            closeSecondaryView();
          }, 'Processing');
        }}"
      />
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <List noHairlinesBetween>
      <ListItem title="Remark" />
      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-input-wrap">
            <textarea
              placeholder="Text"
              bind:value="{text}"
              on:paste="{(e) => {
                addSelectedFiles(e.clipboardData.files);
              }}"></textarea>
          </div>
        </div>
      </li>
      <ListItem title="Attachments" />
    </List>
    <AttachmentUploadPreview bind:addSelectedFiles bind:selectedFileList />
  </div>
</Page>
