<script>
  import { Page, Navbar, List } from 'framework7-svelte';
  import ListItemLink from '../components/ListItemLink.svelte';
  import SquareImage from '../components/SquareImage.svelte';
  import { getPlatformThumbnail } from '../js/util';
  import { mainView, showLoadingDialog, remoteAddChannel, selectedDesk } from '../js/store';

  function showVerifyPromoCodePrompt(platform) {
    $mainView.app.dialog
      .create({
        title: '',
        text: 'Enter Promo Code',
        content: `
          <div class="dialog-input-field input">
            <input class="dialog-input" type="text" placeholder="Promo Code" required validate>
          </div>`,
        buttons: [
          { text: 'Cancel', keyCodes: [27] },
          {
            text: 'Verify',
            bold: true,
            keyCodes: [13],
            close: false,
            onClick: (dialog, index) => {
              const promoCode = dialog.$el.find('.dialog-input').val();
              if (promoCode) {
                // Close fetch desk name dialog then show creating dialog.
                $mainView.app.dialog.close();
                showLoadingDialog(async () => {
                  await remoteAddChannel($selectedDesk._id, { platform, promoCode });
                }, 'Verifying');
              }
            },
          },
        ],
        on: {
          opened(dialog) {
            // Focus on text input upon dialog open.
            dialog.$el.find('.dialog-input').eq(0).focus();
          },
        },
      })
      .open();
  }
</script>

<Page>
  <Navbar title="Select Platform" backLink />

  <div class="h-full overflow-auto">
    <List>
      <ListItemLink
        title="WhatsApp"
        media
        mediaComponent
        on:click="{() => {
          showVerifyPromoCodePrompt('whatsapp');
        }}"
      >
        <i slot="media">
          <SquareImage src="{getPlatformThumbnail({ platform: 'whatsapp', square: true })}" />
        </i>
      </ListItemLink>
      <ListItemLink
        title="Messenger"
        disabled
        media
        mediaComponent
        on:click="{() => {
          showVerifyPromoCodePrompt('messenger');
        }}"
      >
        <i slot="media">
          <SquareImage src="{getPlatformThumbnail({ platform: 'messenger', square: true })}" />
        </i>
      </ListItemLink>
      <ListItemLink
        title="LINE"
        disabled
        media
        mediaComponent
        on:click="{() => {
          showVerifyPromoCodePrompt('line');
        }}"
      >
        <i slot="media">
          <SquareImage src="{getPlatformThumbnail({ platform: 'line', square: true })}" />
        </i>
      </ListItemLink>
      <ListItemLink
        title="WeChat"
        disabled
        media
        mediaComponent
        on:click="{() => {
          showVerifyPromoCodePrompt('wechat');
        }}"
      >
        <i slot="media">
          <SquareImage src="{getPlatformThumbnail({ platform: 'wechat', square: true })}" />
        </i>
      </ListItemLink>
      <ListItemLink
        title="Voice"
        disabled
        media
        mediaComponent
        on:click="{() => {
          showVerifyPromoCodePrompt('voice');
        }}"
      >
        <i slot="media">
          <SquareImage src="{getPlatformThumbnail({ platform: 'voice', square: true })}" />
        </i>
      </ListItemLink>
    </List>
  </div>
</Page>
