<script>
  import { Page, Navbar, List } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';
  import ListItemLink from '../components/ListItemLink.svelte';
  import SquareImage from '../components/SquareImage.svelte';
  import { mainView, remoteVerifyDeskToken, userInfo, showLoadingDialog } from '../js/store';
  import { memberInvitationProcess } from '../js/member';
</script>

<Page>
  <Navbar
    title="Invitations{$userInfo &&
    Array.isArray($userInfo.invitation) &&
    !isEmpty($userInfo.invitation)
      ? ` (${$userInfo.invitation.length})`
      : ''}"
    backLink
  />

  <div class="h-full overflow-auto">
    <List mediaList noHairlines>
      <ListItemLink
        title="Join desk by invitation code"
        iconF7="ticket"
        media
        on:click="{() => {
          $mainView.app.dialog
            .create({
              title: '',
              text: 'Desk Invitation Code',
              content: `
          <div class="dialog-input-field input">
            <input class="dialog-input" type="text" placeholder="Invitation Code" required validate>
          </div>`,
              buttons: [
                { text: 'Cancel', keyCodes: [27] },
                {
                  text: 'Verify',
                  bold: true,
                  keyCodes: [13],
                  close: false,
                  onClick: (dialog, index) => {
                    const invitationCode = dialog.$el.find('.dialog-input').val();
                    if (invitationCode) {
                      // Close fetch desk name dialog then show creating dialog.
                      $mainView.app.dialog.close();
                      showLoadingDialog(async () => {
                        await remoteVerifyDeskToken(invitationCode);
                      }, 'Verifying');
                    }
                  },
                },
              ],
              on: {
                opened(dialog) {
                  // Focus on text input upon dialog open.
                  dialog.$el.find('.dialog-input').eq(0).focus();
                },
              },
            })
            .open();
        }}"
      />
    </List>
    <div class="list media-list chevron-center">
      <ul>
        {#if !$userInfo || isEmpty($userInfo.invitation)}
          <li>
            <div class="item-content">
              <div class="item-inner">
                <div class="item-title select-none">No pending invitation found</div>
              </div>
            </div>
          </li>
        {:else}
          {#each $userInfo.invitation as invitation}
            <li>
              <span
                class="item-link item-content"
                on:click="{() => {
                  memberInvitationProcess(invitation);
                }}"
              >
                <div class="item-media">
                  <SquareImage rounded src="{invitation.deskProfilePic}" />
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    {invitation.deskName}
                  </div>
                  <div class="item-subtitle">
                    Invitation from <span class="font-medium"
                      >{invitation.creatorName || 'Unknown'}</span
                    >
                    as <span class="font-medium">{invitation.name}</span>
                  </div>
                </div>
              </span>
            </li>
          {/each}
        {/if}
      </ul>
    </div>
  </div>
</Page>
