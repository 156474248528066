<script>
  import { Page, Navbar, List, ListItem } from 'framework7-svelte';
  import { isEmpty, union } from 'lodash-es';
  import { onMount } from 'svelte';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ListItemToggle from '../components/ListItemToggle.svelte';
  import {
    colorTheme,
    userInfo,
    socketInfo,
    showDialog,
    remotePushNotificationTest,
    showLoadingDialog,
    deviceUuid,
    fingerPrintSupported,
    remoteUserDetailUpdate,
    fingerPrintRequired,
  } from '../js/store';
  import { isMacSafari, setupPushNotification } from '../js/util';
  import { enableLoginIdEditView, enableBiometric } from '../js/config';

  const themeList = [
    { name: 'Auto', value: 'auto' },
    { name: 'Light', value: 'light' },
    { name: 'Dark', value: 'dark' },
  ];
  const platformList = [
    { name: 'Auto', value: 'auto' },
    { name: 'iOS', value: 'ios' },
    { name: 'Android', value: 'md' },
  ];

  const originalSelectedPlatform = window.localStorage.getItem('platform_theme') || 'auto';
  let selectedPlatform = originalSelectedPlatform;
  $: {
    window.localStorage.setItem('color_theme', $colorTheme);
  }
  $: {
    if (selectedPlatform && selectedPlatform !== originalSelectedPlatform) {
      window.localStorage.setItem('platform_theme', selectedPlatform);
      window.location.reload();
    }
  }
  let notification;

  onMount(() => {
    notification = $socketInfo.pushRegistered;
  });

  $: if (notification) {
    setupPushNotification(true).catch((err) => {
      showDialog('Push Registration Failed');
      notification = false;
    });
  }

  $: showBiometricToggle = $fingerPrintSupported && $deviceUuid && enableBiometric;
  $: biometricEnabled = $fingerPrintRequired;

  function updateBiometric(enabled) {
    if (enabled) {
      // If already enabled and user want to disable, ask user for biometric verification once more to disable.
      if (Array.isArray($userInfo.biometric) && $userInfo.biometric.includes($deviceUuid)) {
        window.Fingerprint.show(
          {
            title: 'Verify Identity',
            description: 'To Disable Biometric',
            confirmationRequired: false,
          },
          () => {
            showLoadingDialog(async () => {
              await remoteUserDetailUpdate({
                biometric: isEmpty($userInfo.biometric)
                  ? []
                  : $userInfo.biometric.filter((b) => b !== $deviceUuid),
              });
            }, 'Disabling');
          },
          (err) => {
            console.log('FingerPrint Verification Error', err);
          }
        );
      }
    } else {
      // Not enabled, enable it now.
      if (
        isEmpty($userInfo.biometric) ||
        (Array.isArray($userInfo.biometric) && !$userInfo.biometric.includes($deviceUuid))
      ) {
        window.Fingerprint.show(
          {
            title: 'Verify Identity',
            description: 'To Enable Biometric',
            confirmationRequired: false,
          },
          () => {
            showLoadingDialog(async () => {
              await remoteUserDetailUpdate({
                biometric: isEmpty($userInfo.biometric)
                  ? [$deviceUuid]
                  : union([...$userInfo.biometric, $deviceUuid]),
              });
            }, 'Enabling');
          },
          (err) => {
            console.log('FingerPrint Verification Error', err);
          }
        );
      }
    }
  }
</script>

<Page>
  <Navbar title="Settings" backLink />

  <div class="h-full overflow-auto">
    <List>
      <!-- Change color theme -->
      <ListItem title="Theme" smartSelect smartSelectParams="{{ pageBackLinkText: '' }}">
        <i slot="media" class="f7-icons text-icon-primary">sun_dust</i>
        <select name="theme" bind:value="{$colorTheme}">
          {#each themeList as theme}
            <option selected="{$colorTheme === theme.value}" value="{theme.value}">
              {theme.name}
            </option>
          {/each}
        </select>
      </ListItem>
      <!-- Change platform theme -->
      <ListItem title="Skin" smartSelect smartSelectParams="{{ pageBackLinkText: '' }}">
        <i slot="media" class="f7-icons text-icon-primary">squares_below_rectangle</i>
        <select name="platform" bind:value="{selectedPlatform}">
          {#each platformList as platform}
            <option selected="{selectedPlatform === platform.value}" value="{platform.value}">
              {platform.name}
            </option>
          {/each}
        </select>
      </ListItem>
      {#if !isMacSafari}
        <ListItemToggle title="Notification" iconF7="bell" bind:checked="{notification}" media />
        {#if notification && ($userInfo.role === 'admin' || $userInfo.role === 'superadmin')}
          <ListItemLink
            title="Receive test notification"
            iconF7="bolt_horizontal"
            media
            on:click="{() => {
              showLoadingDialog(async () => {
                await remotePushNotificationTest();
              }, 'Sending');
            }}"
          />
        {/if}
      {/if}
      {#if showBiometricToggle}
        <ListItemToggle
          title="Biometric"
          iconF7="shield"
          media
          bind:checked="{biometricEnabled}"
          on:change="{(e) => {
            updateBiometric(e.target.checked);
          }}"
        />
      {/if}
      {#if enableLoginIdEditView}
        <ListItemLink
          title="Login ID"
          link="loginid"
          iconF7="square_arrow_right"
          media
          after="{Array.isArray($userInfo.loginId)
            ? $userInfo.loginId.filter((loginId) => !loginId.deactivatedAt).length
            : ''}"
        />
      {/if}
    </List>
  </div>
</Page>
