<script>
  import { Page, Navbar, List, ListItem, Block, NavRight, Link } from 'framework7-svelte';
  import { cloneDeep, isEmpty, isEqual, compact, upperFirst } from 'lodash-es';
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ListItemToggle from '../components/ListItemToggle.svelte';
  import {
    selectedChannel,
    selectedDeskAutoReplyList,
    remoteChannelInfoUpdate,
    showLoadingDialog,
    mainViewNavigateBack,
    mainViewNavigate,
    selectedDesk,
    showDialog,
  } from '../js/store';
  import { generateGreetingSecondaryText, recurringIntervalOverlap } from '../js/util';

  const channelId = $selectedChannel._id;
  const originAutoReply = cloneDeep($selectedChannel.autoReply);
  const autoReply = cloneDeep(originAutoReply);

  const greetingAutoReplyStore = writable(autoReply.greeting || []);
  const botAutoReplyStore = writable(autoReply.bot || []);

  onMount(() => {
    const unsubscribeFnList = [];

    unsubscribeFnList.push(
      greetingAutoReplyStore.subscribe((greeting) => {
        autoReply.greeting = greeting;
      })
    );
    unsubscribeFnList.push(
      botAutoReplyStore.subscribe((bot) => {
        autoReply.bot = compact(bot);
      })
    );

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });

  $: edited = !isEqual(originAutoReply, autoReply);
</script>

<Page>
  <Navbar title="Auto Replies" backLink>
    <NavRight>
      {#if edited}
        <Link
          iconF7="checkmark_alt"
          on:click="{() => {
            showLoadingDialog(async () => {
              const payload = {};

              if (originAutoReply.active !== autoReply.active) {
                payload.active = autoReply.active;
              }
              if (originAutoReply.type !== autoReply.type) {
                payload.type = autoReply.type;
              }
              if (!isEqual(originAutoReply.greeting, autoReply.greeting)) {
                payload.greeting = autoReply.greeting;
              }
              if (!isEqual(originAutoReply.bot, autoReply.bot)) {
                payload.bot = autoReply.bot;
              }

              await remoteChannelInfoUpdate(channelId, { autoReply: payload });
              mainViewNavigateBack();
            }, 'Saving');
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <List>
      <ListItemToggle title="Enable" bind:checked="{autoReply.active}" />
      <ListItem
        title="Type"
        smartSelect
        smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
      >
        <select name="provider" bind:value="{autoReply.type}">
          <option value="greeting">Greetings</option>
          <option value="bot">Bot</option>
        </select>
      </ListItem>
      {#if autoReply.type === 'greeting'}
        <ListItemLink
          title="Auto Replies"
          class="ucc-select"
          after="{!isEmpty(autoReply.greeting) ? autoReply.greeting.length : 'Not Selected'}"
          on:click="{() => {
            mainViewNavigate('select', {
              title: 'Auto Replies (Greetings)',
              value: greetingAutoReplyStore,
              multiple: true,
              showSearchBtn: true,
              itemList: $selectedDeskAutoReplyList
                .filter((ar) => ar.type === 'greeting')
                .map((ar) => {
                  return {
                    title: ar.name,
                    subtitle: `Text: ${ar.text}` || '[attachment]',
                    text: generateGreetingSecondaryText(ar),
                    data: ar,
                    value: ar._id,
                  };
                }),
              // Dynamically disable options depending on what is currently selected.
              editOnChange: (localValue, itemList) => {
                const nonSelectedAutoReply = $selectedDeskAutoReplyList.filter(
                  (ar) => ar.type === 'greeting' && !localValue.includes(ar._id)
                );
                const selectedAutoReply = $selectedDeskAutoReplyList.filter(
                  (ar) => ar.type === 'greeting' && localValue.includes(ar._id)
                );

                itemList.map((item) => {
                  // Reset disabled status.
                  item.disabled = false;
                  item.disabledTooltip = '';
                  item.disabledOnClick = undefined;
                  return item;
                });

                itemList.map((item) => {
                  const autoReplyItem = nonSelectedAutoReply.find((ar) => ar._id === item.value);
                  // Non selected ar, check whether it has overlapping timeframe with selected ar, if so disable it.
                  if (autoReplyItem) {
                    let subscribedToOtherRecurringOverlappingAr = false;
                    let subscribedToOtherOverlappingAr = false;

                    selectedAutoReply.forEach((ar) => {
                      if (autoReplyItem.recurring && ar.recurring) {
                        if (
                          recurringIntervalOverlap(
                            autoReplyItem.start,
                            autoReplyItem.end,
                            ar.start,
                            ar.end,
                            $selectedDesk.timezone
                          )
                        ) {
                          subscribedToOtherRecurringOverlappingAr = true;
                        }
                      }
                      // Check has overlapping timeframe.
                      // https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
                      else if (
                        autoReplyItem.start.at &&
                        autoReplyItem.end.at &&
                        ar.start.at &&
                        ar.end.at &&
                        new Date(autoReplyItem.start.at) <= new Date(ar.end.at) &&
                        new Date(ar.start.at) <= new Date(autoReplyItem.end.at)
                      ) {
                        subscribedToOtherOverlappingAr = true;
                      }
                    });

                    // Display reason for disabling that ar.
                    let disabledTooltip = '';
                    if (subscribedToOtherRecurringOverlappingAr) {
                      disabledTooltip =
                        'Timeframe overlap with other subscribed recurring greeting';
                    } else if (subscribedToOtherOverlappingAr) {
                      disabledTooltip = 'Timeframe overlap with other subscribed one time greeting';
                    }

                    if (disabledTooltip) {
                      item.disabledTooltip = disabledTooltip;
                      item.disabled = true;
                      item.disabledOnClick = (item) => {
                        showDialog(disabledTooltip);
                      };
                    }
                  }

                  return item;
                });

                return itemList;
              },
              triggerEditOnChangeOnMount: true,
              countSelected: true,
            });
          }}"
        />
      {:else}
        <ListItemLink
          title="Auto Replies"
          class="ucc-select"
          after="{!isEmpty(autoReply.bot)
            ? $selectedDeskAutoReplyList.find((ar) => ar._id === autoReply.bot[0]).name
            : 'Not Selected'}"
          on:click="{() => {
            mainViewNavigate('select', {
              title: 'Auto Replies (Bot)',
              value: botAutoReplyStore,
              returnOnSelect: true,
              showSearchBtn: true,
              itemList: [
                {
                  title: 'Not Selected',
                  value: '',
                },
                ...$selectedDeskAutoReplyList
                  .filter((ar) => ar.type === 'bot')
                  .map((ar) => {
                    return {
                      title: ar.name,
                      subtitle: upperFirst(ar.provider),
                      data: ar,
                      value: ar._id,
                    };
                  }),
              ],
            });
          }}"
        />
      {/if}
    </List>
    <Block strong>
      <p class="pb-2">Channel can only have either greeting or bot active at any one time.</p>
      <p>Channel can subscribe to multiple greetings, but only 1 bot at any time.</p>
    </Block>
  </div>
</Page>
