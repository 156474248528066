<script>
  import { Page, Navbar, NavRight, Link, List, ListItem } from 'framework7-svelte';
  import { onMount } from 'svelte';
  import { cloneDeep, isEmpty, isEqual, sortBy } from 'lodash-es';
  import Select from 'svelte-select';
  import ListItemToggle from '../components/ListItemToggle.svelte';
  import ProfileImage from '../components/ProfileImage.svelte';
  import {
    closeSecondaryView,
    selectedContact,
    showLoadingDialog,
    remoteContactDetailUpdate,
    remoteDeskTagDistinct,
    darkMode,
    selectedDeskMemberList,
    selectedDesk,
  } from '../js/store';
  import {
    contactRoutePrimaryName,
    contactRouteSecondaryName,
    contactPickProfilePic,
    splitTagIntoMemberTagAndNormalTagWithSelectUiItem,
    convertMongoIdToDateTimestamp,
  } from '../js/util';
  import { uccPlaceholderLogo, enablePinnedChatFunction } from '../js/config';

  $: clonedContact = cloneDeep($selectedContact);
  $: originContactName = contactRoutePrimaryName(clonedContact);
  $: originCrn = clonedContact.crn || '';
  $: originRemark = clonedContact.remark;
  $: originTag = clonedContact.tag || [];
  $: originArchive = originTag.includes('_archive');
  $: originSticky = originTag.includes('_sticky');
  $: contactName = originContactName;
  $: platformName = clonedContact.pfName
    ? `${clonedContact.pfName} (${contactRouteSecondaryName(clonedContact)})`
    : contactRouteSecondaryName(clonedContact);
  $: crn = originCrn;
  $: remark = originRemark;
  $: userProfilePic = contactPickProfilePic(clonedContact);
  $: archive = originArchive;
  $: sticky = originSticky;
  $: tagEdited = !isEqual(
    sortBy(originTag.filter((t) => t !== '_archive' && t !== '_sticky')),
    sortBy(
      selectedTagList
        .concat(selectedMemberTagList)
        .filter((t) => t !== '_archive' && t !== '_sticky')
    )
  );
  $: edited =
    originContactName !== contactName ||
    originCrn !== crn ||
    originRemark !== remark ||
    originArchive !== archive ||
    originSticky !== sticky ||
    tagEdited;

  // Only show archive toggle if it is not desk chat.
  $: showArchiveToggle = clonedContact.platform !== 'ucc';
  // Only allow toggling archive toggle if case is closed.
  $: enableArchiveToggle = clonedContact.status === 'C';

  // Split tag list into member and normal tags.
  // Convert original tag list into select UI tag list, which contains image if tag is a member, else convert as normal tag.
  // Initially available tag list.
  let loadedSelectItemMemberList = $selectedDeskMemberList.map((member) => {
    return {
      value: member._id,
      label: `<span class="flex items-center">
          <img class="w-6 h-6 mr-2 rounded-full" src="${member.profilePic || uccPlaceholderLogo}">
          <span>${member.name}</span>
        </span>`,
    };
  });
  let loadedSelectItemTagList = [];

  // Loaded tag list. Split them into member and normal tag, as well as value label pair used by select UI.
  let originSelectItemMemberList = [];
  let originSelectItemTagList = [];
  let originMemberTagList = [];
  let originTagList = [];
  $: if (!isEmpty(originTag)) {
    const splitTag = splitTagIntoMemberTagAndNormalTagWithSelectUiItem(
      originTag,
      $selectedDeskMemberList
    );
    originSelectItemMemberList = splitTag.selectItemMemberList;
    originSelectItemTagList = splitTag.selectItemTagList;
    originMemberTagList = splitTag.memberTagList;
    originTagList = splitTag.tagList;
  }

  // Setup initial value after element is fully drawn.
  let selectedTagList = [];
  let selectedMemberTagList = [];
  onMount(() => {
    selectedTagList = originTagList;
    selectedMemberTagList = originMemberTagList;

    if ($selectedDesk._id) {
      remoteDeskTagDistinct($selectedDesk._id).then((tags) => {
        loadedSelectItemTagList = splitTagIntoMemberTagAndNormalTagWithSelectUiItem(
          tags,
          $selectedDeskMemberList
        ).tagList;
      });
    }
  });
</script>

<style lang="scss">
  .tag {
    // Default color palette sourced from lib itself.
    // https://github.com/rob-balfre/svelte-select/blob/master/test/public/index.html
    --border: 1px solid #d8dbdf;
    --borderHoverColor: #b2b8bf;
    --borderFocusColor: #006fe8;
    --background: #fff;
    --inputColor: #3f4f5f;
    --placeholderColor: var(--f7-searchbar-placeholder-color);
    --disabledBackground: #ebedef;
    --disabledBorderColor: #ebedef;
    --disabledColor: #c1c6cc;
    --disabledPlaceholderColor: #c1c6cc;
    --clearSelectColor: var(--f7-searchbar-input-clear-button-color);
    --clearSelectHoverColor: var(--f7-searchbar-input-clear-button-color);
    --clearSelectFocusColor: var(--f7-searchbar-input-clear-button-color);
    --indicatorColor: #c5cacf;
    --indicatorFill: currentcolor;
    --indicatorStroke: currentcolor;
    --spinnerColor: #51ce6c;
    --errorBorder: 1px solid #ff2d55;
    --errorBackground: transparent;
    --listShadow: 0 2px 3px 0 rgba(44, 62, 80, 0.24);
    --listBackground: #fff;
    --groupTitleColor: #8f8f8f;
    --listEmptyColor: #78848f;
    --itemColor: #000000;
    --itemActiveBg: #b9daff;
    --itemIsActiveBG: #007aff;
    --itemIsActiveColor: #fff;
    --itemHoverBG: #e7f2ff;
    --multiItemBG: #ebedef;
    --multiItemActiveBG: #006fff;
    --multiItemActiveColor: #fff;
    --multiItemDisabledHoverBg: #ebedef;
    --multiItemDisabledHoverColor: #c1c6cc;
    --multiClearBG: #52616f;
    --multiClearHoverBG: #fff;
    --multiClearHoverFill: #006fff;
    --multiClearFill: #ebedef;
    &.dark {
      --background: transparent;
      --listBackground: var(--f7-block-strong-bg-color);
      --multiItemBG: var(--f7-chip-bg-color);
      --itemHoverBG: #2d3748; // tailwind gray-800
      --multiItemActiveBG: #2d3748; // tailwind gray-800
      --multiClearHoverBG: #2d3748; // tailwind gray-800
      --itemColor: var(--f7-input-text-color);
      --border: 1px solid var(--f7-block-strong-border-color);
      --borderHoverColor: var(--f7-block-strong-border-color);
      --inputColor: currentColor;
    }
  }
</style>

<Page>
  <Navbar
    title="Profile"
    backLink
    on:clickBack="{() => {
      closeSecondaryView();
    }}"
  >
    <NavRight>
      {#if edited}
        <Link
          iconF7="checkmark_alt"
          on:click="{() => {
            showLoadingDialog(async () => {
              const payload = {};
              if (originContactName !== contactName) {
                payload.name = contactName;
              }
              if (originCrn !== crn) {
                payload.crn = crn;
              }
              if (originRemark !== remark) {
                payload.remark = remark;
              }
              if (tagEdited) {
                payload.tag = selectedTagList.concat(selectedMemberTagList);
              }

              if (originArchive !== archive) {
                if (!payload.tag) {
                  payload.tag = cloneDeep(originTag);
                }
                if (archive) {
                  payload.tag.push('_archive');
                } else {
                  payload.tag = payload.tag.filter((tag) => tag !== '_archive');
                }
              }

              if (originSticky !== sticky) {
                if (!payload.tag) {
                  payload.tag = cloneDeep(originTag);
                }
                if (sticky) {
                  payload.tag.push('_sticky');
                } else {
                  payload.tag = payload.tag.filter((tag) => tag !== '_sticky');
                }
              }

              await remoteContactDetailUpdate(clonedContact._id, payload);
            }, 'Saving');
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <ProfileImage src="{userProfilePic}" rounded />

    <List noHairlines>
      <li class="item-content item-input item-input-with-info">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Display Name</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Display name" bind:value="{contactName}" />
          </div>
        </div>
      </li>
      <li class="item-content item-input item-input-with-info">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Default Name</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Platform name" bind:value="{platformName}" readonly />
          </div>
        </div>
      </li>
      <li class="item-content item-input item-input-with-info">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">CRN</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Customer Ref Number" bind:value="{crn}" />
          </div>
        </div>
      </li>
      <li class="item-content item-input item-input-with-info">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Notes</div>
          <div class="item-input-wrap">
            <textarea class="resizable" placeholder="Text" bind:value="{remark}" maxlength="600"
            ></textarea>
          </div>
        </div>
      </li>
      <li class="item-content item-input">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Tag</div>
          <div class="w-full my-2 tag relative z-20 {$darkMode ? 'dark' : ''}">
            <Select
              items="{loadedSelectItemTagList}"
              selectedValue="{isEmpty(originSelectItemTagList)
                ? undefined
                : originSelectItemTagList}"
              placeholder="Tag"
              isCreatable
              isMulti
              listPlacement="top"
              inputStyles="cursor: text;"
              on:select="{(e) => {
                if (Array.isArray(e.detail)) {
                  selectedTagList = e.detail.map((tag) => tag.value);
                } else {
                  selectedTagList = [];
                }
              }}"
            />
          </div>
        </div>
      </li>
      <li class="item-content item-input">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="item-title item-label">Assign</div>
          <div class="w-full my-2 tag relative z-20 {$darkMode ? 'dark' : ''}">
            <Select
              items="{loadedSelectItemMemberList}"
              selectedValue="{isEmpty(originSelectItemMemberList)
                ? undefined
                : originSelectItemMemberList}"
              placeholder="Co-workers"
              isMulti
              listPlacement="top"
              inputStyles="cursor: text;"
              on:select="{(e) => {
                if (Array.isArray(e.detail)) {
                  selectedMemberTagList = e.detail.map((tag) => tag.value);
                } else {
                  selectedMemberTagList = [];
                }
              }}"
            />
          </div>
        </div>
      </li>
      {#if showArchiveToggle}
        <ListItemToggle
          title="Archive"
          disabled="{!enableArchiveToggle}"
          iconF7="archivebox"
          bind:checked="{archive}"
          media
        />
      {/if}
      {#if enablePinnedChatFunction}
        <ListItemToggle title="Pin" iconF7="pin" bind:checked="{sticky}" media />
      {/if}
      <li class="item-content item-input">
        <div class="item-media"><i class="icon demo-list-icon"></i></div>
        <div class="item-inner">
          <div class="flex space-x-2 item-subtitle">
            <span class="space-y-1">
              <div>Since:</div>
              <div>
                {$selectedContact.caseCount && $selectedContact.caseCount > 1 ? 'Cases:' : 'Case:'}
              </div>
            </span>
            <span class="space-y-1">
              <div>{convertMongoIdToDateTimestamp($selectedContact._id, true)}</div>
              <div>{$selectedContact.caseCount || 0}</div>
            </span>
          </div>
        </div>
      </li>
    </List>
  </div>
</Page>
