<script>
  import { Page } from 'framework7-svelte';
  import SortSearchList from '../components/SortSearchList.svelte';
  import { userInfo, showLoadingDialog } from '../js/store';
  import { getPlatformThumbnail } from '../js/util';

  $: loginIdList =
    $userInfo && Array.isArray($userInfo.loginId)
      ? $userInfo.loginId
          .filter((loginId) => !loginId.deactivatedAt)
          .map((loginId) => {
            return {
              title: loginId.route || loginId.platform,
              subtitle: '',
              image: getPlatformThumbnail({ platform: loginId.platform }),
              data: loginId,
            };
          })
      : [];
</script>

<Page>
  <SortSearchList
    title="Login ID"
    showCounter
    itemList="{loginIdList}"
    on:save="{(e) => {
      showLoadingDialog(async () => {
        console.log('Save loginId');
      }, 'Saving');
    }}"
    on:add="{() => {
      console.log('Create new login ID');
    }}"
    media
    allowClick
  />
</Page>
