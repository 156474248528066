<script>
  import {
    Page,
    Navbar,
    NavRight,
    Link,
    List,
    ListItem,
    Block,
    BlockTitle,
  } from 'framework7-svelte';
  import { writable } from 'svelte/store';
  import { tick } from 'svelte';
  import { cloneDeep, isEqual, isEmpty } from 'lodash-es';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ListItemToggle from '../components/ListItemToggle.svelte';
  import {
    mainView,
    showLoadingDialog,
    selectedDesk,
    selectedChannel,
    remoteCreateBot,
    remoteAutoReplyUpdate,
    remoteChannelBotTestMsg,
    showDialog,
    mainViewNavigateRoot,
    mainViewNavigateBack,
    mainViewNavigate,
    selectedDeskChannelList,
    selectedDeskAutoReplyList,
    sharedF7,
  } from '../js/store';
  import {
    channelGetDisplayName,
    channelGetDisplayRoute,
    getPlatformThumbnail,
    channelStatusTextMarkup,
  } from '../js/util';
  import { deleteAutoReply } from '../js/auto-reply';

  export let props = {};
  const { add } = props;

  // Default template.
  const bot = props.bot || {
    throttle: true,
    provider: 'dialogflow',
    name: '',
    jsonStr: '',
    variant: 'es',
    agentId: '', // Only required for dialogflow cx. dialogflow es only allow 1 agent per google project.
  };

  // Clone origin to detect changes.
  const originBot = cloneDeep(bot) || {};

  // Bot must be specified if using edit mode.
  if (!add && !bot._id) {
    mainViewNavigateRoot();
  }

  let jsonParsed;
  $: {
    if (bot.jsonStr) {
      try {
        jsonParsed = JSON.parse(bot.jsonStr);
      } catch (err) {
        console.log(err);
      }
    }
  }

  $: edited = !isEqual(bot, originBot) || subscriberChanged;
  // Must specify bot json credential and name if wanted to create new bot.
  let addReady;
  $: if (bot.provider === 'dialogflow') {
    addReady =
      bot.name && bot.jsonStr && bot.variant && (bot.variant === 'es' ? true : bot.agentId);
  } else if (bot.provider === 'watson') {
    addReady = bot.name && bot.apiKey && bot.serviceUrl && bot.assistantId;
  } else {
    addReady = false;
  }

  let jsonFileInput;

  // Fetch list of subscribed channels by checking which channel has this auto reply enabled.
  const subscribedChannel = writable(
    !isEmpty($selectedDeskChannelList)
      ? $selectedDeskChannelList.reduce((arr, channel) => {
          if (channel.autoReply.bot.find((g) => g === bot._id)) {
            arr.push(channel._id);
          }
          return arr;
        }, [])
      : []
  );
  const initialSubscribedChannel = $subscribedChannel;
  $: subscriberChanged = !isEqual(initialSubscribedChannel, $subscribedChannel);

  let cxAgentTooltip;
  $: if (bot.variant) {
    tick().then(() => {
      $sharedF7.tooltip.create({
        targetEl: cxAgentTooltip,
        text: 'Agent ID, not agent name, select target agent from dialogflow site, then extract agentId from there: https://dialogflow.cloud.google.com/cx/projects/project-name/locations/global/agents/[agentId]',
      });
    });
  }
</script>

<Page>
  <Navbar title="Bots" backLink>
    <NavRight>
      {#if add}
        {#if addReady}
          <Link
            iconF7="checkmark_alt"
            on:click="{() => {
              showLoadingDialog(async () => {
                await remoteCreateBot(
                  $selectedChannel._id,
                  $selectedDesk._id,
                  bot,
                  subscriberChanged ? $subscribedChannel : undefined
                );
                mainViewNavigateBack();
              }, 'Creating');
            }}"
          />
        {/if}
      {:else if !add}
        {#if edited}
          <Link
            iconF7="checkmark_alt"
            on:click="{() => {
              showLoadingDialog(async () => {
                await remoteAutoReplyUpdate(
                  bot._id,
                  bot,
                  // Only forward if subscribed channels had changed.
                  subscriberChanged ? $subscribedChannel : undefined
                );
                mainViewNavigateBack();
              }, 'Saving');
            }}"
          />
        {:else}
          <Link
            iconF7="trash"
            on:click="{() => {
              deleteAutoReply(bot.name, bot.type, bot._id);
            }}"
          />
        {/if}
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <List inlineLabel>
      <ListItemLink
        title="Channels"
        class="ucc-select"
        after="{$subscribedChannel.length || 'None'}"
        on:click="{() => {
          // Disable item if channel already had another subscribed bot.
          // Only 1 bot can be subscribed to a channel at any time.
          const itemList = $selectedDeskChannelList
            .filter((channel) => channel.platform !== 'ucc' && channel.platform !== 'voice')
            .map((channel) => {
              let subscribedToOtherBot = false;

              $selectedDeskAutoReplyList.forEach((ar) => {
                // Exclude self so it can toggle self subscribe.
                if (ar._id !== bot._id && channel.autoReply.bot.includes(ar._id)) {
                  subscribedToOtherBot = true;
                }
              });

              let disabledTooltip = '';
              if (channel.autoReply.type !== 'bot') {
                disabledTooltip = 'Channel is set to use greeting';
              } else if (subscribedToOtherBot) {
                disabledTooltip = 'Channel already subscribed to another bot';
              }

              return {
                title: channelGetDisplayName(channel),
                subtitle: `Status: ${
                  channelStatusTextMarkup(channel.status, channel.mergeProgress).markup
                }`,
                text: `Route: ${channelGetDisplayRoute(channel)}`,
                image: channel.profilePic || getPlatformThumbnail({ platform: channel.platform }),
                data: channel,
                value: channel._id,
                disabledTooltip,
                disabled: !isEmpty(disabledTooltip),
                disabledOnClick: (item) => {
                  showDialog(disabledTooltip);
                },
              };
            });
          mainViewNavigate('select', {
            title: 'Channel',
            itemList,
            value: subscribedChannel,
            initialValue: $subscribedChannel,
            rounded: false,
            media: true,
            multiple: true,
            showSearchBtn: true,
            countSelected: true,
          });
        }}"
      />
      <ListItemToggle title="Forward throttle" bind:checked="{bot.throttle}">
        <span slot="after-title">
          <span
            class="link tooltip-init pl-2"
            data-tooltip="Wait for last bot reply to acknowledge delivery before resuming delivery of new received messages, all message received in the mean time will not be forwarded to bot (dropped)."
          >
            <i class="icon f7-icons text-base">info_circle_fill</i>
          </span>
        </span>
      </ListItemToggle>
      <li class="item-content item-input inline-label input-title-color">
        <div class="item-inner">
          <div class="item-title item-label">Name</div>
          <div class="item-input-wrap">
            <input
              type="text"
              placeholder="Bot Name (Required)"
              required
              validate
              bind:value="{bot.name}"
            />
          </div>
        </div>
      </li>
      <ListItem
        title="Provider"
        smartSelect
        smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
      >
        <select name="provider" bind:value="{bot.provider}">
          <option value="dialogflow">Google DialogFlow</option>
          <option value="watson">IBM Watson</option>
        </select>
      </ListItem>
      {#if bot.provider === 'dialogflow'}
        <BlockTitle>DialogFlow</BlockTitle>
        <ListItem
          title="Variant"
          smartSelect
          smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
        >
          <select name="variant" bind:value="{bot.variant}">
            <option value="es">ES</option>
            <option value="cx">CX</option>
          </select>
        </ListItem>
        {#if bot.variant === 'cx'}
          <li class="item-content item-input inline-label input-title-color">
            <div class="item-inner">
              <div class="item-title item-label">
                Agent ID <span bind:this="{cxAgentTooltip}" class="link pl-2">
                  <i class="icon f7-icons text-base">info_circle_fill</i>
                </span>
              </div>
              <div class="item-input-wrap">
                <input
                  type="text"
                  placeholder="Agent ID"
                  required
                  validate
                  bind:value="{bot.agentId}"
                />
              </div>
            </div>
          </li>
        {/if}
        {#if typeof jsonParsed === 'object'}
          <li class="item-content item-input">
            <div class="item-media"><i class="f7-icons text-icon-primary">cube</i></div>
            <div class="item-inner">
              <div class="item-title item-label">ProjectId (Inferred from JSON file)</div>
              <div class="item-input-wrap">
                <input
                  type="text"
                  placeholder="Inferred DialogFlow ProjectId"
                  value="{typeof jsonParsed === 'object' ? jsonParsed.project_id : ''}"
                  readonly
                />
              </div>
            </div>
          </li>
        {/if}
        <ListItemLink
          title="Upload JSON credential file"
          iconF7="cloud_upload"
          media
          on:click="{() => {
            jsonFileInput.click();
          }}"
        />

        <ListItemLink
          title="Send Test Message"
          iconF7="bolt_horizontal"
          media
          on:click="{() => {
            if (!bot.jsonStr) {
              return showDialog('Please upload a JSON credential file first');
            }

            if (bot.variant === 'cx' && !bot.agentId) {
              return showDialog('Please fill in agent ID first');
            }

            $mainView.app.dialog
              .create({
                title: '',
                text: 'Send text message to DialogFlow bot',
                content: `
          <div class="dialog-input-field input">
            <input class="dialog-input" type="text" placeholder="Text message" required validate>
          </div>`,
                buttons: [
                  { text: 'Cancel', keyCodes: [27] },
                  {
                    text: 'Send',
                    bold: true,
                    keyCodes: [13],
                    close: false,
                    onClick: (dialog, index) => {
                      const testMsg = dialog.$el.find('.dialog-input').val();
                      if (testMsg) {
                        // Close fetch desk name dialog then show creating dialog.
                        $mainView.app.dialog.close();
                        showLoadingDialog(async () => {
                          const result = await remoteChannelBotTestMsg($selectedChannel._id, {
                            jsonStr: bot.jsonStr,
                            provider: 'dialogflow',
                            variant: bot.variant,
                            agentId: bot.agentId,
                            msg: testMsg,
                          });
                          mainViewNavigate('/bot-test-send', { content: result });
                        }, 'Verifying');
                      }
                    },
                  },
                ],
                on: {
                  opened(dialog) {
                    // Focus on text input upon dialog open.
                    dialog.$el.find('.dialog-input').eq(0).focus();
                  },
                },
              })
              .open();
          }}"
        />
        <Block strong>
          <p class="pb-2">
            Dialogflow provides 2 variant, ES and CX, whereby only CX provides visual bot builder.
          </p>
          <p>Steps to link DialogFlow with this channel:</p>
          <p>
            1. Register an account on DialogFlow, then create a project and service account there.
          </p>
          <p>2. Grant service account the role of 'Dialogflow API Client'.</p>
          <p>3. Generate JSON file containing the service account credential.</p>
          <p>4. Upload the JSON file here to complete the link.</p>
          {#if bot.variant === 'cx'}
            <p>5. Fill in agent ID to indicate which bot to use.</p>
          {/if}
          <p>
            Click 'Send Test Message' to submit a dummy request to bot to ensure everything works.
          </p>
          <p class="pt-2">
            To make use of advanced WhatsApp function provided by UCC (caption, quote, media,
            anything outside of basic text message),
            <span class="text-blue-primary font-bold cursor-pointer"
              >refer to this doc for more info.</span
            >
          </p>
        </Block>
      {:else if bot.provider === 'watson'}
        <BlockTitle>Watson</BlockTitle>
        <li class="item-content item-input inline-label input-title-color">
          <div class="item-inner">
            <div class="item-title item-label">API Key</div>
            <div class="item-input-wrap">
              <input
                type="text"
                placeholder="API Key"
                required
                validate
                bind:value="{bot.apiKey}"
              />
            </div>
          </div>
        </li>
        <li class="item-content item-input inline-label input-title-color">
          <div class="item-inner">
            <div class="item-title item-label">Service URL</div>
            <div class="item-input-wrap">
              <input
                type="text"
                placeholder="Assistant URL"
                required
                validate
                bind:value="{bot.serviceUrl}"
              />
            </div>
          </div>
        </li>
        <li class="item-content item-input inline-label input-title-color">
          <div class="item-inner">
            <div class="item-title item-label">Assistant ID</div>
            <div class="item-input-wrap">
              <input
                type="text"
                placeholder="Assistant ID"
                required
                validate
                bind:value="{bot.assistantId}"
              />
            </div>
          </div>
        </li>
        <ListItemLink
          title="Send Test Message"
          iconF7="bolt_horizontal"
          media
          on:click="{() => {
            if (!bot.apiKey) {
              return showDialog('Please fill in API key');
            }
            if (!bot.serviceUrl) {
              return showDialog('Please fill in assistant URL');
            }
            if (!bot.assistantId) {
              return showDialog('Please fill in assistant ID');
            }

            $mainView.app.dialog
              .create({
                title: '',
                text: 'Send text message to Watson bot',
                content: `
          <div class="dialog-input-field input">
            <input class="dialog-input" type="text" placeholder="Text message" required validate>
          </div>`,
                buttons: [
                  { text: 'Cancel', keyCodes: [27] },
                  {
                    text: 'Send',
                    bold: true,
                    keyCodes: [13],
                    close: false,
                    onClick: (dialog, index) => {
                      const testMsg = dialog.$el.find('.dialog-input').val();
                      if (testMsg) {
                        // Close fetch desk name dialog then show creating dialog.
                        $mainView.app.dialog.close();
                        showLoadingDialog(async () => {
                          const result = await remoteChannelBotTestMsg($selectedChannel._id, {
                            apiKey: bot.apiKey,
                            serviceUrl: bot.serviceUrl,
                            assistantId: bot.assistantId,
                            provider: 'watson',
                            msg: testMsg,
                          });
                          mainViewNavigate('/bot-test-send', { content: result });
                        }, 'Verifying');
                      }
                    },
                  },
                ],
                on: {
                  opened(dialog) {
                    // Focus on text input upon dialog open.
                    dialog.$el.find('.dialog-input').eq(0).focus();
                  },
                },
              })
              .open();
          }}"
        />
        <Block strong>
          <p>Steps to link Watson with this channel:</p>
          <p>1. Register an account on IBM Watson, then create an assitant there.</p>
          <p>
            2. Copy and fill in assistant credentials from IBM Watson Assistant credential page to
            here.
          </p>
          <p>
            3. Click 'Send Test Message' to submit a dummy request to bot to ensure everything
            works.
          </p>
          <p class="pt-2">
            To make use of advanced WhatsApp function provided by UCC (caption, quote, media,
            anything outside of basic text message),
            <span class="text-blue-primary font-bold cursor-pointer"
              >refer to this doc for more info.</span
            >
          </p>
        </Block>
      {/if}
    </List>
  </div>

  <input
    type="file"
    accept=".json,application/json"
    hidden
    bind:this="{jsonFileInput}"
    on:change="{() => {
      const reader = new window.FileReader();
      reader.onload = (event) => {
        try {
          const parsed = JSON.parse(event.target.result);
          if (!parsed.project_id) {
            showDialog('Invalid JSON file');
          } else {
            bot.jsonStr = event.target.result;
          }
        } catch (err) {
          showDialog('Invalid JSON file');
        } finally {
          // Clear selected file so user can reselect the same file again if needed.
          jsonFileInput.value = null;
        }
      };
      reader.readAsText(jsonFileInput.files[0]);
    }}"
  />
</Page>
