<script>
  // UCC custom list item that uses span instead of a tag for navigation so
  // on hover browser on desktop don't show nav link and on mobile long hold don't open context menu.
  import { createEventDispatcher } from 'svelte';
  import { mainViewNavigate } from '../js/store';

  export let title = '';
  export let after = '';
  export let disabled = false;
  export let iconF7 = '';
  export let iconMd = '';
  export let media = false;
  export let link = '';
  export let mediaStyle = '';
  export let mediaComponent = false;
  export let popoverClose = false;

  const dispatch = createEventDispatcher();
</script>

<style>
  /* Truncate list item content. */
  .item-title-truncate {
    min-width: 0;
    flex-shrink: 1;
    white-space: var(--f7-list-item-title-white-space);
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: var(--f7-list-item-title-font-size);
    font-weight: var(--f7-list-item-title-font-weight);
    color: var(--f7-list-item-title-text-color);
    line-height: var(--f7-list-item-title-line-height);
  }
  .item-after-truncate {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: block;
    white-space: nowrap;
    flex-shrink: 0;
  }
</style>

<li
  class="select-none {disabled ? 'disabled' : ''} {popoverClose ? 'popover-close' : ''}"
  on:click="{() => {
    if (link) {
      mainViewNavigate(link);
    }
    dispatch('click');
  }}"
>
  <div class="item-link item-content">
    {#if media}
      <div class="item-media">
        {#if mediaComponent}
          <slot name="media" />
        {:else}
          <i
            class="{iconF7 ? 'f7-icons' : 'material-icons'} text-icon-primary {media
              ? ''
              : 'hidden'}"
            style="{mediaStyle}"
          >
            {iconF7 || iconMd || ''}
          </i>
        {/if}
      </div>
    {/if}
    <div class="item-inner">
      <div class="item-title item-title-truncate">{title}</div>
      <div class="item-after item-after-truncate">{after}</div>
    </div>
  </div>
</li>
