<script>
  import { tick } from 'svelte';
  import {
    Page,
    Navbar,
    NavRight,
    Link,
    List,
    ListItem,
    ListInput,
    Block,
  } from 'framework7-svelte';
  import { isEmpty, isEqual } from 'lodash-es';
  import dayjs from 'dayjs';
  import { writable } from 'svelte/store';
  import { selectedDesk, showDialog, storeUpdateIfDifferent } from '../js/store';
  import { isValidDate, isEpochZero, mongoIdToTimestamp } from '../js/util';
  import { setupBeforeLeave } from '../js/back-navigation';

  export let props = {};
  let { contactFilter } = props;
  contactFilter = contactFilter || writable({});

  // Set selected desk creation date max resolution to minute, since f7 calendar don't support date containing seconds.
  $: selectedDeskDate = dayjs(mongoIdToTimestamp($selectedDesk._id)).second(0).toDate();
  $: selectedDeskTs = selectedDeskDate ? selectedDeskDate.valueOf() : 0;

  let fromEdited = false;
  let startAtInput;
  let endAtInput;

  $: startAt = $contactFilter.startAt;
  $: endAt = $contactFilter.endAt;
  $: startEndBy = $contactFilter.startEndBy;

  $: displayedStartAt = startAt ? [new Date(startAt)] : fromEdited ? [] : [selectedDeskDate];
  $: displayedEndAt = endAt ? [new Date(endAt)] : [];

  // Replace displayed value when selected value or source value changes.
  $: if (displayedStartAt && startAtInput) {
    startAtInput.value = displayedStartAt;
  }
  $: if (displayedEndAt && endAtInput) {
    endAtInput.value = displayedEndAt;
  }

  $: isInvalidDateRange =
    new Date(startAt) > new Date(endAt) ||
    selectedDeskDate > new Date(endAt) ||
    (fromEdited && !startAt);

  const showConfirmPrompt = writable(false);
  $: storeUpdateIfDifferent(showConfirmPrompt, isInvalidDateRange);
  setupBeforeLeave(showConfirmPrompt, async () => {
    if (isInvalidDateRange) {
      if (fromEdited && !startAt) {
        showDialog('From cannot be empty');
      } else {
        showDialog('Invalid Date Range');
      }
    }
  });

  /**
   * Only update remote if is valid date range.
   * @param {Date} source
   * @param {'start'|'end'} target
   */
  function applyIfNotEqual(source, target) {
    // Convert default date value epoch 0 to empty string.
    source = !source || new Date(source).valueOf === 0 ? '' : source;

    if (target === 'start') {
      startAt = source;
    } else if (target === 'end') {
      endAt = source;
    }

    // Tick to wait for date range check to reevaluate.
    tick().then(() => {
      if (!isInvalidDateRange) {
        if (target === 'start') {
          if (!isEqual($contactFilter.startAt, source)) {
            $contactFilter.startAt = source;
          }

          if (!isEqual($contactFilter.endAt, endAt)) {
            $contactFilter.endAt = endAt;
          }
        } else if (target === 'end') {
          if (!isEqual($contactFilter.endAt, source)) {
            $contactFilter.endAt = source;
          }

          if (!isEqual($contactFilter.startAt, startAt)) {
            $contactFilter.startAt = startAt;
          }
        }
      }
    });
  }

  $: isDefaultSetting = !startAt && !endAt && startEndBy === 'history' && !fromEdited;

  $: startEndByAfterText = (() => {
    if (!startEndBy) {
      return 'Not Selected';
    }
    if (startEndBy === 'creation') {
      return 'Contact Creation Time';
    }
    if (startEndBy === 'history') {
      return 'Chat History';
    }
  })();

  $: isFromDefaultValue =
    (!startAt && !fromEdited) || new Date(startAt).valueOf() === selectedDeskTs;
</script>

<Page>
  <Navbar title="Range" backLink>
    <NavRight>
      {#if !isDefaultSetting}
        <Link
          iconF7="arrow_2_squarepath"
          on:click="{() => {
            $contactFilter.startAt = '';
            $contactFilter.endAt = '';
            $contactFilter.startEndBy = 'history';
            fromEdited = false;
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <List noHairlines>
      <ListInput
        bind:this="{startAtInput}"
        inlineLabel
        clearButton
        label="From"
        type="datepicker"
        placeholder="Required"
        calendarParams="{{
          dateFormat: 'd M yyyy, HH::mm',
          timePicker: true,
          closeOnSelect: true,
        }}"
        value="{displayedStartAt}"
        class="input-title-color"
        on:calendarChange="{(event) => {
          const selectedDate = new Date(event.detail[0][0]);
          // Only treat as changed if selected date is different from initial date.
          if (isEpochZero(selectedDate)) {
            applyIfNotEqual('', 'start');
          } else if (
            isValidDate(selectedDate) &&
            (!isValidDate(new Date(startAt)) ||
              selectedDate.valueOf() !== new Date(startAt).valueOf())
          ) {
            // Selected desk ts same as desk creation ts, it is the default value, save as empty string.
            if (selectedDeskTs === selectedDate.valueOf()) {
              applyIfNotEqual('', 'start');
            } else {
              applyIfNotEqual(selectedDate, 'start');
            }
          } else if (isEmpty(event.detail[0])) {
            applyIfNotEqual('', 'start');
          }
        }}"
        on:inputClear="{() => {
          fromEdited = true;
        }}"
      >
        <span slot="label" class="{isFromDefaultValue ? '' : 'hidden'}">
          <span class="link tooltip-init pl-2" data-tooltip="Date time matches desk creation date">
            <i class="icon f7-icons text-base">info_circle_fill</i>
          </span>
        </span>
        <span
          slot="inner-end"
          class="{isFromDefaultValue ? 'hidden' : ''} text-icon-f7 ml-2"
          on:click="{() => {
            $contactFilter.startAt = '';
            fromEdited = false;
          }}"
        >
          <span class="link tooltip-init pl-2" data-tooltip="Reset to desk creation time">
            <i class="icon f7-icons text-base">arrow_counterclockwise</i>
          </span>
        </span>
      </ListInput>
      <ListInput
        bind:this="{endAtInput}"
        inlineLabel
        clearButton
        label="To"
        type="datepicker"
        placeholder="Now"
        calendarParams="{{
          dateFormat: 'd M yyyy, HH::mm',
          timePicker: true,
          closeOnSelect: true,
        }}"
        value="{displayedEndAt}"
        class="input-title-color"
        on:calendarChange="{(event) => {
          const selectedDate = new Date(event.detail[0][0]);
          // Only treat as changed if selected date is different from initial date.
          if (isEpochZero(selectedDate)) {
            applyIfNotEqual('', 'end');
          } else if (
            isValidDate(selectedDate) &&
            (!isValidDate(new Date(endAt)) || selectedDate.valueOf() !== new Date(endAt).valueOf())
          ) {
            applyIfNotEqual(selectedDate, 'end');
          } else if (isEmpty(event.detail[0])) {
            applyIfNotEqual('', 'end');
          }
        }}"
      />
      <ListItem
        title="By"
        disabled="{isInvalidDateRange}"
        after="{startEndByAfterText}"
        smartSelect
        smartSelectParams="{{
          pageBackLinkText: '',
          closeOnSelect: true,
          setValueText: false,
        }}"
      >
        <!-- svelte-ignore a11y-no-onchange -->
        <select
          bind:value="{startEndBy}"
          on:change="{() => {
            if (startEndBy !== $contactFilter.startEndBy) {
              $contactFilter.startEndBy = startEndBy;
            }
          }}"
        >
          <option selected="{startEndBy === 'creation'}" value="creation">
            Contact Creation Time
          </option>
          <option selected="{startEndBy === 'history'}" value="history"> Chat History </option>
        </select>
      </ListItem>
    </List>

    {#if isInvalidDateRange}
      <Block class="text-red-primary">
        {#if fromEdited && !startAt}
          'From' cannot be empty
        {:else}
          Invalid date range, 'From' date must be earlier than 'To' date
        {/if}
      </Block>
    {/if}
    {#if startEndBy === 'creation'}
      <Block strong>
        <p>
          Filters out contact using the timestamp of the first message received/sent. (Contact
          creation time)
        </p>
      </Block>
    {:else if startEndBy === 'history'}
      <Block strong>
        <p>
          Filters out contact only using its chat history's timestamp, it doesn't remove the
          messages from view.
        </p>
      </Block>
    {/if}
  </div>
</Page>
