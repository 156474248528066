<script>
  import { createEventDispatcher } from 'svelte';
  import ContactListItem from './ContactListItem.svelte';
  import { contactSearchTerm, contactAdvancedSearchTerm, mainViewNavigate } from '../js/store';
  import { advancedSearchPreviewSize } from '../js/config';
  import {
    convertMongoIdToDateTimestamp,
    contactRoutePrimaryName,
    phoneNumberPrependPlusAndRemoveWhatsAppSuffix,
  } from '../js/util';

  /**
   * Type of the list content.
   */
  export let type = '';
  export let itemList = [];
  export let count = 0;
  /**
   * Show title block
   */
  export let showTitle = false;
  /**
   * Enable 'show more' btn.
   */
  export let showMore = false;

  const dispatch = createEventDispatcher();

  function convertSearchTypeToTitle(searchType) {
    if (searchType === 'name') {
      return 'Contacts';
    }
    if (searchType === 'remark') {
      return 'Notes';
    }
    if (searchType === 'crn') {
      return 'CRNs';
    }
    if (searchType === 'repliedMember') {
      return 'Co-workers';
    }
    if (searchType === 'message') {
      return 'Messages';
    }
    if (searchType === 'openCase') {
      return 'Cases';
    }
    if (searchType === 'caseRemark') {
      return 'Case Remarks';
    }
    if (searchType === 'memo') {
      return 'Memos';
    }
    if (searchType === 'flash') {
      return 'Flashes';
    }
    if (searchType === 'metadata') {
      return 'Logs';
    }
    if (searchType === 'virgin') {
      return 'Send to new contact';
    }
    return '';
  }
</script>

<style lang="scss">
  .advanced-title {
    @apply mt-4;
    @apply mb-1;
    @apply text-lg;
    @apply text-green-primary;
    @apply select-none;
    padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
  }
  .advanced-more {
    @apply py-2;
    @apply cursor-pointer;
    @apply text-blue-primary;
    padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
  }
</style>

{#if showTitle}
  <div class="advanced-title">{convertSearchTypeToTitle(type)}</div>
{/if}
{#each itemList as contact}
  {#if type === 'contact'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.lastChat}"
      title="{contactRoutePrimaryName(contact)}"
      searchTerm="{$contactSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.lastChat._id, true)}"
      hoverHighlight
      highlightSelectedContact
      highlightTitle
      highlightSubtitle
      highlightSender
      showAck
      showSender
      showTypeIcon
      showTypeText
      showContactImage
      showMetadataIcon
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'name'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.lastChat}"
      title="{contactRoutePrimaryName(contact)}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.lastChat._id, true)}"
      hoverHighlight
      highlightTitle
      showAck
      showSender
      showTypeIcon
      showTypeText
      showContactImage
      showMetadataIcon
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'remark'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.lastChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.remark}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.lastChat._id, true)}"
      hoverHighlight
      highlightSubtitle
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'crn'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.jumpToChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.crn}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.jumpToChat._id, true)}"
      hoverHighlight
      highlightSubtitle
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'repliedMember'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.jumpToChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.repliedMember}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.jumpToChat._id, true)}"
      hoverHighlight
      showAck
      showSender
      showTypeIcon
      showTypeText
      highlightSender
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'message'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.jumpToChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.message}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.jumpToChat._id, true)}"
      hoverHighlight
      showAck
      showSender
      showTypeIcon
      showTypeText
      highlightSubtitle
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'openCase'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.jumpToChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.openCase}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.jumpToChat._id, true)}"
      hoverHighlight
      highlightSubtitle
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'caseRemark'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.jumpToChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.caseRemark}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.jumpToChat._id, true)}"
      hoverHighlight
      highlightSubtitle
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'memo'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.jumpToChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.memo}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.jumpToChat._id, true)}"
      hoverHighlight
      highlightSubtitle
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'flash'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.jumpToChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.flash}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.jumpToChat._id, true)}"
      hoverHighlight
      highlightSubtitle
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'metadata'}
    <ContactListItem
      contact="{contact}"
      chat="{contact.jumpToChat}"
      title="{contactRoutePrimaryName(contact)}"
      subtitle="{contact.metadata}"
      searchTerm="{$contactAdvancedSearchTerm}"
      after="{convertMongoIdToDateTimestamp(contact.jumpToChat._id, true)}"
      hoverHighlight
      highlightSubtitle
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {:else if type === 'virgin'}
    <ContactListItem
      contact="{contact}"
      title="{phoneNumberPrependPlusAndRemoveWhatsAppSuffix(contact.route)}"
      subtitle="Via {phoneNumberPrependPlusAndRemoveWhatsAppSuffix(contact.channel)}"
      hoverHighlight
      on:click="{() => {
        dispatch('click', contact);
      }}"
    />
  {/if}
{/each}
{#if showMore && count - advancedSearchPreviewSize > 0}
  <div
    class="advanced-more"
    on:click="{() => {
      mainViewNavigate('detail', {
        type,
        searchTermStore: contactAdvancedSearchTerm,
        title: 'Advanced Search',
        showTitle: true,
        showCounter: true,
        onClick: (contact) => {
          dispatch('click', contact);
        },
      });
    }}"
  >
    <i class="f7-icons text-sm mr-2">search</i>
    <span>Show More ({count})</span>
  </div>
{/if}
