<script>
  import {
    Page,
    Navbar,
    NavRight,
    Link,
    List,
    ListItem,
    ListInput,
    Block,
    Chip,
    BlockTitle,
  } from 'framework7-svelte';
  import { writable } from 'svelte/store';
  import { cloneDeep, isEqual, isEmpty } from 'lodash-es';
  import TimePicker from '../components/TimePicker.svelte';
  import AttachmentUploadPreview from '../components/AttachmentUploadPreview.svelte';
  import ListItemLink from '../components/ListItemLink.svelte';
  import {
    showLoadingDialog,
    selectedDesk,
    selectedChannel,
    remoteCreateGreeting,
    remoteAutoReplyUpdate,
    mainViewNavigateRoot,
    fileUpload,
    mainViewNavigateBack,
    mainViewNavigate,
    selectedDeskChannelList,
    showDialog,
    selectedDeskAutoReplyList,
  } from '../js/store';
  import {
    openExternalUrl,
    channelGetDisplayName,
    channelGetDisplayRoute,
    getPlatformThumbnail,
    channelStatusTextMarkup,
    recurringIntervalOverlap,
  } from '../js/util';
  import { deleteAutoReply } from '../js/auto-reply';

  export let props = {};
  const { add } = props;

  // Default template.
  const greeting = props.greeting || {
    name: '',
    text: '',
    media: '',
    recurring: true,
    start: { day: 'Monday', hour: '00', minute: '00' },
    end: { day: 'Sunday', hour: '23', minute: '59' },
  };

  // Clone origin to detect changes.
  const originGreeting = cloneDeep(greeting) || {};

  // Convert from string to date to ensure equality comparison work down the road.
  if (originGreeting.start && originGreeting.start.at) {
    originGreeting.start.at = new Date(originGreeting.start.at);
  }
  if (originGreeting.end && originGreeting.end.at) {
    originGreeting.end.at = new Date(originGreeting.end.at);
  }

  // Cannot be empty if using edit mode.
  if (!add && !greeting._id) {
    mainViewNavigateRoot();
  }

  const initialStartDateTime =
    greeting.start && greeting.start.at ? [new Date(greeting.start.at)] : [new Date()];
  const initialEndDateTime =
    greeting.end && greeting.end.at ? [new Date(greeting.end.at)] : [new Date()];

  $: edited = !isEqual(greeting, originGreeting) || subscriberChanged;
  $: addReady =
    (greeting.text || !isEmpty(selectedFileList)) &&
    greeting.start &&
    greeting.end &&
    greeting.name;

  let mode = greeting.recurring ? 'recurring' : 'single';

  let addSelectedFiles;
  let selectedFileList;

  $: if (!isEmpty(selectedFileList)) {
    greeting.media = 'edited';
  }

  // Fetch list of subscribed channels by checking which channel has this auto reply enabled.
  const subscribedChannel = writable(
    !isEmpty($selectedDeskChannelList)
      ? $selectedDeskChannelList.reduce((arr, channel) => {
          if (channel.autoReply.greeting.find((g) => g === greeting._id)) {
            arr.push(channel._id);
          }
          return arr;
        }, [])
      : []
  );
  const initialSubscribedChannel = $subscribedChannel;
  $: subscriberChanged = !isEqual(initialSubscribedChannel, $subscribedChannel);
</script>

<Page>
  <Navbar title="Greetings" backLink>
    <NavRight>
      {#if add}
        {#if addReady}
          <Link
            iconF7="checkmark_alt"
            on:click="{() => {
              showLoadingDialog(async () => {
                if (
                  greeting.media &&
                  originGreeting.media !== greeting.media &&
                  !isEmpty(selectedFileList) &&
                  greeting.media === 'edited'
                ) {
                  const url = await fileUpload({
                    file: selectedFileList[0],
                    deskId: $selectedDesk._id,
                    emitProgress: true,
                    showDefaultOverlayProgress: true,
                  });
                  greeting.media = url;
                  greeting.mime = selectedFileList[0].type;
                }
                await remoteCreateGreeting(
                  $selectedChannel._id,
                  $selectedDesk._id,
                  greeting,
                  subscriberChanged ? $subscribedChannel : undefined
                );
                mainViewNavigateBack();
              }, 'Creating');
            }}"
          />
        {/if}
      {:else if !add}
        {#if edited}
          <Link
            iconF7="checkmark_alt"
            on:click="{() => {
              showLoadingDialog(async () => {
                if (
                  greeting.media &&
                  originGreeting.media !== greeting.media &&
                  !isEmpty(selectedFileList)
                ) {
                  const url = await fileUpload({
                    file: selectedFileList[0],
                    deskId: $selectedDesk._id,
                    emitProgress: true,
                    showDefaultOverlayProgress: true,
                  });
                  greeting.media = url;
                  greeting.mime = selectedFileList[0].type;
                }
                await remoteAutoReplyUpdate(
                  greeting._id,
                  greeting,
                  // Only forward if subscribed channels had changed.
                  subscriberChanged ? $subscribedChannel : undefined
                );
                mainViewNavigateBack();
              }, 'Saving');
            }}"
          />
        {:else}
          <Link
            iconF7="trash"
            on:click="{() => {
              deleteAutoReply(greeting.name, greeting.type, greeting._id);
            }}"
          />
        {/if}
      {/if}
    </NavRight>
  </Navbar>

  <div class="h-full overflow-auto">
    <List inlineLabels noHairlines>
      <ListItemLink
        title="Channels"
        class="ucc-select"
        after="{$subscribedChannel.length || 'None'}"
        on:click="{() => {
          // Check whether channel is subscribed and if that channel already have other auto reply subscribed,
          // make sure their timeframe don't overlap by disabling channels that has overlapping auto reply.
          const itemList = $selectedDeskChannelList
            .filter((channel) => channel.platform !== 'ucc' && channel.platform !== 'voice')
            .map((channel) => {
              // Subscribed auto reply timeframe collide with current auto reply timeframe.
              let subscribedToOtherRecurringOverlappingAr = false;
              let subscribedToOtherOverlappingAr = false;

              $selectedDeskAutoReplyList.forEach((ar) => {
                // Exclude self so it can toggle self subscribe.
                if (ar._id !== greeting._id && channel.autoReply.greeting.includes(ar._id)) {
                  if (greeting.recurring && ar.recurring) {
                    if (
                      recurringIntervalOverlap(
                        greeting.start,
                        greeting.end,
                        ar.start,
                        ar.end,
                        $selectedDesk.timezone
                      )
                    ) {
                      subscribedToOtherRecurringOverlappingAr = true;
                    }
                  }
                  // Check has overlapping timeframe.
                  // https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
                  else if (
                    greeting.start.at &&
                    greeting.end.at &&
                    ar.start.at &&
                    ar.end.at &&
                    new Date(greeting.start.at) <= new Date(ar.end.at) &&
                    new Date(ar.start.at) <= new Date(greeting.end.at)
                  ) {
                    subscribedToOtherOverlappingAr = true;
                  }
                }
              });

              let disabledTooltip = '';
              if (channel.autoReply.type !== 'greeting') {
                disabledTooltip = 'Channel is set to use bot';
              } else if (subscribedToOtherRecurringOverlappingAr) {
                disabledTooltip = 'Timeframe overlap with other subscribed recurring greeting';
              } else if (subscribedToOtherOverlappingAr) {
                disabledTooltip = 'Timeframe overlap with other subscribed one time greeting';
              }

              return {
                title: channelGetDisplayName(channel),
                subtitle: `Status: ${
                  channelStatusTextMarkup(channel.status, channel.mergeProgress).markup
                }`,
                text: `Route: ${channelGetDisplayRoute(channel)}`,
                image: channel.profilePic || getPlatformThumbnail({ platform: channel.platform }),
                data: channel,
                value: channel._id,
                disabledTooltip,
                disabled: !isEmpty(disabledTooltip),
                disabledOnClick: (item) => {
                  showDialog(disabledTooltip);
                },
              };
            });
          mainViewNavigate('select', {
            title: 'Channel',
            itemList,
            value: subscribedChannel,
            initialValue: $subscribedChannel,
            rounded: false,
            media: true,
            multiple: true,
            showSearchBtn: true,
            countSelected: true,
          });
        }}"
      />
      <ListItem
        title="Mode"
        smartSelect
        smartSelectParams="{{ pageBackLinkText: '', closeOnSelect: true }}"
      >
        <!-- Must use onchange, cannot use onblur as it doesn't trigger while using in tandem with f7 -->
        <!-- svelte-ignore a11y-no-onchange -->
        <select
          name="mode"
          bind:value="{mode}"
          on:change="{() => {
            if (mode === 'recurring') {
              greeting.recurring = true;
            } else {
              greeting.recurring = false;
            }
          }}"
        >
          <option value="recurring">Recurring</option>
          <option value="single">One time</option>
        </select>
      </ListItem>
      <li class="item-content item-input inline-label input-title-color">
        <div class="item-inner">
          <div class="item-title item-label">Name</div>
          <div class="item-input-wrap">
            <input
              type="text"
              placeholder="Greetings Name (Required)"
              required
              validate
              bind:value="{greeting.name}"
            />
          </div>
        </div>
      </li>
      {#if greeting.recurring}
        <TimePicker
          title="Start"
          placeholder="Weekday Time"
          bind:value="{greeting.start}"
          setTitleColor
        />
        <TimePicker
          title="End"
          placeholder="Weekday Time"
          bind:value="{greeting.end}"
          setTitleColor
          end
        />
      {:else}
        <ListInput
          label="Start"
          type="datepicker"
          placeholder="Date Time"
          readonly
          clearButton
          inlineLabel
          value="{initialStartDateTime}"
          class="input-title-color"
          calendarParams="{{
            dateFormat: 'd M yyyy, HH::mm',
            timePicker: true,
            closeOnSelect: true,
          }}"
          on:calendarChange="{(e) => {
            greeting.start = { at: new Date(e.detail[0][0]) };
          }}"
        />
        <ListInput
          label="End"
          type="datepicker"
          placeholder="Date Time"
          readonly
          clearButton
          inlineLabel
          value="{initialEndDateTime}"
          class="input-title-color"
          calendarParams="{{
            dateFormat: 'd M yyyy, HH::mm',
            timePicker: true,
            closeOnSelect: true,
          }}"
          on:calendarChange="{(e) => {
            greeting.end = { at: new Date(e.detail[0][0]) };
          }}"
        />
      {/if}
    </List>
    <BlockTitle>Content</BlockTitle>
    <List noHairlines>
      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-title item-label">Text</div>
          <div class="item-input-wrap">
            <textarea
              placeholder="Text"
              bind:value="{greeting.text}"
              on:paste="{(e) => {
                addSelectedFiles(e.clipboardData.files);
              }}"></textarea>
          </div>
        </div>
      </li>
      {#if greeting.media && greeting.media !== 'edited'}
        <li class="item-content">
          <div class="item-inner">
            <div class="item-input-wrap">
              <Chip
                text="Click here to view uploaded file"
                class="cursor-pointer mb-3"
                deleteable
                on:click="{() => {
                  openExternalUrl(greeting.media);
                }}"
                onDelete="{() => {
                  greeting.media = '';
                }}"
              />
            </div>
          </div>
        </li>
      {/if}
    </List>
    <AttachmentUploadPreview bind:addSelectedFiles bind:selectedFileList limit="{1}" />
    <Block strong>
      <p>
        Note: Attachment may be sent as a separate message if captioned messages is not supported.
      </p>
    </Block>
  </div>
</Page>
