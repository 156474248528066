<script>
  import { Page } from 'framework7-svelte';
  import { upperFirst } from 'lodash-es';
  import SortSearchList from '../components/SortSearchList.svelte';
  import { selectedDeskAutoReplyList, mainViewNavigate } from '../js/store';
</script>

<Page>
  <SortSearchList
    title="Bots"
    showCounter
    itemList="{$selectedDeskAutoReplyList
      .filter((ar) => ar.type === 'bot')
      .map((ar) => {
        return {
          title: ar.name,
          subtitle: upperFirst(ar.provider),
          data: ar,
        };
      })}"
    on:click="{(e) => {
      mainViewNavigate('detail', { add: false, bot: e.detail });
    }}"
    addUrl="add"
    addProps="{{ add: true }}"
    showSearchBtn
    showAddBtn
    rounded
  />
</Page>
