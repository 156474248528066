<script>
  import {
    Page,
    Navbar,
    List,
    ListItem,
    NavRight,
    Link,
    AccordionContent,
  } from 'framework7-svelte';
  import { writable } from 'svelte/store';
  import { cloneDeep, isEmpty, uniqBy, isEqual } from 'lodash-es';
  import { onMount } from 'svelte';
  import ListItemLink from '../components/ListItemLink.svelte';
  import ListItemToggle from '../components/ListItemToggle.svelte';
  import {
    selectedDesk,
    remoteDeskSettingUpdate,
    showLoadingDialog,
    mainViewNavigate,
    selectedDeskUserInfo,
    mainView,
    mainViewNavigateRoot,
    remoteDeleteDesk,
    selectedDeskChannelListIncludeDeactivated,
  } from '../js/store';
  import { channelGetDisplayRoute, getPlatformThumbnail } from '../js/util';
  import { tzVerbose } from '../js/timezone';

  $: deskClone = cloneDeep($selectedDesk);

  $: originDeskChat = deskClone && deskClone.deskChat;
  $: originFlash = deskClone && deskClone.flash;
  $: originAutoReply = deskClone && deskClone.autoReply;

  // Load selected timezone if available, else use local timezone.
  // https://github.com/iamkun/dayjs/issues/695
  $: originTimezone =
    deskClone.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase();

  $: originOpenCaseOnRecv =
    deskClone &&
    deskClone.case &&
    deskClone.case.open &&
    deskClone.case.open.onReceive &&
    deskClone.case.open.onReceive.active;
  $: originOpenCaseOnSend =
    deskClone &&
    deskClone.case &&
    deskClone.case.open &&
    deskClone.case.open.onSend &&
    deskClone.case.open.onSend.active;
  $: openCaseOnRecv = originOpenCaseOnRecv;
  $: openCaseOnSend = originOpenCaseOnSend;

  $: originStopAfterHumanReply =
    deskClone &&
    deskClone.recvForward &&
    deskClone.recvForward.humanReplyStop &&
    deskClone.recvForward.humanReplyStop.active;
  $: originResumeAfterCaseClose =
    deskClone &&
    deskClone.recvForward &&
    deskClone.recvForward.caseCloseStart &&
    deskClone.recvForward.caseCloseStart.active;
  $: stopAfterHumanReply = originStopAfterHumanReply;
  $: resumeAfterCaseClose = originResumeAfterCaseClose;

  $: deskChat = originDeskChat;
  $: flash = originFlash;
  $: autoReply = originAutoReply;
  $: timezone = originTimezone;

  $: edited =
    deskChat !== originDeskChat ||
    flash !== originFlash ||
    autoReply !== originAutoReply ||
    timezone !== originTimezone ||
    !isEqual($selectedDesk.anonChannel, $anonymizeStore) ||
    openCaseOnRecv !== originOpenCaseOnRecv ||
    openCaseOnSend !== originOpenCaseOnSend ||
    stopAfterHumanReply !== originStopAfterHumanReply ||
    resumeAfterCaseClose !== originResumeAfterCaseClose;

  $: isDeskAdmin = $selectedDeskUserInfo.role === 'admin';

  const timezoneStore = writable('');
  const anonymizeStore = writable([]);
  // Set timezone to last timezone value on mount.
  onMount(() => {
    const unsubscribeFnList = [];
    unsubscribeFnList.push(
      timezoneStore.subscribe((tz) => {
        // Select will always return an array, thus extract the result into string.
        if (Array.isArray(tz) && !isEmpty(tz)) {
          timezone = tz[0];
        }
      })
    );
    $timezoneStore = timezone;
    $anonymizeStore = $selectedDesk.anonChannel || [];

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });
</script>

<Page>
  <Navbar title="Settings" backLink>
    <NavRight>
      {#if edited}
        <Link
          iconF7="checkmark_alt"
          on:click="{() => {
            showLoadingDialog(async () => {
              // Shallow merge them without modifying the source.
              const payload = {};
              if (deskChat !== originDeskChat) {
                payload.deskChat = deskChat;
              }
              if (flash !== originFlash) {
                payload.flash = flash;
              }
              if (autoReply !== originAutoReply) {
                payload.autoReply = autoReply;
              }
              if (timezone !== originTimezone) {
                payload.timezone = timezone;
              }
              if ($selectedDesk.anonChannel !== $anonymizeStore) {
                payload.anonChannel = $anonymizeStore;
              }
              if (
                openCaseOnRecv !== originOpenCaseOnRecv ||
                openCaseOnSend !== originOpenCaseOnSend
              ) {
                const original = cloneDeep($selectedDesk.case) || {};
                original.open = {
                  onReceive: { active: openCaseOnRecv || false },
                  onSend: { active: openCaseOnSend || false },
                };
                payload.case = original;
              }
              if (
                stopAfterHumanReply !== originStopAfterHumanReply ||
                resumeAfterCaseClose !== originResumeAfterCaseClose
              ) {
                const original = cloneDeep($selectedDesk.recvForward) || {};
                original.humanReplyStop = { active: stopAfterHumanReply || false };
                original.caseCloseStart = { active: resumeAfterCaseClose || false };
                payload.recvForward = original;
              }
              await remoteDeskSettingUpdate(deskClone._id, payload);
            }, 'Saving');
          }}"
        />
      {/if}
    </NavRight>
  </Navbar>

  <!-- Without this timezone will not be set correctly until selected desk become available, 
  thus wait for it to become available before drawing anything. -->
  {#if !isEmpty($selectedDesk)}
    <div class="h-full overflow-auto">
      <List>
        <ListItemToggle
          title="Desk Chat"
          disabled="{!isDeskAdmin}"
          bind:checked="{deskChat}"
          iconF7="bubble_left_bubble_right"
          media
        />
        <ListItemToggle
          title="Flash"
          disabled="{!isDeskAdmin}"
          bind:checked="{flash}"
          iconF7="bolt"
          media
        />
        <ListItemToggle
          title="Auto Reply"
          disabled="{!isDeskAdmin}"
          bind:checked="{autoReply}"
          iconF7="arrowshape_turn_up_left"
          media
        />
        <ListItemLink
          title="Timezone"
          class="ucc-select"
          disabled="{!isDeskAdmin}"
          after="{timezone ? tzVerbose.find((tz) => tz.value === timezone).desc : ''}"
          iconF7="globe"
          media
          on:click="{() => {
            mainViewNavigate('select', {
              title: 'Timezone',
              itemList: tzVerbose.map((tz) => {
                return { title: tz.desc, data: tz, value: tz.value };
              }),
              value: timezoneStore,
              multiple: false,
              centerText: true,
              showSearchBtn: true,
              returnOnSelect: true,
            });
          }}"
        />
        {#if isDeskAdmin}
          <ListItemLink
            title="Anonymize Numbers"
            class="ucc-select"
            iconF7="eye_slash"
            media
            on:click="{() => {
              // Only allow to filter channels that have valid route and remove duplicates.
              // Only whatsapp and voice channel support anonymize number.
              const itemList = !isEmpty($selectedDeskChannelListIncludeDeactivated)
                ? uniqBy(
                    $selectedDeskChannelListIncludeDeactivated
                      .filter(
                        (channel) =>
                          channel.route &&
                          (channel.platform === 'whatsapp' || channel.platform === 'voice')
                      )
                      .map((channel) => {
                        // Make sure channel is unique on the same platform, not cross platform.
                        channel.platformRoute = channel.platform + channel.route;
                        return channel;
                      }),
                    'platformRoute'
                  )
                : [];

              mainViewNavigate('select', {
                title: 'Anonymize Numbers',
                itemList: itemList.map((channel) => {
                  return {
                    title: channelGetDisplayRoute(channel),
                    subtitle: channelGetDisplayRoute(channel),
                    image:
                      channel.profilePic || getPlatformThumbnail({ platform: channel.platform }),
                    data: channel,
                    value: channel.route,
                  };
                }),
                value: anonymizeStore,
                multiple: true,
                showSearchBtn: true,
                returnOnSelect: false,
                emptyText: 'No anonymizable channel found',
                media: true,
                countSelected: true,
              });
            }}"
          />
          <ListItem accordionItem title="Advanced">
            <i slot="media" class="f7-icons text-icon-primary">wand_stars</i>
            <AccordionContent>
              <List>
                <ListItem title="Case Auto Open Criteria" class="select-none">
                  <i slot="media" class=""></i>
                </ListItem>
                <ListItemToggle
                  title="On receive chat"
                  disabled="{!isDeskAdmin}"
                  bind:checked="{openCaseOnRecv}"
                  media
                />
                <ListItemToggle
                  title="On send chat"
                  disabled="{!isDeskAdmin}"
                  bind:checked="{openCaseOnSend}"
                  media
                />
              </List>
              <List>
                <ListItem title="Receive Message Forwarding Rule" class="select-none">
                  <i slot="media" class=""></i>
                </ListItem>
                <ListItemToggle
                  title="Stop after human reply"
                  disabled="{!isDeskAdmin}"
                  bind:checked="{stopAfterHumanReply}"
                  media
                />
                <ListItemToggle
                  title="Start forward after case close"
                  disabled="{!isDeskAdmin}"
                  bind:checked="{resumeAfterCaseClose}"
                  media
                />
                {#if isDeskAdmin}
                  <li
                    class="item-link item-content"
                    on:click="{() => {
                      $mainView.app.dialog
                        .create({
                          title: '',
                          text: `Delete Desk (Cannot be undone)`,
                          closeByBackdropClick: true,
                          buttons: [
                            {
                              text: 'Cancel',
                              keyCodes: [27],
                            },
                            {
                              text: 'Delete',
                              bold: true,
                              color: 'red',
                              keyCodes: [13],
                              onClick: () => {
                                showLoadingDialog(async () => {
                                  await remoteDeleteDesk($selectedDesk._id);
                                  mainViewNavigateRoot();
                                }, 'Processing');
                              },
                            },
                          ],
                        })
                        .open();
                    }}"
                  >
                    <div class="item-media">
                      <i class="f7-icons text-red-primary">delete_right</i>
                    </div>
                    <div class="item-inner">
                      <div class="item-title" style="color: red; font-weight: 500;">
                        Delete Desk
                      </div>
                    </div>
                  </li>
                {/if}
              </List>
            </AccordionContent>
          </ListItem>
        {/if}
      </List>
    </div>
  {/if}
</Page>
