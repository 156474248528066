<script>
  import { Navbar, Chip } from 'framework7-svelte';
  import { isEmpty } from 'lodash-es';
  import SquareImage from './SquareImage.svelte';
  import BigIcon from './BigIcon.svelte';
  import {
    selectedContact,
    multiView,
    openSecondaryView,
    selectedDeskMemberList,
    hasInternetConnection,
    chatSearchTerm,
    storeUpdateIfDifferent,
    mainViewNavigateBack,
    selectedChatSearch,
    chatSearchResult,
    showDialog,
    showLoadingDialog,
    remoteCompositeSearch,
    selectedDesk,
    selectedDeskContactFilter,
    selectedDeskChannelList,
  } from '../js/store';
  import {
    getPlatformThumbnail,
    contactRoutePrimaryName,
    contactRouteSecondaryName,
    contactPickProfilePic,
    splitTagIntoMemberTagAndNormalTagWithSelectUiItem,
    isMobileBrowser,
  } from '../js/util';
  import { chatSearchSize, uccPlaceholderLogo } from '../js/config';

  // Can hide it but ugly side it won't show any placeholder color on desktop dual panel, thus make its content empty instead.
  // Cannot dynamically recreate navbar else its ordering with the searchbar will swap for unknown reason.
  $: title = contactRoutePrimaryName($selectedContact) || '';
  $: subtitle = contactRouteSecondaryName($selectedContact) || '';

  $: isVirginContact = $selectedContact._id === 'virgin';

  let memberTagList = [];
  let normalTagList = [];

  $: if ($selectedContact && !isEmpty($selectedContact.tag)) {
    const result = splitTagIntoMemberTagAndNormalTagWithSelectUiItem(
      $selectedContact.tag,
      $selectedDeskMemberList
    );
    memberTagList = result.selectItemMemberList;
    normalTagList = result.selectItemTagList;
  } else {
    memberTagList = [];
    normalTagList = [];
  }

  const chatSearchFetchStatus = { term: '', completed: false };
</script>

<Navbar backLink="{!$multiView}" backLinkShowText="false">
  <span slot="left" class="flex flex-row">
    <span
      class="flex h-full self-center ml-2 relative cursor-pointer"
      on:click="{() => {
        if (!isVirginContact) {
          openSecondaryView('/contact', {}, !isMobileBrowser);
        }
      }}"
    >
      {#if !isEmpty($selectedContact)}
        <span class="ripple">
          <SquareImage
            rounded
            src="{contactPickProfilePic($selectedContact)}"
            srcFallback="{uccPlaceholderLogo}"
            bottomRight="{{
              src: getPlatformThumbnail({
                platform: $selectedContact.platform,
                route: $selectedContact.channel,
                channelList: $selectedDeskChannelList,
              }),
              border: true,
            }}"
          />
        </span>
      {/if}
    </span>
    <div
      class="flex flex-col h-full self-center pl-4 {isEmpty(memberTagList)
        ? 'leading-snug'
        : 'leading-none'}"
    >
      {#if title}
        <div class="ios-select-none">{title}</div>
      {/if}

      {#if subtitle}
        <div class="subtitle ios-select-none">{subtitle}</div>
      {/if}

      <span class="space-x-1">
        {#each memberTagList as tag}
          <Chip class="h-5 m-0" text="{tag.name}">
            <img
              slot="media"
              src="{tag.image}"
              alt="thumbnail"
              class="ml-2"
              style="width: 1.25rem; height: 1.25rem;"
              on:contextmenu="{(e) => {
                e.preventDefault();
              }}"
            />
          </Chip>
        {/each}
        {#each normalTagList as tag}
          <Chip class="h-4" text="{tag.value}" />
        {/each}
      </span>
    </div>
  </span>
  <span slot="right" class="mr-3">
    {#if !isEmpty($selectedContact) && !isVirginContact}
      {#if !isEmpty($selectedChatSearch) && !isEmpty($chatSearchResult)}
        <span
          class="mr-5 relative cursor-pointer ripple"
          on:click="{() => {
            // Jump up to previous search match, if reached top of the search result, tell user had reached top.
            // Do not wrap around. Reached top of search result = reached oldest matched search result in relation to timestamp.
            const currentIndex = $chatSearchResult.findIndex(
              (c) => c.jumpToChat._id === $selectedChatSearch._id
            );
            const target = $chatSearchResult[currentIndex + 1];
            if (!isEmpty(target)) {
              storeUpdateIfDifferent(selectedChatSearch, target.jumpToChat);
            }
            // All search result are downloaded.
            else if (
              $chatSearchResult.length < chatSearchSize ||
              (chatSearchFetchStatus.term === $chatSearchTerm && chatSearchFetchStatus.completed)
            ) {
              showDialog('Reached Top');
            } else {
              chatSearchFetchStatus.term = $chatSearchTerm;
              showLoadingDialog(async () => {
                const result = await remoteCompositeSearch({
                  deskId: $selectedDesk._id,
                  field: {
                    message: {
                      term: $chatSearchTerm,
                      size: $chatSearchResult.length + chatSearchSize,
                      contactId: $selectedContact._id,
                    },
                  },
                  filter: $selectedDeskContactFilter,
                });
                $chatSearchResult = result.message;
                if (result.message.length % chatSearchSize) {
                  chatSearchFetchStatus.completed = true;
                } else {
                  chatSearchFetchStatus.completed = false;
                }

                // Jump to next search result using new data.
                const currentIndexInner = $chatSearchResult.findIndex(
                  (c) => c.jumpToChat._id === $selectedChatSearch._id
                );
                const targetInner = $chatSearchResult[currentIndexInner + 1];
                if (!isEmpty(targetInner)) {
                  storeUpdateIfDifferent(selectedChatSearch, targetInner.jumpToChat);
                }
              }, 'Fetching');
            }
          }}"
        >
          <BigIcon f7 icon="chevron_up" />
        </span>
        <span
          class="mr-5 relative cursor-pointer ripple"
          on:click="{() => {
            // Jump down to next search match, if reached bottom of the search result, tell user had reached bottom.
            // Do not wrap around. Reached bottom of search result = reached earliest matched search result in relation to timestamp.
            const currentIndex = $chatSearchResult.findIndex(
              (c) => c.jumpToChat._id === $selectedChatSearch._id
            );
            const target = $chatSearchResult[currentIndex - 1];
            if (currentIndex > 0 && !isEmpty(target)) {
              storeUpdateIfDifferent(selectedChatSearch, target.jumpToChat);
            } else {
              showDialog('Reached Bottom');
            }
          }}"
        >
          <BigIcon f7 icon="chevron_down" />
        </span>
      {/if}
      <span
        class="relative cursor-pointer ripple"
        on:click="{() => {
          openSecondaryView(
            '/chat-search',
            {
              type: 'message',
              searchTermStore: chatSearchTerm,
              title: 'Message Search',
              showCounter: true,
              closeSecondaryViewOnBack: true,
              contactId: $selectedContact._id,
              searchResultStore: chatSearchResult,
              onClick: (contact) => {
                storeUpdateIfDifferent(selectedChatSearch, contact.jumpToChat);
                if (isMobileBrowser) {
                  mainViewNavigateBack();
                }
              },
            },
            !isMobileBrowser
          );
        }}"
      >
        <BigIcon f7 icon="search" />
      </span>
    {/if}
  </span>
  {#if !$hasInternetConnection}
    <img
      class="absolute w-3 h-3 top-1 right-1"
      src="static/images/broken-chain.svg"
      alt="no-internet"
      on:contextmenu="{(e) => {
        e.preventDefault();
      }}"
    />
  {/if}
</Navbar>
