// https://svelte.dev/repl/f34b6159667247e6b6abb5142b276483?version=3.6.3
// Support long press action for svelte
export function longpress(node, threshold = 250, mobile = true) {
  const handle_mousedown = () => {
    let start = Date.now();

    const timeout = setTimeout(() => {
      node.dispatchEvent(new CustomEvent('longpress'));
    }, threshold);

    const cancel = () => {
      clearTimeout(timeout);
      if (mobile) {
        node.removeEventListener('touchmove', cancel);
        node.removeEventListener('touchend', cancel);
      } else {
        node.removeEventListener('mousemove', cancel);
        node.removeEventListener('mouseup', cancel);
      }
    };

    if (mobile) {
      node.addEventListener('touchmove', cancel);
      node.addEventListener('touchend', cancel);
    } else {
      node.addEventListener('mousemove', cancel);
      node.addEventListener('mouseup', cancel);
    }
  };

  if (mobile) {
    node.addEventListener('touchstart', handle_mousedown);
  } else {
    node.addEventListener('mousedown', handle_mousedown);
  }

  return {
    destroy() {
      if (mobile) {
        node.removeEventListener('touchstart', handle_mousedown);
      } else {
        node.removeEventListener('mousedown', handle_mousedown);
      }
    },
  };
}
