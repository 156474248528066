<script>
  import { Page } from 'framework7-svelte';
  import SortSearchList from '../components/SortSearchList.svelte';
  import { mainViewNavigate, selectedDeskAutoReplyList } from '../js/store';
  import { generateGreetingSecondaryText } from '../js/util';
</script>

<Page>
  <SortSearchList
    title="Greetings"
    showCounter
    itemList="{$selectedDeskAutoReplyList
      .filter((ar) => ar.type === 'greeting')
      .map((ar) => {
        return {
          title: ar.name,
          subtitle: `Text: ${ar.text}` || '[attachment]',
          text: generateGreetingSecondaryText(ar),
          data: ar,
        };
      })}"
    on:click="{(e) => {
      mainViewNavigate('detail', { add: false, greeting: e.detail });
    }}"
    addUrl="add"
    addProps="{{ add: true }}"
    showSearchBtn
    showAddBtn
    rounded
  />
</Page>
