<script>
  import { Page } from 'framework7-svelte';
  import SortSearchList from '../components/SortSearchList.svelte';
  import {
    deskInfoList,
    switchDesk,
    remoteDeskSortUpdate,
    showLoadingDialog,
    userInfo,
    mainViewNavigateRoot,
  } from '../js/store';
  import { createDesk } from '../js/desk';
</script>

<Page>
  <SortSearchList
    title="Desks"
    showCounter
    itemList="{$deskInfoList.map((desk) => {
      return {
        title: desk.name,
        subtitle:
          $userInfo &&
          $userInfo.caseCounter &&
          $userInfo.caseCounter[desk._id] &&
          $userInfo.caseCounter[desk._id].open
            ? `Open: ${$userInfo.caseCounter[desk._id].open}`
            : '',
        image: desk.profilePic,
        data: desk,
      };
    })}"
    media
    on:click="{(e) => {
      switchDesk({ deskId: e.detail._id, clearSearchTerm: true }).then(() => {
        mainViewNavigateRoot();
      });
    }}"
    on:save="{(e) => {
      showLoadingDialog(async () => {
        // Extract list of new preferred deskId ordering.
        const deskList = e.detail.map((d) => d.data._id) || [];
        await remoteDeskSortUpdate(deskList);
      }, 'Saving');
    }}"
    on:add="{createDesk}"
    showAddBtn
    showSortBtn
    showSearchBtn
  />
</Page>
