<script>
  // Main Application entry point, do all initialization and on boot one time setup here.
  import { onMount } from 'svelte';
  import { Workbox, messageSW } from 'workbox-window';
  import { getDevice, getSupport } from 'framework7';
  import { f7, f7ready, App, View, Views, Preloader } from 'framework7-svelte';
  import cordovaApp from '../js/cordova-app.js';
  import routes from '../js/routes';
  import {
    loggedIn,
    fullyLoaded,
    f7Loaded,
    mainView,
    secondaryView,
    sharedF7,
    showSecondaryView,
    fullScreen,
    multiView,
    colorTheme,
    darkMode,
    showDialog,
    showLoadingDialog,
    remoteVerifyDeskToken,
    storeUpdateIfDifferent,
    mainViewNavigate,
    init as storeInit,
    mainViewNavigateRoot,
    deviceUuid,
    fingerPrintRequired,
    fingerPrintSupported,
    fingerPrintVerified,
    applyLoginRedirectIfAvailable,
  } from '../js/store';
  import {
    isLocalHost,
    extractPathFromHref,
    isNativeAndroid,
    isNativeIos,
    waitUntilCordovaDeviceReady,
    pushNotificationHandlerInit,
  } from '../js/util';
  import {
    mobileNavigateDelayMs,
    bigScreenLeftTabWidth,
    bigScreenMaxWidth,
    bigScreenMaxHeight,
    enableBigScreenBorder,
  } from '../js/config';
  import { isEmpty } from 'lodash-es';

  // Provided by webpack define plugin, make build info accessible within app.
  // https://stackoverflow.com/questions/24663175/how-can-i-inject-a-build-number-with-webpack
  window.buildInfo =
    typeof __UCC_BUILD_INFO__ === 'string' ? JSON.parse(__UCC_BUILD_INFO__) : __UCC_BUILD_INFO__;

  const device = getDevice();
  const support = getSupport();

  // Load cordova bridge depending on platform.
  // Must load it on our own instead of relying on cordova default load as we are loading from remote external url,
  // which cordova don't support loading bridge for, they only supports loading from local (files bundled within app).
  if (isNativeAndroid) {
    let script = document.createElement('script');
    script.onload = () => {
      console.log('Loaded cordova.js for native android');
      cordovaReadySetup();
    };
    script.src = 'static/cordova/android/cordova.js';
    document.head.appendChild(script);
  } else if (isNativeIos) {
    let script = document.createElement('script');
    script.onload = () => {
      console.log('Loaded cordova.js for native ios');
      cordovaReadySetup();
    };
    script.src = 'static/cordova/ios/cordova.js';
    document.head.appendChild(script);
  }

  let cordovaReady = false;
  async function cordovaReadySetup() {
    await waitUntilCordovaDeviceReady();

    window.StatusBar.show();
    window.device.getInfo((info) => {
      storeUpdateIfDifferent(deviceUuid, info.uuid);
      console.log('Install UUID', info.uuid);
    });

    window.Fingerprint.isAvailable(
      () => {
        storeUpdateIfDifferent(fingerPrintSupported, true);
        console.log('FingerPrint Supported');
      },
      (err) => {
        storeUpdateIfDifferent(fingerPrintSupported, false);
        console.log('FingerPrint Init Error', err);
      }
    );

    window.universalLinks.subscribe('deeplink', (e) => {
      const url = new URL(e.url);
      console.log('Deep link', e, url);

      // Navigate to deep link.
      window.location.hash = url.hash;
      applyLoginRedirectIfAvailable(url.search);
      mainViewNavigate(url.hash.replace('#!', ''), true);

      // Close any in app browser if available.
      try {
        window.cordova.plugins.browsertabs.close();
      } catch (err) {
        console.log(err);
      }
    });

    pushNotificationHandlerInit();

    window.navigator.splashscreen.hide();
    cordovaReady = true;
  }

  $: if (cordovaReady) {
    if ($f7Loaded && $darkMode && $loggedIn) {
      window.StatusBar.backgroundColorByHexString('#000000');
      window.StatusBar.styleLightContent();
      window.NavigationBar.backgroundColorByHexString('#000000', true);
      console.log('Set to dark');
    } else {
      window.StatusBar.backgroundColorByHexString('#FFFFFF');
      window.StatusBar.styleDefault();
      window.NavigationBar.backgroundColorByHexString('#FFFFFF', false);
      console.log('Set to light');
    }
  }

  // Init f7 cordova script.
  $: if (cordovaReady && !isEmpty($sharedF7)) {
    cordovaApp.init($sharedF7);
  }

  // Load user's preferrer theme.
  let platformTheme = window.localStorage.getItem('platform_theme');
  if (platformTheme !== 'ios' && platformTheme !== 'md' && platformTheme !== 'aurora') {
    platformTheme = 'auto';
  }

  let windowInnerWidth;

  // Toggle light / dark mode reactively by adding / removing class from root html tag.
  // Default to dark mode.
  $: if ($colorTheme === 'light') {
    document.documentElement.classList.remove('theme-dark');
    storeUpdateIfDifferent(darkMode, false);
  } else {
    document.documentElement.classList.add('theme-dark');
    storeUpdateIfDifferent(darkMode, true);
  }

  $: if (
    $f7Loaded &&
    cordovaReady &&
    $fingerPrintSupported &&
    $fingerPrintRequired &&
    !$fingerPrintVerified
  ) {
    console.log('Request fingerprint');
    function promptFingerPrint() {
      window.Fingerprint.show(
        {
          title: 'Verify Identity',
          description: 'To Access UCC',
          confirmationRequired: true,
        },
        () => {
          console.log('Fingerprint Verified');
          storeUpdateIfDifferent(fingerPrintVerified, true);
        },
        (err) => {
          console.log('FingerPrint Verification Error', err);
          promptFingerPrint();
        }
      );
    }
    promptFingerPrint();
  }

  // If user is at chat / contact / miniApp page,
  // redirect them back to home page so they don't get stuck in empty chat / contact / miniApp page.
  if (
    window.location.href.includes('#!/contact') ||
    window.location.href.includes('#!/chat') ||
    window.location.href.includes('#!/miniapp-close') ||
    window.location.href.includes('#!/miniapp-memo') ||
    window.location.href.includes('#!/miniapp-flash') ||
    window.location.href.includes('#!/desk/member-detail') ||
    window.location.href.includes('#!/desk/member-sort/detail') ||
    window.location.href.includes('#!/desk/auto-reply/greeting-sort/detail') ||
    window.location.href.includes('#!/channel') ||
    window.location.href.includes('#!/channel/edit') ||
    window.location.href.includes('#!/filter') ||
    window.location.href.includes('#!/detail') ||
    window.location.href.includes('#!/desk/broadcast') ||
    window.location.href.includes('#!/desk/setting') ||
    window.location.href.includes('select') ||
    window.location.href.includes('/image-editor') ||
    window.location.href.includes('/chat-upload-preview') ||
    window.location.href.includes('/flash-popup') ||
    window.location.href.includes('/bot-test-send') ||
    // Home page /#! without any path specified, navigate to root.
    !extractPathFromHref(window.location.href)
  ) {
    // https://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-url-with-javascript-without-page-r
    window.history.replaceState(null, null, ' ');
  }

  // Catch all unhandled error and log full trace.
  window.onerror = (msg, url, lineNo, columnNo, error) => {
    console.trace(msg, url, lineNo, columnNo, error);
    return false;
  };

  // Framework7 Parameters
  const f7params = {
    id: 'chat.ucc.app', // App bundle ID
    name: 'UCC', // App name
    theme: platformTheme, // Automatic theme detection
    autoDarkTheme: false,

    // App routes
    routes: routes,

    // Fix keep alive on 2 page depth navigation root page not perserved.
    // https://forum.framework7.io/t/on-back-page-init-firing/7559
    view: {
      stackPages: true,
      // Disable for ios theme so its navbar don't overlap.
      iosDynamicNavbar: false,
    },

    // Register service worker
    serviceWorker: device.cordova
      ? {}
      : {
          path: '/service-worker.js',
        },
    // Input settings
    input: {
      scrollIntoViewOnFocus: device.cordova && !device.electron,
      scrollIntoViewCentered: device.cordova && !device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
  };
  onMount(() => {
    const unsubscribeFnList = [];
    console.log('App mounted');
    f7ready(() => {
      // Call F7 APIs here

      window.f7 = f7;
      storeUpdateIfDifferent(sharedF7, f7);
      storeUpdateIfDifferent(f7Loaded, true);

      // Update service worker if available.
      // https://developers.google.com/web/tools/workbox/guides/advanced-recipes
      if ('serviceWorker' in navigator) {
        const wb = new Workbox('/service-worker.js');
        let registration;

        const showSkipWaitingPrompt = (event) => {
          // `event.wasWaitingBeforeRegister` will be false if this is
          // the first time the updated service worker is waiting.
          // When `event.wasWaitingBeforeRegister` is true, a previously
          // updated service worker is still waiting.
          // You may want to customize the UI prompt accordingly.

          // Assuming the user accepted the update, set up a listener
          // that will reload the page as soon as the previously waiting
          // service worker has taken control.
          wb.addEventListener('controlling', (event) => {
            console.log('Service worker updated');
            // Clear local db to prevent any fault caused by new db schema.
            if (!isLocalHost() && window.db) {
              window.db.delete().then(() => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          });

          if (registration && registration.waiting) {
            // Send a message to the waiting service worker,
            // instructing it to activate.
            // Note: for this to work, you have to add a message
            // listener in your service worker. See below.
            console.log('Service worker update available');
            showDialog(`Updating to new version`);
            messageSW(registration.waiting, { type: 'SKIP_WAITING' });
          }
        };

        // Add an event listener to detect when the registered
        // service worker has installed but is waiting to activate.
        wb.addEventListener('waiting', showSkipWaitingPrompt);
        wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

        wb.register().then((r) => (registration = r));
      }

      // Check is app version updated, if so notify via toast
      const lastBuildVer = window.localStorage.getItem('lastBuildVer');
      if (lastBuildVer && lastBuildVer !== 'null' && window.buildInfo.ver !== lastBuildVer) {
        showDialog(`Updated to version ${window.buildInfo.ver}`);
      }
      window.localStorage.setItem('lastBuildVer', window.buildInfo.ver);

      // Initiate initial store initialization and establish socket to backend.
      storeInit();
    });

    unsubscribeFnList.push(
      fullyLoaded.subscribe(() => {
        refreshWindowLayout();
      })
    );
    unsubscribeFnList.push(
      loggedIn.subscribe(() => {
        refreshWindowLayout();
      })
    );

    return () => {
      unsubscribeFnList.forEach((fn) => {
        fn();
      });
    };
  });

  function refreshWindowLayout() {
    if ($fullyLoaded && $loggedIn) {
      // Verify query param specified desk invitation token
      const urlParams = new URLSearchParams(window.location.search);
      const deskInviteToken = urlParams.get('deskInviteToken');
      if (deskInviteToken) {
        // Mark token as prompted so it don't get toasted on every boot.
        let deskInviteTokenList = window.localStorage.getItem('deskInviteTokenList')
          ? JSON.parse(window.localStorage.getItem('deskInviteTokenList'))
          : [];
        if (!Array.isArray(deskInviteTokenList)) {
          deskInviteTokenList = [];
        }
        if (!deskInviteTokenList.includes(deskInviteToken)) {
          showLoadingDialog(async () => {
            await remoteVerifyDeskToken(deskInviteToken);
          }, 'Verifying Invitation Token')
            .then(() => {
              // Verification success, navigate to invitation page to show the invitation immediately.
              mainViewNavigate('/invitation');
            })
            .finally(() => {
              deskInviteTokenList.push(deskInviteToken);
              window.localStorage.setItem(
                'deskInviteTokenList',
                JSON.stringify(deskInviteTokenList)
              );
            });
        }
      }
    }

    // Show login screen or main app page depending on whether user is currently logged in.
    if ($fullyLoaded && $mainView) {
      // LoggedIn but root history is not root page, redirect him to his destined location based on his url.
      // If root page is correct ("/"), then no additional navigation needed, default router will bring him to where to go.
      if ($loggedIn) {
        storeUpdateIfDifferent(fullScreen, false);

        // User just logged in, redirect him to root page.
        if ($mainView.router.history.includes('/login')) {
          console.log('Come from login navigate to root');
          mainViewNavigateRoot();
        } else if ($mainView.router.history[0].split('?')[0] !== '/') {
          // Broken history, redirect to destined url.
          console.log('Broken history redirect to root');
          mainViewNavigateRoot();
        }
      }
      // User not logged in, redirect to login page
      else {
        console.log('Navigate to login page');
        storeUpdateIfDifferent(fullScreen, true);
        mainViewNavigate('/login', {}, false, {
          animate: true,
          transition: 'f7-dive',
        });
      }
    }
  }

  // Bind mainView to store to allow page navigation programmatically.
  // Bind to window to simplify route testing during development.
  function bindMainViewToStore(event) {
    $mainView = event.detail[0];
    window.mainView = $mainView;
  }
  function bindSecondaryViewToStore(event) {
    $secondaryView = event.detail[0];
    window.secondaryView = $secondaryView;
  }
</script>

<style lang="scss">
  // https://developers.google.com/web/updates/2017/11/overscroll-behavior
  // Disables pull-to-refresh and overscroll glow effect. Still keeps swipe navigations.
  :global(html) {
    overscroll-behavior-y: none;
  }

  // Group all modified f7 custom style here.
  :global(:root) {
    --f7-panel-width: 25rem;

    // Standardize font size.
    --f7-navbar-title-font-size: 16px;
    --f7-navbar-subtitle-font-size: 15px;
    --f7-navbar-font-size: 16px;
    --f7-list-item-title-font-size: 16px;
    --f7-list-font-size: 15px;
    --f7-list-item-after-font-size: 15px;

    --f7-searchbar-input-font-size: 15px; // text-base

    // Remove list top and bottom extra whitespace.
    --f7-list-margin-vertical: 0;

    --f7-list-media-item-title-font-weight: 500;

    --f7-badge-size: 15px;

    --f7-list-media-item-padding-vertical: 12px;
  }
  :global(:root.theme-dark) {
    --f7-list-bg-color: transparent;
  }
  :global(.ios) {
    // Text always align left on nav bar.
    --f7-navbar-height: 3.75rem;
    --f7-navbar-title-margin-right: auto;

    // Slimmer toggle btn
    --f7-toggle-width: 52px;
    --f7-toggle-height: 26px;
  }

  :global(.select-none) {
    -webkit-touch-callout: none;
  }
  :global(.page-content div, .actions-backdrop, .popover-backdrop) {
    @apply select-none;
  }

  :global(.ios .ios-select-none, .mobile-select-none) {
    @apply select-none;
  }

  :global(:root) {
    // Border color.
    --f7-list-button-border-color: rgba(0, 0, 0, 0.1);
    --f7-list-border-color: rgba(0, 0, 0, 0.1);
    --f7-list-item-border-color: rgba(0, 0, 0, 0.1);
    --f7-list-item-divider-border-color: rgba(0, 0, 0, 0.1);
  }

  :global(:root.theme-dark) {
    // Border color.
    --f7-list-button-border-color: rgba(255, 255, 255, 0.1);
    --f7-list-border-color: rgba(255, 255, 255, 0.1);
    --f7-list-item-border-color: rgba(255, 255, 255, 0.1);
    --f7-list-item-divider-border-color: rgba(255, 255, 255, 0.1);
  }

  // Fix ios and android popup dialog error msg weird default positioning
  :global(.ios .dialog-input-field .input-error-message) {
    margin-bottom: -10px;
    margin-top: 0;
  }
  :global(.md .dialog-input-field.input-with-error-message) {
    padding-bottom: 0;
  }

  // Fix ios smart select back btn without text wrong positioning.
  // Style is copied from 'icon-only' class that appears on all other ios navbar without text which positions correctly.
  :global(.ios .navbar .left a.link.back) {
    &:not(.icon-only) {
      width: 44px;
      margin: 0;
      justify-content: center;
    }
  }

  :global(.list li.ucc-select .item-link .item-after) {
    // https://github.com/framework7io/framework7/blob/78d29c14de9facb26a763a595e3d70f06e929f9e/src/core/components/smart-select/smart-select.less
    // Copied from f7 smart select css.`
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: block;
  }

  :global(.navbar .navbar-inner .title) {
    // Disable text select on f7 navbar title.
    user-select: none;
  }

  :global(.block-title) {
    // Disable text select on block title.
    user-select: none;
  }

  :global(.md) {
    // 8px to be consistent with ios theme.
    --f7-toolbar-inner-padding-right: 8px;
    --f7-toolbar-inner-padding-left: 8px;
  }
  :global(.aurora) {
    --f7-searchbar-bg-color: #f7fafc;
  }

  // Thin scrollbar on big screen (desktop) only, mobile browser use default platform specific scrollbar
  @media screen and (min-width: 800px) {
    :global(*) {
      // Firefox
      // https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
      scrollbar-width: thin;

      // All webkit browsers (chrome, safari)
      // https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
      // Light theme scrollbar, color same as firefox
      &::-webkit-scrollbar {
        width: var(--ucc-scrollbar-width);
      }
      &::-webkit-scrollbar-track {
        background: var(--ucc-scrollbar-track);
      }
      &::-webkit-scrollbar-thumb {
        background: var(--ucc-scrollbar-thumb);
      }
      &::-webkit-scrollbar-thumb:hover {
        background: var(--ucc-scrollbar-hover);
      }
    }
  }
  // Light theme scrollbar
  :global(:root) {
    --ucc-scrollbar-width: 7px;
    --ucc-scrollbar-track: #f0f0f0;
    --ucc-scrollbar-thumb: #cdcdcd;
    --ucc-scrollbar-hover: #a6a6a6;
  }
  // Dark theme scrollbar
  :global(:root.theme-dark) {
    --ucc-scrollbar-width: 7px;
    --ucc-scrollbar-track: transparent;
    --ucc-scrollbar-thumb: #6f6f6f;
    --ucc-scrollbar-hover: #5c5c5c;
  }

  // Make input title color the same as label color.
  :global(.input-title-color .item-title.item-label) {
    color: var(--f7-list-item-title-text-color);
    user-select: none;
  }
</style>

<!-- Show loading icon until page is fully loaded -->
{#if !$fullyLoaded}
  <div class="flex absolute items-center justify-center w-full h-full">
    <Preloader />
  </div>
{/if}

<svelte:window bind:innerWidth="{windowInnerWidth}" />

<!-- Main Framework7 App component where we pass Framework7 params -->
<App
  {...f7params}
  class="{enableBigScreenBorder && $multiView && !$fullScreen
    ? 'flex items-center justify-center'
    : ''}"
>
  <!-- initial page is specified in routes.js -->
  <!-- 
      https://forum.framework7.io/t/3-block-split-view/3854/2
      https://jsfiddle.net/mnahara/83Lr4hye/72/
      Split into 2 views, custom sized
      Do not make use of framework7 provided master-detail split view since we need
      to control both master (left) and detail (right) panel on our own, routed separately.
      Framework7 provided master-detail view cannot switch master and detail view independently, and can only switch detail view, master view cannot be changed. 
      2 view, 1 for contact page and another for chat page, display at once on load.
      Can optionally display as full screen by toggling a fullScreen flag for displaying login screen.
    -->
  <!-- Hardcode 800 instead of allowing dynamic screen size update as desktop version (>800px) 
    make use of both view concurrently to display data concurrently, which is impossible on smaller single view window.
    Size change will break those function since it will become uncertain whether should display first or secondary view when
    switched to smaller screen since both screen are equally important. -->
  {#if $multiView}
    <Views
      class="flex {enableBigScreenBorder && !$fullScreen ? 'flex-grow' : ''}"
      style="{enableBigScreenBorder && !$fullScreen
        ? `max-width: ${bigScreenMaxWidth}; max-height: ${bigScreenMaxHeight}`
        : ''}"
    >
      <!-- Fullscreen / Left side -->
      <View
        main
        tabs
        browserHistory
        class="safe-areas {$fullyLoaded ? '' : 'hidden'} {$fullScreen
          ? 'flex-grow'
          : `w-1/3 ${$darkMode ? 'border-r border-white border-opacity-20' : 'border-r'}`}"
        style="{$fullScreen
          ? ''
          : `min-width: ${bigScreenLeftTabWidth}; max-width: ${bigScreenLeftTabWidth};`}"
        url="/"
        on:viewInit="{bindMainViewToStore}"
      />
      <!-- Right side (primary, normally visible) -->
      <View
        tabs
        class="safe-areas {$fullyLoaded ? '' : 'hidden'} {$fullScreen
          ? 'hidden'
          : `${
              $showSecondaryView ? (windowInnerWidth < 1024 ? 'hidden' : 'flex-grow') : 'flex-grow'
            }`}"
        url="/chat"
      />
      <!-- Right side (secondary, normally hidden, if screen too small and this screen is open, override primary screen) -->
      <View
        tabs
        class="safe-areas {$fullyLoaded ? '' : 'hidden'} {$fullScreen
          ? 'hidden'
          : `${$showSecondaryView ? (windowInnerWidth < 1024 ? 'flex-grow' : 'w-1/3') : 'hidden'}`}"
        url="/contact"
        on:viewInit="{bindSecondaryViewToStore}"
      />
    </Views>
  {:else}
    <!-- Small mobile view only have at most 1 page, thus single view is enough -->
    <View
      main
      tabs
      browserHistory
      class="safe-areas {$fullyLoaded ? '' : 'hidden'}"
      url="/"
      mdPageLoadDelay="{mobileNavigateDelayMs}"
      iosPageLoadDelay="{mobileNavigateDelayMs}"
      on:viewInit="{bindMainViewToStore}"
    />
  {/if}
</App>
