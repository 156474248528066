import { onDestroy, onMount, tick } from 'svelte';
import EventEmitter from 'eventemitter3';
import { get } from 'svelte/store';
import { isEmpty } from 'lodash-es';
import { sharedF7 } from './store';
import { extractPathFromHref } from './util';

/**
 * Handle both f7 navbar back btn and browser back btn navigation.
 * Allow programmatically decide whether to stay or go back to last page.
 * Vanilla f7 back router doesn't preserve url, this implementation does.
 *
 * Allow code to handle when back navigation is initiated via callback.
 * Allow code to handle whether to block navigation via svelte store variable.
 */

// Emit an back event so each listener will get notified.
const ee = new EventEmitter();
/**
 * Call for each route that uses before leave, to capture back navigation via back btn on navbar.
 */
export function beforeLeave({ to, from, resolve, reject, direction, router, app }) {
  ee.emit('f7-confirm-back', {
    to,
    from,
    resolve,
    reject,
    direction,
    router,
    app,
    fromRouter: true,
    href: window.location.href,
  });
}

function getHrefPath() {
  return extractPathFromHref(window.location.href);
}

/**
 * Setup f7 navbar and browser back navigation checkpoint.
 * Allow programmatically decide whether to return to last page when user request to navigate back.
 * Can call without param to setup as always pass.
 * @param {import('svelte/store').Writable<boolean>} showConfirm Svelte store, true to stop back navigation, false to allow back navigation.
 * @param {function} cb Async callback function when back navigation is initiated.
 */
export function setupBeforeLeave(showConfirm, cb = async () => {}) {
  if (!showConfirm) {
    const alwaysPass = (e) => {
      if (e.fromRouter) {
        cb();
        e.resolve();
      }
    };
    onMount(() => {
      window.addEventListener('popstate', alwaysPass);
      ee.addListener('f7-confirm-back', alwaysPass);
    });
    onDestroy(() => {
      window.removeEventListener('popstate', alwaysPass);
      ee.removeListener('f7-confirm-back', alwaysPass);
    });
    return;
  }

  const confirmUnsubscribeFn = showConfirm.subscribe((shouldShowConfirm) => {
    // Set f7 native history api's block popstate to true to stop it from recording any pop state event when our custom pushstate is running
    // to prevent it from recording bad state, and auto retry state changes after navigation (the click open then auto close again bug).
    if (!isEmpty(get(sharedF7))) {
      if (shouldShowConfirm) {
        get(sharedF7).modules.history.static.history.blockPopstate = true;
      } else {
        get(sharedF7).modules.history.static.history.blockPopstate = false;
      }
    }
  });

  // Handle stopping user from leaving the page when there are unsaved stuff.
  // https://forum.framework7.io/t/beforeleave-in-svelte/11107
  let initialStartHref;
  const confirmBeforeBack = (e) => {
    // For example when user open other input while on the page which changes the url, or when closing the input, for example date input.
    // Skip f7 navbar back if it is not for us.  "http://localhost:3000/#!/filter/range/date/" -> "/filter/range/date/" !== e.to.path ("/filter/range/")
    if (
      e.fromRouter &&
      getHrefPath() !== e.to.path &&
      // Current path not parent of the target path, aka it is not returning to last page from current target page.
      !initialStartHref.includes(e.to.path)
    ) {
      // console.log('SKipped navbar', getHrefPath(), e.to.path, e);
      return e.resolve();
    }
    // Skip browser navigate back if it is not for us.
    if (!e.fromRouter && window.location.href === initialStartHref) {
      // console.log('Skipped browser back', window.location.href);
      return;
    }

    cb().then(() => {
      const shouldShowConfirm = get(showConfirm);
      if (shouldShowConfirm) {
        // From f7 navbar back btn, reject means do not go back to last page.
        if (e.fromRouter) {
          e.reject();
        }
        // From browser back btn, push state to remain at current page.
        else {
          window.history.pushState(null, document.title, initialStartHref);
          // console.log('Pushed state');
        }
      } else {
        // From f7 navbar back btn, resolve means go back to last page normally.
        if (e.fromRouter) {
          // console.log('Go back to last page normally');
          e.resolve();
        }
        // From browser back btn, go back to last page normally.
        else {
          // console.log('Go back to last page browser');
        }
      }
    });
  };
  onMount(() => {
    window.addEventListener('popstate', confirmBeforeBack);
    ee.addListener('f7-confirm-back', confirmBeforeBack);
    tick().then(() => {
      initialStartHref = window.location.href;
    });
  });
  onDestroy(() => {
    window.removeEventListener('popstate', confirmBeforeBack);
    ee.removeListener('f7-confirm-back', confirmBeforeBack);
    confirmUnsubscribeFn();
  });
}
