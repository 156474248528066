<script>
  import { Navbar } from 'framework7-svelte';
  import {
    selectedDesk,
    selectedDeskUserInfo,
    hasInternetConnection,
    mainViewNavigate,
  } from '../js/store';
  import { uccPlaceholderLogo, deskAdminIcon } from '../js/config';
  import SquareImage from './SquareImage.svelte';

  let topRightImage = '';
  $: if ($hasInternetConnection) {
    if ($selectedDeskUserInfo.role === 'admin') {
      topRightImage = deskAdminIcon;
    } else {
      topRightImage = '';
    }
  } else {
    topRightImage = 'static/images/broken-chain.svg';
  }
</script>

<!-- Use navbar instead of pure f7 class as it provides fixed position positioning. -->
<Navbar noShadow>
  <div slot="default" class="flex w-full">
    <!-- Follow f7 list item spacing and override navbar default padding -->
    <div
      class="cursor-pointer select-none ripple relative"
      style="padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left) - calc(var(--f7-navbar-inner-padding-left) + var(--f7-safe-area-left));"
      on:click="{() => {
        mainViewNavigate('/desk');
      }}"
    >
      <SquareImage
        src="{$selectedDesk.profilePic ? $selectedDesk.profilePic : uccPlaceholderLogo}"
      />
    </div>
    <div
      class="flex-grow self-center truncate leading-tight"
      style="margin-left: var(--f7-list-item-media-margin); margin-right: var(--f7-list-item-media-margin);"
    >
      <div class="truncate ios-select-none">{$selectedDesk.name || 'Loading'}</div>
      <div class="truncate ios-select-none" style="color: var(--f7-navbar-subtitle-text-color);">
        {$selectedDesk.slogan || ''}
      </div>
    </div>
    <div
      class="cursor-pointer mr-2 ripple relative"
      on:click="{() => {
        mainViewNavigate('/user');
      }}"
    >
      <SquareImage
        rounded
        src="{$selectedDeskUserInfo.profilePic || uccPlaceholderLogo}"
        topRight="{topRightImage ? { src: topRightImage, small: true, shadow: true } : {}}"
      />
    </div>
  </div>
</Navbar>
